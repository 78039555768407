import React from 'react';

import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  List,
  Button as ReactAdminButton,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  regex,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import {
  GenreBasicsFragment,
  useSyncContentGenresMutation,
} from '../../graphql/types';
import { EnvLevel, getEnvLevel } from '../../utils/getEnvLevel';

import ClipboardField from './components/ClipboardField';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const charactersValidator = regex(
  /^[a-zA-Z0-9 \-_~%]+$/,
  `Only alphanumeric characters, spaces, and -_~ % are allowed`,
);

const GenreActions: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  const [syncContentGenres] = useSyncContentGenresMutation();

  const handleSyncButtonClick = async () => {
    setLoading(true);
    await syncContentGenres();
    refresh();
    setLoading(false);
  };

  return (
    <TopToolbar>
      <CreateButton />
      {(getEnvLevel() === EnvLevel.DEV ||
        getEnvLevel() === EnvLevel.TESTING) && (
        <ReactAdminButton
          label="Sync from Prod"
          disabled={loading}
          onClick={handleSyncButtonClick}
        >
          <SyncAltIcon />
        </ReactAdminButton>
      )}
    </TopToolbar>
  );
};

export const GenreList: React.FC = () => (
  <List pagination={false} actions={<GenreActions />}>
    {(getEnvLevel() === EnvLevel.DEV || getEnvLevel() === EnvLevel.TESTING) && (
      <div style={{ paddingLeft: '16px' }}>
        <p>
          <strong>Note:</strong> You are in the dev environment. Any genres
          added here will only show up in the dev app, they will not be
          available in Storyblok.
        </p>
        <p>
          To add a new genre, first add it in production and then use the
          &quot;Sync from Prod&quot; button.
        </p>
      </div>
    )}
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <ClipboardField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <BooleanField source="isHidden" label="Hidden" sortable={false} />
      <TextField source="numberChildren" label="Children" sortable={false} />
      <WriteOnlyEditButton />
      <ShowButton />
    </WriteOnlyDatagrid>
  </List>
);

const GenreShowComponent: React.FC = () => {
  const record = useRecordContext<GenreBasicsFragment>();
  return (
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="value" label="Value" />
      <BooleanField source="isHidden" label="Hidden" />
      {record?.parent && (
        <ReferenceField label="Parent" reference="Genre" source="parent.id">
          <TextField source="name" />
        </ReferenceField>
      )}
      {!record?.parent && (
        <ReferenceArrayField
          source="children.ids"
          reference="Genre"
          label="Subgenres"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" />
            <BooleanField source="isHidden" label="Hidden" sortable={false} />
            <ShowButton />
          </Datagrid>
        </ReferenceArrayField>
      )}
    </SimpleShowLayout>
  );
};

export const GenreShow: React.FC = () => (
  <Show>
    <GenreShowComponent />
  </Show>
);

const CreateChildGenreButton = () => {
  const record = useRecordContext();
  return (
    <CreateButton
      resource="Genre"
      label="New Subgenre"
      state={{ record: { parentId: record?.id } }}
    />
  );
};

const GenreEditToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const GenreEditComponent: React.FC = () => {
  const record = useRecordContext<GenreBasicsFragment>();
  const isRoot = record?.parent === null;

  return (
    <SimpleForm toolbar={<GenreEditToolbar />}>
      <TextInput source="id" InputProps={{ disabled: true }} />
      {!isRoot && <TextInput source="parent.name" label="Parent" disabled />}
      <TextInput source="name" label="Name" validate={charactersValidator} />
      <TextInput source="value" label="Value" validate={charactersValidator} />
      <BooleanInput source="isHidden" label="Hidden" />
      {isRoot && (
        <>
          <span>Subgenres</span>
          <ReferenceArrayField
            source="children.ids"
            reference="Genre"
            label="Subgenres"
          >
            <Datagrid bulkActionButtons={false}>
              <TextField source="name" />
              <BooleanField source="isHidden" label="Hidden" sortable={false} />
              <ShowButton />
            </Datagrid>
          </ReferenceArrayField>
          <CreateChildGenreButton />
        </>
      )}
    </SimpleForm>
  );
};

export const GenreEdit: React.FC = () => (
  <Edit redirect={false}>
    <GenreEditComponent />
  </Edit>
);

const GenreCreateComponent: React.FC = () => {
  const record = useRecordContext<
    GenreBasicsFragment & { parentId?: string }
  >();
  const parentId = record?.parentId;
  return (
    <SimpleForm>
      {parentId && (
        <ReferenceInput
          label="Parent"
          reference="Genre"
          link="show"
          source="parentId"
        >
          <SelectInput optionText="name" optionValue="id" readOnly />
        </ReferenceInput>
      )}
      <TextInput
        source="name"
        label="Name"
        validate={charactersValidator}
        helperText="User-facing name of the genre, ex. 'Recharge Breaks'"
      />
      <TextInput
        source="value"
        label="Value"
        validate={charactersValidator}
        helperText="Value is used in URLs/deeplinks, ex. 'recharge_breaks'"
      />
      <BooleanInput
        source="isHidden"
        label="Hidden"
        defaultValue
        helperText="Hidden genres are visible in Storyblok but not in-app"
      />
    </SimpleForm>
  );
};

export const GenreCreate: React.FC = () => (
  <Create>
    <GenreCreateComponent />
  </Create>
);
