/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';

import { ZoneId, ZonedDateTime } from '@js-joda/core';
import {
  CheckCircleOutline,
  ErrorOutline,
  Link as LinkIcon,
  OpenInNew,
  Save,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Slider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { CreatedUser, TestDataService } from '@teamexos/test-data-service';
import debounce from 'lodash/debounce';
import { Link } from 'react-admin';
import { Controller, useForm } from 'react-hook-form';

import { clientOptions } from '../../../apollo/client';
import {
  CoachTouchPointType,
  CoachType,
  useAdminGetUserByEmailLazyQuery,
  useAdminListDomainsQuery,
} from '../../../graphql/types';
import { EnvLevel, getEnvLevel } from '../../../utils/getEnvLevel';
import Errors from '../../reusable/ErrorMessage';
import { Divider } from '../components/Divider';

import {
  getCoachTrainingMembers,
  getHpCoachTrainingMembers,
  getTaskCoachTrainingMembers,
} from './data/coachTraining';

interface CreateCoachFields {
  hpMembers: boolean;
  taskMembers: boolean;
  addActivityItems: boolean;
  completeOnboardingSurveys: boolean;
  createGameplan: boolean;
  createTask: boolean;
  submitDailyCheckIn: boolean;
  assignPractice: boolean;
  assignProgram: boolean;

  manualActivityCount: string;
  sleepActivityCount: string;

  memberCreationStrategy: 'random' | 'predefined';

  coachEmail: string;
  coachFirstName: string;
  coachLastName: string;
  memberCount: string;
}

const getCoachHubUrl = () => {
  const nodeEnv = process.env.NODE_ENV;
  const envLevel = getEnvLevel();

  if (envLevel === EnvLevel.PROD) {
    throw new Error('Not supported in prod');
  }

  if (nodeEnv === 'development') {
    return 'http://localhost:8000';
  }

  return `https://coach.${envLevel}.prince.ex-os.net`;
};

interface MemberCreationStatusItem {
  label: string;
  status: 'loading' | 'done' | 'error';
  message?: string;
}

export const CreateCoach = () => {
  const [saving, setSaving] = useState(false);
  const [createdCoach, setCreatedCoach] = useState<CreatedUser>();
  const [createdMembers, setCreatedMembers] = useState<CreatedUser[]>();
  const [errors, setErrors] = useState<string[]>([]);
  const [memberCreationStatus, setMemberCreationStatus] = useState<
    MemberCreationStatusItem[]
  >([]);
  const [memberCreationProgress, setMemberCreationProgress] = useState<
    number[]
  >([]);
  const methods = useForm<CreateCoachFields>({
    defaultValues: {
      hpMembers: false,
      taskMembers: false,
      addActivityItems: true,
      completeOnboardingSurveys: true,
      createGameplan: true,
      createTask: true,
      submitDailyCheckIn: true,
      assignPractice: true,
      assignProgram: true,

      manualActivityCount: '2',
      sleepActivityCount: '2',

      memberCreationStrategy: 'predefined',

      coachEmail: '',
      coachFirstName: '',
      coachLastName: '',
      memberCount: '1',
    },
  });
  const [getCoachByEmail, { loading: loadingCoachByEmail }] =
    useAdminGetUserByEmailLazyQuery();
  const { data: testUserDomainData } = useAdminListDomainsQuery({
    variables: {
      filter: {
        name: 'testuser.ex-os.net',
      },
    },
  });
  const testUserClient = testUserDomainData?.data?.nodes[0].client;

  const initializeTestDataService = React.useCallback(async () => {
    const { clientUrl } = clientOptions;
    const selfToken = await clientOptions.getToken();
    if (!clientUrl || !selfToken) {
      throw new Error('clientUrl or token is not set');
    }
    return TestDataService.initTestDataGraphqlClient({
      clientUrl,
      authToken: selfToken,
    });
  }, []);

  useEffect(() => {
    (async () => {
      await initializeTestDataService();
    })();
  }, [initializeTestDataService]);

  async function logMemberCreationAction<T>(
    status: string,
    action: () => Promise<T>,
    retrying = false,
  ): Promise<T | null> {
    setMemberCreationStatus((p) => [
      { label: status, status: 'loading' },
      ...p,
    ]);
    try {
      const result = await action();
      setMemberCreationStatus((p) => [
        { label: status, status: 'done' },
        ...p.slice(1),
      ]);
      return result;
    } catch (e) {
      setMemberCreationStatus((p) => [
        { label: status, status: 'error', message: e.message },
        ...p.slice(1),
      ]);

      if (e.message.includes('Unauthorized')) {
        if (!retrying) {
          await logMemberCreationAction(
            'Token expired, reinitializing client',
            () => initializeTestDataService(),
          );
          await logMemberCreationAction(`Retrying: ${status}`, action, true);
        } else {
          setErrors((p) => [...p, 'Could not reinitialize client']);
        }
      }

      return null;
    }
  }

  const createRandomMembers = async (
    values: CreateCoachFields,
    coach: CreatedUser,
  ) => {
    const memberCount = parseInt(values.memberCount, 10);
    setMemberCreationProgress([0, memberCount]);
    for await (const index of new Array(memberCount)) {
      const member = await logMemberCreationAction('Creating member', () =>
        TestDataService.createUser({
          hasHighPerformanceCoaching: values.hpMembers,
        }),
      );

      if (!member) {
        setErrors((p) => [...p, `Failed to create member #${index + 1}`]);
        return;
      }

      await logMemberCreationAction('Assigning member to coach', () =>
        TestDataService.assignFitMemberToCoach({
          coachId: coach.id,
          memberId: member.id,
        }),
      );

      if (values.assignPractice) {
        await logMemberCreationAction('Assigning practice', async () => {
          await TestDataService.assignPractice({
            memberId: member.id,
          });
        });
      }

      if (values.assignProgram) {
        await logMemberCreationAction('Assigning program', async () => {
          await TestDataService.assignProgram({
            memberId: member.id,
          });
        });
      }

      if (values.hpMembers) {
        if (values.submitDailyCheckIn) {
          await logMemberCreationAction(
            'Submitting daily check-in',
            async () => {
              await TestDataService.createSurvey({
                memberId: member.id,
                input: {
                  surveyName: 'Daily Check-In',
                  surveyIdentifier: 'SV_dou5ONzvSeShFUq',
                },
              });
            },
          );
        }

        if (values.createGameplan) {
          await logMemberCreationAction('Creating custom plan', () =>
            TestDataService.createUserPlan(member.id),
          );
        }
      }

      if (values.createTask) {
        await logMemberCreationAction('Creating task', () =>
          TestDataService.createCoachTouchPoint({
            coachId: coach.id,
            memberId: member.id,
            type: CoachTouchPointType.Custom,
            description: 'Custom task',
            title: 'Custom task',
            due: ZonedDateTime.now(ZoneId.UTC).toString(),
          }),
        );
      }

      if (values.completeOnboardingSurveys) {
        await logMemberCreationAction('Completing onboarding surveys', () =>
          TestDataService.completeOnboardingSurveys({
            memberId: member.id,
            hasHighPerformanceCoaching: values.hpMembers,
          }),
        );
      }

      if (values.addActivityItems) {
        if (values.hpMembers) {
          await logMemberCreationAction('Creating sleep data', () =>
            TestDataService.createSleepData(member.id, {
              count: parseInt(values.sleepActivityCount, 10),
            }),
          );
        }

        await logMemberCreationAction('Creating manual activies', () =>
          TestDataService.createManualActivities({
            memberId: member.id,
            count: parseInt(values.manualActivityCount, 10),
          }),
        );

        await logMemberCreationAction('Creating survey response', () =>
          TestDataService.createSurvey({
            memberId: member.id,
          }),
        );
      }

      if (member) {
        setCreatedMembers((prev) => [...(prev || []), member]);
        setMemberCreationProgress((prev) => [prev[0] + 1, memberCount]);
      }
    }
  };

  const createPredefinedMembers = async (
    values: CreateCoachFields,
    coach: CreatedUser,
  ) => {
    const nutritionist = await logMemberCreationAction(
      'Creating nutritionist',
      () =>
        TestDataService.createUser({
          isCoach: true,
          coachData: {
            type: CoachType.Dietitian,
          },
        }),
    );

    const memberData = getCoachTrainingMembers({
      coachId: coach.id,
      nutritionistId: nutritionist?.id,
    });

    if (values.hpMembers) {
      memberData.push(
        ...getHpCoachTrainingMembers({
          coachId: coach.id,
        }),
      );
    }

    if (values.taskMembers) {
      memberData.push(
        ...getTaskCoachTrainingMembers({
          coachId: coach.id,
        }),
      );
    }

    setMemberCreationProgress([0, memberData.length]);

    for await (const member of memberData) {
      try {
        const createdMember = await TestDataService.createDemoUser(
          member,
          logMemberCreationAction,
        );
        setCreatedMembers((prev) => [...(prev || []), createdMember]);
      } catch {
        setErrors((p) => [...p, `Failed to create member`]);
      } finally {
        setMemberCreationProgress(([prevIndex]) => [
          prevIndex + 1,
          memberData.length,
        ]);
        // setMemberCreationStatus({});
      }
    }
  };

  const handleSubmit = async (values: CreateCoachFields) => {
    setSaving(true);
    setErrors([]);
    setCreatedCoach(undefined);
    setCreatedMembers(undefined);
    setMemberCreationProgress([]);
    setMemberCreationStatus([]);

    try {
      const coach = await logMemberCreationAction('Creating coach', () =>
        TestDataService.createUser({
          email: values.coachEmail,
          firstName: values.coachFirstName,
          lastName: values.coachLastName,
          isCoach: true,
        }),
      );

      if (coach && testUserClient) {
        await logMemberCreationAction(
          `Assigning coach to ${testUserClient.name} client`,
          () =>
            TestDataService.assignCoachToClient({
              coachId: coach.id,
              clientId: testUserClient.id,
            }),
        );
      }

      if (!coach) {
        setErrors((p) => [...p, 'Failed to create coach']);
        return;
      }

      setCreatedCoach(coach);
      // setMemberCreationStatus({});

      if (values.memberCreationStrategy === 'random') {
        await createRandomMembers(values, coach);
      } else if (values.memberCreationStrategy === 'predefined') {
        await createPredefinedMembers(values, coach);
      }
    } finally {
      setSaving(false);
    }
  };

  const addActivityItemsWatcher = methods.watch('addActivityItems');
  const hpWatcher = methods.watch('hpMembers');
  const randomDataWatcher = methods.watch('memberCreationStrategy');

  const fetchAndSetCoach = debounce(async (email: string) => {
    const { data } = await getCoachByEmail({
      variables: {
        email,
      },
    });
    methods.setValue('coachFirstName', data?.data?.firstName || '');
    methods.setValue('coachLastName', data?.data?.lastName || '');
  }, 500);

  return (
    <Box>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4">Create Coach</Typography>
        <Typography variant="body1">
          Fill out information for the new coach to create an account, or enter
          the email of an existing coach to add members to their account.
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handleSubmit)}
          display="flex"
          flexDirection="column"
          alignItems="start"
          width="40%"
          gap={2}
          sx={{ mb: 2 }}
        >
          <Controller
            control={methods.control}
            name="coachEmail"
            render={({ field }) => (
              <TextField
                onChange={(e) => {
                  field.onChange(e);
                  fetchAndSetCoach(e.target.value);
                }}
                name={field.name}
                value={field.value}
                fullWidth
                placeholder="Leave blank for generated email"
                label="Coach Email"
              />
            )}
          />
          <Controller
            control={methods.control}
            name="coachFirstName"
            render={({ field }) => (
              <TextField
                onChange={field.onChange}
                name={field.name}
                value={loadingCoachByEmail ? '' : field.value}
                fullWidth
                label="Coach First Name"
                disabled={loadingCoachByEmail}
                InputProps={{
                  startAdornment: loadingCoachByEmail ? (
                    <CircularProgress size={20} />
                  ) : null,
                }}
              />
            )}
          />
          <Controller
            control={methods.control}
            name="coachLastName"
            render={({ field }) => (
              <TextField
                onChange={field.onChange}
                name={field.name}
                value={loadingCoachByEmail ? '' : field.value}
                fullWidth
                label="Coach last name"
                disabled={loadingCoachByEmail}
                InputProps={{
                  startAdornment: loadingCoachByEmail ? (
                    <CircularProgress size={20} />
                  ) : null,
                }}
              />
            )}
          />
          <Typography fontSize="small">
            <b>Note:</b> If any fields are left blank, a randomly generated
            value will be used.
          </Typography>

          <Divider />

          <Controller
            control={methods.control}
            name="memberCreationStrategy"
            render={({ field }) => (
              <FormControl>
                <FormLabel>Member Creation Strategy</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <FormControlLabel
                    value="predefined"
                    control={<Radio />}
                    label={`Coach Training Data (${getCoachTrainingMembers({ coachId: '', nutritionistId: '' }).length} predefined members)`}
                  />
                  <FormControlLabel
                    value="random"
                    control={<Radio />}
                    label="Random"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />

          <Controller
            control={methods.control}
            name="hpMembers"
            render={({ field }) => (
              <FormControlLabel
                checked={field.value}
                onChange={field.onChange}
                name={field.name}
                value={field.value}
                control={<Switch />}
                label={`High Performance Members ${
                  randomDataWatcher === 'predefined'
                    ? `(${getHpCoachTrainingMembers({ coachId: '' }).length})`
                    : ''
                }`}
              />
            )}
          />

          {randomDataWatcher === 'predefined' && (
            <Controller
              control={methods.control}
              name="taskMembers"
              render={({ field }) => (
                <FormControlLabel
                  checked={field.value}
                  onChange={field.onChange}
                  name={field.name}
                  value={field.value}
                  control={<Switch />}
                  label={`Task Members (${getTaskCoachTrainingMembers({ coachId: '', nutritionistId: '' }).length})`}
                />
              )}
            />
          )}

          {randomDataWatcher === 'random' && (
            <>
              <Controller
                control={methods.control}
                name="memberCount"
                rules={{
                  required: 'This field is required',
                  min: {
                    value: 1,
                    message: 'Min member count is 1',
                  },
                  max: {
                    value: 20,
                    message: 'Max member count is 20',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    type="number"
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    fullWidth
                    label="Member count"
                    inputProps={{
                      min: 1,
                      max: 20,
                    }}
                  />
                )}
              />
              <Errors name="memberCount" errors={methods.formState.errors} />

              <Controller
                control={methods.control}
                name="addActivityItems"
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    control={<Switch />}
                    label="Add Activity Items"
                  />
                )}
              />
              <Controller
                control={methods.control}
                name="createTask"
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    control={<Switch />}
                    label="Add Custom Task"
                  />
                )}
              />
              <Controller
                control={methods.control}
                name="completeOnboardingSurveys"
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    control={<Switch />}
                    label="Complete Onboarding Surveys"
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="assignPractice"
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    control={<Switch />}
                    label="Assign a practice"
                  />
                )}
              />

              <Controller
                control={methods.control}
                name="assignProgram"
                render={({ field }) => (
                  <FormControlLabel
                    checked={field.value}
                    onChange={field.onChange}
                    name={field.name}
                    value={field.value}
                    control={<Switch />}
                    label="Assign a program"
                  />
                )}
              />

              {hpWatcher && (
                <>
                  <Controller
                    control={methods.control}
                    name="createGameplan"
                    render={({ field }) => (
                      <FormControlLabel
                        checked={field.value}
                        onChange={field.onChange}
                        name={field.name}
                        value={field.value}
                        control={<Switch />}
                        label="Create demo gameplan"
                      />
                    )}
                  />
                  <Controller
                    control={methods.control}
                    name="submitDailyCheckIn"
                    render={({ field }) => (
                      <FormControlLabel
                        checked={field.value}
                        onChange={field.onChange}
                        name={field.name}
                        value={field.value}
                        control={<Switch />}
                        label="Submit a daily check-in"
                      />
                    )}
                  />
                </>
              )}

              {addActivityItemsWatcher && (
                <Box width="100%">
                  <Typography>Manual Activity Count</Typography>
                  <Controller
                    control={methods.control}
                    name="manualActivityCount"
                    render={({ field }) => (
                      <Slider
                        onChange={field.onChange}
                        name={field.name}
                        value={parseInt(field.value, 10)}
                        min={0}
                        max={20}
                        valueLabelDisplay="auto"
                      />
                    )}
                  />
                  {hpWatcher && (
                    <>
                      <Typography>Sleep Activity Count</Typography>
                      <Controller
                        control={methods.control}
                        name="sleepActivityCount"
                        render={({ field }) => (
                          <Slider
                            onChange={field.onChange}
                            name={field.name}
                            value={parseInt(field.value, 10)}
                            min={0}
                            max={20}
                            valueLabelDisplay="auto"
                          />
                        )}
                      />
                    </>
                  )}
                </Box>
              )}
            </>
          )}

          <Button
            type="submit"
            startIcon={<Save />}
            variant="contained"
            disabled={saving}
          >
            Create
          </Button>
        </Box>

        <Box
          bgcolor="#F0F0F0"
          width="60%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            borderRadius: 2,
            gap: 2,
          }}
        >
          {createdCoach && (
            <>
              <Typography variant="h4">Created Coach</Typography>
              <Link to={`/User/${createdCoach.id}/show`}>
                {createdCoach.email}
              </Link>
            </>
          )}
          {createdMembers && (
            <Typography variant="h4">
              Created Members{' '}
              {memberCreationProgress[1] && <>({memberCreationProgress[1]})</>}
            </Typography>
          )}

          {errors.length > 0 && (
            <Box>
              {errors.map((error) => (
                <Typography key={error} color="error" fontSize="small">
                  Error: {error}
                </Typography>
              ))}
              <Typography fontSize="small">
                An error occurred, refresh the page and try again. If the issue
                persists, please contact support.
              </Typography>
            </Box>
          )}
          {Object.keys(memberCreationStatus || {}).length > 0 && (
            <>
              <Box maxHeight="200px" overflow="auto">
                {memberCreationProgress.length > 0 && (
                  <Typography>
                    {memberCreationProgress[0]} / {memberCreationProgress[1]}
                  </Typography>
                )}
                {memberCreationStatus.map(({ label, status, message }) => (
                  <Box
                    key={label + status}
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                    gap={1}
                  >
                    <Typography> {label}</Typography>
                    {
                      {
                        loading: <CircularProgress size={14} />,
                        done: (
                          <CheckCircleOutline
                            fontSize="small"
                            color="success"
                          />
                        ),
                        error: (
                          <Tooltip title={message}>
                            <ErrorOutline fontSize="small" color="error" />
                          </Tooltip>
                        ),
                      }[status]
                    }
                  </Box>
                ))}
              </Box>
              <LinearProgress
                variant="determinate"
                value={
                  (memberCreationProgress[0] / memberCreationProgress[1]) * 100
                }
              />
            </>
          )}
          {createdMembers && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Admin Page</TableCell>
                  <TableCell>CH Profile</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {createdMembers.map((member) => (
                  <TableRow key={member.id}>
                    <TableCell>{member.email}</TableCell>
                    <TableCell>
                      {member.firstName} {member.lastName}
                    </TableCell>
                    <TableCell>
                      <Link to={`/User/${member.id}/show`} key={member.id}>
                        <LinkIcon />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        rel="noopener noreferrer"
                        target="_blank"
                        to={`${getCoachHubUrl()}/app/members/${member.id}`}
                        key={member.id}
                      >
                        <OpenInNew fontSize="small" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Box>
      </Box>
    </Box>
  );
};
