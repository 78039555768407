import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Duration: { input: any; output: any };
  JSONObject: {
    input: Record<string, unknown>;
    output: Record<string, unknown>;
  };
  LocalDate: { input: string; output: string };
  LocalDateTime: { input: string; output: string };
  LocalTime: { input: string; output: string };
  ZoneId: { input: string; output: string };
  ZonedDateTime: { input: string; output: string };
};

export type ActiveCustomPlanDay = {
  __typename?: 'ActiveCustomPlanDay';
  date: Scalars['LocalDate']['output'];
  itemCompletedCount: Scalars['Int']['output'];
  itemCount: Scalars['Int']['output'];
};

export type Activity = {
  date: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  program?: Maybe<Program>;
  progress: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  trendType?: Maybe<ActivityTrendType>;
  type: ActivityType;
};

export type ActivityAggregates = {
  __typename?: 'ActivityAggregates';
  /** count of activities completed excluding sleep */
  activities: Scalars['Int']['output'];
  /** selected date range */
  date?: Maybe<ZonedDateTimeRange>;
  /** count of days that had at least 1 activity */
  days: Scalars['Int']['output'];
  /** count of real activity minutes excluding sleep */
  minutes: Scalars['Int']['output'];
  minutesAsleepPerDayAverage: Scalars['Int']['output'];
  restingHeartRatePerDayAverage: Scalars['Int']['output'];
};

export type ActivityAttribution = AssignedPractice | AssignedProgram;

export type ActivityConnection = Connection & {
  __typename?: 'ActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ActivityEdge = Edge & {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ActivityFilter = {
  includeIncomplete?: InputMaybe<Scalars['Boolean']['input']>;
  trendType?: InputMaybe<Array<InputMaybe<ActivityTrendType>>>;
  type?: InputMaybe<Array<InputMaybe<ActivityType>>>;
};

export type ActivityGenre = {
  __typename?: 'ActivityGenre';
  canBeAddedManually: Scalars['Boolean']['output'];
  canHaveDistance: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ActivityGenresFilter = {
  canBeAddedManually?: InputMaybe<Scalars['Boolean']['input']>;
  canHaveDistance?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityPayload = {
  __typename?: 'ActivityPayload';
  activity: Activity;
  user: User;
};

export type ActivitySource =
  | Article
  | Audio
  | MboClass
  | PhysicalActivity
  | Survey
  | Video
  | WearableActivity;

export enum ActivityTrendType {
  Classes = 'classes',
  ManualEntry = 'manual_entry',
  Practice = 'practice',
  Program = 'program',
  Sleep = 'sleep',
  /** @deprecated training plans have been retired */
  TrainingPlan = 'training_plan',
  Wearables = 'wearables',
}

export enum ActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  Virtual = 'Virtual',
  Vod = 'Vod',
  /** @deprecated use wearable sdk specific type */
  Wearable = 'Wearable',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthConnectSleep = 'WearableHealthConnectSleep',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type AddPerformanceQualityScoresPayload = {
  __typename?: 'AddPerformanceQualityScoresPayload';
  performanceQualityScores: Array<PerformanceQualityScore>;
};

export type AddReactionInput = {
  kind: ReactionKind;
  messageId: Scalars['ID']['input'];
};

export type AddUserToStudioPayload = {
  __typename?: 'AddUserToStudioPayload';
  studio: Studio;
  user: User;
};

export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressLine3?: Maybe<Scalars['String']['output']>;
  addressLine4?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  localityOrCity?: Maybe<Scalars['String']['output']>;
  postalOrZipCode?: Maybe<Scalars['String']['output']>;
  stateOrDistrictOrProvince?: Maybe<Scalars['String']['output']>;
};

export type AdminBackfillAssessmentAttributionTypeInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminChangeAuthProviderInput = {
  currentEmail: Scalars['String']['input'];
  idpUsername: Scalars['String']['input'];
  provider?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateUserInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  setTestPassword?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Answer = EnumAnswer | NumberAnswer | PhotoAnswer | StringAnswer;

export type AnswerSurveyInput = {
  answers: Array<SurveyResponseInput>;
  assessmentId?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  surveyIdentifier: Scalars['String']['input'];
  surveyName?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum App {
  Heart = 'heart',
  Prince = 'prince',
}

export type Article = Content &
  Node & {
    __typename?: 'Article';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ArticleRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ArticleActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'ArticleActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Article>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type ArticlesConnection = Connection & {
  __typename?: 'ArticlesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ArticlesEdge>;
  nodes: Array<Article>;
  pageInfo: PageInfo;
};

export type ArticlesEdge = Edge & {
  __typename?: 'ArticlesEdge';
  cursor: Scalars['String']['output'];
  node: Article;
};

export type AssessmentAssignment = Node & {
  __typename?: 'AssessmentAssignment';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  score?: Maybe<Scalars['Float']['output']>;
  survey?: Maybe<Survey>;
  surveyContent: InAppSurvey;
  template: SurveyTemplate;
};

export type AssessmentSurvey = Node & {
  __typename?: 'AssessmentSurvey';
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type AssessmentSurveyEdge = Edge & {
  __typename?: 'AssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentSurvey;
};

export type AssessmentSurveysConnection = Connection & {
  __typename?: 'AssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<AssessmentSurveyEdge>;
  nodes: Array<AssessmentSurvey>;
  pageInfo: PageInfo;
};

export enum AssessmentType {
  Coach = 'Coach',
  Member = 'Member',
  NutritionSpecialist = 'NutritionSpecialist',
}

export type AssignAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  /** If true, will allow admin users in the dev environment to override assignment date */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  memberId: Scalars['ID']['input'];
  surveyContentIds: Array<Scalars['ID']['input']>;
};

export type AssignAssessmentsPayload = {
  __typename?: 'AssignAssessmentsPayload';
  assessments: Array<AssessmentAssignment>;
};

export type AssignFitCoachPayload = {
  __typename?: 'AssignFitCoachPayload';
  coach: User;
  user: User;
};

export type AssignPracticePayload = {
  __typename?: 'AssignPracticePayload';
  assignedPractice: AssignedPractice;
};

export type AssignProgramPayload = {
  __typename?: 'AssignProgramPayload';
  assignedProgram: AssignedProgram;
};

export type AssignedPractice = {
  __typename?: 'AssignedPractice';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  completionNotified: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  practiceProgram?: Maybe<PracticeProgram>;
  user: User;
};

export type AssignedProgram = Node & {
  __typename?: 'AssignedProgram';
  assigningCoach?: Maybe<User>;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isUnenrolled: Scalars['Boolean']['output'];
  program?: Maybe<Program>;
  user: User;
};

export type Audio = Content &
  Node & {
    __typename?: 'Audio';
    audio: Scalars['String']['output'];
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    movementProgressions: MovementProgressionsConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    transcript?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
  };

export type AudioMovementProgressionsArgs = {
  filter?: InputMaybe<MovementProgressionsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type AudioRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type AudioActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'AudioActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Audio>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  action: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  data?: Maybe<Scalars['JSONObject']['output']>;
  id: Scalars['ID']['output'];
  principal: Principal;
};

export type AuditLogEdge = Edge & {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['String']['output'];
  node: AuditLog;
};

export type AuditLogsConnection = Connection & {
  __typename?: 'AuditLogsConnection';
  count: Scalars['Int']['output'];
  edges: Array<AuditLogEdge>;
  nodes: Array<AuditLog>;
  pageInfo: PageInfo;
};

export type Banner = Content &
  Node & {
    __typename?: 'Banner';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    snackbarText?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type BannerRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type BlockUserInput = {
  isBlocked: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type BlockUserPayload = {
  __typename?: 'BlockUserPayload';
  user?: Maybe<User>;
};

export type BookClassPayload = {
  __typename?: 'BookClassPayload';
  mboClass: MboClass;
};

export type BookSessionInput = {
  additionalInfo?: InputMaybe<Scalars['String']['input']>;
  calendlyId?: InputMaybe<Scalars['ID']['input']>;
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  coachId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  eventId?: InputMaybe<Scalars['ID']['input']>;
  eventType?: InputMaybe<Scalars['String']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  joinURL?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  sessionSubtype?: InputMaybe<CoachSessionSubtype>;
  sessionType?: InputMaybe<CoachSessionType>;
  start: Scalars['ZonedDateTime']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BookSessionPayload = {
  __typename?: 'BookSessionPayload';
  session: CoachSession;
};

export enum BotKind {
  Automessages = 'automessages',
  Methodology = 'methodology',
}

export type BulkReassignCoachMembersPayload = {
  __typename?: 'BulkReassignCoachMembersPayload';
  userCountReassigned: Scalars['Int']['output'];
  userIdsNotReassigned: Array<Scalars['String']['output']>;
};

export type Calendar = Node & {
  __typename?: 'Calendar';
  duration: Scalars['Int']['output'];
  end: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['ZonedDateTime']['output'];
};

export type CalendarBuffer = {
  __typename?: 'CalendarBuffer';
  afterInMinutes: Scalars['Int']['output'];
  beforeInMinutes: Scalars['Int']['output'];
};

export type CalendarProfile = {
  __typename?: 'CalendarProfile';
  platform?: Maybe<ClientCalendarPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileInitialSyncRequired: Scalars['Boolean']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
  providerService?: Maybe<Scalars['String']['output']>;
};

export type CancelClassPayload = {
  __typename?: 'CancelClassPayload';
  mboClass: MboClass;
};

export type Challenge = Node & {
  __typename?: 'Challenge';
  activity: ChallengesActivityConnection;
  auditLogs: AuditLogsConnection;
  background?: Maybe<Photo>;
  badge?: Maybe<Photo>;
  challengeType: ChallengeType;
  challenger?: Maybe<User>;
  clientSpecific: Scalars['Boolean']['output'];
  clients: ChallengeClientsConnection;
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  /** @deprecated Use endDateTime */
  endDate: Scalars['LocalDate']['output'];
  endDateTime: Scalars['ZonedDateTime']['output'];
  enrolled: Scalars['Boolean']['output'];
  featured: Scalars['Boolean']['output'];
  goal: Scalars['Float']['output'];
  hasSeenEarnedBadge: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isChallenger: Scalars['Boolean']['output'];
  participants: ChallengeParticipantConnection;
  place: Scalars['String']['output'];
  qualifyingContentGenres: Array<Genre>;
  /** @deprecated Use qualifyingContentGenres */
  qualifyingGenres: Array<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  scoreUnit: ScoreUnit;
  share?: Maybe<Share>;
  /** @deprecated Use startDateTime */
  startDate: Scalars['LocalDate']['output'];
  startDateTime: Scalars['ZonedDateTime']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  team: Scalars['Boolean']['output'];
  teamScore: Scalars['Float']['output'];
  teams: ChallengeTeamConnection;
  title: Scalars['String']['output'];
  /** @deprecated Use scoreUnit */
  unit: Scalars['String']['output'];
};

export type ChallengeActivityArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamsArgs = {
  filter?: InputMaybe<ChallengeTeamsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ChallengeClientsConnection = Connection & {
  __typename?: 'ChallengeClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type ChallengeClientsEdge = Edge & {
  __typename?: 'ChallengeClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type ChallengeEdge = Edge & {
  __typename?: 'ChallengeEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
};

export type ChallengeFilter = {
  excludeCustomChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeExosChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum ChallengeOrder {
  Enabled = 'enabled',
  EndDate = 'endDate',
  Featured = 'featured',
  StartDate = 'startDate',
  Title = 'title',
}

export type ChallengeParticipantConnection = Connection & {
  __typename?: 'ChallengeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChallengeParticipantsEdge = Edge & {
  __typename?: 'ChallengeParticipantsEdge';
  challengeTeam?: Maybe<ChallengeTeam>;
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: User;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeSort = {
  field: ChallengeOrder;
  order: OrderDirection;
};

export type ChallengeTeam = Node & {
  __typename?: 'ChallengeTeam';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  joined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  participants: ChallengeParticipantConnection;
};

export type ChallengeTeamParticipantsArgs = {
  filter?: InputMaybe<ParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ParticipantsOrderBy>>;
};

export type ChallengeTeamConnection = Connection & {
  __typename?: 'ChallengeTeamConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeTeamsEdge>;
  nodes: Array<ChallengeTeam>;
  pageInfo: PageInfo;
};

export type ChallengeTeamsEdge = Edge & {
  __typename?: 'ChallengeTeamsEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: ChallengeTeam;
  place: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type ChallengeTeamsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  excludeTeamsWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChallengeTemplate = Node & {
  __typename?: 'ChallengeTemplate';
  background: Photo;
  badge: Photo;
  challengeType?: Maybe<ChallengeType>;
  endDate?: Maybe<Scalars['LocalDate']['output']>;
  goal?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  qualifyingGenres?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  startDate?: Maybe<Scalars['LocalDate']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  unit?: Maybe<UnitsPreference>;
};

export type ChallengeTemplateEdge = Edge & {
  __typename?: 'ChallengeTemplateEdge';
  cursor: Scalars['String']['output'];
  node: ChallengeTemplate;
};

export enum ChallengeType {
  ClassCount = 'ClassCount',
  Distance = 'Distance',
  Time = 'Time',
}

export type ChallengesActivityConnection = Connection & {
  __typename?: 'ChallengesActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengesActivityEdge>;
  nodes: Array<Activity>;
  pageInfo: PageInfo;
};

export type ChallengesActivityEdge = Edge & {
  __typename?: 'ChallengesActivityEdge';
  cursor: Scalars['String']['output'];
  node: Activity;
};

export type ChallengesFilter = {
  excludeActiveChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeEarnedBadgesSeen?: InputMaybe<Scalars['Boolean']['input']>;
  excludeFutureChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludeIncompleteChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  excludePastChallenges?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ChatRoom = Node & {
  __typename?: 'ChatRoom';
  app?: Maybe<App>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  /** @deprecated No longer supported */
  isStarred: Scalars['Boolean']['output'];
  kind: ChatRoomKind;
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  lastMessageReceivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  messages: ChatRoomMessagesConnection;
  name?: Maybe<Scalars['String']['output']>;
  users: ChatRoomUsersConnection;
};

export type ChatRoomMessagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export enum ChatRoomKind {
  Bot = 'bot',
  Direct = 'direct',
  Generic = 'generic',
  /** @deprecated No longer supported */
  Team = 'team',
}

export type ChatRoomMessagesConnection = Connection & {
  __typename?: 'ChatRoomMessagesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomMessagesEdge>;
  nodes: Array<Message>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type ChatRoomMessagesEdge = Edge & {
  __typename?: 'ChatRoomMessagesEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type ChatRoomUsersConnection = Connection & {
  __typename?: 'ChatRoomUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChatRoomUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ChatRoomUsersEdge = Edge & {
  __typename?: 'ChatRoomUsersEdge';
  cursor: Scalars['String']['output'];
  lastMessageRead?: Maybe<Scalars['ZonedDateTime']['output']>;
  node: User;
};

export type ChatRoomsFilter = {
  apps?: InputMaybe<Array<App>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  createdAtDateRange?: InputMaybe<DateRange>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
  name?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum ChatRoomsOrder {
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LastPerformActivity = 'lastPerformActivity',
  UserJoinedAt = 'userJoinedAt',
  /** @deprecated use lastFitActivity or lastPerformActivity */
  UserLastActivity = 'userLastActivity',
}

export type ChatRoomsOrderBy = {
  direction: OrderDirection;
  order: ChatRoomsOrder;
};

export type ClickThroughMedia = Content &
  Node & {
    __typename?: 'ClickThroughMedia';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    icon?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    pages: ContentConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ClickThroughMediaPagesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClickThroughMediaRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type Client = Node & {
  __typename?: 'Client';
  auditLogs: AuditLogsConnection;
  calendarPlatform?: Maybe<ClientCalendarPlatform>;
  coaches: ClientUsersConnection;
  collectsMemberData: Scalars['Boolean']['output'];
  collectsMemberDataForEEP: Scalars['Boolean']['output'];
  /** Used by admin to diagnose configuration issues */
  configurationIssues: Array<ConfigurationIssue>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  domains: DomainConnection;
  id: Scalars['ID']['output'];
  inactivityDuration?: Maybe<Scalars['Duration']['output']>;
  isArchived: Scalars['Boolean']['output'];
  logo?: Maybe<Photo>;
  maxFamilyMembers: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  needsUsToFixTheirUserNames: Scalars['Boolean']['output'];
  ssoIdentity?: Maybe<SsoIdentity>;
  /** if true, members from this client will be subject to stricter app check enforcement than other clients */
  strictAppCheck: Scalars['Boolean']['output'];
  studios: Array<Studio>;
  users: ClientUsersConnection;
  usesCustomChallenges: Scalars['Boolean']['output'];
  usesEligibility: Scalars['Boolean']['output'];
  usesEligibilityServiceType: Scalars['Boolean']['output'];
  usesFitnessCenterForEligibility: Scalars['Boolean']['output'];
  usesGlobalSignOut: Scalars['Boolean']['output'];
  usesHighPerformanceCoaching: Scalars['Boolean']['output'];
  usesMboRegistrationFlow: Scalars['Boolean']['output'];
  usesPersonalTraining: Scalars['Boolean']['output'];
  usesSSO: Scalars['Boolean']['output'];
  /** validLocations are locations with coach assignments */
  validLocations: CoachLocationsConnection;
  videoPlatform?: Maybe<ClientVideoPlatform>;
};

export type ClientAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientCoachesArgs = {
  coachName?: InputMaybe<Scalars['String']['input']>;
  excludeInvalidCoaches?: InputMaybe<Scalars['Boolean']['input']>;
  includeHighPerformance?: InputMaybe<Scalars['Boolean']['input']>;
  includeWellbeing?: InputMaybe<Scalars['Boolean']['input']>;
  input?: InputMaybe<ConnectionInput>;
  locationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  random?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<CoachType>;
};

export type ClientCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientCronofyExternalCalendarUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ClientDomainsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ClientStudiosArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  includeCentralStudio?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ClientValidLocationsArgs = {
  filter?: InputMaybe<LocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export enum ClientCalendarPlatform {
  Apple = 'apple',
  Exchange = 'exchange',
  ExosOnly = 'exos_only',
  Google = 'google',
  LiveConnect = 'live_connect',
  Office365 = 'office365',
}

export type ClientUsersConnection = Connection & {
  __typename?: 'ClientUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<ClientUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ClientUsersEdge = Edge & {
  __typename?: 'ClientUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export enum ClientVideoPlatform {
  EightByEight = 'eight_by_eight',
  GoTo = 'go_to',
  Indeed = 'indeed',
  Integrated = 'integrated',
  MsTeams = 'ms_teams',
  Zoom = 'zoom',
}

export type ClientsFilter = {
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CoachBasedActivity = {
  coachAvatar?: Maybe<Photo>;
  coachFirstName?: Maybe<Scalars['String']['output']>;
  coachLastName?: Maybe<Scalars['String']['output']>;
};

export type CoachClientsConnection = Connection & {
  __typename?: 'CoachClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type CoachClientsEdge = Edge & {
  __typename?: 'CoachClientsEdge';
  availableForAssignment: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  node: Client;
  supportsHighPerformanceCoaching: Scalars['Boolean']['output'];
  supportsWellbeingCoaching: Scalars['Boolean']['output'];
};

export type CoachClientsInput = {
  availableForAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  clientId: Scalars['ID']['input'];
  supportsHighPerformanceCoaching: Scalars['Boolean']['input'];
  supportsWellbeingCoaching: Scalars['Boolean']['input'];
};

export type CoachData = {
  __typename?: 'CoachData';
  availability: UserAvailabilityConnection;
  /** @deprecated Use coachClientConfiguration instead */
  availableForMemberAssignment: Scalars['Boolean']['output'];
  bio: Scalars['String']['output'];
  bodyPhoto?: Maybe<Photo>;
  calendarBuffer: CalendarBuffer;
  calendarConnected: Scalars['Boolean']['output'];
  calendarProfiles: Array<CalendarProfile>;
  calendlyUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated use coachClients instead */
  clients: RootClientsConnection;
  coachClients: CoachClientsConnection;
  coachUsers: CoachUsersConnection;
  coachingStyle?: Maybe<CoachingStyle>;
  conferencingProfiles: Array<ConferencingProfile>;
  cronofyAuthUrl?: Maybe<Scalars['String']['output']>;
  cronofyConferencingUrl?: Maybe<Scalars['String']['output']>;
  cronofyElementToken?: Maybe<Scalars['String']['output']>;
  cronofyExternalCalendarUrl?: Maybe<Scalars['String']['output']>;
  cronofySub?: Maybe<Scalars['String']['output']>;
  cronofyTimezone?: Maybe<Scalars['ZoneId']['output']>;
  dailySessionLimit?: Maybe<Scalars['Int']['output']>;
  degrees: Array<Scalars['String']['output']>;
  externalCalendarConnected?: Maybe<ClientCalendarPlatform>;
  fitCoachId?: Maybe<Scalars['String']['output']>;
  hasHpcMembers: Scalars['Boolean']['output'];
  headshot?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  inOfficeDays: Array<Scalars['Int']['output']>;
  /** @deprecated Use calendlyUrl instead */
  inPersonCalendlyUrl?: Maybe<Scalars['String']['output']>;
  locations: CoachLocationsConnection;
  mboStaffId?: Maybe<Scalars['String']['output']>;
  passions: Array<Scalars['String']['output']>;
  sessions: UserCoachSessionsConnection;
  supportedCountries: Array<Country>;
  supportedGoals: Array<Goal>;
  titles: Array<Scalars['String']['output']>;
  totalUnreadMessageCount: Scalars['Int']['output'];
  touchPoints: CoachTouchPointConnection;
  type?: Maybe<CoachType>;
  videoPlatformConnected?: Maybe<ClientVideoPlatform>;
  /** @deprecated We do not use Zoom API directly anymore */
  zoomId?: Maybe<Scalars['String']['output']>;
};

export type CoachDataAvailabilityArgs = {
  bypassOneDayDelay?: InputMaybe<Scalars['Boolean']['input']>;
  duration: Scalars['Int']['input'];
  interval: Scalars['Int']['input'];
  sessionStyle?: InputMaybe<CoachSessionStyle>;
  start: Scalars['ZonedDateTime']['input'];
};

export type CoachDataClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataCoachClientsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataCoachUsersArgs = {
  filter?: InputMaybe<CoachUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachUsersOrderBy>>;
};

export type CoachDataCronofyAuthUrlArgs = {
  returnUrl: Scalars['String']['input'];
};

export type CoachDataCronofyConferencingUrlArgs = {
  returnUrl: Scalars['String']['input'];
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CoachDataCronofyElementTokenArgs = {
  permissions?: InputMaybe<Array<CronofyElementPermission>>;
};

export type CoachDataCronofyExternalCalendarUrlArgs = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  returnUrl: Scalars['String']['input'];
};

export type CoachDataLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachDataSessionsArgs = {
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type CoachDataTouchPointsArgs = {
  filter?: InputMaybe<TouchPointFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<TouchPointsOrderBy>>;
};

export type CoachLocation = Node & {
  __typename?: 'CoachLocation';
  auditLogs: AuditLogsConnection;
  coaches: LocationCoachesConnection;
  id: Scalars['ID']['output'];
  isHeadquarters: Scalars['Boolean']['output'];
  mboLocationId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  studio?: Maybe<Studio>;
};

export type CoachLocationAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationCoachesArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type CoachLocationsConnection = Connection & {
  __typename?: 'CoachLocationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachLocationsEdge>;
  nodes: Array<CoachLocation>;
  pageInfo: PageInfo;
};

export type CoachLocationsEdge = Edge & {
  __typename?: 'CoachLocationsEdge';
  cursor: Scalars['String']['output'];
  node: CoachLocation;
};

export type CoachLocationsFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  studioIsArchived?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CoachSession = Node & {
  __typename?: 'CoachSession';
  additionalInfo?: Maybe<Scalars['String']['output']>;
  attendance?: Maybe<CoachSessionAttendance>;
  attended?: Maybe<Scalars['Boolean']['output']>;
  calendar?: Maybe<Calendar>;
  cancelUrl?: Maybe<Scalars['String']['output']>;
  cancellableUntil: Scalars['ZonedDateTime']['output'];
  chatRoom?: Maybe<ChatRoom>;
  coach: User;
  id: Scalars['ID']['output'];
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  meetingUrl?: Maybe<Scalars['String']['output']>;
  member: User;
  reason?: Maybe<Scalars['String']['output']>;
  rescheduleUrl?: Maybe<Scalars['String']['output']>;
  sessionStyle: CoachSessionStyle;
  sessionSubtype?: Maybe<CoachSessionSubtype>;
  sessionType?: Maybe<CoachSessionType>;
  /** @deprecated Use sessionType instead */
  type: Scalars['String']['output'];
};

export enum CoachSessionAttendance {
  Attended = 'Attended',
  Excused = 'Excused',
  NoShow = 'NoShow',
}

export type CoachSessionCredits = {
  __typename?: 'CoachSessionCredits';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
};

export type CoachSessionFilter = {
  attendance?: InputMaybe<CoachSessionAttendance>;
  attendances?: InputMaybe<Array<InputMaybe<CoachSessionAttendance>>>;
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachSessionStyle?: InputMaybe<Array<CoachSessionStyle>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  dateRange?: InputMaybe<DateRange>;
  memberName?: InputMaybe<Scalars['String']['input']>;
  sessionType?: InputMaybe<CoachSessionType>;
  sessionTypes?: InputMaybe<Array<CoachSessionType>>;
};

export enum CoachSessionOrder {
  SessionTime = 'sessionTime',
}

export type CoachSessionOrderBy = {
  direction: OrderDirection;
  order: CoachSessionOrder;
};

export enum CoachSessionStyle {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export enum CoachSessionSubtype {
  BodyCompositionAssessment = 'BodyCompositionAssessment',
  ErgonomicEvaluation = 'ErgonomicEvaluation',
  MovementAssessment = 'MovementAssessment',
  NewMemberOrientation = 'NewMemberOrientation',
}

export enum CoachSessionType {
  CoachingSession = 'CoachingSession',
  PersonalTraining = 'PersonalTraining',
  UserCoachingConsult = 'UserCoachingConsult',
  UserNutritionConsult = 'UserNutritionConsult',
}

export type CoachTouchPoint = Node & {
  __typename?: 'CoachTouchPoint';
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  description: Scalars['String']['output'];
  due: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  member: User;
  metadata?: Maybe<CoachTouchPointMetadata>;
  status: CoachTouchPointStatus;
  title: Scalars['String']['output'];
  type: CoachTouchPointType;
};

export type CoachTouchPointConnection = Connection & {
  __typename?: 'CoachTouchPointConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachTouchPointEdge>;
  nodes: Array<CoachTouchPoint>;
  pageInfo: PageInfo;
};

export type CoachTouchPointEdge = Edge & {
  __typename?: 'CoachTouchPointEdge';
  cursor: Scalars['String']['output'];
  node: CoachTouchPoint;
};

export type CoachTouchPointMetadata = CoachTouchPointSessionMetadata;

export enum CoachTouchPointOrder {
  Due = 'due',
}

export type CoachTouchPointSessionMetadata = {
  __typename?: 'CoachTouchPointSessionMetadata';
  isCancelled: Scalars['Boolean']['output'];
  isRebooked: Scalars['Boolean']['output'];
  session?: Maybe<CoachSession>;
};

export enum CoachTouchPointStatus {
  Completed = 'Completed',
  Incomplete = 'Incomplete',
}

export enum CoachTouchPointType {
  AssessmentNotCompleted = 'AssessmentNotCompleted',
  CompleteFirstDailyCheckIn = 'CompleteFirstDailyCheckIn',
  Custom = 'Custom',
  CustomPlanAdherence = 'CustomPlanAdherence',
  CustomPlanCheckInNote = 'CustomPlanCheckInNote',
  CustomPlanExpired = 'CustomPlanExpired',
  CustomPlanExpiring = 'CustomPlanExpiring',
  DailyCheckInNotCompleted = 'DailyCheckInNotCompleted',
  NewMemberActivationAtRisk = 'NewMemberActivationAtRisk',
  OneWeekPostSessionCheckIn = 'OneWeekPostSessionCheckIn',
  ReassessmentReminder = 'ReassessmentReminder',
  SendMessageReminder = 'SendMessageReminder',
  SessionReminder = 'SessionReminder',
  TrackAttendance = 'TrackAttendance',
}

export enum CoachType {
  Coach = 'Coach',
  Dietitian = 'Dietitian',
  PerformCoach = 'PerformCoach',
}

export enum CoachUserCoachingExperienceTypeFilter {
  HighPerformance = 'highPerformance',
  Wellbeing = 'wellbeing',
}

export enum CoachUserGeneralFilter {
  HasUnreadMessages = 'hasUnreadMessages',
  IsStarred = 'isStarred',
}

export enum CoachUserSessionFilter {
  HasAttendedSession = 'hasAttendedSession',
  HasUpcomingSession = 'hasUpcomingSession',
  NoAttendedSession = 'noAttendedSession',
  NoUpcomingSession = 'noUpcomingSession',
}

export enum CoachUserStatusFilter {
  IsActive = 'isActive',
  IsInactive = 'isInactive',
  IsNew = 'isNew',
}

export type CoachUsersConnection = Connection & {
  __typename?: 'CoachUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<CoachUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type CoachUsersEdge = Edge & {
  __typename?: 'CoachUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type CoachUsersFilter = {
  clientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  coachingExperienceType?: InputMaybe<
    Array<CoachUserCoachingExperienceTypeFilter>
  >;
  createdAtDateRange?: InputMaybe<DateRange>;
  general?: InputMaybe<Array<CoachUserGeneralFilter>>;
  hasUnreadMessages?: InputMaybe<Scalars['Boolean']['input']>;
  isStarred?: InputMaybe<Scalars['Boolean']['input']>;
  recentActivityDateRange?: InputMaybe<DateRange>;
  session?: InputMaybe<Array<CoachUserSessionFilter>>;
  status?: InputMaybe<Array<CoachUserStatusFilter>>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export enum CoachUsersOrder {
  /** @deprecated No longer supported */
  LastFitActivity = 'lastFitActivity',
  LastMessageReceivedAt = 'lastMessageReceivedAt',
  LatestUserActivityStartTime = 'latestUserActivityStartTime',
}

export type CoachUsersOrderBy = {
  direction: OrderDirection;
  order: CoachUsersOrder;
};

export enum CoachingStyle {
  Hybrid = 'Hybrid',
  Virtual = 'Virtual',
}

export type ConferencingProfile = {
  __typename?: 'ConferencingProfile';
  platform?: Maybe<ClientVideoPlatform>;
  profileConnected: Scalars['Boolean']['output'];
  profileId: Scalars['String']['output'];
  profileName: Scalars['String']['output'];
  providerName: Scalars['String']['output'];
};

export type ConfigurationIssue = {
  __typename?: 'ConfigurationIssue';
  description: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type Connection = {
  count: Scalars['Int']['output'];
  edges: Array<Edge>;
  nodes: Array<Node>;
  pageInfo: PageInfo;
};

export type ConnectionInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Content = {
  author?: Maybe<Scalars['String']['output']>;
  contentEquipment: Array<Equipment>;
  contentGenre?: Maybe<Genre>;
  contentLevel?: Maybe<Level>;
  contentSplit?: Maybe<Split>;
  contentTime?: Maybe<ContentTime>;
  feedback?: Maybe<FeedbackKind>;
  heroPhoto?: Maybe<Photo>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  /** @deprecated use contentLevel */
  level?: Maybe<Scalars['String']['output']>;
  recommendations: ContentRecommendationsConnection;
  saved?: Maybe<Scalars['Boolean']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  /** @deprecated use contentTime */
  time?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  trainingCommitment?: Maybe<Scalars['String']['output']>;
};

export type ContentRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentAttribute = {
  __typename?: 'ContentAttribute';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContentCollection = {
  __typename?: 'ContentCollection';
  id: Scalars['ID']['output'];
  sections: ContentCollectionSectionsConnection;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ContentCollectionSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentCollectionSectionsConnection = Connection & {
  __typename?: 'ContentCollectionSectionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentCollectionSectionsEdge>;
  nodes: Array<ContentSection>;
  pageInfo: PageInfo;
};

export type ContentCollectionSectionsEdge = Edge & {
  __typename?: 'ContentCollectionSectionsEdge';
  cursor: Scalars['String']['output'];
  node: ContentSection;
};

export type ContentConnection = SimpleContentConnection & {
  __typename?: 'ContentConnection';
  completedCount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  edges: Array<ContentEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentEdge = SimpleContentEdge & {
  __typename?: 'ContentEdge';
  completed: Scalars['Boolean']['output'];
  cursor: Scalars['String']['output'];
  locked: Scalars['Boolean']['output'];
  node: Content;
  progress?: Maybe<Scalars['Float']['output']>;
};

export type ContentFeedbackPayload = {
  __typename?: 'ContentFeedbackPayload';
  content: Content;
};

export type ContentFilter = {
  associatedProject?: InputMaybe<Array<Scalars['String']['input']>>;
  brand?: InputMaybe<Array<Scalars['String']['input']>>;
  component?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Array<Scalars['Int']['input']>>;
  equipment?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanPrimarySubcomponents?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondaryComponent?: InputMaybe<Array<Scalars['String']['input']>>;
  gameplanSecondarySubcomponents?: InputMaybe<
    Array<Scalars['String']['input']>
  >;
  genre?: InputMaybe<Array<Scalars['String']['input']>>;
  intensity?: InputMaybe<Array<Scalars['String']['input']>>;
  isExperiential?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<Array<Scalars['String']['input']>>;
  performanceQuality?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  split?: InputMaybe<Array<Scalars['String']['input']>>;
  subGenre?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ContentGenreFilter = {
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ContentKind {
  Article = 'article',
  Inappsurvey = 'inappsurvey',
  Link = 'link',
  Video = 'video',
}

export enum ContentOrder {
  PublishedAt = 'published_at',
  Title = 'title',
}

export type ContentOrderBy = {
  direction: OrderDirection;
  order?: InputMaybe<ContentOrder>;
};

export type ContentRecommendationsConnection = SimpleContentConnection & {
  __typename?: 'ContentRecommendationsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentRecommendationsEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentRecommendationsEdge = SimpleContentEdge & {
  __typename?: 'ContentRecommendationsEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSavedConnection = SimpleContentConnection & {
  __typename?: 'ContentSavedConnection';
  count: Scalars['Int']['output'];
  edges: Array<ContentSavedEdge>;
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type ContentSavedEdge = SimpleContentEdge & {
  __typename?: 'ContentSavedEdge';
  cursor: Scalars['String']['output'];
  node: Content;
};

export type ContentSection = Content &
  Node & {
    __typename?: 'ContentSection';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    contents: ContentConnection;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    logoPhoto?: Maybe<Photo>;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ContentSectionContentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentSectionRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ContentTime = {
  __typename?: 'ContentTime';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  challengeType: ChallengeType;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  clientSpecific?: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled: Scalars['Boolean']['input'];
  endDate: Scalars['LocalDate']['input'];
  featured: Scalars['Boolean']['input'];
  goal: Scalars['Float']['input'];
  qualifyingGenres: Array<Scalars['String']['input']>;
  startDate: Scalars['LocalDate']['input'];
  subtitle?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['Boolean']['input']>;
  teams?: InputMaybe<Array<CreateChallengeTeamInput>>;
  title: Scalars['String']['input'];
};

export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  challenge: Challenge;
};

export type CreateChallengeTeamInput = {
  name: Scalars['String']['input'];
};

export type CreateChatRoomInput = {
  app?: InputMaybe<App>;
  initialMessage?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<ChatRoomKind>;
  name: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};

export type CreateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesCustomChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  usesMboRegistrationFlow?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type CreateCoachLocationInput = {
  isHeadquarters: Scalars['Boolean']['input'];
  mboLocationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  stateCode: Scalars['String']['input'];
  studioId: Scalars['String']['input'];
};

export type CreateCoachTouchPointInput = {
  coachId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  memberId: Scalars['ID']['input'];
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  sessionTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type: CoachTouchPointType;
};

export type CreateContentGenreInput = {
  hideChildren?: InputMaybe<Scalars['Boolean']['input']>;
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  value: Scalars['String']['input'];
};

export type CreateCustomChallengeInput = {
  challengeType: ChallengeType;
  distanceUnit?: InputMaybe<DistanceUnit>;
  endDate: Scalars['LocalDate']['input'];
  goal: Scalars['Int']['input'];
  qualifyingGenres: Array<Scalars['String']['input']>;
  startDate: Scalars['LocalDate']['input'];
};

export type CreateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
};

export type CreateEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateMboMembershipPayload = {
  __typename?: 'CreateMboMembershipPayload';
  user: User;
};

export type CreateNoteInput = {
  content: Scalars['String']['input'];
  memberId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type CreateNotificationOptionInput = {
  app: App;
  description: Scalars['String']['input'];
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  visible: Scalars['Boolean']['input'];
};

export type CreatePerformanceQualityScoreInput = {
  component: Scalars['String']['input'];
  performanceQuality: Scalars['String']['input'];
  score: Scalars['Int']['input'];
  subComponent: Scalars['String']['input'];
};

export type CreatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  planItems?: InputMaybe<Array<CreatePlanItemInput>>;
  startDate: Scalars['LocalDate']['input'];
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
  userId: Scalars['ID']['input'];
};

export type CreatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date: Scalars['LocalDate']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  order: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type: PlanItemType;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  plan: Plan;
};

export type CreateStudioInput = {
  clientId: Scalars['String']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual: Scalars['Boolean']['input'];
  mboServiceId: Scalars['String']['input'];
  mboStudioId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type CreateUserGoalInput = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  type: UserGoalType;
};

export type CreateUserGoalPayload = {
  __typename?: 'CreateUserGoalPayload';
  user: User;
};

export type CreateUserInput = {
  adminCreate?: InputMaybe<Scalars['Boolean']['input']>;
  app?: InputMaybe<App>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
  skipIterableRegistration?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  user: User;
};

export type CreateUsersInput = {
  users: Array<CreateUserInput>;
};

export type CreateUsersPayload = {
  __typename?: 'CreateUsersPayload';
  users: Array<User>;
};

export enum CronofyElementPermission {
  Agenda = 'agenda',
  Availability = 'availability',
  ManagedAvailability = 'managed_availability',
}

export type CustomChallengeDefaults = {
  __typename?: 'CustomChallengeDefaults';
  background: Photo;
  badge: Photo;
};

export type CustomPlanActiveDaysFilter = {
  localDateRange: RequiredLocalDateRange;
};

export type CustomPlansFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type CustomTaskPlanItem = Node &
  PlanItem & {
    __typename?: 'CustomTaskPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    title: Scalars['String']['output'];
    type: PlanItemType;
    url?: Maybe<Scalars['String']['output']>;
  };

export enum DateFilter {
  Future = 'Future',
  Past = 'Past',
}

export type DateRange = {
  end?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  start?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type DeleteFitCoachPayload = {
  __typename?: 'DeleteFitCoachPayload';
  user: User;
};

export type DeleteMboMembershipsPayload = {
  __typename?: 'DeleteMboMembershipsPayload';
  user: User;
};

export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  plan: Plan;
};

export type DeleteUserGoalPayload = {
  __typename?: 'DeleteUserGoalPayload';
  user: User;
};

export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  userId: Scalars['ID']['output'];
};

export type Device = {
  __typename?: 'Device';
  app?: Maybe<App>;
  createdAt: Scalars['ZonedDateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['ZonedDateTime']['output'];
  user: User;
};

export type Distance = {
  __typename?: 'Distance';
  unit?: Maybe<DistanceUnit>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DistanceInput = {
  unit: DistanceUnit;
  value: Scalars['Float']['input'];
};

export enum DistanceUnit {
  Kilometers = 'Kilometers',
  Meters = 'Meters',
  Miles = 'Miles',
  Yards = 'Yards',
}

export type Domain = Node & {
  __typename?: 'Domain';
  client?: Maybe<Client>;
  createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  count: Scalars['Int']['output'];
  edges: Array<DomainEdge>;
  nodes: Array<Domain>;
  pageInfo: PageInfo;
};

export type DomainEdge = Edge & {
  __typename?: 'DomainEdge';
  cursor: Scalars['String']['output'];
  node: Domain;
};

export type DomainsFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Edge = {
  cursor: Scalars['String']['output'];
  node: Node;
};

export type EditActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  heartRate?: InputMaybe<HeartRateInput>;
  id: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type EligibleEmailAddress = Node & {
  __typename?: 'EligibleEmailAddress';
  addedManually: Scalars['Boolean']['output'];
  archivedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  auditLogs: AuditLogsConnection;
  client?: Maybe<Client>;
  createdAt: Scalars['ZonedDateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  /** @deprecated firstName is no longer required */
  firstName?: Maybe<Scalars['String']['output']>;
  hasHpcEligibility: Scalars['Boolean']['output'];
  hpcEligibilityEnd?: Maybe<Scalars['ZonedDateTime']['output']>;
  hpcEligibilityStart?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  invitingRelationship?: Maybe<FamilyRelationship>;
  invitingUser?: Maybe<User>;
  isArchived: Scalars['Boolean']['output'];
  isStaff: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  shareInvitation?: Maybe<Share>;
  updatedAt: Scalars['ZonedDateTime']['output'];
  user?: Maybe<User>;
};

export type EligibleEmailAddressAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type EligibleEmailAddressDetailsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EligibleEmailAddressesFilter = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  hasHpcEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type EmergencyContactDetails = {
  __typename?: 'EmergencyContactDetails';
  address?: Maybe<Address>;
  emergencyContact?: Maybe<EmergencyContact>;
  updatedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type EnumAnswer = {
  __typename?: 'EnumAnswer';
  choices: Array<Scalars['String']['output']>;
  enumValue: Array<Scalars['Int']['output']>;
};

export type Equipment = {
  __typename?: 'Equipment';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ExosContentPlanItem = Node &
  PlanItem & {
    __typename?: 'ExosContentPlanItem';
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated Use contentComponent instead */
    component?: Maybe<Scalars['String']['output']>;
    content: Content;
    contentComponent?: Maybe<ContentAttribute>;
    date: Scalars['LocalDate']['output'];
    id: Scalars['ID']['output'];
    order: Scalars['Int']['output'];
    type: PlanItemType;
  };

export enum FamilyRelationship {
  Dependent = 'dependent',
  Spouse = 'spouse',
}

export enum FeedbackContextKind {
  Application = 'application',
  Challenge = 'challenge',
  Content = 'content',
  MboClass = 'mboClass',
  Practice = 'practice',
  PracticeProgram = 'practiceProgram',
  Program = 'program',
}

export enum FeedbackKind {
  Needed = 'needed',
  Negative = 'negative',
  Positive = 'positive',
  Undecided = 'undecided',
}

export type FeedbackPayload = {
  __typename?: 'FeedbackPayload';
  challenge?: Maybe<Challenge>;
  content?: Maybe<Content>;
  mboClass?: Maybe<MboClass>;
};

export type FitnessCenterContract = {
  __typename?: 'FitnessCenterContract';
  agreementTerms: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type FitnessCenterMembership = {
  __typename?: 'FitnessCenterMembership';
  canConnectGym: Scalars['Boolean']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<FitnessCenterContract>;
  hasConnectedGym: Scalars['Boolean']['output'];
  postConnectionInstructions: Scalars['String']['output'];
};

export type GameplanMetadata = {
  __typename?: 'GameplanMetadata';
  associatedProject?: Maybe<ContentAttribute>;
  isExperiential?: Maybe<Scalars['Boolean']['output']>;
  primaryComponent?: Maybe<ContentAttribute>;
  primarySubcomponents: Array<Maybe<ContentAttribute>>;
  secondaryComponent?: Maybe<ContentAttribute>;
  secondarySubcomponents: Array<Maybe<ContentAttribute>>;
};

export type Genre = {
  __typename?: 'Genre';
  children: Array<Genre>;
  hideChildren: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isHidden: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Genre>;
  value: Scalars['String']['output'];
};

export type GenreChildrenArgs = {
  filter?: InputMaybe<ContentGenreFilter>;
};

export type Goal = {
  __typename?: 'Goal';
  description: Scalars['String']['output'];
  type: UserGoalType;
};

export type HeartRate = {
  __typename?: 'HeartRate';
  average: Scalars['Int']['output'];
  max: Scalars['Int']['output'];
  min: Scalars['Int']['output'];
};

export type HeartRateInput = {
  average: Scalars['Int']['input'];
  max: Scalars['Int']['input'];
  min: Scalars['Int']['input'];
};

export type InAppSurvey = Content &
  Node & {
    __typename?: 'InAppSurvey';
    author?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    finishedBody?: Maybe<Scalars['String']['output']>;
    finishedCardSubtitle?: Maybe<Scalars['String']['output']>;
    finishedCardTitle?: Maybe<Scalars['String']['output']>;
    finishedTitle?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    initialCardSubtitle?: Maybe<Scalars['String']['output']>;
    initialCardTitle?: Maybe<Scalars['String']['output']>;
    introBody?: Maybe<Scalars['String']['output']>;
    introTitle?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    /** @deprecated User Question.savedAnswer now */
    survey?: Maybe<Survey>;
    surveyTemplate?: Maybe<SurveyTemplate>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type InAppSurveyRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type InviteFamilyMemberInput = {
  email: Scalars['String']['input'];
  relationship: FamilyRelationship;
};

export type InviteFamilyMemberPayload = {
  __typename?: 'InviteFamilyMemberPayload';
  familyInvitation: EligibleEmailAddress;
  user: User;
};

export type IosSleepSamples = {
  endDate: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  sourceId: Scalars['String']['input'];
  sourceName: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type JoinChallengePayload = {
  __typename?: 'JoinChallengePayload';
  challenge?: Maybe<Challenge>;
};

export type LastActivity = {
  __typename?: 'LastActivity';
  heart?: Maybe<Scalars['ZonedDateTime']['output']>;
  heartWeb?: Maybe<Scalars['ZonedDateTime']['output']>;
  /** @deprecated prince is deprecated */
  prince?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Level = {
  __typename?: 'Level';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Link = Content &
  Node & {
    __typename?: 'Link';
    author?: Maybe<Scalars['String']['output']>;
    bannerPhoto?: Maybe<Photo>;
    buttonText?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    link: Scalars['String']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type LinkRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type LocalDateRange = {
  end?: InputMaybe<Scalars['LocalDate']['input']>;
  start?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type LocationCoachesConnection = Connection & {
  __typename?: 'LocationCoachesConnection';
  count: Scalars['Int']['output'];
  edges: Array<LocationCoachesEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type LocationCoachesEdge = Edge & {
  __typename?: 'LocationCoachesEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type LocationsFilter = {
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LogInput = {
  level?: InputMaybe<LogLevel>;
  message: Scalars['String']['input'];
};

export enum LogLevel {
  Error = 'error',
  Info = 'info',
  Warn = 'warn',
}

export type MachineClient = {
  __typename?: 'MachineClient';
  id: Scalars['ID']['output'];
};

export type ManualActivityInput = {
  distance?: InputMaybe<DistanceInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  sourceId: Scalars['String']['input'];
  startTime: Scalars['ZonedDateTime']['input'];
};

export type MboBookingsFilter = {
  dates: DateRange;
};

export type MboClass = Node & {
  __typename?: 'MboClass';
  attendees: MboClassUsersConnection;
  attending: Scalars['Boolean']['output'];
  /** The times at which booking for this class open and close. */
  bookingWindow?: Maybe<ZonedDateTimeRange>;
  cancelled: Scalars['Boolean']['output'];
  category: Scalars['String']['output'];
  client: Client;
  description: Scalars['String']['output'];
  endTime: Scalars['ZonedDateTime']['output'];
  feedback?: Maybe<FeedbackKind>;
  /**
   * True if the class is marked as 'free' in the external provider. Not for
   * user-facing purposes.
   */
  freeClass: Scalars['Boolean']['output'];
  full: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  joinUrl?: Maybe<Scalars['String']['output']>;
  joinableTime: Scalars['ZonedDateTime']['output'];
  location?: Maybe<CoachLocation>;
  mboClassId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  room?: Maybe<Scalars['String']['output']>;
  /** The service category’s schedule type. */
  scheduleType: Scalars['String']['output'];
  share?: Maybe<Share>;
  staff?: Maybe<User>;
  staffFirstName?: Maybe<Scalars['String']['output']>;
  staffLastName?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['ZonedDateTime']['output'];
  studio: Studio;
  /** The detailed type of the class: in-person, live, or encore */
  subtype: MboClassSubtype;
  /** @deprecated Prefer subtype */
  type: MboClassType;
};

export type MboClassAttendeesArgs = {
  filter?: InputMaybe<MboClassUsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type MboClassActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'MboClassActivity';
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    location?: Maybe<Scalars['String']['output']>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    room?: Maybe<Scalars['String']['output']>;
    source?: Maybe<MboClass>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type MboClassConnection = Connection & {
  __typename?: 'MboClassConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootMboClassEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type MboClassDetailsInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  mboClassId?: InputMaybe<Scalars['Int']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
};

export type MboClassFilter = {
  classType: MboClassType;
  dates: DateRange;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum MboClassSubtype {
  Encore = 'Encore',
  InPerson = 'InPerson',
  LiveVirtual = 'LiveVirtual',
}

export enum MboClassType {
  InPerson = 'InPerson',
  Virtual = 'Virtual',
}

export type MboClassUsersConnection = Connection & {
  __typename?: 'MboClassUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<MboClassUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type MboClassUsersEdge = Edge & {
  __typename?: 'MboClassUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type MboClassUsersFilter = {
  excludeAbsentUsers?: InputMaybe<Scalars['Boolean']['input']>;
  includeCancelledUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Message = Node & {
  __typename?: 'Message';
  automated: Scalars['Boolean']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  photos: Array<Photo>;
  previews: Array<MessagePreview>;
  reactions: Array<Reaction>;
  text: Scalars['String']['output'];
  user: User;
};

export type MessagePreview = {
  __typename?: 'MessagePreview';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Photo>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ModalStatus = {
  __typename?: 'ModalStatus';
  id: Scalars['ID']['output'];
  modalSeen: Scalars['Boolean']['output'];
};

export type MovementProgression = Node & {
  __typename?: 'MovementProgression';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  repetitions?: Maybe<Scalars['String']['output']>;
};

export type MovementProgressionsConnection = Connection & {
  __typename?: 'MovementProgressionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<MovementProgressionsEdge>;
  nodes: Array<MovementProgression>;
  pageInfo: PageInfo;
};

export type MovementProgressionsEdge = Edge & {
  __typename?: 'MovementProgressionsEdge';
  cursor: Scalars['String']['output'];
  node: MovementProgression;
};

export type MovementProgressionsFilter = {
  search?: InputMaybe<Scalars['String']['input']>;
};

export type MultipleChoiceOption = {
  __typename?: 'MultipleChoiceOption';
  /**
   * If true, when this option is selected, it should clear all other multiple choice options
   * If another option is selected while this is selected, it should clear this option
   */
  clearMultipleChoice?: Maybe<Scalars['Boolean']['output']>;
  description: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  iconSelected?: Maybe<Scalars['String']['output']>;
  /** if next question is supplied and this option was chosen, this is the next question to show the member */
  nextQuestion?: Maybe<Question>;
  optionIdentifier: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  textEntry: Scalars['Boolean']['output'];
};

export type MutateChatRoomPayload = {
  __typename?: 'MutateChatRoomPayload';
  chatRoom?: Maybe<ChatRoom>;
};

export type MutateClientPayload = {
  __typename?: 'MutateClientPayload';
  client: Client;
};

export type MutateCoachLocationPayload = {
  __typename?: 'MutateCoachLocationPayload';
  coachLocation: CoachLocation;
};

export type MutateCoachPayload = {
  __typename?: 'MutateCoachPayload';
  coachData: CoachData;
};

export type MutateDomainPayload = {
  __typename?: 'MutateDomainPayload';
  domain: Domain;
};

export type MutateEligibleEmailAddressInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  hpcEligibilityEnd?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  hpcEligibilityStart?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutateEligibleEmailAddressPayload = {
  __typename?: 'MutateEligibleEmailAddressPayload';
  eligibleEmailAddress: EligibleEmailAddress;
};

export type MutateGenrePayload = {
  __typename?: 'MutateGenrePayload';
  genre: Genre;
};

export type MutateNotePayload = {
  __typename?: 'MutateNotePayload';
  note?: Maybe<Note>;
};

export type MutateNotificationOptionPayload = {
  __typename?: 'MutateNotificationOptionPayload';
  notificationOption: NotificationOption;
};

export type MutateStudioPayload = {
  __typename?: 'MutateStudioPayload';
  studio: Studio;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManualActivity: ActivityPayload;
  addPerformanceQualityScores: AddPerformanceQualityScoresPayload;
  addReaction: Message;
  addSaved: SavedPayload;
  addToChatRoom: MutateChatRoomPayload;
  addToClient: MutateClientPayload;
  addUserToStudio: AddUserToStudioPayload;
  adminBackfillAssessmentAttributionType: Scalars['Int']['output'];
  adminChangeUserAuthProvider: UpdateUserPayload;
  adminConfirmUser: UpdateUserPayload;
  adminUpdateLastActivity?: Maybe<UpdateUserPayload>;
  adminUpdateUser: UpdateUserPayload;
  answerSurvey: Survey;
  assignAssessments: AssignAssessmentsPayload;
  assignFitCoach: AssignFitCoachPayload;
  assignHistoricalFitCoach: AssignFitCoachPayload;
  assignPractice: AssignPracticePayload;
  assignProgram: AssignProgramPayload;
  backFillIterableMarketing: RootUsersConnection;
  blockUser: BlockUserPayload;
  bookClass: BookClassPayload;
  bookSession: BookSessionPayload;
  bulkReassignCoachMembers: BulkReassignCoachMembersPayload;
  cancelClass: CancelClassPayload;
  cancelSession: Scalars['Boolean']['output'];
  changeEmail: UpdateUserPayload;
  clearCache: Scalars['Boolean']['output'];
  completePractice: UpdatePracticePayload;
  completeProgram: UpdateProgramPayload;
  createChallenge: CreateChallengePayload;
  createChatRoom: MutateChatRoomPayload;
  createClient: MutateClientPayload;
  createCoachLocation: MutateCoachLocationPayload;
  createCoachTouchPoint: CoachTouchPoint;
  createContentGenre: MutateGenrePayload;
  createCustomChallenge: CreateChallengePayload;
  createDomain: MutateDomainPayload;
  createEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  createFeedback: FeedbackPayload;
  createMboMembership: CreateMboMembershipPayload;
  createNote: MutateNotePayload;
  createNotificationOption: MutateNotificationOptionPayload;
  createPlan: CreatePlanPayload;
  createStudio: MutateStudioPayload;
  createUser: CreateUserPayload;
  createUserGoal: CreateUserGoalPayload;
  createUsers: CreateUsersPayload;
  deleteActivity: Scalars['Boolean']['output'];
  deleteAssignedAssessment?: Maybe<AssessmentAssignment>;
  deleteClientEligibleEmails: Scalars['Int']['output'];
  deleteCoachTouchPoint: Scalars['Boolean']['output'];
  deleteCustomChallenge: Scalars['Boolean']['output'];
  deleteFitCoach: DeleteFitCoachPayload;
  deleteMboMemberships: DeleteMboMembershipsPayload;
  deletePerformanceQualityScore: Scalars['Boolean']['output'];
  deletePlan: DeletePlanPayload;
  deleteUser: DeleteUserPayload;
  deleteUserGoal: DeleteUserGoalPayload;
  editActivity: ActivityPayload;
  generateSignedUrl: SignedUrlPayload;
  inviteFamilyMember: InviteFamilyMemberPayload;
  joinChallenge: JoinChallengePayload;
  joinLiveClass: ActivityPayload;
  leaveChallenge: JoinChallengePayload;
  leavePractice: UpdatePracticePayload;
  leaveProgram: UpdateProgramPayload;
  markBadgesSeenForChallenge: Challenge;
  markChatRead: ChatRoom;
  /** @deprecated No longer supported */
  markChatStarred: ChatRoom;
  markPlanItemComplete: PlanItem;
  markSurveyResponseViewed: SurveyResponsePayload;
  markUserStarred: User;
  purgeWearableData: PurgeWearableDataPayload;
  rateApp: RateAppPayload;
  rebookSession: BookSessionPayload;
  refreshContent: RefreshContentPayload;
  refreshContents: Scalars['Boolean']['output'];
  register: RegisterUserPayload;
  registerDevice: Scalars['Boolean']['output'];
  remindFamilyMember: InviteFamilyMemberPayload;
  removeAllUserSurveys: Scalars['Int']['output'];
  removeChallenge: Scalars['Boolean']['output'];
  removeClient: Scalars['Boolean']['output'];
  removeCoachMembers: Scalars['Boolean']['output'];
  removeDomain: Scalars['Boolean']['output'];
  removeEligibleEmailAddress: Scalars['Boolean']['output'];
  removeFamilyMember: InviteFamilyMemberPayload;
  removeFromChallenge: JoinChallengePayload;
  removeFromChatRoom: MutateChatRoomPayload;
  removeFromClient: MutateClientPayload;
  removeLocation: Scalars['Boolean']['output'];
  removeReaction: Message;
  removeSaved: SavedPayload;
  removeStudio: Scalars['Boolean']['output'];
  removeUserSurveys: Scalars['Int']['output'];
  repairClientUsers: RepairClientUsersPayload;
  reportUser: Scalars['Boolean']['output'];
  resetPreview?: Maybe<Photo>;
  resetProgress: ResetProgressPayload;
  resyncIterableUser?: Maybe<User>;
  /** @deprecated Use syncWearableSamples */
  saveWearableSamples: Scalars['Boolean']['output'];
  sendHistoricalMessage: Message;
  sendMessage: Message;
  sendSupportRequest: Scalars['Boolean']['output'];
  setAllClassesFree: SetAllClassesFreeResult;
  setMinBuildNumber: Scalars['Int']['output'];
  setModalStatus: ModalStatus;
  setNotificationOptions: SetNotificationOptionsPayload;
  setSessionCredits: SessionCreditsPayload;
  setUnitsPreference: UnitsPayload;
  startPractice: StartPracticePayload;
  startProgram: StartProgramPayload;
  /** @deprecated No longer supported */
  syncChatUsers: Scalars['Boolean']['output'];
  syncContentGenres: Scalars['Boolean']['output'];
  syncEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  syncIosSleepSamples: SyncIosSleepSamplesPayload;
  syncMboMemberships: SyncMboMembershipsPayload;
  syncMovementProgressions: Scalars['Int']['output'];
  syncWearableSamples: Scalars['Boolean']['output'];
  triggerIterablePush?: Maybe<TriggerIterablePushPayload>;
  unforgetIterableUser: Scalars['Boolean']['output'];
  unforgetUser: UnforgetUserPayload;
  updateAttendance: CoachSession;
  /** @deprecated Moved to enum column "updateAttendance" */
  updateAttended: CoachSession;
  updateAvatar: UpdateAvatarPayload;
  updateChallenge: CreateChallengePayload;
  updateChatRoom: MutateChatRoomPayload;
  updateClient: MutateClientPayload;
  updateCoachCronofyTimezone: UpdateCoachCronofyTimezoneResponse;
  updateCoachData: UpdateCoachDataPayload;
  updateCoachLocation: MutateCoachLocationPayload;
  updateCoachTouchPoint: CoachTouchPoint;
  updateContentGenre: MutateGenrePayload;
  updateDomain: MutateDomainPayload;
  updateEligibleEmailAddress: MutateEligibleEmailAddressPayload;
  updateLastActivity?: Maybe<UpdateUserPayload>;
  updateNote: MutateNotePayload;
  updateNotificationOption: MutateNotificationOptionPayload;
  updatePerformanceQualityScore: PerformanceQualityScore;
  updatePlan: UpdatePlanPayload;
  updatePractice: UpdatePracticePayload;
  updatePrivacyPolicyDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  updateProfileJson: UpdateProfileJsonPayload;
  updateProgram: UpdateProgramPayload;
  updateProgress: ContentEdge;
  updateStudio: MutateStudioPayload;
  updateUnreadCount: Scalars['Int']['output'];
  updateUser: UpdateUserPayload;
  updateUserGoal: UpdateUserGoalPayload;
  /** @deprecated Removed welcome section */
  updateWelcomeSectionDisplay: UpdateUserPayload;
};

export type MutationAddManualActivityArgs = {
  activity: ManualActivityInput;
};

export type MutationAddPerformanceQualityScoresArgs = {
  performanceQualityScores: Array<CreatePerformanceQualityScoreInput>;
};

export type MutationAddReactionArgs = {
  input: AddReactionInput;
};

export type MutationAddSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationAddToChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAddToClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAddUserToStudioArgs = {
  studioId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAdminBackfillAssessmentAttributionTypeArgs = {
  input: AdminBackfillAssessmentAttributionTypeInput;
};

export type MutationAdminChangeUserAuthProviderArgs = {
  input: AdminChangeAuthProviderInput;
};

export type MutationAdminConfirmUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationAdminUpdateLastActivityArgs = {
  app: App;
  date?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  platform?: TokenPlatform;
  userId: Scalars['ID']['input'];
};

export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};

export type MutationAnswerSurveyArgs = {
  input: AnswerSurveyInput;
};

export type MutationAssignAssessmentsArgs = {
  input: AssignAssessmentsInput;
};

export type MutationAssignFitCoachArgs = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationAssignHistoricalFitCoachArgs = {
  coachId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignPracticeArgs = {
  practiceId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationAssignProgramArgs = {
  programId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationBackFillIterableMarketingArgs = {
  input?: InputMaybe<ConnectionInput>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type MutationBlockUserArgs = {
  input: BlockUserInput;
};

export type MutationBookClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationBookSessionArgs = {
  input: BookSessionInput;
};

export type MutationBulkReassignCoachMembersArgs = {
  newCoachId: Scalars['ID']['input'];
  oldCoachId: Scalars['ID']['input'];
  reassignAllUsers?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationCancelClassArgs = {
  classId: Scalars['ID']['input'];
};

export type MutationCancelSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationChangeEmailArgs = {
  allowSSO?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationCompletePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationCompleteProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};

export type MutationCreateChatRoomArgs = {
  input: CreateChatRoomInput;
};

export type MutationCreateClientArgs = {
  input: CreateClientInput;
};

export type MutationCreateCoachLocationArgs = {
  input: CreateCoachLocationInput;
};

export type MutationCreateCoachTouchPointArgs = {
  input: CreateCoachTouchPointInput;
};

export type MutationCreateContentGenreArgs = {
  input: CreateContentGenreInput;
};

export type MutationCreateCustomChallengeArgs = {
  input: CreateCustomChallengeInput;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateEligibleEmailAddressArgs = {
  input: CreateEligibleEmailAddressInput;
};

export type MutationCreateFeedbackArgs = {
  context: FeedbackContextKind;
  feedback: FeedbackKind;
  id: Scalars['ID']['input'];
};

export type MutationCreateMboMembershipArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};

export type MutationCreateNotificationOptionArgs = {
  input: CreateNotificationOptionInput;
};

export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};

export type MutationCreateStudioArgs = {
  input: CreateStudioInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserGoalArgs = {
  input: CreateUserGoalInput;
};

export type MutationCreateUsersArgs = {
  input: CreateUsersInput;
};

export type MutationDeleteActivityArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteAssignedAssessmentArgs = {
  assignmentId: Scalars['ID']['input'];
};

export type MutationDeleteClientEligibleEmailsArgs = {
  clientId: Scalars['String']['input'];
};

export type MutationDeleteCoachTouchPointArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCustomChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteFitCoachArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteMboMembershipsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeletePerformanceQualityScoreArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeletePlanArgs = {
  planId: Scalars['ID']['input'];
};

export type MutationDeleteUserArgs = {
  forgetIterable?: Scalars['Boolean']['input'];
  isBulkDelete?: Scalars['Boolean']['input'];
  jira?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationDeleteUserGoalArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationEditActivityArgs = {
  activity: EditActivityInput;
};

export type MutationGenerateSignedUrlArgs = {
  input: SignedUrlInput;
};

export type MutationInviteFamilyMemberArgs = {
  input: InviteFamilyMemberInput;
};

export type MutationJoinChallengeArgs = {
  challengeTeamId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationJoinLiveClassArgs = {
  classId: Scalars['String']['input'];
};

export type MutationLeaveChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationLeavePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationLeaveProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationMarkBadgesSeenForChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkChatReadArgs = {
  chatId: Scalars['ID']['input'];
  lastMessageRead: Scalars['ZonedDateTime']['input'];
};

export type MutationMarkChatStarredArgs = {
  chatId: Scalars['ID']['input'];
  isStarred: Scalars['Boolean']['input'];
};

export type MutationMarkPlanItemCompleteArgs = {
  id: Scalars['ID']['input'];
};

export type MutationMarkSurveyResponseViewedArgs = {
  surveyResponseId: Scalars['ID']['input'];
};

export type MutationMarkUserStarredArgs = {
  isStarred: Scalars['Boolean']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationPurgeWearableDataArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationRateAppArgs = {
  feedback: FeedbackKind;
};

export type MutationRebookSessionArgs = {
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
  start: Scalars['ZonedDateTime']['input'];
  update?: InputMaybe<RebookSessionUpdateInput>;
  useCronofy?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationRefreshContentArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRefreshContentsArgs = {
  input?: InputMaybe<RefreshContentsInput>;
};

export type MutationRegisterArgs = {
  input: RegisterUserInput;
};

export type MutationRegisterDeviceArgs = {
  app?: InputMaybe<App>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type MutationRemindFamilyMemberArgs = {
  input: RemindFamilyMemberInput;
};

export type MutationRemoveChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveClientArgs = {
  clientId: Scalars['ID']['input'];
};

export type MutationRemoveCoachMembersArgs = {
  clientId: Scalars['ID']['input'];
  coachId: Scalars['ID']['input'];
};

export type MutationRemoveDomainArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveEligibleEmailAddressArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveFamilyMemberArgs = {
  input: RemoveFamilyMemberInput;
};

export type MutationRemoveFromChallengeArgs = {
  challengeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveFromChatRoomArgs = {
  chatRoomId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationRemoveFromClientArgs = {
  clientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRemoveLocationArgs = {
  locationId: Scalars['ID']['input'];
};

export type MutationRemoveReactionArgs = {
  reactionId: Scalars['ID']['input'];
};

export type MutationRemoveSavedArgs = {
  contentId: Scalars['ID']['input'];
};

export type MutationRemoveStudioArgs = {
  id: Scalars['ID']['input'];
};

export type MutationRemoveUserSurveysArgs = {
  surveyIdentifier: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRepairClientUsersArgs = {
  clientId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReportUserArgs = {
  input: ReportUserInput;
};

export type MutationResetPreviewArgs = {
  extension?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type MutationResetProgressArgs = {
  contentId?: InputMaybe<Scalars['ID']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationResyncIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationSaveWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationSendHistoricalMessageArgs = {
  chatId: Scalars['ID']['input'];
  createdAt: Scalars['ZonedDateTime']['input'];
  text: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSendMessageArgs = {
  chatId: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  messageId?: InputMaybe<Scalars['ID']['input']>;
  optimisticId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
};

export type MutationSendSupportRequestArgs = {
  app: App;
  type: SupportRequestType;
};

export type MutationSetAllClassesFreeArgs = {
  studioId: Scalars['ID']['input'];
};

export type MutationSetMinBuildNumberArgs = {
  input: SetMinBuildNumberInput;
};

export type MutationSetModalStatusArgs = {
  status: SetModalStatus;
};

export type MutationSetNotificationOptionsArgs = {
  input: Array<NotificationOptionInput>;
};

export type MutationSetSessionCreditsArgs = {
  input: SessionCreditsInput;
};

export type MutationSetUnitsPreferenceArgs = {
  input: UnitsInput;
};

export type MutationStartPracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationStartProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationSyncEligibleEmailAddressArgs = {
  input: SyncEligibleEmailAddressInput;
};

export type MutationSyncIosSleepSamplesArgs = {
  build: Scalars['String']['input'];
  platformVersion: Scalars['String']['input'];
  samples: Array<IosSleepSamples>;
};

export type MutationSyncMboMembershipsArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationSyncWearableSamplesArgs = {
  data: WearableSamplesInput;
};

export type MutationTriggerIterablePushArgs = {
  campaignId: Scalars['Int']['input'];
  dataFields?: InputMaybe<Scalars['JSONObject']['input']>;
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetIterableUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUnforgetUserArgs = {
  userId: Scalars['ID']['input'];
};

export type MutationUpdateAttendanceArgs = {
  attendance: CoachSessionAttendance;
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAttendedArgs = {
  attended: Scalars['Boolean']['input'];
  sessionId: Scalars['ID']['input'];
};

export type MutationUpdateAvatarArgs = {
  input: UpdateAvatarInput;
};

export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};

export type MutationUpdateChatRoomArgs = {
  input: UpdateChatRoomInput;
};

export type MutationUpdateClientArgs = {
  input: UpdateClientInput;
};

export type MutationUpdateCoachCronofyTimezoneArgs = {
  input: UpdateCoachCronofyTimezoneInput;
};

export type MutationUpdateCoachDataArgs = {
  input: UpdateCoachDataInput;
};

export type MutationUpdateCoachLocationArgs = {
  input: UpdateCoachLocationInput;
};

export type MutationUpdateCoachTouchPointArgs = {
  input: UpdateCoachTouchPointInput;
};

export type MutationUpdateContentGenreArgs = {
  input: UpdateContentGenreInput;
};

export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};

export type MutationUpdateEligibleEmailAddressArgs = {
  input: MutateEligibleEmailAddressInput;
};

export type MutationUpdateLastActivityArgs = {
  app: App;
  platform?: TokenPlatform;
};

export type MutationUpdateNoteArgs = {
  input: UpdateNoteInput;
};

export type MutationUpdateNotificationOptionArgs = {
  input: UpdateNotificationOptionInput;
};

export type MutationUpdatePerformanceQualityScoreArgs = {
  performanceQualityScore: UpdatePerformanceQualityScoreInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdatePracticeArgs = {
  practiceId: Scalars['ID']['input'];
};

export type MutationUpdatePrivacyPolicyDateArgs = {
  newDate?: InputMaybe<Scalars['ZonedDateTime']['input']>;
};

export type MutationUpdateProfileJsonArgs = {
  input: UpdateProfileJsonInput;
};

export type MutationUpdateProgramArgs = {
  programId: Scalars['ID']['input'];
};

export type MutationUpdateProgressArgs = {
  contentId: Scalars['ID']['input'];
  customPlanItemId?: InputMaybe<Scalars['ID']['input']>;
  progress?: InputMaybe<Scalars['Float']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateStudioArgs = {
  input: UpdateStudioInput;
};

export type MutationUpdateUnreadCountArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserGoalArgs = {
  input: UpdateUserGoalInput;
};

export type MutationUpdateWelcomeSectionDisplayArgs = {
  display: WelcomeSectionDisplay;
};

export type NewMessagePayload = {
  __typename?: 'NewMessagePayload';
  chatRoom: ChatRoom;
  message: Message;
  optimisticId?: Maybe<Scalars['ID']['output']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NotHello = {
  __typename?: 'NotHello';
  not?: Maybe<Scalars['String']['output']>;
};

export type Note = Node & {
  __typename?: 'Note';
  coach?: Maybe<User>;
  content: Scalars['String']['output'];
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy?: Maybe<User>;
  member: User;
  title: Scalars['String']['output'];
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum NotificationKind {
  Push = 'push',
}

export type NotificationOption = Node & {
  __typename?: 'NotificationOption';
  app: App;
  description: Scalars['String']['output'];
  featureFlag?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iterableId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  push: Scalars['Boolean']['output'];
  visible: Scalars['Boolean']['output'];
};

export type NotificationOptionConnection = Connection & {
  __typename?: 'NotificationOptionConnection';
  count: Scalars['Int']['output'];
  edges: Array<NotificationOptionEdge>;
  nodes: Array<NotificationOption>;
  pageInfo: PageInfo;
};

export type NotificationOptionEdge = Edge & {
  __typename?: 'NotificationOptionEdge';
  cursor: Scalars['String']['output'];
  node: NotificationOption;
};

export type NotificationOptionInput = {
  optionId: Scalars['String']['input'];
  push?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NumberAnswer = {
  __typename?: 'NumberAnswer';
  numberValue: Scalars['Float']['output'];
};

export type Onboarding = {
  __typename?: 'Onboarding';
  isCompleted: Scalars['Boolean']['output'];
  surveys: ContentConnection;
};

export type OnboardingMotivation = {
  __typename?: 'OnboardingMotivation';
  id: Scalars['ID']['output'];
  isSelected: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Os {
  Android = 'android',
  Ios = 'ios',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

export type ParticipantsFilter = {
  appendSelf?: InputMaybe<Scalars['Boolean']['input']>;
  challengeTeamId?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUsersWithoutScore?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ParticipantsOrder {
  CreatedAt = 'createdAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  Score = 'score',
}

export type ParticipantsOrderBy = {
  direction: OrderDirection;
  order: ParticipantsOrder;
};

export enum PerformanceQuality {
  BodyComposition = 'BodyComposition',
  Curiosity = 'Curiosity',
  Endurance = 'Endurance',
  FvProfile = 'FVProfile',
  Flowability = 'Flowability',
  FuelUtilization = 'FuelUtilization',
  Grit = 'Grit',
  InnerAwareness = 'InnerAwareness',
  LoadAccumulation = 'LoadAccumulation',
  MovementEfficiency = 'MovementEfficiency',
  NutrientProfile = 'NutrientProfile',
  SelfRegulation = 'SelfRegulation',
  SleepProficiency = 'SleepProficiency',
}

export type PerformanceQualityConnection = Connection & {
  __typename?: 'PerformanceQualityConnection';
  count: Scalars['Int']['output'];
  edges: Array<PerformanceQualityEdge>;
  nodes: Array<PerformanceQualityScore>;
  pageInfo: PageInfo;
};

export type PerformanceQualityEdge = Edge & {
  __typename?: 'PerformanceQualityEdge';
  cursor: Scalars['String']['output'];
  node: PerformanceQualityScore;
};

export type PerformanceQualityScore = Node & {
  __typename?: 'PerformanceQualityScore';
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  performanceQuality: Scalars['String']['output'];
  score: Scalars['Int']['output'];
  subComponent: Scalars['String']['output'];
};

export type Photo = Node & {
  __typename?: 'Photo';
  basePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preview?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type PhotoUrlArgs = {
  circle?: InputMaybe<Scalars['Boolean']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type PhotoAnswer = {
  __typename?: 'PhotoAnswer';
  caption?: Maybe<Scalars['String']['output']>;
  photo: Photo;
};

export type PhysicalActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'PhysicalActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<ActivityGenre>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type Plan = Node & {
  __typename?: 'Plan';
  endDate: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  planItems: PlanItemConnection;
  startDate: Scalars['LocalDate']['output'];
  targetedPerformanceQualities: Array<PerformanceQuality>;
};

export type PlanPlanItemsArgs = {
  filter?: InputMaybe<PlanItemsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PlanItem = {
  completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  date: Scalars['LocalDate']['output'];
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  type: PlanItemType;
};

export type PlanItemConnection = Connection & {
  __typename?: 'PlanItemConnection';
  count: Scalars['Int']['output'];
  edges: Array<PlanItemEdge>;
  nodes: Array<PlanItem>;
  pageInfo: PageInfo;
};

export type PlanItemEdge = Edge & {
  __typename?: 'PlanItemEdge';
  cursor: Scalars['String']['output'];
  node: PlanItem;
};

export enum PlanItemType {
  CustomTask = 'CustomTask',
  ExosContent = 'ExosContent',
}

export type PlanItemsFilter = {
  localDateRange?: InputMaybe<LocalDateRange>;
};

export type PracticeParticipantConnection = Connection & {
  __typename?: 'PracticeParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<PracticeParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type PracticeParticipantsEdge = Edge & {
  __typename?: 'PracticeParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type PracticeParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PracticeProgram = Content &
  Node & {
    __typename?: 'PracticeProgram';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: PracticeParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type PracticeProgramParticipantsArgs = {
  filter?: InputMaybe<PracticeParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type PracticeProgramFilter = {
  status?: InputMaybe<PracticeProgramStatus>;
};

export enum PracticeProgramStatus {
  All = 'All',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum Prefix {
  Avatar = 'avatar',
  Challenge = 'challenge',
  Client = 'client',
  Coach = 'coach',
  Uploads = 'uploads',
}

export type Principal = MachineClient | User;

export type Program = Content &
  Node & {
    __typename?: 'Program';
    author?: Maybe<Scalars['String']['output']>;
    completedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    congratulations?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    createdAt?: Maybe<Scalars['ZonedDateTime']['output']>;
    currentSection?: Maybe<ContentSection>;
    equipment: Array<Scalars['String']['output']>;
    /** @deprecated use subtitle now */
    eyebrow?: Maybe<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    isPublished: Scalars['Boolean']['output'];
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    participants: ProgramParticipantConnection;
    progress: Scalars['Float']['output'];
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    sections: ContentCollectionSectionsConnection;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    skills: Array<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
  };

export type ProgramParticipantsArgs = {
  filter?: InputMaybe<ProgramParticipantsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramSectionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type ProgramParticipantConnection = Connection & {
  __typename?: 'ProgramParticipantConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramParticipantsEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type ProgramParticipantsEdge = Edge & {
  __typename?: 'ProgramParticipantsEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type ProgramParticipantsFilter = {
  excludeUsersWithoutAvatar?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProgramsConnection = Connection & {
  __typename?: 'ProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<ProgramsEdge>;
  nodes: Array<Program>;
  pageInfo: PageInfo;
};

export type ProgramsEdge = Edge & {
  __typename?: 'ProgramsEdge';
  cursor: Scalars['String']['output'];
  node: Program;
};

export type PurgeWearableDataPayload = {
  __typename?: 'PurgeWearableDataPayload';
  user: User;
};

export type Query = {
  __typename?: 'Query';
  activity?: Maybe<Activity>;
  activityGenre?: Maybe<ActivityGenre>;
  activityGenres: Array<ActivityGenre>;
  /** @deprecated No longer supported */
  article?: Maybe<Article>;
  /** @deprecated No longer supported */
  articles: ArticlesConnection;
  assessment?: Maybe<AssessmentAssignment>;
  auditLogs: AuditLogsConnection;
  availableAssessments: AssessmentSurveysConnection;
  challenge?: Maybe<Challenge>;
  challengeTeam?: Maybe<ChallengeTeam>;
  challengeTemplates: RootChallengeTemplatesConnection;
  challenges: RootChallengesConnection;
  chatRoom?: Maybe<ChatRoom>;
  chatRooms: RootChatRoomsConnection;
  client?: Maybe<Client>;
  clients: RootClientsConnection;
  coachLocation?: Maybe<CoachLocation>;
  coachLocations: CoachLocationsConnection;
  coachSession?: Maybe<CoachSession>;
  content?: Maybe<Content>;
  contentCollection?: Maybe<ContentCollection>;
  contentEquipment: Array<Equipment>;
  contentGenre?: Maybe<Genre>;
  contentGenres: Array<Genre>;
  contentIntensities: Array<ContentAttribute>;
  contentLevels: Array<Level>;
  contentProjects: Array<ContentAttribute>;
  contentSplits: Array<Split>;
  contents: ContentConnection;
  countries: Array<Country>;
  customChallengeDefaults: CustomChallengeDefaults;
  customPlan: Plan;
  domain?: Maybe<Domain>;
  domains: DomainConnection;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  eligibleEmailAddresses: RootEligibleEmailAddressesConnection;
  gameplanComponents: Array<ContentAttribute>;
  gameplanSubcomponents: Array<ContentAttribute>;
  getModalStatus: ModalStatus;
  hello: Scalars['String']['output'];
  /** @deprecated No longer supported */
  home: ContentConnection;
  manyChallenges: Array<Challenge>;
  manyChatRooms: Array<ChatRoom>;
  manyClients: Array<Client>;
  manyCoachLocations: Array<CoachLocation>;
  manyContentGenres: Array<Genre>;
  manyDomains: Array<Domain>;
  manyEligibleEmailAddresses: Array<EligibleEmailAddress>;
  manyStudios: Array<Studio>;
  manyUsers: Array<User>;
  mboClass?: Maybe<MboClass>;
  /** @deprecated No longer supported */
  notHello?: Maybe<NotHello>;
  note?: Maybe<Note>;
  notificationOption: NotificationOption;
  notificationOptions: NotificationOptionConnection;
  performanceQualities: Array<ContentAttribute>;
  performanceQualityScores: PerformanceQualityConnection;
  planItem: PlanItem;
  programs: ProgramsConnection;
  self?: Maybe<User>;
  studio?: Maybe<Studio>;
  studios: StudiosConnection;
  survey?: Maybe<Survey>;
  surveyTemplate?: Maybe<SurveyTemplate>;
  systemPreferences: SystemPreferences;
  user?: Maybe<User>;
  userActivity?: Maybe<UserActivity>;
  userAssessmentSurvey: UserAssessmentSurvey;
  users: RootUsersConnection;
};

export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenreArgs = {
  id: Scalars['ID']['input'];
};

export type QueryActivityGenresArgs = {
  filter?: InputMaybe<ActivityGenresFilter>;
};

export type QueryArticleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAssessmentArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryAvailableAssessmentsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryChallengeArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengeTeamArgs = {
  id: Scalars['ID']['input'];
};

export type QueryChallengeTemplatesArgs = {
  timezone: Scalars['ZoneId']['input'];
};

export type QueryChallengesArgs = {
  filter?: InputMaybe<ChallengeFilter>;
  input?: InputMaybe<ConnectionInput>;
  sort?: InputMaybe<ChallengeSort>;
};

export type QueryChatRoomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type QueryChatRoomsArgs = {
  filter?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type QueryClientArgs = {
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCoachLocationsArgs = {
  filter?: InputMaybe<CoachLocationsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryCoachSessionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContentArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentCollectionArgs = {
  slug: Scalars['String']['input'];
};

export type QueryContentGenreArgs = {
  id: Scalars['ID']['input'];
};

export type QueryContentGenresArgs = {
  filter?: InputMaybe<ContentGenreFilter>;
};

export type QueryContentsArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<ContentOrderBy>;
};

export type QueryCustomPlanArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainArgs = {
  id: Scalars['ID']['input'];
};

export type QueryDomainsArgs = {
  filter?: InputMaybe<DomainsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryEligibleEmailAddressArgs = {
  details?: InputMaybe<EligibleEmailAddressDetailsInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryEligibleEmailAddressesArgs = {
  filter?: InputMaybe<EligibleEmailAddressesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QueryGetModalStatusArgs = {
  id: Scalars['ID']['input'];
};

export type QueryManyChallengesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyChatRoomsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyClientsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyCoachLocationsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyContentGenresArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyDomainsArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyEligibleEmailAddressesArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyStudiosArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryManyUsersArgs = {
  ids: Array<Scalars['ID']['input']>;
};

export type QueryMboClassArgs = {
  input: MboClassDetailsInput;
};

export type QueryNoteArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationOptionArgs = {
  id: Scalars['ID']['input'];
};

export type QueryNotificationOptionsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPerformanceQualityScoresArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryPlanItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type QueryStudioArgs = {
  id: Scalars['ID']['input'];
};

export type QueryStudiosArgs = {
  filter?: InputMaybe<StudiosFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type QuerySurveyArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySurveyTemplateArgs = {
  id: Scalars['ID']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mboId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryUserActivityArgs = {
  id: Scalars['ID']['input'];
};

export type QueryUserAssessmentSurveyArgs = {
  surveyContentId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type Question = {
  __typename?: 'Question';
  answer?: Maybe<Answer>;
  backgroundImage?: Maybe<Photo>;
  canGoBack: Scalars['Boolean']['output'];
  choices: Array<MultipleChoiceOption>;
  cta?: Maybe<QuestionCta>;
  description?: Maybe<Scalars['String']['output']>;
  /** Text above question */
  eyebrow?: Maybe<Scalars['String']['output']>;
  /** hint text below choices and above CTA */
  hint?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layoutType?: Maybe<QuestionLayoutType>;
  nextQuestion?: Maybe<Question>;
  /** input placeholder */
  placeholder?: Maybe<Scalars['String']['output']>;
  progressIndicator?: Maybe<QuestionProgressIndicator>;
  questionSubtype?: Maybe<QuestionSubtype>;
  questionType: QuestionType;
  required: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type QuestionCta = {
  __typename?: 'QuestionCta';
  text: Scalars['String']['output'];
};

export enum QuestionLayoutType {
  OnColumn = 'OnColumn',
  /** Displays tags and takes the space of the content wrapping when needed */
  Tags = 'Tags',
  TwoColumns = 'TwoColumns',
}

export type QuestionProgressIndicator = {
  __typename?: 'QuestionProgressIndicator';
  current: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export enum QuestionSubtype {
  Multiselect = 'Multiselect',
  OpenTextBox = 'OpenTextBox',
  PhotoUpload = 'PhotoUpload',
  Radio = 'Radio',
  SingleLine = 'SingleLine',
}

export enum QuestionType {
  Birthday = 'Birthday',
  Height = 'Height',
  Intro = 'Intro',
  MultipleChoice = 'MultipleChoice',
  PhotoUpload = 'PhotoUpload',
  TextEntry = 'TextEntry',
  ValueProp = 'ValueProp',
  Weight = 'Weight',
}

export type RateAppPayload = {
  __typename?: 'RateAppPayload';
  user?: Maybe<User>;
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID']['output'];
  kind: ReactionKind;
  user: User;
};

export enum ReactionKind {
  Clap = 'clap',
  Fire = 'fire',
  Grimacing = 'grimacing',
  Laugh = 'laugh',
  RatingNegative = 'rating_negative',
  RatingPositive = 'rating_positive',
  Smile = 'smile',
  Thumbsup = 'thumbsup',
}

export type RebookSessionUpdateInput = {
  duration: Scalars['Int']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  sessionStyle: CoachSessionStyle;
  sessionType: CoachSessionType;
};

export type RefreshContentPayload = {
  __typename?: 'RefreshContentPayload';
  content?: Maybe<Content>;
};

export type RefreshContentsInput = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  maxPages?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type RegisterUserInput = {
  consent?: InputMaybe<Scalars['Boolean']['input']>;
  consentDate?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  marketingOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<User>;
};

export type RemindFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RemoveFamilyMemberInput = {
  eligibleEmailAddressId: Scalars['ID']['input'];
};

export type RepairClientUsersPayload = {
  __typename?: 'RepairClientUsersPayload';
  processed: Scalars['Int']['output'];
  remaining: Scalars['Int']['output'];
};

export type ReportUserInput = {
  chatRoomId?: InputMaybe<Scalars['ID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  surveyResponseId?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type RequiredLocalDateRange = {
  end: Scalars['LocalDate']['input'];
  start: Scalars['LocalDate']['input'];
};

export type ResetProgressPayload = {
  __typename?: 'ResetProgressPayload';
  contentEdge?: Maybe<ContentEdge>;
  section?: Maybe<ContentSection>;
};

export type RootChallengeTemplatesConnection = Connection & {
  __typename?: 'RootChallengeTemplatesConnection';
  count: Scalars['Int']['output'];
  edges: Array<ChallengeTemplateEdge>;
  nodes: Array<ChallengeTemplate>;
  pageInfo: PageInfo;
};

export type RootChallengesConnection = {
  __typename?: 'RootChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<Maybe<ChallengeEdge>>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type RootChatRoomsConnection = Connection & {
  __typename?: 'RootChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
};

export type RootChatRoomsEdge = Edge & {
  __typename?: 'RootChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export type RootClientsConnection = Connection & {
  __typename?: 'RootClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type RootClientsEdge = Edge & {
  __typename?: 'RootClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type RootEligibleEmailAddressesConnection = Connection & {
  __typename?: 'RootEligibleEmailAddressesConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootEligibleEmailAddressesEdge>;
  nodes: Array<EligibleEmailAddress>;
  pageInfo: PageInfo;
};

export type RootEligibleEmailAddressesEdge = Edge & {
  __typename?: 'RootEligibleEmailAddressesEdge';
  cursor: Scalars['String']['output'];
  node: EligibleEmailAddress;
};

export type RootMboClassEdge = Edge & {
  __typename?: 'RootMboClassEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type RootUsersConnection = Connection & {
  __typename?: 'RootUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<RootUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type RootUsersEdge = Edge & {
  __typename?: 'RootUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type SsoIdentity = {
  __typename?: 'SSOIdentity';
  connection?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
};

export type SavedPayload = {
  __typename?: 'SavedPayload';
  content: Content;
  saved: Scalars['Boolean']['output'];
};

export enum ScoreUnit {
  Activities = 'Activities',
  Kilometers = 'Kilometers',
  Miles = 'Miles',
  Minutes = 'Minutes',
}

export type SessionCreditsInput = {
  count: Scalars['Int']['input'];
  rowVersion?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['ID']['input'];
};

export type SessionCreditsPayload = {
  __typename?: 'SessionCreditsPayload';
  count: Scalars['Int']['output'];
  rowVersion: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type SetAllClassesFreeResult = {
  __typename?: 'SetAllClassesFreeResult';
  classScheduleIds: Array<Scalars['Int']['output']>;
  classSchedules: Scalars['Int']['output'];
  classes: Scalars['Int']['output'];
};

export type SetMinBuildNumberInput = {
  app: App;
  buildNumber: Scalars['Int']['input'];
  os: Os;
};

export type SetModalStatus = {
  id: Scalars['ID']['input'];
  modalSeen: Scalars['Boolean']['input'];
};

export type SetNotificationOptionsPayload = {
  __typename?: 'SetNotificationOptionsPayload';
  notificationOptions: Array<NotificationOption>;
};

export type Share = {
  __typename?: 'Share';
  message?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type SignedUrlInput = {
  contentType: Scalars['String']['input'];
  prefix: Prefix;
};

export type SignedUrlPayload = {
  __typename?: 'SignedUrlPayload';
  key: Scalars['String']['output'];
  photo: Photo;
  signedUrl: Scalars['String']['output'];
};

export type SimpleContentConnection = {
  count: Scalars['Int']['output'];
  nodes: Array<Content>;
  pageInfo: PageInfo;
};

export type SimpleContentEdge = {
  cursor: Scalars['String']['output'];
  node: Content;
};

export type SleepActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'SleepActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    sleepEfficiency?: Maybe<Scalars['Float']['output']>;
    sleepOverview: SleepOverview;
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type SleepOverview = {
  __typename?: 'SleepOverview';
  asleep: Scalars['Int']['output'];
  awake: Scalars['Int']['output'];
  inbed: Scalars['Int']['output'];
};

export type SleepTrend = {
  __typename?: 'SleepTrend';
  activities: Array<SleepTrendActivity>;
  averageAsleep: Scalars['Int']['output'];
};

export type SleepTrendActivity = {
  __typename?: 'SleepTrendActivity';
  activity: Activity;
  deviation: Scalars['Float']['output'];
  preceding28DaysAverageAsleep: Scalars['Int']['output'];
};

export type SleepTrendFilter = {
  end: Scalars['ZonedDateTime']['input'];
  start: Scalars['ZonedDateTime']['input'];
};

export type Split = {
  __typename?: 'Split';
  children: Array<Split>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Split>;
  value: Scalars['String']['output'];
};

export type StartPracticePayload = {
  __typename?: 'StartPracticePayload';
  assignedPractice: AssignedPractice;
};

export type StartProgramPayload = {
  __typename?: 'StartProgramPayload';
  assignedProgram: AssignedProgram;
};

export type StringAnswer = {
  __typename?: 'StringAnswer';
  stringValue: Scalars['String']['output'];
};

export type Studio = Node & {
  __typename?: 'Studio';
  auditLogs: AuditLogsConnection;
  classes?: Maybe<StudioMboClassesConnection>;
  client: Client;
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCentral: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  locations: CoachLocationsConnection;
  mboAccessToken?: Maybe<Scalars['String']['output']>;
  mboServiceId: Scalars['String']['output'];
  mboStudioId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  requiredFields: Array<Scalars['String']['output']>;
  timezone: Scalars['String']['output'];
};

export type StudioAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioClassesArgs = {
  filter?: InputMaybe<StudioClassesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type StudioLocationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type StudioClassesFilter = {
  endDate?: InputMaybe<Scalars['LocalDate']['input']>;
  freeClass?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
};

export type StudioEdge = Edge & {
  __typename?: 'StudioEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type StudioMboClassEdge = Edge & {
  __typename?: 'StudioMboClassEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type StudioMboClassesConnection = Connection & {
  __typename?: 'StudioMboClassesConnection';
  count: Scalars['Int']['output'];
  edges: Array<StudioMboClassEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type StudiosConnection = Connection & {
  __typename?: 'StudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<StudioEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type StudiosFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatRoomUpdate: ChatRoom;
  newMessages: NewMessagePayload;
  newTotalUnreadMessageCount: Scalars['Int']['output'];
  totalUnreadMessageCount: TotalUnreadMessageCountPayload;
};

export type SubscriptionChatRoomUpdateArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewMessagesArgs = {
  chatId?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionNewTotalUnreadMessageCountArgs = {
  apps?: InputMaybe<Array<App>>;
  kinds?: InputMaybe<Array<ChatRoomKind>>;
};

export type SubscriptionTotalUnreadMessageCountArgs = {
  fromCoachedUsers?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SupportRequestType {
  DataAccess = 'DATA_ACCESS',
  DataDeletion = 'DATA_DELETION',
}

export type Survey = Node & {
  __typename?: 'Survey';
  createdAt: Scalars['ZonedDateTime']['output'];
  id: Scalars['ID']['output'];
  responses: Array<SurveyResponse>;
  scope?: Maybe<Scalars['String']['output']>;
  surveyIdentifier: Scalars['String']['output'];
  surveyName?: Maybe<Scalars['String']['output']>;
  template?: Maybe<SurveyTemplate>;
  user: User;
};

export type SurveyFilter = {
  surveyGroup?: InputMaybe<SurveyGroup>;
  surveyIdentifier?: InputMaybe<Scalars['String']['input']>;
};

export enum SurveyGroup {
  Assessment = 'assessment',
  Basics = 'basics',
  Motivations = 'motivations',
}

export type SurveyResponse = Node & {
  __typename?: 'SurveyResponse';
  id: Scalars['ID']['output'];
  numericValue?: Maybe<Scalars['Float']['output']>;
  question: Scalars['String']['output'];
  questionIdentifier: Scalars['String']['output'];
  response: Answer;
  subcategory?: Maybe<Scalars['String']['output']>;
  survey: Survey;
  viewedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type SurveyResponseInput = {
  caption?: InputMaybe<Scalars['String']['input']>;
  choices?: InputMaybe<Array<Scalars['String']['input']>>;
  numberAnswer?: InputMaybe<Scalars['Float']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  questionIdentifier: Scalars['String']['input'];
  scores?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedChoices?: InputMaybe<Array<Scalars['Int']['input']>>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type SurveyResponsePayload = {
  __typename?: 'SurveyResponsePayload';
  surveyResponse: SurveyResponse;
};

export type SurveyTemplate = {
  __typename?: 'SurveyTemplate';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questions: Array<Question>;
};

export type SyncEligibleEmailAddressInput = {
  clientId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
};

export type SyncIosSleepSamplesPayload = {
  __typename?: 'SyncIosSleepSamplesPayload';
  success: Scalars['Boolean']['output'];
};

export type SyncMboMembershipsPayload = {
  __typename?: 'SyncMboMembershipsPayload';
  user: User;
};

export type SystemPreferences = {
  __typename?: 'SystemPreferences';
  currentPrivacyPolicyDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  minFitAndroidBuild: Scalars['Int']['output'];
  minFitIosBuild: Scalars['Int']['output'];
  minPerformAndroidBuild: Scalars['Int']['output'];
  minPerformIosBuild: Scalars['Int']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String']['output'];
};

export type TimeBasedActivity = {
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
  startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type Token = {
  __typename?: 'Token';
  accessToken: Scalars['String']['output'];
  expiresIn: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum TokenPlatform {
  Mobile = 'mobile',
  Web = 'web',
}

export type TotalUnreadMessageCountPayload = {
  __typename?: 'TotalUnreadMessageCountPayload';
  count: Scalars['Int']['output'];
};

export type TouchPointFilter = {
  dueRange?: InputMaybe<DateRange>;
  includeCompletedAfter?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  status?: InputMaybe<Array<CoachTouchPointStatus>>;
};

export type TouchPointsOrderBy = {
  direction: OrderDirection;
  order: CoachTouchPointOrder;
};

export type TriggerIterablePushPayload = {
  __typename?: 'TriggerIterablePushPayload';
  success: Scalars['Boolean']['output'];
};

export type UnforgetUserPayload = {
  __typename?: 'UnforgetUserPayload';
  userId: Scalars['ID']['output'];
};

export type UnitsInput = {
  unitsPreference: UnitsPreference;
};

export type UnitsPayload = {
  __typename?: 'UnitsPayload';
  user: User;
};

export enum UnitsPreference {
  Imperial = 'imperial',
  Metric = 'metric',
}

export type UpdateAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressLine3?: InputMaybe<Scalars['String']['input']>;
  addressLine4?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  localityOrCity?: InputMaybe<Scalars['String']['input']>;
  postalOrZipCode?: InputMaybe<Scalars['String']['input']>;
  stateOrDistrictOrProvince?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarInput = {
  avatarId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAvatarPayload = {
  __typename?: 'UpdateAvatarPayload';
  user: User;
};

export type UpdateChallengeInput = {
  backgroundId?: InputMaybe<Scalars['String']['input']>;
  badgeId?: InputMaybe<Scalars['String']['input']>;
  clientIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  distanceUnit?: InputMaybe<DistanceUnit>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  featured?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  teams?: InputMaybe<Array<UpdateChallengeTeamInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChallengeTeamInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type UpdateChatRoomInput = {
  app?: InputMaybe<App>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateClientInput = {
  calendarPlatform?: InputMaybe<ClientCalendarPlatform>;
  collectsMemberData?: InputMaybe<Scalars['Boolean']['input']>;
  collectsMemberDataForEEP?: InputMaybe<Scalars['Boolean']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  domainIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ID']['input'];
  inactivityDuration?: InputMaybe<Scalars['Duration']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  logoId?: InputMaybe<Scalars['String']['input']>;
  maxFamilyMembers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needsUsToFixTheirUserNames?: InputMaybe<Scalars['Boolean']['input']>;
  ssoConnection?: InputMaybe<Scalars['String']['input']>;
  strictAppCheck?: InputMaybe<Scalars['Boolean']['input']>;
  usesCustomChallenges?: InputMaybe<Scalars['Boolean']['input']>;
  usesEligibilityServiceType?: InputMaybe<Scalars['Boolean']['input']>;
  usesFitnessCenterForEligibility?: InputMaybe<Scalars['Boolean']['input']>;
  usesGlobalSignOut?: InputMaybe<Scalars['Boolean']['input']>;
  usesHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  usesMboRegistrationFlow?: InputMaybe<Scalars['Boolean']['input']>;
  usesPersonalTraining?: InputMaybe<Scalars['Boolean']['input']>;
  videoPlatform?: InputMaybe<ClientVideoPlatform>;
};

export type UpdateCoachCronofyTimezoneInput = {
  coachId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['ZoneId']['input'];
};

export type UpdateCoachCronofyTimezoneResponse = {
  __typename?: 'UpdateCoachCronofyTimezoneResponse';
  user: User;
};

export type UpdateCoachDataInput = {
  availableForMemberAssignment?: InputMaybe<Scalars['Boolean']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  bodyPhotoId?: InputMaybe<Scalars['String']['input']>;
  calendlyUrl?: InputMaybe<Scalars['String']['input']>;
  coachClients?: InputMaybe<Array<CoachClientsInput>>;
  coachingStyle?: InputMaybe<CoachingStyle>;
  dailySessionLimit?: InputMaybe<Scalars['Int']['input']>;
  degrees?: InputMaybe<Array<Scalars['String']['input']>>;
  enableCalendarBuffer?: InputMaybe<Scalars['Boolean']['input']>;
  headshotPhotoId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  inOfficeDays?: InputMaybe<Array<Scalars['Int']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mboStaffId?: InputMaybe<Scalars['String']['input']>;
  passions?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedCountries?: InputMaybe<Array<Scalars['String']['input']>>;
  supportedGoals?: InputMaybe<Array<UserGoalType>>;
  titles?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<CoachType>;
};

export type UpdateCoachDataPayload = {
  __typename?: 'UpdateCoachDataPayload';
  user: User;
};

export type UpdateCoachLocationInput = {
  id: Scalars['ID']['input'];
  isHeadquarters?: InputMaybe<Scalars['Boolean']['input']>;
  mboLocationId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  studioId: Scalars['String']['input'];
};

export type UpdateCoachTouchPointInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  due?: InputMaybe<Scalars['ZonedDateTime']['input']>;
  id: Scalars['ID']['input'];
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContentGenreInput = {
  hideChildren?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDomainInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  domain: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateEmergencyContactInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMboBookingsPayload = {
  __typename?: 'UpdateMboBookingsPayload';
  remainingBookings: Scalars['Int']['output'];
};

export type UpdateNoteInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateNotificationOptionInput = {
  app?: InputMaybe<App>;
  description?: InputMaybe<Scalars['String']['input']>;
  featureFlag?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  iterableId?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdatePerformanceQualityScoreInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  performanceQuality?: InputMaybe<Scalars['String']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  subComponent?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanInput = {
  /** If true, will allow admin users in the dev environment to override start date restrictions */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  planId: Scalars['ID']['input'];
  planItems?: InputMaybe<Array<UpdatePlanItemInput>>;
  startDate?: InputMaybe<Scalars['LocalDate']['input']>;
  targetedPerformanceQualities?: InputMaybe<Array<PerformanceQuality>>;
};

export type UpdatePlanItemInput = {
  component?: InputMaybe<Scalars['String']['input']>;
  contentId?: InputMaybe<Scalars['ID']['input']>;
  date?: InputMaybe<Scalars['LocalDate']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PlanItemType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  plan: Plan;
};

export type UpdatePracticePayload = {
  __typename?: 'UpdatePracticePayload';
  assignedPractice: AssignedPractice;
};

export type UpdateProfileJsonInput = {
  profileJson?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateProfileJsonPayload = {
  __typename?: 'UpdateProfileJsonPayload';
  user: User;
};

export type UpdateProgramPayload = {
  __typename?: 'UpdateProgramPayload';
  assignedProgram: AssignedProgram;
};

export type UpdateStudioInput = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  isCentral?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  mboServiceId?: InputMaybe<Scalars['String']['input']>;
  mboStudioId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserGoalInput = {
  coachNote?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  type: UserGoalType;
};

export type UpdateUserGoalPayload = {
  __typename?: 'UpdateUserGoalPayload';
  user: User;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<UpdateAddressInput>;
  birthday?: InputMaybe<Scalars['LocalDate']['input']>;
  clientRelationship?: InputMaybe<UserClientRelationship>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<UpdateEmergencyContactInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitClientId?: InputMaybe<Scalars['ID']['input']>;
  hasHighPerformanceCoaching?: InputMaybe<Scalars['Boolean']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  identity?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isConcierge?: InputMaybe<Scalars['Boolean']['input']>;
  isMegaAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isReadOnlyAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isSalesTeam?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  officeLocation?: InputMaybe<Scalars['ID']['input']>;
  pronoun?: InputMaybe<Scalars['String']['input']>;
  registered?: InputMaybe<Scalars['Boolean']['input']>;
  shareDataWithEmployer?: InputMaybe<Scalars['Boolean']['input']>;
  shareEEPData?: InputMaybe<Scalars['Boolean']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  workingStyle?: InputMaybe<WorkingStyle>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user: User;
};

export type User = Node & {
  __typename?: 'User';
  active: Scalars['Boolean']['output'];
  activity: ActivityConnection;
  activityAggregates: ActivityAggregates;
  age?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Performs poorly and doesn't serve our use cases well. Prefer the app field on a chat or other object which is actually related to the interaction you're interested in. */
  app: App;
  assessmentSurveys: UserAssessmentSurveysConnection;
  assignedPrograms: UserAssignedProgramsConnection;
  auditLogs: AuditLogsConnection;
  authHistory: UserAuthHistoryConnection;
  availableClasses: UserAvailableClassesConnection;
  avatar?: Maybe<Photo>;
  birthday?: Maybe<Scalars['LocalDate']['output']>;
  blockedUsers: UserBlockedUsersConnection;
  bookedClasses: UserBookingsConnection;
  /** Creates or returns the chat between the user and the selected bot. Returns null if the user does not have access to the bot in question. */
  botChat?: Maybe<ChatRoom>;
  /** Tells the type of the bot. Returns null if the user is not a bot. */
  botKind?: Maybe<BotKind>;
  canShareData: Scalars['Boolean']['output'];
  challenges: UserChallengesConnection;
  chatRooms: UserChatRoomsConnection;
  clientRelationship?: Maybe<UserClientRelationship>;
  /** @deprecated use fitClient instead */
  clients: UserClientsConnection;
  coachData?: Maybe<CoachData>;
  coachSessionCredits?: Maybe<CoachSessionCredits>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ZonedDateTime']['output'];
  currentCustomPlan?: Maybe<Plan>;
  currentPractice?: Maybe<AssignedPractice>;
  currentProgram?: Maybe<AssignedProgram>;
  customPlans: UserPlansConnection;
  customPlansActiveDays: Array<ActiveCustomPlanDay>;
  devices: Array<Device>;
  eligibleEmailAddress?: Maybe<EligibleEmailAddress>;
  email: Scalars['String']['output'];
  emergencyContactDetails?: Maybe<EmergencyContactDetails>;
  familyInvitations: Array<EligibleEmailAddress>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitClient?: Maybe<Client>;
  fitCoach?: Maybe<User>;
  fitCoachChat?: Maybe<ChatRoom>;
  fitnessCenterMembership: FitnessCenterMembership;
  goal?: Maybe<UserGoal>;
  hasHighPerformanceCoaching: Scalars['Boolean']['output'];
  hasInPersonMemberships: Scalars['Boolean']['output'];
  height?: Maybe<Scalars['Float']['output']>;
  highPerformanceEndDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  highPerformanceStartDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  identity?: Maybe<Scalars['String']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isBlocked: Scalars['Boolean']['output'];
  isCoach: Scalars['Boolean']['output'];
  isConcierge: Scalars['Boolean']['output'];
  isFamilyMember: Scalars['Boolean']['output'];
  isMegaAdmin: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  isReadOnlyAdmin: Scalars['Boolean']['output'];
  isSalesTeam: Scalars['Boolean']['output'];
  isStarred: Scalars['Boolean']['output'];
  isTestUser: Scalars['Boolean']['output'];
  iterableToken: Token;
  lastActivity: LastActivity;
  lastName?: Maybe<Scalars['String']['output']>;
  notes: UserNotesConnection;
  notificationOptions: Array<NotificationOption>;
  officeLocation?: Maybe<CoachLocation>;
  onboarding: Onboarding;
  opportunities: Array<OnboardingMotivation>;
  practicePrograms: UserPracticeProgramsConnection;
  profileComplete: Scalars['Boolean']['output'];
  profileJson?: Maybe<Scalars['JSONObject']['output']>;
  pronouns?: Maybe<Scalars['String']['output']>;
  registered: Scalars['Boolean']['output'];
  savedContent: ContentSavedConnection;
  section?: Maybe<ContentSection>;
  sessions: UserCoachSessionsConnection;
  share?: Maybe<Share>;
  shareDataWithEmployer: Scalars['Boolean']['output'];
  shareEEPData: Scalars['Boolean']['output'];
  shouldSeeRateAppModal: Scalars['Boolean']['output'];
  sleepTrend: SleepTrend;
  strengths: Array<OnboardingMotivation>;
  studios: UserStudiosConnection;
  surveys: UserSurveysConnection;
  token?: Maybe<Scalars['JSONObject']['output']>;
  unitsPreference: UnitsPreference;
  userActivity: UserActivityConnection;
  userActivityTrend: Array<UserActivitySummary>;
  userId: Scalars['String']['output'];
  wearableSamples: WearableSamplesConnection;
  wearableSamplesLastSyncedAt?: Maybe<Scalars['ZonedDateTime']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Removed welcome section */
  welcomeSectionDisplay: WelcomeSectionDisplay;
  workingStyle?: Maybe<WorkingStyle>;
};

export type UserActivityArgs = {
  filter?: InputMaybe<ActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivityAggregatesArgs = {
  dateRange?: InputMaybe<DateRange>;
};

export type UserAssessmentSurveysArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssignedProgramsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAuditLogsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserAvailableClassesArgs = {
  filter: MboClassFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBlockedUsersArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserBookedClassesArgs = {
  filter: MboBookingsFilter;
  input?: InputMaybe<ConnectionInput>;
};

export type UserBotChatArgs = {
  bot: BotKind;
};

export type UserChallengesArgs = {
  filter?: InputMaybe<ChallengesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserChatRoomsArgs = {
  filters?: InputMaybe<ChatRoomsFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<ChatRoomsOrderBy>>;
};

export type UserClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansArgs = {
  filter?: InputMaybe<CustomPlansFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserCustomPlansActiveDaysArgs = {
  filter: CustomPlanActiveDaysFilter;
};

export type UserIterableTokenArgs = {
  tokenType?: InputMaybe<TokenPlatform>;
};

export type UserNotesArgs = {
  filter?: InputMaybe<UserNotesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserNotificationOptionsArgs = {
  app: App;
};

export type UserPracticeProgramsArgs = {
  filter?: InputMaybe<PracticeProgramFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSavedContentArgs = {
  filter?: InputMaybe<ContentFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserSectionArgs = {
  id: Scalars['String']['input'];
};

export type UserSessionsArgs = {
  dateFilter?: InputMaybe<Array<DateFilter>>;
  filter?: InputMaybe<CoachSessionFilter>;
  input?: InputMaybe<ConnectionInput>;
  orderBy?: InputMaybe<Array<CoachSessionOrderBy>>;
};

export type UserSleepTrendArgs = {
  filter: SleepTrendFilter;
};

export type UserStudiosArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type UserSurveysArgs = {
  filter?: InputMaybe<SurveyFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserUserActivityArgs = {
  filter?: InputMaybe<UserActivityFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserWearableSamplesArgs = {
  filter?: InputMaybe<WearableSamplesFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserActivity = Node & {
  __typename?: 'UserActivity';
  activity?: Maybe<Activity>;
  attribution?: Maybe<ActivityAttribution>;
  attributionTitle?: Maybe<Scalars['String']['output']>;
  attributionType: UserActivityAttributionType;
  distance?: Maybe<Distance>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime: Scalars['ZonedDateTime']['output'];
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use SleepActivity.sleepEfficiency instead */
  sleepEfficiency?: Maybe<Scalars['Float']['output']>;
  source?: Maybe<ActivitySource>;
  startTime: Scalars['ZonedDateTime']['output'];
  title: Scalars['String']['output'];
  type: UserActivityType;
};

export enum UserActivityAttributionType {
  Assessment = 'Assessment',
  Classes = 'Classes',
  Monitoring = 'Monitoring',
  Other = 'Other',
  Practice = 'Practice',
  Program = 'Program',
  Sleep = 'Sleep',
}

export type UserActivityConnection = Connection & {
  __typename?: 'UserActivityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserActivityEdge>;
  nodes: Array<UserActivity>;
  pageInfo: PageInfo;
};

export type UserActivityEdge = Edge & {
  __typename?: 'UserActivityEdge';
  cursor: Scalars['String']['output'];
  node: UserActivity;
};

export type UserActivityFilter = {
  attributionType?: InputMaybe<Array<InputMaybe<UserActivityAttributionType>>>;
  type?: InputMaybe<Array<InputMaybe<UserActivityType>>>;
};

export type UserActivitySummary = {
  __typename?: 'UserActivitySummary';
  classes: Scalars['Int']['output'];
  date: Scalars['LocalDate']['output'];
  other: Scalars['Int']['output'];
  practice: Scalars['Int']['output'];
  program: Scalars['Int']['output'];
};

export enum UserActivityType {
  Aod = 'Aod',
  Article = 'Article',
  Assessment = 'Assessment',
  Audio = 'Audio',
  Encore = 'Encore',
  InPerson = 'InPerson',
  ManualEntry = 'ManualEntry',
  PracticeSelection = 'PracticeSelection',
  ProgramSelection = 'ProgramSelection',
  Video = 'Video',
  Virtual = 'Virtual',
  Vod = 'Vod',
  WearableGoogleFit = 'WearableGoogleFit',
  WearableHealthConnectActivity = 'WearableHealthConnectActivity',
  WearableHealthKit = 'WearableHealthKit',
  WearableHealthKitSleep = 'WearableHealthKitSleep',
}

export type UserAssessmentAssignmentEdge = Edge & {
  __typename?: 'UserAssessmentAssignmentEdge';
  cursor: Scalars['String']['output'];
  node: AssessmentAssignment;
};

export type UserAssessmentAssignmentsConnection = Connection & {
  __typename?: 'UserAssessmentAssignmentsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentAssignmentEdge>;
  nodes: Array<AssessmentAssignment>;
  pageInfo: PageInfo;
};

export type UserAssessmentAssignmentsFilter = {
  completed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserAssessmentSurvey = Node & {
  __typename?: 'UserAssessmentSurvey';
  assignments: UserAssessmentAssignmentsConnection;
  id: Scalars['ID']['output'];
  /**
   * whether or not this survey is shown to members
   * these are not directly assignable and are completed by coaches
   * @deprecated Use type instead
   */
  isMemberFacing: Scalars['Boolean']['output'];
  mnmrType?: Maybe<Scalars['String']['output']>;
  template: SurveyTemplate;
  title?: Maybe<Scalars['String']['output']>;
  type: AssessmentType;
};

export type UserAssessmentSurveyAssignmentsArgs = {
  filter?: InputMaybe<UserAssessmentAssignmentsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type UserAssessmentSurveyEdge = Edge & {
  __typename?: 'UserAssessmentSurveyEdge';
  cursor: Scalars['String']['output'];
  node: UserAssessmentSurvey;
};

export type UserAssessmentSurveysConnection = Connection & {
  __typename?: 'UserAssessmentSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssessmentSurveyEdge>;
  nodes: Array<UserAssessmentSurvey>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsConnection = Connection & {
  __typename?: 'UserAssignedProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAssignedProgramsEdge>;
  nodes: Array<AssignedProgram>;
  pageInfo: PageInfo;
};

export type UserAssignedProgramsEdge = Edge & {
  __typename?: 'UserAssignedProgramsEdge';
  cursor: Scalars['String']['output'];
  node: AssignedProgram;
};

export type UserAuthHistory = Node & {
  __typename?: 'UserAuthHistory';
  challengeResponses: Array<Scalars['String']['output']>;
  eventDate?: Maybe<Scalars['ZonedDateTime']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type UserAuthHistoryConnection = Connection & {
  __typename?: 'UserAuthHistoryConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAuthHistoryEdge>;
  nodes: Array<UserAuthHistory>;
  pageInfo: PageInfo;
};

export type UserAuthHistoryEdge = Edge & {
  __typename?: 'UserAuthHistoryEdge';
  cursor: Scalars['String']['output'];
  node: UserAuthHistory;
};

export type UserAvailabilityConnection = Connection & {
  __typename?: 'UserAvailabilityConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailabilityEdge>;
  nodes: Array<Calendar>;
  pageInfo: PageInfo;
};

export type UserAvailabilityEdge = Edge & {
  __typename?: 'UserAvailabilityEdge';
  cursor: Scalars['String']['output'];
  node: Calendar;
};

export type UserAvailableClassesConnection = Connection & {
  __typename?: 'UserAvailableClassesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserAvailableClassesEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserAvailableClassesEdge = Edge & {
  __typename?: 'UserAvailableClassesEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserBlockedUsersConnection = Connection & {
  __typename?: 'UserBlockedUsersConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBlockedUsersEdge>;
  nodes: Array<User>;
  pageInfo: PageInfo;
};

export type UserBlockedUsersEdge = Edge & {
  __typename?: 'UserBlockedUsersEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserBookingsConnection = Connection & {
  __typename?: 'UserBookingsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserBookingsEdge>;
  nodes: Array<MboClass>;
  pageInfo: PageInfo;
};

export type UserBookingsEdge = Edge & {
  __typename?: 'UserBookingsEdge';
  cursor: Scalars['String']['output'];
  node: MboClass;
};

export type UserChallengesConnection = Connection & {
  __typename?: 'UserChallengesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChallengesEdge>;
  nodes: Array<Challenge>;
  pageInfo: PageInfo;
};

export type UserChallengesEdge = Edge & {
  __typename?: 'UserChallengesEdge';
  cursor: Scalars['String']['output'];
  node: Challenge;
  place?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Float']['output']>;
};

export type UserChatRoomsConnection = Connection & {
  __typename?: 'UserChatRoomsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserChatRoomsEdge>;
  nodes: Array<ChatRoom>;
  pageInfo: PageInfo;
  unreadMessageCount: Scalars['Int']['output'];
};

export type UserChatRoomsEdge = Edge & {
  __typename?: 'UserChatRoomsEdge';
  cursor: Scalars['String']['output'];
  node: ChatRoom;
};

export enum UserClientRelationship {
  Dependent = 'Dependent',
  Employee = 'Employee',
}

export type UserClientsConnection = Connection & {
  __typename?: 'UserClientsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserClientsEdge>;
  nodes: Array<Client>;
  pageInfo: PageInfo;
};

export type UserClientsEdge = Edge & {
  __typename?: 'UserClientsEdge';
  cursor: Scalars['String']['output'];
  node: Client;
};

export type UserCoachSessionsConnection = Connection & {
  __typename?: 'UserCoachSessionsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserCoachSessionsEdge>;
  nodes: Array<CoachSession>;
  pageInfo: PageInfo;
};

export type UserCoachSessionsEdge = Edge & {
  __typename?: 'UserCoachSessionsEdge';
  cursor: Scalars['String']['output'];
  node: CoachSession;
};

export type UserGoal = {
  __typename?: 'UserGoal';
  coachNote?: Maybe<Scalars['String']['output']>;
  coachNoteUpdatedBy?: Maybe<User>;
  createdAt: Scalars['ZonedDateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type: UserGoalType;
  updatedAt: Scalars['ZonedDateTime']['output'];
};

export enum UserGoalType {
  Appearance = 'Appearance',
  Creativity = 'Creativity',
  EmotionalIntelligence = 'EmotionalIntelligence',
  EmotionalWellbeing = 'EmotionalWellbeing',
  EnergyLevels = 'EnergyLevels',
  GeneralRegulation = 'GeneralRegulation',
  Health = 'Health',
  /** @deprecated Not used */
  NotSure = 'NotSure',
  PainManagement = 'PainManagement',
  /** @deprecated Use 'PainManagement' instead */
  PainReduction = 'PainReduction',
  PhysicalPerformance = 'PhysicalPerformance',
  /** @deprecated Use 'SocialWellbeing' instead */
  Relationships = 'Relationships',
  Resilience = 'Resilience',
  SharperBrain = 'SharperBrain',
  /** @deprecated Not used */
  SocialWellbeing = 'SocialWellbeing',
  /** @deprecated Use 'PhysicalPerformance' instead */
  ToneUp = 'ToneUp',
  /** @deprecated Not used */
  TryingNewThings = 'TryingNewThings',
  /** @deprecated Not used */
  WorkPerformance = 'WorkPerformance',
}

export type UserNotesConnection = Connection & {
  __typename?: 'UserNotesConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserNotesEdge>;
  nodes: Array<Note>;
  pageInfo: PageInfo;
};

export type UserNotesEdge = Edge & {
  __typename?: 'UserNotesEdge';
  cursor: Scalars['String']['output'];
  node: Note;
};

export type UserNotesFilter = {
  coachId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserPlansConnection = Connection & {
  __typename?: 'UserPlansConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPlansEdge>;
  nodes: Array<Plan>;
  pageInfo: PageInfo;
};

export type UserPlansEdge = Edge & {
  __typename?: 'UserPlansEdge';
  cursor: Scalars['String']['output'];
  node: Plan;
};

export type UserPracticeProgramEdge = Edge & {
  __typename?: 'UserPracticeProgramEdge';
  cursor: Scalars['String']['output'];
  node: PracticeProgram;
};

export type UserPracticeProgramsConnection = Connection & {
  __typename?: 'UserPracticeProgramsConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserPracticeProgramEdge>;
  nodes: Array<PracticeProgram>;
  pageInfo: PageInfo;
};

export type UserStudiosConnection = Connection & {
  __typename?: 'UserStudiosConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserStudiosEdge>;
  nodes: Array<Studio>;
  pageInfo: PageInfo;
};

export type UserStudiosEdge = Edge & {
  __typename?: 'UserStudiosEdge';
  cursor: Scalars['String']['output'];
  node: Studio;
};

export type UserSurveysConnection = Connection & {
  __typename?: 'UserSurveysConnection';
  count: Scalars['Int']['output'];
  edges: Array<UserSurveysEdge>;
  nodes: Array<Survey>;
  pageInfo: PageInfo;
};

export type UserSurveysEdge = Edge & {
  __typename?: 'UserSurveysEdge';
  cursor: Scalars['String']['output'];
  node: Survey;
};

export type UsersFilter = {
  clientId?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isCoach?: InputMaybe<Scalars['Boolean']['input']>;
  isTestUser?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Video = Content &
  Node & {
    __typename?: 'Video';
    author?: Maybe<Scalars['String']['output']>;
    brand?: Maybe<Scalars['String']['output']>;
    contentEquipment: Array<Equipment>;
    contentGenre?: Maybe<Genre>;
    contentLevel?: Maybe<Level>;
    contentSplit?: Maybe<Split>;
    contentTime?: Maybe<ContentTime>;
    /** @deprecated use contentEquipment */
    equipment: Array<Scalars['String']['output']>;
    feedback?: Maybe<FeedbackKind>;
    gameplanMetadata?: Maybe<GameplanMetadata>;
    heroPhoto?: Maybe<Photo>;
    html?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    intensity?: Maybe<Scalars['String']['output']>;
    isPublished: Scalars['Boolean']['output'];
    kind?: Maybe<Scalars['String']['output']>;
    /** @deprecated use contentLevel */
    level?: Maybe<Scalars['String']['output']>;
    mediaType?: Maybe<Scalars['String']['output']>;
    mnmrType?: Maybe<Scalars['String']['output']>;
    movementProgressions: MovementProgressionsConnection;
    recommendations: ContentRecommendationsConnection;
    saved?: Maybe<Scalars['Boolean']['output']>;
    share?: Maybe<Share>;
    shortDescription?: Maybe<Scalars['String']['output']>;
    split?: Maybe<Split>;
    subGenre?: Maybe<Scalars['String']['output']>;
    subtitle?: Maybe<Scalars['String']['output']>;
    tags: Array<Scalars['String']['output']>;
    /** @deprecated use contentTime */
    time?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    trainingCommitment?: Maybe<Scalars['String']['output']>;
    type?: Maybe<Scalars['String']['output']>;
    video: Scalars['String']['output'];
  };

export type VideoMovementProgressionsArgs = {
  filter?: InputMaybe<MovementProgressionsFilter>;
  input?: InputMaybe<ConnectionInput>;
};

export type VideoRecommendationsArgs = {
  input?: InputMaybe<ConnectionInput>;
};

export type VideoActivity = Activity &
  CoachBasedActivity &
  Node &
  TimeBasedActivity & {
    __typename?: 'VideoActivity';
    brand?: Maybe<Scalars['String']['output']>;
    coachAvatar?: Maybe<Photo>;
    coachFirstName?: Maybe<Scalars['String']['output']>;
    coachLastName?: Maybe<Scalars['String']['output']>;
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heroPhoto?: Maybe<Photo>;
    id: Scalars['ID']['output'];
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Video>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableActivity = Activity &
  Node &
  TimeBasedActivity & {
    __typename?: 'WearableActivity';
    contentGenre?: Maybe<Genre>;
    date: Scalars['ZonedDateTime']['output'];
    distance?: Maybe<Distance>;
    duration?: Maybe<Scalars['Int']['output']>;
    endTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    /** @deprecated use contentGenre */
    genre?: Maybe<Scalars['String']['output']>;
    heartRate?: Maybe<HeartRate>;
    id: Scalars['ID']['output'];
    overlappingActivity?: Maybe<Activity>;
    program?: Maybe<Program>;
    progress: Scalars['Float']['output'];
    source?: Maybe<Scalars['JSONObject']['output']>;
    startTime?: Maybe<Scalars['ZonedDateTime']['output']>;
    title: Scalars['String']['output'];
    trendType?: Maybe<ActivityTrendType>;
    type: ActivityType;
  };

export type WearableSample = Node & {
  __typename?: 'WearableSample';
  id: Scalars['ID']['output'];
  platform: Os;
  platformVersion: Scalars['String']['output'];
  sample: Scalars['JSONObject']['output'];
  sdk: WearableSdk;
  startDate: Scalars['ZonedDateTime']['output'];
};

export type WearableSampleEdge = Edge & {
  __typename?: 'WearableSampleEdge';
  cursor: Scalars['String']['output'];
  node: WearableSample;
};

export type WearableSamplesConnection = Connection & {
  __typename?: 'WearableSamplesConnection';
  count: Scalars['Int']['output'];
  edges: Array<WearableSampleEdge>;
  nodes: Array<WearableSample>;
  pageInfo: PageInfo;
};

export type WearableSamplesFilter = {
  platform?: InputMaybe<Os>;
  startDateRange?: InputMaybe<DateRange>;
};

export type WearableSamplesInput = {
  build: Scalars['String']['input'];
  platform: Os;
  platformVersion: Scalars['String']['input'];
  samples: Array<Scalars['JSONObject']['input']>;
  sdk: WearableSdk;
};

export enum WearableSdk {
  GoogleFit = 'GoogleFit',
  HealthConnect = 'HealthConnect',
  HealthKit = 'HealthKit',
}

export enum WelcomeSectionDisplay {
  Default = 'default',
  Hidden = 'hidden',
  ShowAlways = 'showAlways',
}

export enum WorkingStyle {
  Hybrid = 'hybrid',
  Office = 'office',
  Remote = 'remote',
}

export type ZonedDateTimeRange = {
  __typename?: 'ZonedDateTimeRange';
  end?: Maybe<Scalars['ZonedDateTime']['output']>;
  start?: Maybe<Scalars['ZonedDateTime']['output']>;
};

export type SleepOverviewFragment = {
  __typename?: 'SleepOverview';
  inbed: number;
  awake: number;
  asleep: number;
};

export type HeartRateFragment = { __typename?: 'HeartRate'; min: number };

export type SleepActivityFragment = {
  __typename?: 'SleepActivity';
  id: string;
  sleepEfficiency?: number | null;
  sleepOverview: {
    __typename?: 'SleepOverview';
    inbed: number;
    awake: number;
    asleep: number;
  };
  heartRate?: { __typename?: 'HeartRate'; min: number } | null;
};

export type PracticeAttributionFragment = {
  __typename?: 'AssignedPractice';
  assigningCoach?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type ProgramAttributionFragment = {
  __typename?: 'AssignedProgram';
  assigningCoach?: {
    __typename?: 'User';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type ArticleSourceFragment = {
  __typename?: 'Article';
  id: string;
  shortDescription?: string | null;
  equipment: Array<string>;
};

export type AudioSourceFragment = {
  __typename?: 'Audio';
  id: string;
  shortDescription?: string | null;
};

export type VideoSourceFragment = {
  __typename?: 'Video';
  id: string;
  shortDescription?: string | null;
  equipment: Array<string>;
  author?: string | null;
};

export type PhysicalActivitySourceFragment = {
  __typename?: 'PhysicalActivity';
  id: string;
};

export type MboClassSourceFragment = {
  __typename?: 'MboClass';
  id: string;
  description: string;
  staffFirstName?: string | null;
  staffLastName?: string | null;
  location?: { __typename?: 'CoachLocation'; name?: string | null } | null;
};

export type SurveySourceFragment = {
  __typename?: 'Survey';
  id: string;
  surveyIdentifier: string;
};

export type WearableActivitySourceFragment = {
  __typename?: 'WearableActivity';
  id: string;
};

type ActivitySourceArticleFragment = {
  __typename?: 'Article';
  id: string;
  shortDescription?: string | null;
  equipment: Array<string>;
};

type ActivitySourceAudioFragment = {
  __typename?: 'Audio';
  id: string;
  shortDescription?: string | null;
};

type ActivitySourceMboClassFragment = {
  __typename?: 'MboClass';
  id: string;
  description: string;
  staffFirstName?: string | null;
  staffLastName?: string | null;
  location?: { __typename?: 'CoachLocation'; name?: string | null } | null;
};

type ActivitySourcePhysicalActivityFragment = {
  __typename?: 'PhysicalActivity';
  id: string;
};

type ActivitySourceSurveyFragment = {
  __typename?: 'Survey';
  id: string;
  surveyIdentifier: string;
};

type ActivitySourceVideoFragment = {
  __typename?: 'Video';
  id: string;
  shortDescription?: string | null;
  equipment: Array<string>;
  author?: string | null;
};

type ActivitySourceWearableActivityFragment = {
  __typename?: 'WearableActivity';
  id: string;
};

export type ActivitySourceFragment =
  | ActivitySourceArticleFragment
  | ActivitySourceAudioFragment
  | ActivitySourceMboClassFragment
  | ActivitySourcePhysicalActivityFragment
  | ActivitySourceSurveyFragment
  | ActivitySourceVideoFragment
  | ActivitySourceWearableActivityFragment;

export type UserActivityBasicsFragment = {
  __typename?: 'UserActivity';
  id: string;
  genre?: string | null;
  type: UserActivityType;
  attributionType: UserActivityAttributionType;
  attributionTitle?: string | null;
  title: string;
  startTime: string;
  endTime: string;
  duration?: number | null;
  distance?: {
    __typename?: 'Distance';
    value?: number | null;
    unit?: DistanceUnit | null;
  } | null;
  source?:
    | {
        __typename: 'Article';
        id: string;
        shortDescription?: string | null;
        equipment: Array<string>;
      }
    | { __typename: 'Audio'; id: string; shortDescription?: string | null }
    | {
        __typename: 'MboClass';
        id: string;
        description: string;
        staffFirstName?: string | null;
        staffLastName?: string | null;
        location?: {
          __typename?: 'CoachLocation';
          name?: string | null;
        } | null;
      }
    | { __typename: 'PhysicalActivity'; id: string }
    | { __typename: 'Survey'; id: string; surveyIdentifier: string }
    | {
        __typename: 'Video';
        id: string;
        shortDescription?: string | null;
        equipment: Array<string>;
        author?: string | null;
      }
    | { __typename: 'WearableActivity'; id: string }
    | null;
  activity?:
    | { __typename?: 'ArticleActivity' }
    | { __typename?: 'AudioActivity' }
    | { __typename?: 'MboClassActivity' }
    | { __typename?: 'PhysicalActivity' }
    | {
        __typename?: 'SleepActivity';
        id: string;
        sleepEfficiency?: number | null;
        sleepOverview: {
          __typename?: 'SleepOverview';
          inbed: number;
          awake: number;
          asleep: number;
        };
        heartRate?: { __typename?: 'HeartRate'; min: number } | null;
      }
    | { __typename?: 'VideoActivity' }
    | { __typename?: 'WearableActivity' }
    | null;
  attribution?:
    | {
        __typename?: 'AssignedPractice';
        assigningCoach?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }
    | {
        __typename?: 'AssignedProgram';
        assigningCoach?: {
          __typename?: 'User';
          firstName?: string | null;
          lastName?: string | null;
        } | null;
      }
    | null;
};

export type AdminGetChallengeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetChallengeQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'Challenge';
    id: string;
    title: string;
    subtitle?: string | null;
    description?: string | null;
    qualifyingGenres: Array<string>;
    challengeType: ChallengeType;
    unit: string;
    startDateTime: string;
    endDateTime: string;
    enabled: boolean;
    isArchived: boolean;
    clientSpecific: boolean;
    featured: boolean;
    goal: number;
    team: boolean;
    badge?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    background?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    challenger?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    clients: {
      __typename?: 'ChallengeClientsConnection';
      count: number;
      nodes: Array<{ __typename?: 'Client'; id: string; name?: string | null }>;
    };
    participants: {
      __typename?: 'ChallengeParticipantConnection';
      count: number;
    };
    teams: {
      __typename?: 'ChallengeTeamConnection';
      count: number;
      nodes: Array<{ __typename?: 'ChallengeTeam'; id: string; name: string }>;
    };
  } | null;
};

export type AdminListChallengesQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<ChallengeFilter>;
  sort?: InputMaybe<ChallengeSort>;
}>;

export type AdminListChallengesQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'RootChallengesConnection';
    count: number;
    nodes: Array<{
      __typename?: 'Challenge';
      id: string;
      title: string;
      subtitle?: string | null;
      description?: string | null;
      qualifyingGenres: Array<string>;
      challengeType: ChallengeType;
      unit: string;
      startDateTime: string;
      endDateTime: string;
      enabled: boolean;
      isArchived: boolean;
      clientSpecific: boolean;
      featured: boolean;
      goal: number;
      team: boolean;
      badge?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      background?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      challenger?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      clients: {
        __typename?: 'ChallengeClientsConnection';
        count: number;
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          name?: string | null;
        }>;
      };
      participants: {
        __typename?: 'ChallengeParticipantConnection';
        count: number;
      };
      teams: {
        __typename?: 'ChallengeTeamConnection';
        count: number;
        nodes: Array<{
          __typename?: 'ChallengeTeam';
          id: string;
          name: string;
        }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminManyChallengesQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyChallengesQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Challenge';
    id: string;
    title: string;
    subtitle?: string | null;
    description?: string | null;
    qualifyingGenres: Array<string>;
    challengeType: ChallengeType;
    unit: string;
    startDateTime: string;
    endDateTime: string;
    enabled: boolean;
    isArchived: boolean;
    clientSpecific: boolean;
    featured: boolean;
    goal: number;
    team: boolean;
    badge?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    background?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    challenger?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    clients: {
      __typename?: 'ChallengeClientsConnection';
      count: number;
      nodes: Array<{ __typename?: 'Client'; id: string; name?: string | null }>;
    };
    participants: {
      __typename?: 'ChallengeParticipantConnection';
      count: number;
    };
    teams: {
      __typename?: 'ChallengeTeamConnection';
      count: number;
      nodes: Array<{ __typename?: 'ChallengeTeam'; id: string; name: string }>;
    };
  }>;
};

export type AdminCreateChallengeMutationVariables = Exact<{
  input: CreateChallengeInput;
}>;

export type AdminCreateChallengeMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'CreateChallengePayload';
    challenge: {
      __typename?: 'Challenge';
      id: string;
      title: string;
      subtitle?: string | null;
      description?: string | null;
      qualifyingGenres: Array<string>;
      challengeType: ChallengeType;
      unit: string;
      startDateTime: string;
      endDateTime: string;
      enabled: boolean;
      isArchived: boolean;
      clientSpecific: boolean;
      featured: boolean;
      goal: number;
      team: boolean;
      badge?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      background?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      challenger?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      clients: {
        __typename?: 'ChallengeClientsConnection';
        count: number;
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          name?: string | null;
        }>;
      };
      participants: {
        __typename?: 'ChallengeParticipantConnection';
        count: number;
      };
      teams: {
        __typename?: 'ChallengeTeamConnection';
        count: number;
        nodes: Array<{
          __typename?: 'ChallengeTeam';
          id: string;
          name: string;
        }>;
      };
    };
  };
};

export type AdminUpdateChallengeMutationVariables = Exact<{
  input: UpdateChallengeInput;
}>;

export type AdminUpdateChallengeMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'CreateChallengePayload';
    challenge: {
      __typename?: 'Challenge';
      id: string;
      title: string;
      subtitle?: string | null;
      description?: string | null;
      qualifyingGenres: Array<string>;
      challengeType: ChallengeType;
      unit: string;
      startDateTime: string;
      endDateTime: string;
      enabled: boolean;
      isArchived: boolean;
      clientSpecific: boolean;
      featured: boolean;
      goal: number;
      team: boolean;
      badge?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      background?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      challenger?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      clients: {
        __typename?: 'ChallengeClientsConnection';
        count: number;
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          name?: string | null;
        }>;
      };
      participants: {
        __typename?: 'ChallengeParticipantConnection';
        count: number;
      };
      teams: {
        __typename?: 'ChallengeTeamConnection';
        count: number;
        nodes: Array<{
          __typename?: 'ChallengeTeam';
          id: string;
          name: string;
        }>;
      };
    };
  };
};

export type AdminRemoveChallengeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminRemoveChallengeMutation = {
  __typename?: 'Mutation';
  data: boolean;
};

export type GetActivityGenresQueryVariables = Exact<{ [key: string]: never }>;

export type GetActivityGenresQuery = {
  __typename?: 'Query';
  activityGenres: Array<{
    __typename?: 'ActivityGenre';
    name: string;
    value: string;
  }>;
};

export type ChallengeBasicsFragment = {
  __typename?: 'Challenge';
  id: string;
  title: string;
  subtitle?: string | null;
  description?: string | null;
  qualifyingGenres: Array<string>;
  challengeType: ChallengeType;
  unit: string;
  startDateTime: string;
  endDateTime: string;
  enabled: boolean;
  isArchived: boolean;
  clientSpecific: boolean;
  featured: boolean;
  goal: number;
  team: boolean;
  badge?: {
    __typename?: 'Photo';
    url: string;
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  background?: {
    __typename?: 'Photo';
    url: string;
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  challenger?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  clients: {
    __typename?: 'ChallengeClientsConnection';
    count: number;
    nodes: Array<{ __typename?: 'Client'; id: string; name?: string | null }>;
  };
  participants: {
    __typename?: 'ChallengeParticipantConnection';
    count: number;
  };
  teams: {
    __typename?: 'ChallengeTeamConnection';
    count: number;
    nodes: Array<{ __typename?: 'ChallengeTeam'; id: string; name: string }>;
  };
};

export type AdminGetChatRoomQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetChatRoomQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'ChatRoom';
    id: string;
    name?: string | null;
    kind: ChatRoomKind;
    app?: App | null;
    isArchived: boolean;
    lastMessageReceivedAt?: string | null;
    messages: { __typename?: 'ChatRoomMessagesConnection'; count: number };
    users: {
      __typename?: 'ChatRoomUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
  } | null;
};

export type AdminManyChatRoomsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyChatRoomsQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'ChatRoom';
    id: string;
    name?: string | null;
    kind: ChatRoomKind;
    app?: App | null;
    isArchived: boolean;
    lastMessageReceivedAt?: string | null;
    messages: { __typename?: 'ChatRoomMessagesConnection'; count: number };
    users: {
      __typename?: 'ChatRoomUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
  }>;
};

export type AdminListChatRoomsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<ChatRoomsFilter>;
}>;

export type AdminListChatRoomsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'RootChatRoomsConnection';
    count: number;
    nodes: Array<{
      __typename?: 'ChatRoom';
      id: string;
      name?: string | null;
      kind: ChatRoomKind;
      app?: App | null;
      isArchived: boolean;
      lastMessageReceivedAt?: string | null;
      messages: { __typename?: 'ChatRoomMessagesConnection'; count: number };
      users: {
        __typename?: 'ChatRoomUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminCreateChatRoomMutationVariables = Exact<{
  input: CreateChatRoomInput;
}>;

export type AdminCreateChatRoomMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateChatRoomPayload';
    chatRoom?: {
      __typename?: 'ChatRoom';
      id: string;
      name?: string | null;
      app?: App | null;
      users: {
        __typename?: 'ChatRoomUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
    } | null;
  };
};

export type AdminUpdateChatRoomMutationVariables = Exact<{
  input: UpdateChatRoomInput;
}>;

export type AdminUpdateChatRoomMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateChatRoomPayload';
    chatRoom?: {
      __typename?: 'ChatRoom';
      id: string;
      name?: string | null;
      app?: App | null;
      isArchived: boolean;
      users: {
        __typename?: 'ChatRoomUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
    } | null;
  };
};

export type AdminChatRoomDetailsFragment = {
  __typename?: 'ChatRoom';
  id: string;
  name?: string | null;
  kind: ChatRoomKind;
  app?: App | null;
  isArchived: boolean;
  lastMessageReceivedAt?: string | null;
  messages: { __typename?: 'ChatRoomMessagesConnection'; count: number };
  users: {
    __typename?: 'ChatRoomUsersConnection';
    count: number;
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
  };
};

export type AdminGetClientQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetClientQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'Client';
    id: string;
    isArchived: boolean;
    name?: string | null;
    contactName?: string | null;
    displayName: string;
    contactEmail?: string | null;
    usesPersonalTraining: boolean;
    usesHighPerformanceCoaching: boolean;
    usesFitnessCenterForEligibility: boolean;
    usesEligibilityServiceType: boolean;
    usesMboRegistrationFlow: boolean;
    usesCustomChallenges: boolean;
    needsUsToFixTheirUserNames: boolean;
    usesSSO: boolean;
    calendarPlatform?: ClientCalendarPlatform | null;
    videoPlatform?: ClientVideoPlatform | null;
    collectsMemberData: boolean;
    collectsMemberDataForEEP: boolean;
    maxFamilyMembers: number;
    inactivityDuration?: any | null;
    strictAppCheck: boolean;
    logo?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    domains: {
      __typename?: 'DomainConnection';
      nodes: Array<{
        __typename?: 'Domain';
        id: string;
        domain: string;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
      }>;
    };
    studios: Array<{
      __typename?: 'Studio';
      id: string;
      mboStudioId: string;
      name: string;
      isVirtual: boolean;
      mboServiceId: string;
      timezone: string;
      isCentral: boolean;
      isArchived: boolean;
      client: { __typename?: 'Client'; id: string; name?: string | null };
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
    }>;
    users: {
      __typename?: 'ClientUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
    coaches: {
      __typename?: 'ClientUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
  } | null;
};

export type AdminManyClientsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyClientsQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Client';
    id: string;
    isArchived: boolean;
    name?: string | null;
    contactName?: string | null;
    displayName: string;
    contactEmail?: string | null;
    usesPersonalTraining: boolean;
    usesHighPerformanceCoaching: boolean;
    usesFitnessCenterForEligibility: boolean;
    usesEligibilityServiceType: boolean;
    usesMboRegistrationFlow: boolean;
    usesCustomChallenges: boolean;
    needsUsToFixTheirUserNames: boolean;
    usesSSO: boolean;
    calendarPlatform?: ClientCalendarPlatform | null;
    videoPlatform?: ClientVideoPlatform | null;
    collectsMemberData: boolean;
    collectsMemberDataForEEP: boolean;
    maxFamilyMembers: number;
    inactivityDuration?: any | null;
    strictAppCheck: boolean;
    logo?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    domains: {
      __typename?: 'DomainConnection';
      nodes: Array<{
        __typename?: 'Domain';
        id: string;
        domain: string;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
      }>;
    };
    studios: Array<{
      __typename?: 'Studio';
      id: string;
      mboStudioId: string;
      name: string;
      isVirtual: boolean;
      mboServiceId: string;
      timezone: string;
      isCentral: boolean;
      isArchived: boolean;
      client: { __typename?: 'Client'; id: string; name?: string | null };
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
    }>;
    users: {
      __typename?: 'ClientUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
    coaches: {
      __typename?: 'ClientUsersConnection';
      count: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      }>;
    };
  }>;
};

export type AdminListClientsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<ClientsFilter>;
}>;

export type AdminListClientsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'RootClientsConnection';
    count: number;
    nodes: Array<{
      __typename?: 'Client';
      id: string;
      name?: string | null;
      displayName: string;
      isArchived: boolean;
      users: { __typename?: 'ClientUsersConnection'; count: number };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type CoachDetailsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  coachData?: {
    __typename?: 'CoachData';
    id: string;
    type?: CoachType | null;
    coachingStyle?: CoachingStyle | null;
    availableForMemberAssignment: boolean;
    coachUsers: { __typename?: 'CoachUsersConnection'; count: number };
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{
        __typename?: 'CoachLocation';
        id: string;
        name?: string | null;
      }>;
    };
    supportedCountries: Array<{
      __typename?: 'Country';
      id: string;
      name: string;
    }>;
    coachClients: {
      __typename?: 'CoachClientsConnection';
      edges: Array<{
        __typename?: 'CoachClientsEdge';
        availableForAssignment: boolean;
        supportsHighPerformanceCoaching: boolean;
        supportsWellbeingCoaching: boolean;
        node: { __typename?: 'Client'; id: string; displayName: string };
      }>;
    };
  } | null;
};

export type ClientCoachesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
}>;

export type ClientCoachesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    coaches: {
      __typename?: 'ClientUsersConnection';
      count: number;
      edges: Array<{
        __typename?: 'ClientUsersEdge';
        node: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
          coachData?: {
            __typename?: 'CoachData';
            id: string;
            type?: CoachType | null;
            coachingStyle?: CoachingStyle | null;
            availableForMemberAssignment: boolean;
            coachUsers: { __typename?: 'CoachUsersConnection'; count: number };
            locations: {
              __typename?: 'CoachLocationsConnection';
              nodes: Array<{
                __typename?: 'CoachLocation';
                id: string;
                name?: string | null;
              }>;
            };
            supportedCountries: Array<{
              __typename?: 'Country';
              id: string;
              name: string;
            }>;
            coachClients: {
              __typename?: 'CoachClientsConnection';
              edges: Array<{
                __typename?: 'CoachClientsEdge';
                availableForAssignment: boolean;
                supportsHighPerformanceCoaching: boolean;
                supportsWellbeingCoaching: boolean;
                node: {
                  __typename?: 'Client';
                  id: string;
                  displayName: string;
                };
              }>;
            };
          } | null;
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
    };
  } | null;
};

export type ClientConfigurationIssuesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type ClientConfigurationIssuesQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    id: string;
    configurationIssues: Array<{
      __typename?: 'ConfigurationIssue';
      title: string;
      description: string;
      link?: string | null;
    }>;
  } | null;
};

export type AdminCreateClientMutationVariables = Exact<{
  input: CreateClientInput;
}>;

export type AdminCreateClientMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateClientPayload';
    client: {
      __typename?: 'Client';
      id: string;
      isArchived: boolean;
      name?: string | null;
      contactName?: string | null;
      displayName: string;
      contactEmail?: string | null;
      usesPersonalTraining: boolean;
      usesHighPerformanceCoaching: boolean;
      usesFitnessCenterForEligibility: boolean;
      usesEligibilityServiceType: boolean;
      usesMboRegistrationFlow: boolean;
      usesCustomChallenges: boolean;
      needsUsToFixTheirUserNames: boolean;
      usesSSO: boolean;
      calendarPlatform?: ClientCalendarPlatform | null;
      videoPlatform?: ClientVideoPlatform | null;
      collectsMemberData: boolean;
      collectsMemberDataForEEP: boolean;
      maxFamilyMembers: number;
      inactivityDuration?: any | null;
      strictAppCheck: boolean;
      logo?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      domains: {
        __typename?: 'DomainConnection';
        nodes: Array<{
          __typename?: 'Domain';
          id: string;
          domain: string;
          client?: {
            __typename?: 'Client';
            id: string;
            name?: string | null;
          } | null;
        }>;
      };
      studios: Array<{
        __typename?: 'Studio';
        id: string;
        mboStudioId: string;
        name: string;
        isVirtual: boolean;
        mboServiceId: string;
        timezone: string;
        isCentral: boolean;
        isArchived: boolean;
        client: { __typename?: 'Client'; id: string; name?: string | null };
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
      }>;
      users: {
        __typename?: 'ClientUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
      coaches: {
        __typename?: 'ClientUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
    };
  };
};

export type AdminUpdateClientMutationVariables = Exact<{
  input: UpdateClientInput;
}>;

export type AdminUpdateClientMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateClientPayload';
    client: {
      __typename?: 'Client';
      id: string;
      isArchived: boolean;
      name?: string | null;
      contactName?: string | null;
      displayName: string;
      contactEmail?: string | null;
      usesPersonalTraining: boolean;
      usesHighPerformanceCoaching: boolean;
      usesFitnessCenterForEligibility: boolean;
      usesEligibilityServiceType: boolean;
      usesMboRegistrationFlow: boolean;
      usesCustomChallenges: boolean;
      needsUsToFixTheirUserNames: boolean;
      usesSSO: boolean;
      calendarPlatform?: ClientCalendarPlatform | null;
      videoPlatform?: ClientVideoPlatform | null;
      collectsMemberData: boolean;
      collectsMemberDataForEEP: boolean;
      maxFamilyMembers: number;
      inactivityDuration?: any | null;
      strictAppCheck: boolean;
      logo?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      domains: {
        __typename?: 'DomainConnection';
        nodes: Array<{
          __typename?: 'Domain';
          id: string;
          domain: string;
          client?: {
            __typename?: 'Client';
            id: string;
            name?: string | null;
          } | null;
        }>;
      };
      studios: Array<{
        __typename?: 'Studio';
        id: string;
        mboStudioId: string;
        name: string;
        isVirtual: boolean;
        mboServiceId: string;
        timezone: string;
        isCentral: boolean;
        isArchived: boolean;
        client: { __typename?: 'Client'; id: string; name?: string | null };
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
      }>;
      users: {
        __typename?: 'ClientUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
      coaches: {
        __typename?: 'ClientUsersConnection';
        count: number;
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
        }>;
      };
    };
  };
};

export type AdminClientBasicsFragment = {
  __typename?: 'Client';
  id: string;
  name?: string | null;
  displayName: string;
  isArchived: boolean;
  users: { __typename?: 'ClientUsersConnection'; count: number };
};

export type AdminClientDetailsFragment = {
  __typename?: 'Client';
  id: string;
  isArchived: boolean;
  name?: string | null;
  contactName?: string | null;
  displayName: string;
  contactEmail?: string | null;
  usesPersonalTraining: boolean;
  usesHighPerformanceCoaching: boolean;
  usesFitnessCenterForEligibility: boolean;
  usesEligibilityServiceType: boolean;
  usesMboRegistrationFlow: boolean;
  usesCustomChallenges: boolean;
  needsUsToFixTheirUserNames: boolean;
  usesSSO: boolean;
  calendarPlatform?: ClientCalendarPlatform | null;
  videoPlatform?: ClientVideoPlatform | null;
  collectsMemberData: boolean;
  collectsMemberDataForEEP: boolean;
  maxFamilyMembers: number;
  inactivityDuration?: any | null;
  strictAppCheck: boolean;
  logo?: {
    __typename?: 'Photo';
    url: string;
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  domains: {
    __typename?: 'DomainConnection';
    nodes: Array<{
      __typename?: 'Domain';
      id: string;
      domain: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
    }>;
  };
  studios: Array<{
    __typename?: 'Studio';
    id: string;
    mboStudioId: string;
    name: string;
    isVirtual: boolean;
    mboServiceId: string;
    timezone: string;
    isCentral: boolean;
    isArchived: boolean;
    client: { __typename?: 'Client'; id: string; name?: string | null };
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
    };
  }>;
  users: {
    __typename?: 'ClientUsersConnection';
    count: number;
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
  };
  coaches: {
    __typename?: 'ClientUsersConnection';
    count: number;
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    }>;
  };
};

export type AdminEligibleEmailFieldsFragment = {
  __typename?: 'EligibleEmailAddress';
  createdAt: string;
  email?: string | null;
  employeeId?: string | null;
  id: string;
  isArchived: boolean;
  invitingRelationship?: FamilyRelationship | null;
  client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
  invitingUser?: { __typename?: 'User'; id: string; email: string } | null;
  user?: { __typename?: 'User'; id: string; email: string } | null;
};

export type AdminListCoachesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
}>;

export type AdminListCoachesQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    fitClient?: {
      __typename?: 'Client';
      id: string;
      coaches: {
        __typename?: 'ClientUsersConnection';
        count: number;
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          endCursor: string;
        };
        nodes: Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        }>;
      };
    } | null;
  } | null;
};

export type AdminCountryListQueryVariables = Exact<{ [key: string]: never }>;

export type AdminCountryListQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Country';
    id: string;
    name: string;
    alpha2: string;
  }>;
};

export type RemoveCoachMembersMutationVariables = Exact<{
  coachId: Scalars['ID']['input'];
  clientId: Scalars['ID']['input'];
}>;

export type RemoveCoachMembersMutation = {
  __typename?: 'Mutation';
  removeCoachMembers: boolean;
};

export type AssignFitCoachMutationVariables = Exact<{
  coachId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
}>;

export type AssignFitCoachMutation = {
  __typename?: 'Mutation';
  assignFitCoach: {
    __typename?: 'AssignFitCoachPayload';
    user: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      createdAt: string;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      avatar?: {
        __typename?: 'Photo';
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
    };
  };
};

export type DeleteFitCoachMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type DeleteFitCoachMutation = {
  __typename?: 'Mutation';
  deleteFitCoach: {
    __typename?: 'DeleteFitCoachPayload';
    user: { __typename?: 'User'; id: string };
  };
};

export type BulkReassignCoachMembersMutationVariables = Exact<{
  oldCoachId: Scalars['ID']['input'];
  newCoachId: Scalars['ID']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  reassignAllUsers?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BulkReassignCoachMembersMutation = {
  __typename?: 'Mutation';
  bulkReassignCoachMembers: {
    __typename?: 'BulkReassignCoachMembersPayload';
    userCountReassigned: number;
    userIdsNotReassigned: Array<string>;
  };
};

export type CoachPhotoFragment = {
  __typename?: 'Photo';
  url: string;
  id: string;
  basePath: string;
  preview?: string | null;
};

export type AdminCoachDataDetailsFragment = {
  __typename?: 'User';
  coachData?: {
    __typename?: 'CoachData';
    id: string;
    bio: string;
    passions: Array<string>;
    titles: Array<string>;
    degrees: Array<string>;
    type?: CoachType | null;
    coachingStyle?: CoachingStyle | null;
    fitCoachId?: string | null;
    cronofySub?: string | null;
    cronofyElementToken?: string | null;
    inOfficeDays: Array<number>;
    availableForMemberAssignment: boolean;
    dailySessionLimit?: number | null;
    cronofyTimezone?: string | null;
    calendarConnected: boolean;
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
    };
    coachClients: {
      __typename?: 'CoachClientsConnection';
      edges: Array<{
        __typename?: 'CoachClientsEdge';
        availableForAssignment: boolean;
        supportsHighPerformanceCoaching: boolean;
        supportsWellbeingCoaching: boolean;
        node: { __typename?: 'Client'; id: string; displayName: string };
      }>;
    };
    clients: {
      __typename?: 'RootClientsConnection';
      nodes: Array<{
        __typename?: 'Client';
        id: string;
        displayName: string;
        videoPlatform?: ClientVideoPlatform | null;
        calendarPlatform?: ClientCalendarPlatform | null;
        studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
      }>;
    };
    bodyPhoto?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    headshot?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    supportedCountries: Array<{
      __typename?: 'Country';
      id: string;
      name: string;
      alpha2: string;
    }>;
    supportedGoals: Array<{
      __typename?: 'Goal';
      type: UserGoalType;
      description: string;
    }>;
    calendarBuffer: {
      __typename?: 'CalendarBuffer';
      beforeInMinutes: number;
      afterInMinutes: number;
    };
    clientRequiredCalendarProfiles: {
      __typename?: 'RootClientsConnection';
      edges: Array<{
        __typename?: 'RootClientsEdge';
        node: {
          __typename?: 'Client';
          id: string;
          displayName: string;
          calendarPlatform?: ClientCalendarPlatform | null;
        };
      }>;
    };
    clientRequiredConferencingProfiles: {
      __typename?: 'RootClientsConnection';
      edges: Array<{
        __typename?: 'RootClientsEdge';
        node: {
          __typename?: 'Client';
          id: string;
          displayName: string;
          calendarPlatform?: ClientCalendarPlatform | null;
          videoPlatform?: ClientVideoPlatform | null;
        };
      }>;
    };
    calendarProfiles: Array<{
      __typename?: 'CalendarProfile';
      profileId: string;
      profileName: string;
      profileConnected: boolean;
      platform?: ClientCalendarPlatform | null;
      providerService?: string | null;
    }>;
    conferencingProfiles: Array<{
      __typename?: 'ConferencingProfile';
      providerName: string;
      profileId: string;
      profileName: string;
      profileConnected: boolean;
      platform?: ClientVideoPlatform | null;
    }>;
  } | null;
};

export type AdminListCoachUsersQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<CoachUsersFilter>;
}>;

export type AdminListCoachUsersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      coachUsers: {
        __typename?: 'CoachUsersConnection';
        count: number;
        pageInfo: {
          __typename?: 'PageInfo';
          hasNextPage: boolean;
          endCursor: string;
        };
        edges: Array<{
          __typename?: 'CoachUsersEdge';
          node: {
            __typename?: 'User';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            email: string;
            createdAt: string;
            fitCoachChat?: {
              __typename?: 'ChatRoom';
              id: string;
              isArchived: boolean;
            } | null;
            fitClient?: {
              __typename?: 'Client';
              id: string;
              name?: string | null;
            } | null;
            lastActivity: {
              __typename?: 'LastActivity';
              heart?: string | null;
              heartWeb?: string | null;
            };
            avatar?: {
              __typename?: 'Photo';
              id: string;
              basePath: string;
              preview?: string | null;
            } | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CalendarBufferFragment = {
  __typename?: 'CalendarBuffer';
  beforeInMinutes: number;
  afterInMinutes: number;
};

export type ConnectionDetailsFragment = {
  __typename?: 'CoachData';
  id: string;
  calendarConnected: boolean;
  clientRequiredCalendarProfiles: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        displayName: string;
        calendarPlatform?: ClientCalendarPlatform | null;
      };
    }>;
  };
  clientRequiredConferencingProfiles: {
    __typename?: 'RootClientsConnection';
    edges: Array<{
      __typename?: 'RootClientsEdge';
      node: {
        __typename?: 'Client';
        id: string;
        displayName: string;
        calendarPlatform?: ClientCalendarPlatform | null;
        videoPlatform?: ClientVideoPlatform | null;
      };
    }>;
  };
  calendarProfiles: Array<{
    __typename?: 'CalendarProfile';
    profileId: string;
    profileName: string;
    profileConnected: boolean;
    platform?: ClientCalendarPlatform | null;
    providerService?: string | null;
  }>;
  conferencingProfiles: Array<{
    __typename?: 'ConferencingProfile';
    providerName: string;
    profileId: string;
    profileName: string;
    profileConnected: boolean;
    platform?: ClientVideoPlatform | null;
  }>;
};

export type ClientCalendarProfilesBasicsFragment = {
  __typename?: 'Client';
  id: string;
  displayName: string;
  calendarPlatform?: ClientCalendarPlatform | null;
};

export type ClientConferencingProfilesBasicsFragment = {
  __typename?: 'Client';
  id: string;
  displayName: string;
  calendarPlatform?: ClientCalendarPlatform | null;
  videoPlatform?: ClientVideoPlatform | null;
};

export type CalendarProfileDetailsFragment = {
  __typename?: 'CalendarProfile';
  profileId: string;
  profileName: string;
  profileConnected: boolean;
  platform?: ClientCalendarPlatform | null;
  providerService?: string | null;
};

export type ConferencingProfileDetailsFragment = {
  __typename?: 'ConferencingProfile';
  providerName: string;
  profileId: string;
  profileName: string;
  profileConnected: boolean;
  platform?: ClientVideoPlatform | null;
};

export type AdminListDomainsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<DomainsFilter>;
}>;

export type AdminListDomainsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'DomainConnection';
    count: number;
    nodes: Array<{
      __typename?: 'Domain';
      id: string;
      domain: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminGetDomainQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetDomainQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'Domain';
    id: string;
    domain: string;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
  } | null;
};

export type AdminManyDomainsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyDomainsQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Domain';
    id: string;
    domain: string;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
  }>;
};

export type AdminCreateDomainMutationVariables = Exact<{
  input: CreateDomainInput;
}>;

export type AdminCreateDomainMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateDomainPayload';
    domain: {
      __typename?: 'Domain';
      id: string;
      domain: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
    };
  };
};

export type AdminUpdateDomainMutationVariables = Exact<{
  input: UpdateDomainInput;
}>;

export type AdminUpdateDomainMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateDomainPayload';
    domain: {
      __typename?: 'Domain';
      id: string;
      domain: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
    };
  };
};

export type AdminRemoveDomainMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminRemoveDomainMutation = {
  __typename?: 'Mutation';
  data: boolean;
};

export type DomainBasicsFragment = {
  __typename?: 'Domain';
  id: string;
  domain: string;
  client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
};

export type AdminE2ETestUserQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AdminE2ETestUserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type AdminEligibleEmailDetailsFragment = {
  __typename?: 'EligibleEmailAddress';
  id: string;
  email?: string | null;
  employeeId?: string | null;
  lastName?: string | null;
  isArchived: boolean;
  isStaff: boolean;
  archivedAt?: string | null;
  invitingRelationship?: FamilyRelationship | null;
  hasHpcEligibility: boolean;
  hpcEligibilityStart?: string | null;
  hpcEligibilityEnd?: string | null;
  addedManually: boolean;
  client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
  invitingUser?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  user?: {
    __typename?: 'User';
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    registered: boolean;
  } | null;
};

export type AdminListEligibleEmailsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<EligibleEmailAddressesFilter>;
}>;

export type AdminListEligibleEmailsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'RootEligibleEmailAddressesConnection';
    count: number;
    nodes: Array<{
      __typename?: 'EligibleEmailAddress';
      id: string;
      email?: string | null;
      employeeId?: string | null;
      lastName?: string | null;
      isArchived: boolean;
      isStaff: boolean;
      archivedAt?: string | null;
      invitingRelationship?: FamilyRelationship | null;
      hasHpcEligibility: boolean;
      hpcEligibilityStart?: string | null;
      hpcEligibilityEnd?: string | null;
      addedManually: boolean;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      invitingUser?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      user?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        registered: boolean;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminManyEligibleEmailsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyEligibleEmailsQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'EligibleEmailAddress';
    id: string;
    email?: string | null;
    employeeId?: string | null;
    lastName?: string | null;
    isArchived: boolean;
    isStaff: boolean;
    archivedAt?: string | null;
    invitingRelationship?: FamilyRelationship | null;
    hasHpcEligibility: boolean;
    hpcEligibilityStart?: string | null;
    hpcEligibilityEnd?: string | null;
    addedManually: boolean;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
    invitingUser?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      registered: boolean;
    } | null;
  }>;
};

export type AdminGetEligibleEmailQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AdminGetEligibleEmailQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'EligibleEmailAddress';
    id: string;
    email?: string | null;
    employeeId?: string | null;
    lastName?: string | null;
    isArchived: boolean;
    isStaff: boolean;
    archivedAt?: string | null;
    invitingRelationship?: FamilyRelationship | null;
    hasHpcEligibility: boolean;
    hpcEligibilityStart?: string | null;
    hpcEligibilityEnd?: string | null;
    addedManually: boolean;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
    invitingUser?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    user?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      registered: boolean;
    } | null;
  } | null;
};

export type AdminCreateEligibleEmailAddressMutationVariables = Exact<{
  input: CreateEligibleEmailAddressInput;
}>;

export type AdminCreateEligibleEmailAddressMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateEligibleEmailAddressPayload';
    eligibleEmailAddress: {
      __typename?: 'EligibleEmailAddress';
      id: string;
      email?: string | null;
      employeeId?: string | null;
      lastName?: string | null;
      isArchived: boolean;
      isStaff: boolean;
      archivedAt?: string | null;
      invitingRelationship?: FamilyRelationship | null;
      hasHpcEligibility: boolean;
      hpcEligibilityStart?: string | null;
      hpcEligibilityEnd?: string | null;
      addedManually: boolean;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      invitingUser?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      user?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        registered: boolean;
      } | null;
    };
  };
};

export type AdminUpdateEligibleEmailAddressMutationVariables = Exact<{
  input: MutateEligibleEmailAddressInput;
}>;

export type AdminUpdateEligibleEmailAddressMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateEligibleEmailAddressPayload';
    eligibleEmailAddress: {
      __typename?: 'EligibleEmailAddress';
      id: string;
      email?: string | null;
      employeeId?: string | null;
      lastName?: string | null;
      isArchived: boolean;
      isStaff: boolean;
      archivedAt?: string | null;
      invitingRelationship?: FamilyRelationship | null;
      hasHpcEligibility: boolean;
      hpcEligibilityStart?: string | null;
      hpcEligibilityEnd?: string | null;
      addedManually: boolean;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      invitingUser?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      user?: {
        __typename?: 'User';
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        registered: boolean;
      } | null;
    };
  };
};

export type AdminRemoveEligibleEmailAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminRemoveEligibleEmailAddressMutation = {
  __typename?: 'Mutation';
  data: boolean;
};

export type AdminListContentGenresQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminListContentGenresQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    isHidden: boolean;
    parent?: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
    } | null;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
      children: Array<{
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
      }>;
    }>;
  }>;
};

export type AdminGetContentGenreQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetContentGenreQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    isHidden: boolean;
    parent?: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
    } | null;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
      children: Array<{
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
      }>;
    }>;
  } | null;
};

export type AdminManyContentGenresQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyContentGenresQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    isHidden: boolean;
    parent?: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
    } | null;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
      children: Array<{
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
      }>;
    }>;
  }>;
};

export type AdminCreateContentGenreMutationVariables = Exact<{
  input: CreateContentGenreInput;
}>;

export type AdminCreateContentGenreMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateGenrePayload';
    genre: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
      parent?: {
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
      } | null;
      children: Array<{
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
        children: Array<{
          __typename?: 'Genre';
          id: string;
          name: string;
          value: string;
          isHidden: boolean;
        }>;
      }>;
    };
  };
};

export type AdminUpdateContentGenreMutationVariables = Exact<{
  input: UpdateContentGenreInput;
}>;

export type AdminUpdateContentGenreMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateGenrePayload';
    genre: {
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
      parent?: {
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
      } | null;
      children: Array<{
        __typename?: 'Genre';
        id: string;
        name: string;
        value: string;
        isHidden: boolean;
        children: Array<{
          __typename?: 'Genre';
          id: string;
          name: string;
          value: string;
          isHidden: boolean;
        }>;
      }>;
    };
  };
};

export type SyncContentGenresMutationVariables = Exact<{
  [key: string]: never;
}>;

export type SyncContentGenresMutation = {
  __typename?: 'Mutation';
  syncContentGenres: boolean;
};

export type GenreBasicsFragment = {
  __typename?: 'Genre';
  id: string;
  name: string;
  value: string;
  isHidden: boolean;
  parent?: {
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    isHidden: boolean;
  } | null;
  children: Array<{
    __typename?: 'Genre';
    id: string;
    name: string;
    value: string;
    isHidden: boolean;
    children: Array<{
      __typename?: 'Genre';
      id: string;
      name: string;
      value: string;
      isHidden: boolean;
    }>;
  }>;
};

export type GenreFieldsFragment = {
  __typename?: 'Genre';
  id: string;
  name: string;
  value: string;
  isHidden: boolean;
};

export type AdminGetLocationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetLocationQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
    stateCode?: string | null;
    isHeadquarters: boolean;
    mboLocationId?: string | null;
    studio?: {
      __typename?: 'Studio';
      id: string;
      name: string;
      isVirtual: boolean;
    } | null;
    coaches: { __typename?: 'LocationCoachesConnection'; count: number };
  } | null;
};

export type AdminListLocationsQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<CoachLocationsFilter>;
}>;

export type AdminListLocationsQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'CoachLocationsConnection';
    count: number;
    nodes: Array<{
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
      stateCode?: string | null;
      isHeadquarters: boolean;
      mboLocationId?: string | null;
      studio?: {
        __typename?: 'Studio';
        id: string;
        name: string;
        isVirtual: boolean;
      } | null;
      coaches: { __typename?: 'LocationCoachesConnection'; count: number };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminManyLocationsQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyLocationsQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'CoachLocation';
    id: string;
    name?: string | null;
    stateCode?: string | null;
    isHeadquarters: boolean;
    mboLocationId?: string | null;
    studio?: {
      __typename?: 'Studio';
      id: string;
      name: string;
      isVirtual: boolean;
    } | null;
    coaches: { __typename?: 'LocationCoachesConnection'; count: number };
  }>;
};

export type AdminCreateLocationMutationVariables = Exact<{
  input: CreateCoachLocationInput;
}>;

export type AdminCreateLocationMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateCoachLocationPayload';
    coachLocation: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
      stateCode?: string | null;
      isHeadquarters: boolean;
      mboLocationId?: string | null;
      studio?: {
        __typename?: 'Studio';
        id: string;
        name: string;
        isVirtual: boolean;
      } | null;
      coaches: { __typename?: 'LocationCoachesConnection'; count: number };
    };
  };
};

export type AdminUpdateLocationMutationVariables = Exact<{
  input: UpdateCoachLocationInput;
}>;

export type AdminUpdateLocationMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateCoachLocationPayload';
    coachLocation: {
      __typename?: 'CoachLocation';
      id: string;
      name?: string | null;
      stateCode?: string | null;
      isHeadquarters: boolean;
      mboLocationId?: string | null;
      studio?: {
        __typename?: 'Studio';
        id: string;
        name: string;
        isVirtual: boolean;
      } | null;
      coaches: { __typename?: 'LocationCoachesConnection'; count: number };
    };
  };
};

export type AdminLocationDetailsFragment = {
  __typename?: 'CoachLocation';
  id: string;
  name?: string | null;
  stateCode?: string | null;
  isHeadquarters: boolean;
  mboLocationId?: string | null;
  studio?: {
    __typename?: 'Studio';
    id: string;
    name: string;
    isVirtual: boolean;
  } | null;
  coaches: { __typename?: 'LocationCoachesConnection'; count: number };
};

export type LocationCoachesQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
}>;

export type LocationCoachesQuery = {
  __typename?: 'Query';
  coachLocation?: {
    __typename?: 'CoachLocation';
    id: string;
    coaches: {
      __typename?: 'LocationCoachesConnection';
      count: number;
      edges: Array<{
        __typename?: 'LocationCoachesEdge';
        node: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
          email: string;
          coachData?: {
            __typename?: 'CoachData';
            id: string;
            type?: CoachType | null;
            coachingStyle?: CoachingStyle | null;
            availableForMemberAssignment: boolean;
            coachUsers: { __typename?: 'CoachUsersConnection'; count: number };
            locations: {
              __typename?: 'CoachLocationsConnection';
              nodes: Array<{
                __typename?: 'CoachLocation';
                id: string;
                name?: string | null;
              }>;
            };
            supportedCountries: Array<{
              __typename?: 'Country';
              id: string;
              name: string;
            }>;
            coachClients: {
              __typename?: 'CoachClientsConnection';
              edges: Array<{
                __typename?: 'CoachClientsEdge';
                availableForAssignment: boolean;
                supportsHighPerformanceCoaching: boolean;
                supportsWellbeingCoaching: boolean;
                node: {
                  __typename?: 'Client';
                  id: string;
                  displayName: string;
                };
              }>;
            };
          } | null;
        };
      }>;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
    };
  } | null;
};

export type GenerateSignedUrlMutationVariables = Exact<{
  input: SignedUrlInput;
}>;

export type GenerateSignedUrlMutation = {
  __typename?: 'Mutation';
  generateSignedUrl: {
    __typename?: 'SignedUrlPayload';
    signedUrl: string;
    key: string;
    photo: { __typename?: 'Photo'; basePath: string; id: string };
  };
};

export type AdminManyStudiosQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyStudiosQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'Studio';
    id: string;
    mboStudioId: string;
    name: string;
    isVirtual: boolean;
    mboServiceId: string;
    timezone: string;
    isCentral: boolean;
    isArchived: boolean;
    client: { __typename?: 'Client'; id: string; name?: string | null };
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
    };
  }>;
};

export type AdminGetStudioQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetStudioQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'Studio';
    id: string;
    mboStudioId: string;
    name: string;
    isVirtual: boolean;
    mboServiceId: string;
    timezone: string;
    isCentral: boolean;
    isArchived: boolean;
    client: { __typename?: 'Client'; id: string; name?: string | null };
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
    };
  } | null;
};

export type AdminListStudiosQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<StudiosFilter>;
}>;

export type AdminListStudiosQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'StudiosConnection';
    count: number;
    nodes: Array<{
      __typename?: 'Studio';
      id: string;
      mboStudioId: string;
      name: string;
      isVirtual: boolean;
      mboServiceId: string;
      timezone: string;
      isCentral: boolean;
      isArchived: boolean;
      client: { __typename?: 'Client'; id: string; name?: string | null };
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminCreateStudioMutationVariables = Exact<{
  input: CreateStudioInput;
}>;

export type AdminCreateStudioMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateStudioPayload';
    studio: {
      __typename?: 'Studio';
      id: string;
      mboStudioId: string;
      name: string;
      isVirtual: boolean;
      mboServiceId: string;
      timezone: string;
      isCentral: boolean;
      isArchived: boolean;
      client: { __typename?: 'Client'; id: string; name?: string | null };
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
    };
  };
};

export type AdminUpdateStudioMutationVariables = Exact<{
  input: UpdateStudioInput;
}>;

export type AdminUpdateStudioMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'MutateStudioPayload';
    studio: {
      __typename?: 'Studio';
      id: string;
      mboStudioId: string;
      name: string;
      isVirtual: boolean;
      mboServiceId: string;
      timezone: string;
      isCentral: boolean;
      isArchived: boolean;
      client: { __typename?: 'Client'; id: string; name?: string | null };
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
    };
  };
};

export type AdminRemoveStudioMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminRemoveStudioMutation = {
  __typename?: 'Mutation';
  data: boolean;
};

export type AdminStudioDetailsFragment = {
  __typename?: 'Studio';
  id: string;
  mboStudioId: string;
  name: string;
  isVirtual: boolean;
  mboServiceId: string;
  timezone: string;
  isCentral: boolean;
  isArchived: boolean;
  client: { __typename?: 'Client'; id: string; name?: string | null };
  locations: {
    __typename?: 'CoachLocationsConnection';
    nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
  };
};

export type GetSystemPreferencesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSystemPreferencesQuery = {
  __typename?: 'Query';
  systemPreferences: {
    __typename?: 'SystemPreferences';
    minFitAndroidBuild: number;
    minFitIosBuild: number;
    minPerformAndroidBuild: number;
    minPerformIosBuild: number;
  };
};

export type SetMinBuildNumberMutationVariables = Exact<{
  input: SetMinBuildNumberInput;
}>;

export type SetMinBuildNumberMutation = {
  __typename?: 'Mutation';
  setMinBuildNumber: number;
};

export type SelfQueryVariables = Exact<{ [key: string]: never }>;

export type SelfQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    isAdmin: boolean;
    isReadOnlyAdmin: boolean;
    isCoach: boolean;
    app: App;
    birthday?: string | null;
    pronouns?: string | null;
    createdAt: string;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    avatar?: {
      __typename?: 'Photo';
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
  } | null;
};

export type AdminSelfQueryVariables = Exact<{ [key: string]: never }>;

export type AdminSelfQuery = {
  __typename?: 'Query';
  self?: {
    __typename?: 'User';
    id: string;
    isAdmin: boolean;
    isReadOnlyAdmin: boolean;
    isCoach: boolean;
    isMegaAdmin: boolean;
    isTestUser: boolean;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type AdminGetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type AdminGetUserQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'User';
    countryCode?: string | null;
    isFamilyMember: boolean;
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    birthday?: string | null;
    pronouns?: string | null;
    active: boolean;
    isAdmin: boolean;
    isReadOnlyAdmin: boolean;
    isCoach: boolean;
    isMegaAdmin: boolean;
    isSalesTeam: boolean;
    isTestUser: boolean;
    profileComplete: boolean;
    profileJson?: Record<string, unknown> | null;
    createdAt: string;
    hasHighPerformanceCoaching: boolean;
    avatar?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    fitCoach?: {
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    chatRooms: {
      __typename?: 'UserChatRoomsConnection';
      nodes: Array<{ __typename?: 'ChatRoom'; id: string }>;
    };
    fitClient?: {
      __typename?: 'Client';
      id: string;
      name?: string | null;
    } | null;
    currentPractice?: {
      __typename?: 'AssignedPractice';
      id: string;
      practiceProgram?: {
        __typename?: 'PracticeProgram';
        id: string;
        title?: string | null;
      } | null;
    } | null;
    currentProgram?: {
      __typename?: 'AssignedProgram';
      id: string;
      program?: {
        __typename?: 'Program';
        id: string;
        title?: string | null;
      } | null;
    } | null;
    currentCustomPlan?: {
      __typename?: 'Plan';
      id: string;
      name: string;
      startDate: string;
      endDate: string;
      planItems: { __typename?: 'PlanItemConnection'; count: number };
    } | null;
    challenges: {
      __typename?: 'UserChallengesConnection';
      count: number;
      nodes: Array<{ __typename?: 'Challenge'; id: string; title: string }>;
    };
    devices: Array<{
      __typename?: 'Device';
      name: string;
      app?: App | null;
      createdAt: string;
      updatedAt: string;
      enabled: boolean;
    }>;
    eligibleEmailAddress?: {
      __typename?: 'EligibleEmailAddress';
      createdAt: string;
      email?: string | null;
      employeeId?: string | null;
      id: string;
      isArchived: boolean;
      invitingRelationship?: FamilyRelationship | null;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      invitingUser?: { __typename?: 'User'; id: string; email: string } | null;
      user?: { __typename?: 'User'; id: string; email: string } | null;
    } | null;
    familyInvitations: Array<{
      __typename?: 'EligibleEmailAddress';
      createdAt: string;
      email?: string | null;
      employeeId?: string | null;
      id: string;
      isArchived: boolean;
      invitingRelationship?: FamilyRelationship | null;
      client?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      invitingUser?: { __typename?: 'User'; id: string; email: string } | null;
      user?: { __typename?: 'User'; id: string; email: string } | null;
    }>;
    userActivity: {
      __typename?: 'UserActivityConnection';
      count: number;
      nodes: Array<{
        __typename?: 'UserActivity';
        id: string;
        genre?: string | null;
        type: UserActivityType;
        attributionType: UserActivityAttributionType;
        attributionTitle?: string | null;
        title: string;
        startTime: string;
        endTime: string;
        duration?: number | null;
        distance?: {
          __typename?: 'Distance';
          value?: number | null;
          unit?: DistanceUnit | null;
        } | null;
        source?:
          | {
              __typename: 'Article';
              id: string;
              shortDescription?: string | null;
              equipment: Array<string>;
            }
          | {
              __typename: 'Audio';
              id: string;
              shortDescription?: string | null;
            }
          | {
              __typename: 'MboClass';
              id: string;
              description: string;
              staffFirstName?: string | null;
              staffLastName?: string | null;
              location?: {
                __typename?: 'CoachLocation';
                name?: string | null;
              } | null;
            }
          | { __typename: 'PhysicalActivity'; id: string }
          | { __typename: 'Survey'; id: string; surveyIdentifier: string }
          | {
              __typename: 'Video';
              id: string;
              shortDescription?: string | null;
              equipment: Array<string>;
              author?: string | null;
            }
          | { __typename: 'WearableActivity'; id: string }
          | null;
        activity?:
          | { __typename?: 'ArticleActivity' }
          | { __typename?: 'AudioActivity' }
          | { __typename?: 'MboClassActivity' }
          | { __typename?: 'PhysicalActivity' }
          | {
              __typename?: 'SleepActivity';
              id: string;
              sleepEfficiency?: number | null;
              sleepOverview: {
                __typename?: 'SleepOverview';
                inbed: number;
                awake: number;
                asleep: number;
              };
              heartRate?: { __typename?: 'HeartRate'; min: number } | null;
            }
          | { __typename?: 'VideoActivity' }
          | { __typename?: 'WearableActivity' }
          | null;
        attribution?:
          | {
              __typename?: 'AssignedPractice';
              assigningCoach?: {
                __typename?: 'User';
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            }
          | {
              __typename?: 'AssignedProgram';
              assigningCoach?: {
                __typename?: 'User';
                firstName?: string | null;
                lastName?: string | null;
              } | null;
            }
          | null;
      }>;
    };
    studios: {
      __typename?: 'UserStudiosConnection';
      count: number;
      nodes: Array<{ __typename?: 'Studio'; id: string; name: string }>;
    };
    coachData?: {
      __typename?: 'CoachData';
      id: string;
      bio: string;
      passions: Array<string>;
      titles: Array<string>;
      degrees: Array<string>;
      type?: CoachType | null;
      coachingStyle?: CoachingStyle | null;
      fitCoachId?: string | null;
      cronofySub?: string | null;
      cronofyElementToken?: string | null;
      inOfficeDays: Array<number>;
      availableForMemberAssignment: boolean;
      dailySessionLimit?: number | null;
      cronofyTimezone?: string | null;
      calendarConnected: boolean;
      locations: {
        __typename?: 'CoachLocationsConnection';
        nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
      };
      coachClients: {
        __typename?: 'CoachClientsConnection';
        edges: Array<{
          __typename?: 'CoachClientsEdge';
          availableForAssignment: boolean;
          supportsHighPerformanceCoaching: boolean;
          supportsWellbeingCoaching: boolean;
          node: { __typename?: 'Client'; id: string; displayName: string };
        }>;
      };
      clients: {
        __typename?: 'RootClientsConnection';
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          displayName: string;
          videoPlatform?: ClientVideoPlatform | null;
          calendarPlatform?: ClientCalendarPlatform | null;
          studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
        }>;
      };
      bodyPhoto?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      headshot?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      supportedCountries: Array<{
        __typename?: 'Country';
        id: string;
        name: string;
        alpha2: string;
      }>;
      supportedGoals: Array<{
        __typename?: 'Goal';
        type: UserGoalType;
        description: string;
      }>;
      calendarBuffer: {
        __typename?: 'CalendarBuffer';
        beforeInMinutes: number;
        afterInMinutes: number;
      };
      clientRequiredCalendarProfiles: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            calendarPlatform?: ClientCalendarPlatform | null;
          };
        }>;
      };
      clientRequiredConferencingProfiles: {
        __typename?: 'RootClientsConnection';
        edges: Array<{
          __typename?: 'RootClientsEdge';
          node: {
            __typename?: 'Client';
            id: string;
            displayName: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          };
        }>;
      };
      calendarProfiles: Array<{
        __typename?: 'CalendarProfile';
        profileId: string;
        profileName: string;
        profileConnected: boolean;
        platform?: ClientCalendarPlatform | null;
        providerService?: string | null;
      }>;
      conferencingProfiles: Array<{
        __typename?: 'ConferencingProfile';
        providerName: string;
        profileId: string;
        profileName: string;
        profileConnected: boolean;
        platform?: ClientVideoPlatform | null;
      }>;
    } | null;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    platforms?: {
      __typename?: 'CoachData';
      id: string;
      calendarConnected: boolean;
      externalCalendarConnected?: ClientCalendarPlatform | null;
      videoPlatformConnected?: ClientVideoPlatform | null;
      clients: {
        __typename?: 'RootClientsConnection';
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          calendarPlatform?: ClientCalendarPlatform | null;
          videoPlatform?: ClientVideoPlatform | null;
        }>;
      };
    } | null;
  } | null;
};

export type AdminGetUserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type AdminGetUserByEmailQuery = {
  __typename?: 'Query';
  data?: {
    __typename?: 'User';
    createdAt: string;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
  } | null;
};

export type AdminListUsersQueryVariables = Exact<{
  input?: InputMaybe<ConnectionInput>;
  filter?: InputMaybe<UsersFilter>;
}>;

export type AdminListUsersQuery = {
  __typename?: 'Query';
  data: {
    __typename?: 'RootUsersConnection';
    count: number;
    nodes: Array<{
      __typename?: 'User';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      birthday?: string | null;
      pronouns?: string | null;
      active: boolean;
      isAdmin: boolean;
      isReadOnlyAdmin: boolean;
      isCoach: boolean;
      isMegaAdmin: boolean;
      isSalesTeam: boolean;
      isTestUser: boolean;
      profileComplete: boolean;
      profileJson?: Record<string, unknown> | null;
      createdAt: string;
      hasHighPerformanceCoaching: boolean;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      platforms?: {
        __typename?: 'CoachData';
        id: string;
        calendarConnected: boolean;
        externalCalendarConnected?: ClientCalendarPlatform | null;
        videoPlatformConnected?: ClientVideoPlatform | null;
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          }>;
        };
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor: string;
      startCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type AdminManyUsersQueryVariables = Exact<{
  ids: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AdminManyUsersQuery = {
  __typename?: 'Query';
  data: Array<{
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    birthday?: string | null;
    pronouns?: string | null;
    active: boolean;
    isAdmin: boolean;
    isReadOnlyAdmin: boolean;
    isCoach: boolean;
    isMegaAdmin: boolean;
    isSalesTeam: boolean;
    isTestUser: boolean;
    profileComplete: boolean;
    profileJson?: Record<string, unknown> | null;
    createdAt: string;
    hasHighPerformanceCoaching: boolean;
    lastActivity: {
      __typename?: 'LastActivity';
      heart?: string | null;
      heartWeb?: string | null;
    };
    platforms?: {
      __typename?: 'CoachData';
      id: string;
      calendarConnected: boolean;
      externalCalendarConnected?: ClientCalendarPlatform | null;
      videoPlatformConnected?: ClientVideoPlatform | null;
      clients: {
        __typename?: 'RootClientsConnection';
        nodes: Array<{
          __typename?: 'Client';
          id: string;
          calendarPlatform?: ClientCalendarPlatform | null;
          videoPlatform?: ClientVideoPlatform | null;
        }>;
      };
    } | null;
  }>;
};

export type UserAuditLogsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  input?: InputMaybe<ConnectionInput>;
}>;

export type UserAuditLogsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    auditLogs: {
      __typename?: 'AuditLogsConnection';
      count: number;
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor: string;
      };
      edges: Array<{
        __typename?: 'AuditLogEdge';
        node: {
          __typename?: 'AuditLog';
          id: string;
          action: string;
          createdAt: string;
          data?: Record<string, unknown> | null;
          principal:
            | { __typename: 'MachineClient'; id: string }
            | {
                __typename: 'User';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
              };
        };
      }>;
    };
  } | null;
};

export type AdminGetUserCoachSessionsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type AdminGetUserCoachSessionsQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    sessions: {
      __typename?: 'UserCoachSessionsConnection';
      count: number;
      nodes: Array<{
        __typename?: 'CoachSession';
        id: string;
        sessionType?: CoachSessionType | null;
        sessionStyle: CoachSessionStyle;
        attendance?: CoachSessionAttendance | null;
        calendar?: {
          __typename?: 'Calendar';
          id: string;
          start: string;
        } | null;
        coach: {
          __typename?: 'User';
          id: string;
          firstName?: string | null;
          lastName?: string | null;
        };
      }>;
    };
  } | null;
};

export type AdminGetUserAuthHistoryQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;

export type AdminGetUserAuthHistoryQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    authHistory: {
      __typename?: 'UserAuthHistoryConnection';
      nodes: Array<{
        __typename?: 'UserAuthHistory';
        id: string;
        type?: string | null;
        status?: string | null;
        challengeResponses: Array<string>;
        eventDate?: string | null;
      }>;
    };
  } | null;
};

export type CreateUsersMutationVariables = Exact<{
  input: CreateUsersInput;
}>;

export type CreateUsersMutation = {
  __typename?: 'Mutation';
  createUsers: {
    __typename?: 'CreateUsersPayload';
    users: Array<{ __typename?: 'User'; id: string }>;
  };
};

export type AdminCreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type AdminCreateUserMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'CreateUserPayload';
    user: {
      __typename?: 'User';
      countryCode?: string | null;
      isFamilyMember: boolean;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      birthday?: string | null;
      pronouns?: string | null;
      active: boolean;
      isAdmin: boolean;
      isReadOnlyAdmin: boolean;
      isCoach: boolean;
      isMegaAdmin: boolean;
      isSalesTeam: boolean;
      isTestUser: boolean;
      profileComplete: boolean;
      profileJson?: Record<string, unknown> | null;
      createdAt: string;
      hasHighPerformanceCoaching: boolean;
      avatar?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      fitCoach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      chatRooms: {
        __typename?: 'UserChatRoomsConnection';
        nodes: Array<{ __typename?: 'ChatRoom'; id: string }>;
      };
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      currentPractice?: {
        __typename?: 'AssignedPractice';
        id: string;
        practiceProgram?: {
          __typename?: 'PracticeProgram';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentProgram?: {
        __typename?: 'AssignedProgram';
        id: string;
        program?: {
          __typename?: 'Program';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentCustomPlan?: {
        __typename?: 'Plan';
        id: string;
        name: string;
        startDate: string;
        endDate: string;
        planItems: { __typename?: 'PlanItemConnection'; count: number };
      } | null;
      challenges: {
        __typename?: 'UserChallengesConnection';
        count: number;
        nodes: Array<{ __typename?: 'Challenge'; id: string; title: string }>;
      };
      devices: Array<{
        __typename?: 'Device';
        name: string;
        app?: App | null;
        createdAt: string;
        updatedAt: string;
        enabled: boolean;
      }>;
      eligibleEmailAddress?: {
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      } | null;
      familyInvitations: Array<{
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      }>;
      userActivity: {
        __typename?: 'UserActivityConnection';
        count: number;
        nodes: Array<{
          __typename?: 'UserActivity';
          id: string;
          genre?: string | null;
          type: UserActivityType;
          attributionType: UserActivityAttributionType;
          attributionTitle?: string | null;
          title: string;
          startTime: string;
          endTime: string;
          duration?: number | null;
          distance?: {
            __typename?: 'Distance';
            value?: number | null;
            unit?: DistanceUnit | null;
          } | null;
          source?:
            | {
                __typename: 'Article';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
              }
            | {
                __typename: 'Audio';
                id: string;
                shortDescription?: string | null;
              }
            | {
                __typename: 'MboClass';
                id: string;
                description: string;
                staffFirstName?: string | null;
                staffLastName?: string | null;
                location?: {
                  __typename?: 'CoachLocation';
                  name?: string | null;
                } | null;
              }
            | { __typename: 'PhysicalActivity'; id: string }
            | { __typename: 'Survey'; id: string; surveyIdentifier: string }
            | {
                __typename: 'Video';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
                author?: string | null;
              }
            | { __typename: 'WearableActivity'; id: string }
            | null;
          activity?:
            | { __typename?: 'ArticleActivity' }
            | { __typename?: 'AudioActivity' }
            | { __typename?: 'MboClassActivity' }
            | { __typename?: 'PhysicalActivity' }
            | {
                __typename?: 'SleepActivity';
                id: string;
                sleepEfficiency?: number | null;
                sleepOverview: {
                  __typename?: 'SleepOverview';
                  inbed: number;
                  awake: number;
                  asleep: number;
                };
                heartRate?: { __typename?: 'HeartRate'; min: number } | null;
              }
            | { __typename?: 'VideoActivity' }
            | { __typename?: 'WearableActivity' }
            | null;
          attribution?:
            | {
                __typename?: 'AssignedPractice';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | {
                __typename?: 'AssignedProgram';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | null;
        }>;
      };
      studios: {
        __typename?: 'UserStudiosConnection';
        count: number;
        nodes: Array<{ __typename?: 'Studio'; id: string; name: string }>;
      };
      coachData?: {
        __typename?: 'CoachData';
        id: string;
        bio: string;
        passions: Array<string>;
        titles: Array<string>;
        degrees: Array<string>;
        type?: CoachType | null;
        coachingStyle?: CoachingStyle | null;
        fitCoachId?: string | null;
        cronofySub?: string | null;
        cronofyElementToken?: string | null;
        inOfficeDays: Array<number>;
        availableForMemberAssignment: boolean;
        dailySessionLimit?: number | null;
        cronofyTimezone?: string | null;
        calendarConnected: boolean;
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
        coachClients: {
          __typename?: 'CoachClientsConnection';
          edges: Array<{
            __typename?: 'CoachClientsEdge';
            availableForAssignment: boolean;
            supportsHighPerformanceCoaching: boolean;
            supportsWellbeingCoaching: boolean;
            node: { __typename?: 'Client'; id: string; displayName: string };
          }>;
        };
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            displayName: string;
            videoPlatform?: ClientVideoPlatform | null;
            calendarPlatform?: ClientCalendarPlatform | null;
            studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
          }>;
        };
        bodyPhoto?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        headshot?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        supportedCountries: Array<{
          __typename?: 'Country';
          id: string;
          name: string;
          alpha2: string;
        }>;
        supportedGoals: Array<{
          __typename?: 'Goal';
          type: UserGoalType;
          description: string;
        }>;
        calendarBuffer: {
          __typename?: 'CalendarBuffer';
          beforeInMinutes: number;
          afterInMinutes: number;
        };
        clientRequiredCalendarProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
            };
          }>;
        };
        clientRequiredConferencingProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
              videoPlatform?: ClientVideoPlatform | null;
            };
          }>;
        };
        calendarProfiles: Array<{
          __typename?: 'CalendarProfile';
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientCalendarPlatform | null;
          providerService?: string | null;
        }>;
        conferencingProfiles: Array<{
          __typename?: 'ConferencingProfile';
          providerName: string;
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientVideoPlatform | null;
        }>;
      } | null;
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      platforms?: {
        __typename?: 'CoachData';
        id: string;
        calendarConnected: boolean;
        externalCalendarConnected?: ClientCalendarPlatform | null;
        videoPlatformConnected?: ClientVideoPlatform | null;
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          }>;
        };
      } | null;
    };
  };
};

export type AdminUpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
  coachDataInput: UpdateCoachDataInput;
  updateCoachData: Scalars['Boolean']['input'];
  updateAvatar: Scalars['Boolean']['input'];
  avatarInput: UpdateAvatarInput;
}>;

export type AdminUpdateUserMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'UpdateUserPayload';
    user: {
      __typename?: 'User';
      countryCode?: string | null;
      isFamilyMember: boolean;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      birthday?: string | null;
      pronouns?: string | null;
      active: boolean;
      isAdmin: boolean;
      isReadOnlyAdmin: boolean;
      isCoach: boolean;
      isMegaAdmin: boolean;
      isSalesTeam: boolean;
      isTestUser: boolean;
      profileComplete: boolean;
      profileJson?: Record<string, unknown> | null;
      createdAt: string;
      hasHighPerformanceCoaching: boolean;
      avatar?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      fitCoach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      chatRooms: {
        __typename?: 'UserChatRoomsConnection';
        nodes: Array<{ __typename?: 'ChatRoom'; id: string }>;
      };
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      currentPractice?: {
        __typename?: 'AssignedPractice';
        id: string;
        practiceProgram?: {
          __typename?: 'PracticeProgram';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentProgram?: {
        __typename?: 'AssignedProgram';
        id: string;
        program?: {
          __typename?: 'Program';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentCustomPlan?: {
        __typename?: 'Plan';
        id: string;
        name: string;
        startDate: string;
        endDate: string;
        planItems: { __typename?: 'PlanItemConnection'; count: number };
      } | null;
      challenges: {
        __typename?: 'UserChallengesConnection';
        count: number;
        nodes: Array<{ __typename?: 'Challenge'; id: string; title: string }>;
      };
      devices: Array<{
        __typename?: 'Device';
        name: string;
        app?: App | null;
        createdAt: string;
        updatedAt: string;
        enabled: boolean;
      }>;
      eligibleEmailAddress?: {
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      } | null;
      familyInvitations: Array<{
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      }>;
      userActivity: {
        __typename?: 'UserActivityConnection';
        count: number;
        nodes: Array<{
          __typename?: 'UserActivity';
          id: string;
          genre?: string | null;
          type: UserActivityType;
          attributionType: UserActivityAttributionType;
          attributionTitle?: string | null;
          title: string;
          startTime: string;
          endTime: string;
          duration?: number | null;
          distance?: {
            __typename?: 'Distance';
            value?: number | null;
            unit?: DistanceUnit | null;
          } | null;
          source?:
            | {
                __typename: 'Article';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
              }
            | {
                __typename: 'Audio';
                id: string;
                shortDescription?: string | null;
              }
            | {
                __typename: 'MboClass';
                id: string;
                description: string;
                staffFirstName?: string | null;
                staffLastName?: string | null;
                location?: {
                  __typename?: 'CoachLocation';
                  name?: string | null;
                } | null;
              }
            | { __typename: 'PhysicalActivity'; id: string }
            | { __typename: 'Survey'; id: string; surveyIdentifier: string }
            | {
                __typename: 'Video';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
                author?: string | null;
              }
            | { __typename: 'WearableActivity'; id: string }
            | null;
          activity?:
            | { __typename?: 'ArticleActivity' }
            | { __typename?: 'AudioActivity' }
            | { __typename?: 'MboClassActivity' }
            | { __typename?: 'PhysicalActivity' }
            | {
                __typename?: 'SleepActivity';
                id: string;
                sleepEfficiency?: number | null;
                sleepOverview: {
                  __typename?: 'SleepOverview';
                  inbed: number;
                  awake: number;
                  asleep: number;
                };
                heartRate?: { __typename?: 'HeartRate'; min: number } | null;
              }
            | { __typename?: 'VideoActivity' }
            | { __typename?: 'WearableActivity' }
            | null;
          attribution?:
            | {
                __typename?: 'AssignedPractice';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | {
                __typename?: 'AssignedProgram';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | null;
        }>;
      };
      studios: {
        __typename?: 'UserStudiosConnection';
        count: number;
        nodes: Array<{ __typename?: 'Studio'; id: string; name: string }>;
      };
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      platforms?: {
        __typename?: 'CoachData';
        id: string;
        calendarConnected: boolean;
        externalCalendarConnected?: ClientCalendarPlatform | null;
        videoPlatformConnected?: ClientVideoPlatform | null;
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          }>;
        };
      } | null;
      coachData?: {
        __typename?: 'CoachData';
        id: string;
        bio: string;
        passions: Array<string>;
        titles: Array<string>;
        degrees: Array<string>;
        type?: CoachType | null;
        coachingStyle?: CoachingStyle | null;
        fitCoachId?: string | null;
        cronofySub?: string | null;
        cronofyElementToken?: string | null;
        inOfficeDays: Array<number>;
        availableForMemberAssignment: boolean;
        dailySessionLimit?: number | null;
        cronofyTimezone?: string | null;
        calendarConnected: boolean;
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
        coachClients: {
          __typename?: 'CoachClientsConnection';
          edges: Array<{
            __typename?: 'CoachClientsEdge';
            availableForAssignment: boolean;
            supportsHighPerformanceCoaching: boolean;
            supportsWellbeingCoaching: boolean;
            node: { __typename?: 'Client'; id: string; displayName: string };
          }>;
        };
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            displayName: string;
            videoPlatform?: ClientVideoPlatform | null;
            calendarPlatform?: ClientCalendarPlatform | null;
            studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
          }>;
        };
        bodyPhoto?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        headshot?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        supportedCountries: Array<{
          __typename?: 'Country';
          id: string;
          name: string;
          alpha2: string;
        }>;
        supportedGoals: Array<{
          __typename?: 'Goal';
          type: UserGoalType;
          description: string;
        }>;
        calendarBuffer: {
          __typename?: 'CalendarBuffer';
          beforeInMinutes: number;
          afterInMinutes: number;
        };
        clientRequiredCalendarProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
            };
          }>;
        };
        clientRequiredConferencingProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
              videoPlatform?: ClientVideoPlatform | null;
            };
          }>;
        };
        calendarProfiles: Array<{
          __typename?: 'CalendarProfile';
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientCalendarPlatform | null;
          providerService?: string | null;
        }>;
        conferencingProfiles: Array<{
          __typename?: 'ConferencingProfile';
          providerName: string;
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientVideoPlatform | null;
        }>;
      } | null;
    };
  };
  updateCoachData?: {
    __typename?: 'UpdateCoachDataPayload';
    user: {
      __typename?: 'User';
      id: string;
      coachData?: {
        __typename?: 'CoachData';
        id: string;
        bio: string;
        passions: Array<string>;
        titles: Array<string>;
        degrees: Array<string>;
        type?: CoachType | null;
        coachingStyle?: CoachingStyle | null;
        fitCoachId?: string | null;
        cronofySub?: string | null;
        cronofyElementToken?: string | null;
        inOfficeDays: Array<number>;
        availableForMemberAssignment: boolean;
        dailySessionLimit?: number | null;
        cronofyTimezone?: string | null;
        calendarConnected: boolean;
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
        coachClients: {
          __typename?: 'CoachClientsConnection';
          edges: Array<{
            __typename?: 'CoachClientsEdge';
            availableForAssignment: boolean;
            supportsHighPerformanceCoaching: boolean;
            supportsWellbeingCoaching: boolean;
            node: { __typename?: 'Client'; id: string; displayName: string };
          }>;
        };
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            displayName: string;
            videoPlatform?: ClientVideoPlatform | null;
            calendarPlatform?: ClientCalendarPlatform | null;
            studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
          }>;
        };
        bodyPhoto?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        headshot?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        supportedCountries: Array<{
          __typename?: 'Country';
          id: string;
          name: string;
          alpha2: string;
        }>;
        supportedGoals: Array<{
          __typename?: 'Goal';
          type: UserGoalType;
          description: string;
        }>;
        calendarBuffer: {
          __typename?: 'CalendarBuffer';
          beforeInMinutes: number;
          afterInMinutes: number;
        };
        clientRequiredCalendarProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
            };
          }>;
        };
        clientRequiredConferencingProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
              videoPlatform?: ClientVideoPlatform | null;
            };
          }>;
        };
        calendarProfiles: Array<{
          __typename?: 'CalendarProfile';
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientCalendarPlatform | null;
          providerService?: string | null;
        }>;
        conferencingProfiles: Array<{
          __typename?: 'ConferencingProfile';
          providerName: string;
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientVideoPlatform | null;
        }>;
      } | null;
    };
  };
  updateAvatar?: {
    __typename?: 'UpdateAvatarPayload';
    user: { __typename?: 'User'; id: string };
  };
};

export type AdminAdminUpdateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;

export type AdminAdminUpdateUserMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'UpdateUserPayload';
    user: {
      __typename?: 'User';
      countryCode?: string | null;
      isFamilyMember: boolean;
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
      birthday?: string | null;
      pronouns?: string | null;
      active: boolean;
      isAdmin: boolean;
      isReadOnlyAdmin: boolean;
      isCoach: boolean;
      isMegaAdmin: boolean;
      isSalesTeam: boolean;
      isTestUser: boolean;
      profileComplete: boolean;
      profileJson?: Record<string, unknown> | null;
      createdAt: string;
      hasHighPerformanceCoaching: boolean;
      avatar?: {
        __typename?: 'Photo';
        url: string;
        id: string;
        basePath: string;
        preview?: string | null;
      } | null;
      fitCoach?: {
        __typename?: 'User';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      chatRooms: {
        __typename?: 'UserChatRoomsConnection';
        nodes: Array<{ __typename?: 'ChatRoom'; id: string }>;
      };
      fitClient?: {
        __typename?: 'Client';
        id: string;
        name?: string | null;
      } | null;
      currentPractice?: {
        __typename?: 'AssignedPractice';
        id: string;
        practiceProgram?: {
          __typename?: 'PracticeProgram';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentProgram?: {
        __typename?: 'AssignedProgram';
        id: string;
        program?: {
          __typename?: 'Program';
          id: string;
          title?: string | null;
        } | null;
      } | null;
      currentCustomPlan?: {
        __typename?: 'Plan';
        id: string;
        name: string;
        startDate: string;
        endDate: string;
        planItems: { __typename?: 'PlanItemConnection'; count: number };
      } | null;
      challenges: {
        __typename?: 'UserChallengesConnection';
        count: number;
        nodes: Array<{ __typename?: 'Challenge'; id: string; title: string }>;
      };
      devices: Array<{
        __typename?: 'Device';
        name: string;
        app?: App | null;
        createdAt: string;
        updatedAt: string;
        enabled: boolean;
      }>;
      eligibleEmailAddress?: {
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      } | null;
      familyInvitations: Array<{
        __typename?: 'EligibleEmailAddress';
        createdAt: string;
        email?: string | null;
        employeeId?: string | null;
        id: string;
        isArchived: boolean;
        invitingRelationship?: FamilyRelationship | null;
        client?: {
          __typename?: 'Client';
          id: string;
          name?: string | null;
        } | null;
        invitingUser?: {
          __typename?: 'User';
          id: string;
          email: string;
        } | null;
        user?: { __typename?: 'User'; id: string; email: string } | null;
      }>;
      userActivity: {
        __typename?: 'UserActivityConnection';
        count: number;
        nodes: Array<{
          __typename?: 'UserActivity';
          id: string;
          genre?: string | null;
          type: UserActivityType;
          attributionType: UserActivityAttributionType;
          attributionTitle?: string | null;
          title: string;
          startTime: string;
          endTime: string;
          duration?: number | null;
          distance?: {
            __typename?: 'Distance';
            value?: number | null;
            unit?: DistanceUnit | null;
          } | null;
          source?:
            | {
                __typename: 'Article';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
              }
            | {
                __typename: 'Audio';
                id: string;
                shortDescription?: string | null;
              }
            | {
                __typename: 'MboClass';
                id: string;
                description: string;
                staffFirstName?: string | null;
                staffLastName?: string | null;
                location?: {
                  __typename?: 'CoachLocation';
                  name?: string | null;
                } | null;
              }
            | { __typename: 'PhysicalActivity'; id: string }
            | { __typename: 'Survey'; id: string; surveyIdentifier: string }
            | {
                __typename: 'Video';
                id: string;
                shortDescription?: string | null;
                equipment: Array<string>;
                author?: string | null;
              }
            | { __typename: 'WearableActivity'; id: string }
            | null;
          activity?:
            | { __typename?: 'ArticleActivity' }
            | { __typename?: 'AudioActivity' }
            | { __typename?: 'MboClassActivity' }
            | { __typename?: 'PhysicalActivity' }
            | {
                __typename?: 'SleepActivity';
                id: string;
                sleepEfficiency?: number | null;
                sleepOverview: {
                  __typename?: 'SleepOverview';
                  inbed: number;
                  awake: number;
                  asleep: number;
                };
                heartRate?: { __typename?: 'HeartRate'; min: number } | null;
              }
            | { __typename?: 'VideoActivity' }
            | { __typename?: 'WearableActivity' }
            | null;
          attribution?:
            | {
                __typename?: 'AssignedPractice';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | {
                __typename?: 'AssignedProgram';
                assigningCoach?: {
                  __typename?: 'User';
                  firstName?: string | null;
                  lastName?: string | null;
                } | null;
              }
            | null;
        }>;
      };
      studios: {
        __typename?: 'UserStudiosConnection';
        count: number;
        nodes: Array<{ __typename?: 'Studio'; id: string; name: string }>;
      };
      lastActivity: {
        __typename?: 'LastActivity';
        heart?: string | null;
        heartWeb?: string | null;
      };
      platforms?: {
        __typename?: 'CoachData';
        id: string;
        calendarConnected: boolean;
        externalCalendarConnected?: ClientCalendarPlatform | null;
        videoPlatformConnected?: ClientVideoPlatform | null;
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            calendarPlatform?: ClientCalendarPlatform | null;
            videoPlatform?: ClientVideoPlatform | null;
          }>;
        };
      } | null;
      coachData?: {
        __typename?: 'CoachData';
        id: string;
        bio: string;
        passions: Array<string>;
        titles: Array<string>;
        degrees: Array<string>;
        type?: CoachType | null;
        coachingStyle?: CoachingStyle | null;
        fitCoachId?: string | null;
        cronofySub?: string | null;
        cronofyElementToken?: string | null;
        inOfficeDays: Array<number>;
        availableForMemberAssignment: boolean;
        dailySessionLimit?: number | null;
        cronofyTimezone?: string | null;
        calendarConnected: boolean;
        locations: {
          __typename?: 'CoachLocationsConnection';
          nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
        };
        coachClients: {
          __typename?: 'CoachClientsConnection';
          edges: Array<{
            __typename?: 'CoachClientsEdge';
            availableForAssignment: boolean;
            supportsHighPerformanceCoaching: boolean;
            supportsWellbeingCoaching: boolean;
            node: { __typename?: 'Client'; id: string; displayName: string };
          }>;
        };
        clients: {
          __typename?: 'RootClientsConnection';
          nodes: Array<{
            __typename?: 'Client';
            id: string;
            displayName: string;
            videoPlatform?: ClientVideoPlatform | null;
            calendarPlatform?: ClientCalendarPlatform | null;
            studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
          }>;
        };
        bodyPhoto?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        headshot?: {
          __typename?: 'Photo';
          url: string;
          id: string;
          basePath: string;
          preview?: string | null;
        } | null;
        supportedCountries: Array<{
          __typename?: 'Country';
          id: string;
          name: string;
          alpha2: string;
        }>;
        supportedGoals: Array<{
          __typename?: 'Goal';
          type: UserGoalType;
          description: string;
        }>;
        calendarBuffer: {
          __typename?: 'CalendarBuffer';
          beforeInMinutes: number;
          afterInMinutes: number;
        };
        clientRequiredCalendarProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
            };
          }>;
        };
        clientRequiredConferencingProfiles: {
          __typename?: 'RootClientsConnection';
          edges: Array<{
            __typename?: 'RootClientsEdge';
            node: {
              __typename?: 'Client';
              id: string;
              displayName: string;
              calendarPlatform?: ClientCalendarPlatform | null;
              videoPlatform?: ClientVideoPlatform | null;
            };
          }>;
        };
        calendarProfiles: Array<{
          __typename?: 'CalendarProfile';
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientCalendarPlatform | null;
          providerService?: string | null;
        }>;
        conferencingProfiles: Array<{
          __typename?: 'ConferencingProfile';
          providerName: string;
          profileId: string;
          profileName: string;
          profileConnected: boolean;
          platform?: ClientVideoPlatform | null;
        }>;
      } | null;
    };
  };
};

export type AdminResetProgressMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  contentId?: InputMaybe<Scalars['ID']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type AdminResetProgressMutation = {
  __typename?: 'Mutation';
  data: {
    __typename?: 'ResetProgressPayload';
    section?: { __typename?: 'ContentSection'; id: string } | null;
  };
};

export type AdminDeleteUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  jira?: InputMaybe<Scalars['String']['input']>;
  forgetIterable?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AdminDeleteUserMutation = {
  __typename?: 'Mutation';
  data: { __typename?: 'DeleteUserPayload'; userId: string };
};

export type AdminUserBasicsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  birthday?: string | null;
  pronouns?: string | null;
  active: boolean;
  isAdmin: boolean;
  isReadOnlyAdmin: boolean;
  isCoach: boolean;
  isMegaAdmin: boolean;
  isSalesTeam: boolean;
  isTestUser: boolean;
  profileComplete: boolean;
  profileJson?: Record<string, unknown> | null;
  createdAt: string;
  hasHighPerformanceCoaching: boolean;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  platforms?: {
    __typename?: 'CoachData';
    id: string;
    calendarConnected: boolean;
    externalCalendarConnected?: ClientCalendarPlatform | null;
    videoPlatformConnected?: ClientVideoPlatform | null;
    clients: {
      __typename?: 'RootClientsConnection';
      nodes: Array<{
        __typename?: 'Client';
        id: string;
        calendarPlatform?: ClientCalendarPlatform | null;
        videoPlatform?: ClientVideoPlatform | null;
      }>;
    };
  } | null;
};

export type AdminUserDetailsFragment = {
  __typename?: 'User';
  countryCode?: string | null;
  isFamilyMember: boolean;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  birthday?: string | null;
  pronouns?: string | null;
  active: boolean;
  isAdmin: boolean;
  isReadOnlyAdmin: boolean;
  isCoach: boolean;
  isMegaAdmin: boolean;
  isSalesTeam: boolean;
  isTestUser: boolean;
  profileComplete: boolean;
  profileJson?: Record<string, unknown> | null;
  createdAt: string;
  hasHighPerformanceCoaching: boolean;
  avatar?: {
    __typename?: 'Photo';
    url: string;
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
  fitCoach?: {
    __typename?: 'User';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  chatRooms: {
    __typename?: 'UserChatRoomsConnection';
    nodes: Array<{ __typename?: 'ChatRoom'; id: string }>;
  };
  fitClient?: {
    __typename?: 'Client';
    id: string;
    name?: string | null;
  } | null;
  currentPractice?: {
    __typename?: 'AssignedPractice';
    id: string;
    practiceProgram?: {
      __typename?: 'PracticeProgram';
      id: string;
      title?: string | null;
    } | null;
  } | null;
  currentProgram?: {
    __typename?: 'AssignedProgram';
    id: string;
    program?: {
      __typename?: 'Program';
      id: string;
      title?: string | null;
    } | null;
  } | null;
  currentCustomPlan?: {
    __typename?: 'Plan';
    id: string;
    name: string;
    startDate: string;
    endDate: string;
    planItems: { __typename?: 'PlanItemConnection'; count: number };
  } | null;
  challenges: {
    __typename?: 'UserChallengesConnection';
    count: number;
    nodes: Array<{ __typename?: 'Challenge'; id: string; title: string }>;
  };
  devices: Array<{
    __typename?: 'Device';
    name: string;
    app?: App | null;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
  }>;
  eligibleEmailAddress?: {
    __typename?: 'EligibleEmailAddress';
    createdAt: string;
    email?: string | null;
    employeeId?: string | null;
    id: string;
    isArchived: boolean;
    invitingRelationship?: FamilyRelationship | null;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
    invitingUser?: { __typename?: 'User'; id: string; email: string } | null;
    user?: { __typename?: 'User'; id: string; email: string } | null;
  } | null;
  familyInvitations: Array<{
    __typename?: 'EligibleEmailAddress';
    createdAt: string;
    email?: string | null;
    employeeId?: string | null;
    id: string;
    isArchived: boolean;
    invitingRelationship?: FamilyRelationship | null;
    client?: { __typename?: 'Client'; id: string; name?: string | null } | null;
    invitingUser?: { __typename?: 'User'; id: string; email: string } | null;
    user?: { __typename?: 'User'; id: string; email: string } | null;
  }>;
  userActivity: {
    __typename?: 'UserActivityConnection';
    count: number;
    nodes: Array<{
      __typename?: 'UserActivity';
      id: string;
      genre?: string | null;
      type: UserActivityType;
      attributionType: UserActivityAttributionType;
      attributionTitle?: string | null;
      title: string;
      startTime: string;
      endTime: string;
      duration?: number | null;
      distance?: {
        __typename?: 'Distance';
        value?: number | null;
        unit?: DistanceUnit | null;
      } | null;
      source?:
        | {
            __typename: 'Article';
            id: string;
            shortDescription?: string | null;
            equipment: Array<string>;
          }
        | { __typename: 'Audio'; id: string; shortDescription?: string | null }
        | {
            __typename: 'MboClass';
            id: string;
            description: string;
            staffFirstName?: string | null;
            staffLastName?: string | null;
            location?: {
              __typename?: 'CoachLocation';
              name?: string | null;
            } | null;
          }
        | { __typename: 'PhysicalActivity'; id: string }
        | { __typename: 'Survey'; id: string; surveyIdentifier: string }
        | {
            __typename: 'Video';
            id: string;
            shortDescription?: string | null;
            equipment: Array<string>;
            author?: string | null;
          }
        | { __typename: 'WearableActivity'; id: string }
        | null;
      activity?:
        | { __typename?: 'ArticleActivity' }
        | { __typename?: 'AudioActivity' }
        | { __typename?: 'MboClassActivity' }
        | { __typename?: 'PhysicalActivity' }
        | {
            __typename?: 'SleepActivity';
            id: string;
            sleepEfficiency?: number | null;
            sleepOverview: {
              __typename?: 'SleepOverview';
              inbed: number;
              awake: number;
              asleep: number;
            };
            heartRate?: { __typename?: 'HeartRate'; min: number } | null;
          }
        | { __typename?: 'VideoActivity' }
        | { __typename?: 'WearableActivity' }
        | null;
      attribution?:
        | {
            __typename?: 'AssignedPractice';
            assigningCoach?: {
              __typename?: 'User';
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          }
        | {
            __typename?: 'AssignedProgram';
            assigningCoach?: {
              __typename?: 'User';
              firstName?: string | null;
              lastName?: string | null;
            } | null;
          }
        | null;
    }>;
  };
  studios: {
    __typename?: 'UserStudiosConnection';
    count: number;
    nodes: Array<{ __typename?: 'Studio'; id: string; name: string }>;
  };
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  platforms?: {
    __typename?: 'CoachData';
    id: string;
    calendarConnected: boolean;
    externalCalendarConnected?: ClientCalendarPlatform | null;
    videoPlatformConnected?: ClientVideoPlatform | null;
    clients: {
      __typename?: 'RootClientsConnection';
      nodes: Array<{
        __typename?: 'Client';
        id: string;
        calendarPlatform?: ClientCalendarPlatform | null;
        videoPlatform?: ClientVideoPlatform | null;
      }>;
    };
  } | null;
  coachData?: {
    __typename?: 'CoachData';
    id: string;
    bio: string;
    passions: Array<string>;
    titles: Array<string>;
    degrees: Array<string>;
    type?: CoachType | null;
    coachingStyle?: CoachingStyle | null;
    fitCoachId?: string | null;
    cronofySub?: string | null;
    cronofyElementToken?: string | null;
    inOfficeDays: Array<number>;
    availableForMemberAssignment: boolean;
    dailySessionLimit?: number | null;
    cronofyTimezone?: string | null;
    calendarConnected: boolean;
    locations: {
      __typename?: 'CoachLocationsConnection';
      nodes: Array<{ __typename?: 'CoachLocation'; id: string }>;
    };
    coachClients: {
      __typename?: 'CoachClientsConnection';
      edges: Array<{
        __typename?: 'CoachClientsEdge';
        availableForAssignment: boolean;
        supportsHighPerformanceCoaching: boolean;
        supportsWellbeingCoaching: boolean;
        node: { __typename?: 'Client'; id: string; displayName: string };
      }>;
    };
    clients: {
      __typename?: 'RootClientsConnection';
      nodes: Array<{
        __typename?: 'Client';
        id: string;
        displayName: string;
        videoPlatform?: ClientVideoPlatform | null;
        calendarPlatform?: ClientCalendarPlatform | null;
        studios: Array<{ __typename?: 'Studio'; id: string; name: string }>;
      }>;
    };
    bodyPhoto?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    headshot?: {
      __typename?: 'Photo';
      url: string;
      id: string;
      basePath: string;
      preview?: string | null;
    } | null;
    supportedCountries: Array<{
      __typename?: 'Country';
      id: string;
      name: string;
      alpha2: string;
    }>;
    supportedGoals: Array<{
      __typename?: 'Goal';
      type: UserGoalType;
      description: string;
    }>;
    calendarBuffer: {
      __typename?: 'CalendarBuffer';
      beforeInMinutes: number;
      afterInMinutes: number;
    };
    clientRequiredCalendarProfiles: {
      __typename?: 'RootClientsConnection';
      edges: Array<{
        __typename?: 'RootClientsEdge';
        node: {
          __typename?: 'Client';
          id: string;
          displayName: string;
          calendarPlatform?: ClientCalendarPlatform | null;
        };
      }>;
    };
    clientRequiredConferencingProfiles: {
      __typename?: 'RootClientsConnection';
      edges: Array<{
        __typename?: 'RootClientsEdge';
        node: {
          __typename?: 'Client';
          id: string;
          displayName: string;
          calendarPlatform?: ClientCalendarPlatform | null;
          videoPlatform?: ClientVideoPlatform | null;
        };
      }>;
    };
    calendarProfiles: Array<{
      __typename?: 'CalendarProfile';
      profileId: string;
      profileName: string;
      profileConnected: boolean;
      platform?: ClientCalendarPlatform | null;
      providerService?: string | null;
    }>;
    conferencingProfiles: Array<{
      __typename?: 'ConferencingProfile';
      providerName: string;
      profileId: string;
      profileName: string;
      profileConnected: boolean;
      platform?: ClientVideoPlatform | null;
    }>;
  } | null;
};

export type UserAuthHistoryFragment = {
  __typename?: 'User';
  authHistory: {
    __typename?: 'UserAuthHistoryConnection';
    nodes: Array<{
      __typename?: 'UserAuthHistory';
      id: string;
      type?: string | null;
      status?: string | null;
      challengeResponses: Array<string>;
      eventDate?: string | null;
    }>;
  };
};

export type UserBasicsFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  createdAt: string;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type UserDetailsFragment = {
  __typename?: 'User';
  birthday?: string | null;
  pronouns?: string | null;
  createdAt: string;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  lastActivity: {
    __typename?: 'LastActivity';
    heart?: string | null;
    heartWeb?: string | null;
  };
  avatar?: {
    __typename?: 'Photo';
    id: string;
    basePath: string;
    preview?: string | null;
  } | null;
};

export type PhotoBasicsFragment = {
  __typename?: 'Photo';
  id: string;
  basePath: string;
  preview?: string | null;
};

export type LastActivityBasicsFragment = {
  __typename?: 'LastActivity';
  heart?: string | null;
  heartWeb?: string | null;
};

export const PhotoBasicsFragmentDoc = gql`
    fragment PhotoBasics on Photo {
  id
  basePath
  preview
}
    `;
export const ChallengeBasicsFragmentDoc = gql`
    fragment ChallengeBasics on Challenge {
  id
  title
  subtitle
  description
  badge {
    ...PhotoBasics
    url(width: 100, height: 100, extension: "png")
  }
  background {
    ...PhotoBasics
    url(width: 780, height: 340, extension: "png")
  }
  qualifyingGenres
  challengeType
  unit
  startDateTime
  endDateTime
  enabled
  isArchived
  clientSpecific
  challenger {
    id
    firstName
    lastName
  }
  clients {
    count
    nodes {
      id
      name
    }
  }
  featured
  goal
  participants {
    count
  }
  team
  teams {
    count
    nodes {
      id
      name
    }
  }
}
    ${PhotoBasicsFragmentDoc}`;
export const AdminChatRoomDetailsFragmentDoc = gql`
    fragment AdminChatRoomDetails on ChatRoom {
  id
  name
  kind
  app
  isArchived
  lastMessageReceivedAt
  messages {
    count
  }
  users {
    count
    nodes {
      id
      firstName
      lastName
      email
    }
  }
}
    `;
export const CoachDetailsFragmentDoc = gql`
    fragment CoachDetails on User {
  id
  firstName
  lastName
  email
  coachData {
    coachUsers {
      count
    }
    id
    type
    coachingStyle
    locations {
      nodes {
        id
        name
      }
    }
    supportedCountries {
      id
      name
    }
    availableForMemberAssignment
    coachClients {
      edges {
        availableForAssignment
        supportsHighPerformanceCoaching
        supportsWellbeingCoaching
        node {
          id
          displayName
        }
      }
    }
  }
}
    `;
export const AdminClientBasicsFragmentDoc = gql`
    fragment AdminClientBasics on Client {
  id
  name
  displayName
  isArchived
  users {
    count
  }
}
    `;
export const DomainBasicsFragmentDoc = gql`
    fragment DomainBasics on Domain {
  id
  domain
  client {
    id
    name
  }
}
    `;
export const AdminStudioDetailsFragmentDoc = gql`
    fragment AdminStudioDetails on Studio {
  id
  mboStudioId
  name
  isVirtual
  mboServiceId
  timezone
  isCentral
  isArchived
  client {
    id
    name
  }
  locations {
    nodes {
      id
    }
  }
}
    `;
export const AdminClientDetailsFragmentDoc = gql`
    fragment AdminClientDetails on Client {
  id
  isArchived
  name
  contactName
  displayName
  contactEmail
  usesPersonalTraining
  usesHighPerformanceCoaching
  usesFitnessCenterForEligibility
  usesEligibilityServiceType
  usesMboRegistrationFlow
  usesCustomChallenges
  needsUsToFixTheirUserNames
  usesSSO
  calendarPlatform
  videoPlatform
  collectsMemberData
  collectsMemberDataForEEP
  maxFamilyMembers
  inactivityDuration
  strictAppCheck
  logo {
    ...PhotoBasics
    url(width: 190, height: 0, extension: "png")
  }
  domains {
    nodes {
      ...DomainBasics
    }
  }
  studios(includeCentralStudio: true, includeArchived: true) {
    ...AdminStudioDetails
  }
  users {
    count
    nodes {
      id
      firstName
      lastName
      email
    }
  }
  coaches {
    count
    nodes {
      id
      firstName
      lastName
      email
    }
  }
}
    ${PhotoBasicsFragmentDoc}
${DomainBasicsFragmentDoc}
${AdminStudioDetailsFragmentDoc}`;
export const AdminEligibleEmailDetailsFragmentDoc = gql`
    fragment AdminEligibleEmailDetails on EligibleEmailAddress {
  id
  client {
    id
    name
  }
  email
  employeeId
  lastName
  isArchived
  isStaff
  archivedAt
  invitingRelationship
  invitingUser {
    id
    email
    firstName
    lastName
  }
  user {
    id
    email
    firstName
    lastName
    registered
  }
  hasHpcEligibility
  hpcEligibilityStart
  hpcEligibilityEnd
  addedManually
}
    `;
export const GenreFieldsFragmentDoc = gql`
    fragment GenreFields on Genre {
  id
  name
  value
  isHidden
}
    `;
export const GenreBasicsFragmentDoc = gql`
    fragment GenreBasics on Genre {
  ...GenreFields
  parent {
    ...GenreFields
  }
  children(filter: {includeHidden: true}) {
    ...GenreFields
    children(filter: {includeHidden: true}) {
      ...GenreFields
    }
  }
}
    ${GenreFieldsFragmentDoc}`;
export const AdminLocationDetailsFragmentDoc = gql`
    fragment AdminLocationDetails on CoachLocation {
  id
  name
  stateCode
  isHeadquarters
  mboLocationId
  studio {
    id
    name
    isVirtual
  }
  coaches {
    count
  }
}
    `;
export const LastActivityBasicsFragmentDoc = gql`
    fragment LastActivityBasics on LastActivity {
  heart
  heartWeb
}
    `;
export const AdminUserBasicsFragmentDoc = gql`
    fragment AdminUserBasics on User {
  id
  firstName
  lastName
  email
  birthday
  pronouns
  active
  isAdmin
  isReadOnlyAdmin
  isCoach
  isMegaAdmin
  isSalesTeam
  isTestUser
  profileComplete
  profileJson
  createdAt
  hasHighPerformanceCoaching
  lastActivity {
    ...LastActivityBasics
  }
  platforms: coachData {
    id
    calendarConnected
    externalCalendarConnected
    videoPlatformConnected
    clients(input: {first: 1}) {
      nodes {
        id
        calendarPlatform
        videoPlatform
      }
    }
  }
}
    ${LastActivityBasicsFragmentDoc}`;
export const CoachPhotoFragmentDoc = gql`
    fragment CoachPhoto on Photo {
  ...PhotoBasics
  url(width: 300, height: 300)
}
    ${PhotoBasicsFragmentDoc}`;
export const CalendarBufferFragmentDoc = gql`
    fragment CalendarBuffer on CalendarBuffer {
  beforeInMinutes
  afterInMinutes
}
    `;
export const ClientCalendarProfilesBasicsFragmentDoc = gql`
    fragment ClientCalendarProfilesBasics on Client {
  id
  displayName
  calendarPlatform
}
    `;
export const ClientConferencingProfilesBasicsFragmentDoc = gql`
    fragment ClientConferencingProfilesBasics on Client {
  id
  displayName
  calendarPlatform
  videoPlatform
}
    `;
export const CalendarProfileDetailsFragmentDoc = gql`
    fragment CalendarProfileDetails on CalendarProfile {
  profileId
  profileName
  profileConnected
  platform
  providerService
}
    `;
export const ConferencingProfileDetailsFragmentDoc = gql`
    fragment ConferencingProfileDetails on ConferencingProfile {
  providerName
  profileId
  profileName
  profileConnected
  platform
}
    `;
export const ConnectionDetailsFragmentDoc = gql`
    fragment ConnectionDetails on CoachData {
  id
  clientRequiredCalendarProfiles: clients {
    edges {
      node {
        ...ClientCalendarProfilesBasics
      }
    }
  }
  clientRequiredConferencingProfiles: clients {
    edges {
      node {
        ...ClientConferencingProfilesBasics
      }
    }
  }
  calendarConnected
  calendarProfiles {
    ...CalendarProfileDetails
  }
  conferencingProfiles {
    ...ConferencingProfileDetails
  }
}
    ${ClientCalendarProfilesBasicsFragmentDoc}
${ClientConferencingProfilesBasicsFragmentDoc}
${CalendarProfileDetailsFragmentDoc}
${ConferencingProfileDetailsFragmentDoc}`;
export const AdminCoachDataDetailsFragmentDoc = gql`
    fragment AdminCoachDataDetails on User {
  coachData {
    id
    bio
    passions
    titles
    degrees
    type
    coachingStyle
    fitCoachId
    cronofySub
    cronofyElementToken(permissions: [availability])
    inOfficeDays
    availableForMemberAssignment
    locations {
      nodes {
        id
      }
    }
    coachClients {
      edges {
        availableForAssignment
        supportsHighPerformanceCoaching
        supportsWellbeingCoaching
        node {
          id
          displayName
        }
      }
    }
    clients {
      nodes {
        id
        displayName
        videoPlatform
        calendarPlatform
        studios {
          id
          name
        }
      }
    }
    bodyPhoto {
      ...PhotoBasics
      url(width: 300, height: 200)
    }
    headshot {
      ...CoachPhoto
    }
    supportedCountries {
      id
      name
      alpha2
    }
    supportedGoals {
      type
      description
    }
    calendarBuffer {
      ...CalendarBuffer
    }
    dailySessionLimit
    cronofyTimezone
    ...ConnectionDetails
  }
}
    ${PhotoBasicsFragmentDoc}
${CoachPhotoFragmentDoc}
${CalendarBufferFragmentDoc}
${ConnectionDetailsFragmentDoc}`;
export const AdminEligibleEmailFieldsFragmentDoc = gql`
    fragment AdminEligibleEmailFields on EligibleEmailAddress {
  client {
    id
    name
  }
  createdAt
  email
  employeeId
  id
  isArchived
  invitingRelationship
  invitingUser {
    id
    email
  }
  user {
    id
    email
  }
}
    `;
export const SleepOverviewFragmentDoc = gql`
    fragment SleepOverview on SleepOverview {
  inbed
  awake
  asleep
}
    `;
export const HeartRateFragmentDoc = gql`
    fragment HeartRate on HeartRate {
  min
}
    `;
export const SleepActivityFragmentDoc = gql`
    fragment SleepActivity on SleepActivity {
  id
  sleepEfficiency
  sleepOverview {
    ...SleepOverview
  }
  heartRate {
    ...HeartRate
  }
}
    ${SleepOverviewFragmentDoc}
${HeartRateFragmentDoc}`;
export const PracticeAttributionFragmentDoc = gql`
    fragment PracticeAttribution on AssignedPractice {
  assigningCoach {
    firstName
    lastName
  }
}
    `;
export const ProgramAttributionFragmentDoc = gql`
    fragment ProgramAttribution on AssignedProgram {
  assigningCoach {
    firstName
    lastName
  }
}
    `;
export const ArticleSourceFragmentDoc = gql`
    fragment ArticleSource on Article {
  id
  shortDescription
  equipment
}
    `;
export const AudioSourceFragmentDoc = gql`
    fragment AudioSource on Audio {
  id
  shortDescription
}
    `;
export const VideoSourceFragmentDoc = gql`
    fragment VideoSource on Video {
  id
  shortDescription
  equipment
  author
}
    `;
export const PhysicalActivitySourceFragmentDoc = gql`
    fragment PhysicalActivitySource on PhysicalActivity {
  id
}
    `;
export const MboClassSourceFragmentDoc = gql`
    fragment MboClassSource on MboClass {
  id
  location {
    name
  }
  description
  staffFirstName
  staffLastName
}
    `;
export const SurveySourceFragmentDoc = gql`
    fragment SurveySource on Survey {
  id
  surveyIdentifier
}
    `;
export const WearableActivitySourceFragmentDoc = gql`
    fragment WearableActivitySource on WearableActivity {
  id
}
    `;
export const ActivitySourceFragmentDoc = gql`
    fragment ActivitySource on ActivitySource {
  ...ArticleSource
  ...AudioSource
  ...VideoSource
  ...PhysicalActivitySource
  ...MboClassSource
  ...SurveySource
  ...WearableActivitySource
}
    ${ArticleSourceFragmentDoc}
${AudioSourceFragmentDoc}
${VideoSourceFragmentDoc}
${PhysicalActivitySourceFragmentDoc}
${MboClassSourceFragmentDoc}
${SurveySourceFragmentDoc}
${WearableActivitySourceFragmentDoc}`;
export const UserActivityBasicsFragmentDoc = gql`
    fragment UserActivityBasics on UserActivity {
  id
  genre
  type
  attributionType
  attributionTitle
  title
  startTime
  endTime
  distance {
    value
    unit
  }
  duration
  source {
    __typename
    ... on Survey {
      id
      surveyIdentifier
    }
  }
  activity {
    ...SleepActivity
  }
  attribution {
    ... on AssignedPractice {
      ...PracticeAttribution
    }
    ... on AssignedProgram {
      ...ProgramAttribution
    }
  }
  source {
    ...ActivitySource
  }
}
    ${SleepActivityFragmentDoc}
${PracticeAttributionFragmentDoc}
${ProgramAttributionFragmentDoc}
${ActivitySourceFragmentDoc}`;
export const AdminUserDetailsFragmentDoc = gql`
    fragment AdminUserDetails on User {
  ...AdminUserBasics
  ...AdminCoachDataDetails
  countryCode
  isFamilyMember
  avatar {
    ...CoachPhoto
  }
  fitCoach {
    id
    firstName
    lastName
  }
  chatRooms {
    nodes {
      id
    }
  }
  fitClient {
    id
    name
  }
  currentPractice {
    id
    practiceProgram {
      id
      title
    }
  }
  currentProgram {
    id
    program {
      id
      title
    }
  }
  currentCustomPlan {
    id
    name
    startDate
    endDate
    planItems {
      count
    }
  }
  challenges {
    count
    nodes {
      id
      title
    }
  }
  devices {
    name
    app
    createdAt
    updatedAt
    enabled
  }
  eligibleEmailAddress {
    ...AdminEligibleEmailFields
  }
  familyInvitations {
    ...AdminEligibleEmailFields
  }
  userActivity {
    count
    nodes {
      ...UserActivityBasics
    }
  }
  studios {
    count
    nodes {
      id
      name
    }
  }
}
    ${AdminUserBasicsFragmentDoc}
${AdminCoachDataDetailsFragmentDoc}
${CoachPhotoFragmentDoc}
${AdminEligibleEmailFieldsFragmentDoc}
${UserActivityBasicsFragmentDoc}`;
export const UserAuthHistoryFragmentDoc = gql`
    fragment UserAuthHistory on User {
  authHistory {
    nodes {
      id
      type
      status
      challengeResponses
      eventDate
    }
  }
}
    `;
export const UserBasicsFragmentDoc = gql`
    fragment UserBasics on User {
  id
  firstName
  lastName
  email
  createdAt
  lastActivity {
    ...LastActivityBasics
  }
  avatar {
    ...PhotoBasics
  }
}
    ${LastActivityBasicsFragmentDoc}
${PhotoBasicsFragmentDoc}`;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on User {
  ...UserBasics
  birthday
  pronouns
  createdAt
}
    ${UserBasicsFragmentDoc}`;
export const AdminGetChallengeDocument = gql`
    query AdminGetChallenge($id: ID!) {
  data: challenge(id: $id) {
    ...ChallengeBasics
  }
}
    ${ChallengeBasicsFragmentDoc}`;

/**
 * __useAdminGetChallengeQuery__
 *
 * To run a query within a React component, call `useAdminGetChallengeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetChallengeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetChallengeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetChallengeQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetChallengeQuery,
    AdminGetChallengeQueryVariables
  > &
    (
      | { variables: AdminGetChallengeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetChallengeQuery,
    AdminGetChallengeQueryVariables
  >(AdminGetChallengeDocument, options);
}
export function useAdminGetChallengeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetChallengeQuery,
    AdminGetChallengeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetChallengeQuery,
    AdminGetChallengeQueryVariables
  >(AdminGetChallengeDocument, options);
}
export function useAdminGetChallengeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetChallengeQuery,
        AdminGetChallengeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetChallengeQuery,
    AdminGetChallengeQueryVariables
  >(AdminGetChallengeDocument, options);
}
export type AdminGetChallengeQueryHookResult = ReturnType<
  typeof useAdminGetChallengeQuery
>;
export type AdminGetChallengeLazyQueryHookResult = ReturnType<
  typeof useAdminGetChallengeLazyQuery
>;
export type AdminGetChallengeSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetChallengeSuspenseQuery
>;
export type AdminGetChallengeQueryResult = Apollo.QueryResult<
  AdminGetChallengeQuery,
  AdminGetChallengeQueryVariables
>;
export const AdminListChallengesDocument = gql`
    query AdminListChallenges($input: ConnectionInput, $filter: ChallengeFilter, $sort: ChallengeSort) {
  data: challenges(input: $input, filter: $filter, sort: $sort) {
    nodes {
      ...ChallengeBasics
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ChallengeBasicsFragmentDoc}`;

/**
 * __useAdminListChallengesQuery__
 *
 * To run a query within a React component, call `useAdminListChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListChallengesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminListChallengesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListChallengesQuery,
    AdminListChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListChallengesQuery,
    AdminListChallengesQueryVariables
  >(AdminListChallengesDocument, options);
}
export function useAdminListChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListChallengesQuery,
    AdminListChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListChallengesQuery,
    AdminListChallengesQueryVariables
  >(AdminListChallengesDocument, options);
}
export function useAdminListChallengesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListChallengesQuery,
        AdminListChallengesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListChallengesQuery,
    AdminListChallengesQueryVariables
  >(AdminListChallengesDocument, options);
}
export type AdminListChallengesQueryHookResult = ReturnType<
  typeof useAdminListChallengesQuery
>;
export type AdminListChallengesLazyQueryHookResult = ReturnType<
  typeof useAdminListChallengesLazyQuery
>;
export type AdminListChallengesSuspenseQueryHookResult = ReturnType<
  typeof useAdminListChallengesSuspenseQuery
>;
export type AdminListChallengesQueryResult = Apollo.QueryResult<
  AdminListChallengesQuery,
  AdminListChallengesQueryVariables
>;
export const AdminManyChallengesDocument = gql`
    query AdminManyChallenges($ids: [ID!]!) {
  data: manyChallenges(ids: $ids) {
    ...ChallengeBasics
  }
}
    ${ChallengeBasicsFragmentDoc}`;

/**
 * __useAdminManyChallengesQuery__
 *
 * To run a query within a React component, call `useAdminManyChallengesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyChallengesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyChallengesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyChallengesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyChallengesQuery,
    AdminManyChallengesQueryVariables
  > &
    (
      | { variables: AdminManyChallengesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminManyChallengesQuery,
    AdminManyChallengesQueryVariables
  >(AdminManyChallengesDocument, options);
}
export function useAdminManyChallengesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyChallengesQuery,
    AdminManyChallengesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyChallengesQuery,
    AdminManyChallengesQueryVariables
  >(AdminManyChallengesDocument, options);
}
export function useAdminManyChallengesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyChallengesQuery,
        AdminManyChallengesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyChallengesQuery,
    AdminManyChallengesQueryVariables
  >(AdminManyChallengesDocument, options);
}
export type AdminManyChallengesQueryHookResult = ReturnType<
  typeof useAdminManyChallengesQuery
>;
export type AdminManyChallengesLazyQueryHookResult = ReturnType<
  typeof useAdminManyChallengesLazyQuery
>;
export type AdminManyChallengesSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyChallengesSuspenseQuery
>;
export type AdminManyChallengesQueryResult = Apollo.QueryResult<
  AdminManyChallengesQuery,
  AdminManyChallengesQueryVariables
>;
export const AdminCreateChallengeDocument = gql`
    mutation AdminCreateChallenge($input: CreateChallengeInput!) {
  data: createChallenge(input: $input) {
    challenge {
      ...ChallengeBasics
    }
  }
}
    ${ChallengeBasicsFragmentDoc}`;
export type AdminCreateChallengeMutationFn = Apollo.MutationFunction<
  AdminCreateChallengeMutation,
  AdminCreateChallengeMutationVariables
>;

/**
 * __useAdminCreateChallengeMutation__
 *
 * To run a mutation, you first call `useAdminCreateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateChallengeMutation, { data, loading, error }] = useAdminCreateChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateChallengeMutation,
    AdminCreateChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateChallengeMutation,
    AdminCreateChallengeMutationVariables
  >(AdminCreateChallengeDocument, options);
}
export type AdminCreateChallengeMutationHookResult = ReturnType<
  typeof useAdminCreateChallengeMutation
>;
export type AdminCreateChallengeMutationResult =
  Apollo.MutationResult<AdminCreateChallengeMutation>;
export type AdminCreateChallengeMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateChallengeMutation,
  AdminCreateChallengeMutationVariables
>;
export const AdminUpdateChallengeDocument = gql`
    mutation AdminUpdateChallenge($input: UpdateChallengeInput!) {
  data: updateChallenge(input: $input) {
    challenge {
      ...ChallengeBasics
    }
  }
}
    ${ChallengeBasicsFragmentDoc}`;
export type AdminUpdateChallengeMutationFn = Apollo.MutationFunction<
  AdminUpdateChallengeMutation,
  AdminUpdateChallengeMutationVariables
>;

/**
 * __useAdminUpdateChallengeMutation__
 *
 * To run a mutation, you first call `useAdminUpdateChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateChallengeMutation, { data, loading, error }] = useAdminUpdateChallengeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateChallengeMutation,
    AdminUpdateChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateChallengeMutation,
    AdminUpdateChallengeMutationVariables
  >(AdminUpdateChallengeDocument, options);
}
export type AdminUpdateChallengeMutationHookResult = ReturnType<
  typeof useAdminUpdateChallengeMutation
>;
export type AdminUpdateChallengeMutationResult =
  Apollo.MutationResult<AdminUpdateChallengeMutation>;
export type AdminUpdateChallengeMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateChallengeMutation,
  AdminUpdateChallengeMutationVariables
>;
export const AdminRemoveChallengeDocument = gql`
    mutation AdminRemoveChallenge($id: ID!) {
  data: removeChallenge(id: $id)
}
    `;
export type AdminRemoveChallengeMutationFn = Apollo.MutationFunction<
  AdminRemoveChallengeMutation,
  AdminRemoveChallengeMutationVariables
>;

/**
 * __useAdminRemoveChallengeMutation__
 *
 * To run a mutation, you first call `useAdminRemoveChallengeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveChallengeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveChallengeMutation, { data, loading, error }] = useAdminRemoveChallengeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveChallengeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveChallengeMutation,
    AdminRemoveChallengeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminRemoveChallengeMutation,
    AdminRemoveChallengeMutationVariables
  >(AdminRemoveChallengeDocument, options);
}
export type AdminRemoveChallengeMutationHookResult = ReturnType<
  typeof useAdminRemoveChallengeMutation
>;
export type AdminRemoveChallengeMutationResult =
  Apollo.MutationResult<AdminRemoveChallengeMutation>;
export type AdminRemoveChallengeMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveChallengeMutation,
  AdminRemoveChallengeMutationVariables
>;
export const GetActivityGenresDocument = gql`
    query getActivityGenres {
  activityGenres {
    name
    value
  }
}
    `;

/**
 * __useGetActivityGenresQuery__
 *
 * To run a query within a React component, call `useGetActivityGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActivityGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetActivityGenresQuery,
    GetActivityGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetActivityGenresQuery,
    GetActivityGenresQueryVariables
  >(GetActivityGenresDocument, options);
}
export function useGetActivityGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActivityGenresQuery,
    GetActivityGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetActivityGenresQuery,
    GetActivityGenresQueryVariables
  >(GetActivityGenresDocument, options);
}
export function useGetActivityGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetActivityGenresQuery,
        GetActivityGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetActivityGenresQuery,
    GetActivityGenresQueryVariables
  >(GetActivityGenresDocument, options);
}
export type GetActivityGenresQueryHookResult = ReturnType<
  typeof useGetActivityGenresQuery
>;
export type GetActivityGenresLazyQueryHookResult = ReturnType<
  typeof useGetActivityGenresLazyQuery
>;
export type GetActivityGenresSuspenseQueryHookResult = ReturnType<
  typeof useGetActivityGenresSuspenseQuery
>;
export type GetActivityGenresQueryResult = Apollo.QueryResult<
  GetActivityGenresQuery,
  GetActivityGenresQueryVariables
>;
export const AdminGetChatRoomDocument = gql`
    query AdminGetChatRoom($id: ID!) {
  data: chatRoom(id: $id) {
    ...AdminChatRoomDetails
  }
}
    ${AdminChatRoomDetailsFragmentDoc}`;

/**
 * __useAdminGetChatRoomQuery__
 *
 * To run a query within a React component, call `useAdminGetChatRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetChatRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetChatRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetChatRoomQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetChatRoomQuery,
    AdminGetChatRoomQueryVariables
  > &
    (
      | { variables: AdminGetChatRoomQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetChatRoomQuery, AdminGetChatRoomQueryVariables>(
    AdminGetChatRoomDocument,
    options,
  );
}
export function useAdminGetChatRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetChatRoomQuery,
    AdminGetChatRoomQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetChatRoomQuery,
    AdminGetChatRoomQueryVariables
  >(AdminGetChatRoomDocument, options);
}
export function useAdminGetChatRoomSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetChatRoomQuery,
        AdminGetChatRoomQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetChatRoomQuery,
    AdminGetChatRoomQueryVariables
  >(AdminGetChatRoomDocument, options);
}
export type AdminGetChatRoomQueryHookResult = ReturnType<
  typeof useAdminGetChatRoomQuery
>;
export type AdminGetChatRoomLazyQueryHookResult = ReturnType<
  typeof useAdminGetChatRoomLazyQuery
>;
export type AdminGetChatRoomSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetChatRoomSuspenseQuery
>;
export type AdminGetChatRoomQueryResult = Apollo.QueryResult<
  AdminGetChatRoomQuery,
  AdminGetChatRoomQueryVariables
>;
export const AdminManyChatRoomsDocument = gql`
    query AdminManyChatRooms($ids: [ID!]!) {
  data: manyChatRooms(ids: $ids) {
    ...AdminChatRoomDetails
  }
}
    ${AdminChatRoomDetailsFragmentDoc}`;

/**
 * __useAdminManyChatRoomsQuery__
 *
 * To run a query within a React component, call `useAdminManyChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyChatRoomsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyChatRoomsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyChatRoomsQuery,
    AdminManyChatRoomsQueryVariables
  > &
    (
      | { variables: AdminManyChatRoomsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminManyChatRoomsQuery,
    AdminManyChatRoomsQueryVariables
  >(AdminManyChatRoomsDocument, options);
}
export function useAdminManyChatRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyChatRoomsQuery,
    AdminManyChatRoomsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyChatRoomsQuery,
    AdminManyChatRoomsQueryVariables
  >(AdminManyChatRoomsDocument, options);
}
export function useAdminManyChatRoomsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyChatRoomsQuery,
        AdminManyChatRoomsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyChatRoomsQuery,
    AdminManyChatRoomsQueryVariables
  >(AdminManyChatRoomsDocument, options);
}
export type AdminManyChatRoomsQueryHookResult = ReturnType<
  typeof useAdminManyChatRoomsQuery
>;
export type AdminManyChatRoomsLazyQueryHookResult = ReturnType<
  typeof useAdminManyChatRoomsLazyQuery
>;
export type AdminManyChatRoomsSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyChatRoomsSuspenseQuery
>;
export type AdminManyChatRoomsQueryResult = Apollo.QueryResult<
  AdminManyChatRoomsQuery,
  AdminManyChatRoomsQueryVariables
>;
export const AdminListChatRoomsDocument = gql`
    query AdminListChatRooms($input: ConnectionInput, $filter: ChatRoomsFilter) {
  data: chatRooms(input: $input, filter: $filter) {
    nodes {
      ...AdminChatRoomDetails
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminChatRoomDetailsFragmentDoc}`;

/**
 * __useAdminListChatRoomsQuery__
 *
 * To run a query within a React component, call `useAdminListChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListChatRoomsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListChatRoomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListChatRoomsQuery,
    AdminListChatRoomsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListChatRoomsQuery,
    AdminListChatRoomsQueryVariables
  >(AdminListChatRoomsDocument, options);
}
export function useAdminListChatRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListChatRoomsQuery,
    AdminListChatRoomsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListChatRoomsQuery,
    AdminListChatRoomsQueryVariables
  >(AdminListChatRoomsDocument, options);
}
export function useAdminListChatRoomsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListChatRoomsQuery,
        AdminListChatRoomsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListChatRoomsQuery,
    AdminListChatRoomsQueryVariables
  >(AdminListChatRoomsDocument, options);
}
export type AdminListChatRoomsQueryHookResult = ReturnType<
  typeof useAdminListChatRoomsQuery
>;
export type AdminListChatRoomsLazyQueryHookResult = ReturnType<
  typeof useAdminListChatRoomsLazyQuery
>;
export type AdminListChatRoomsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListChatRoomsSuspenseQuery
>;
export type AdminListChatRoomsQueryResult = Apollo.QueryResult<
  AdminListChatRoomsQuery,
  AdminListChatRoomsQueryVariables
>;
export const AdminCreateChatRoomDocument = gql`
    mutation AdminCreateChatRoom($input: CreateChatRoomInput!) {
  data: createChatRoom(input: $input) {
    chatRoom {
      id
      name
      app
      users {
        count
        nodes {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
}
    `;
export type AdminCreateChatRoomMutationFn = Apollo.MutationFunction<
  AdminCreateChatRoomMutation,
  AdminCreateChatRoomMutationVariables
>;

/**
 * __useAdminCreateChatRoomMutation__
 *
 * To run a mutation, you first call `useAdminCreateChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateChatRoomMutation, { data, loading, error }] = useAdminCreateChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateChatRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateChatRoomMutation,
    AdminCreateChatRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateChatRoomMutation,
    AdminCreateChatRoomMutationVariables
  >(AdminCreateChatRoomDocument, options);
}
export type AdminCreateChatRoomMutationHookResult = ReturnType<
  typeof useAdminCreateChatRoomMutation
>;
export type AdminCreateChatRoomMutationResult =
  Apollo.MutationResult<AdminCreateChatRoomMutation>;
export type AdminCreateChatRoomMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateChatRoomMutation,
  AdminCreateChatRoomMutationVariables
>;
export const AdminUpdateChatRoomDocument = gql`
    mutation AdminUpdateChatRoom($input: UpdateChatRoomInput!) {
  data: updateChatRoom(input: $input) {
    chatRoom {
      id
      name
      app
      isArchived
      users {
        count
        nodes {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
}
    `;
export type AdminUpdateChatRoomMutationFn = Apollo.MutationFunction<
  AdminUpdateChatRoomMutation,
  AdminUpdateChatRoomMutationVariables
>;

/**
 * __useAdminUpdateChatRoomMutation__
 *
 * To run a mutation, you first call `useAdminUpdateChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateChatRoomMutation, { data, loading, error }] = useAdminUpdateChatRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateChatRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateChatRoomMutation,
    AdminUpdateChatRoomMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateChatRoomMutation,
    AdminUpdateChatRoomMutationVariables
  >(AdminUpdateChatRoomDocument, options);
}
export type AdminUpdateChatRoomMutationHookResult = ReturnType<
  typeof useAdminUpdateChatRoomMutation
>;
export type AdminUpdateChatRoomMutationResult =
  Apollo.MutationResult<AdminUpdateChatRoomMutation>;
export type AdminUpdateChatRoomMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateChatRoomMutation,
  AdminUpdateChatRoomMutationVariables
>;
export const AdminGetClientDocument = gql`
    query AdminGetClient($id: ID!) {
  data: client(id: $id) {
    ...AdminClientDetails
  }
}
    ${AdminClientDetailsFragmentDoc}`;

/**
 * __useAdminGetClientQuery__
 *
 * To run a query within a React component, call `useAdminGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetClientQuery,
    AdminGetClientQueryVariables
  > &
    (
      | { variables: AdminGetClientQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetClientQuery, AdminGetClientQueryVariables>(
    AdminGetClientDocument,
    options,
  );
}
export function useAdminGetClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetClientQuery,
    AdminGetClientQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetClientQuery, AdminGetClientQueryVariables>(
    AdminGetClientDocument,
    options,
  );
}
export function useAdminGetClientSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetClientQuery,
        AdminGetClientQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetClientQuery,
    AdminGetClientQueryVariables
  >(AdminGetClientDocument, options);
}
export type AdminGetClientQueryHookResult = ReturnType<
  typeof useAdminGetClientQuery
>;
export type AdminGetClientLazyQueryHookResult = ReturnType<
  typeof useAdminGetClientLazyQuery
>;
export type AdminGetClientSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetClientSuspenseQuery
>;
export type AdminGetClientQueryResult = Apollo.QueryResult<
  AdminGetClientQuery,
  AdminGetClientQueryVariables
>;
export const AdminManyClientsDocument = gql`
    query AdminManyClients($ids: [ID!]!) {
  data: manyClients(ids: $ids) {
    ...AdminClientDetails
  }
}
    ${AdminClientDetailsFragmentDoc}`;

/**
 * __useAdminManyClientsQuery__
 *
 * To run a query within a React component, call `useAdminManyClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyClientsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyClientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyClientsQuery,
    AdminManyClientsQueryVariables
  > &
    (
      | { variables: AdminManyClientsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminManyClientsQuery, AdminManyClientsQueryVariables>(
    AdminManyClientsDocument,
    options,
  );
}
export function useAdminManyClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyClientsQuery,
    AdminManyClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyClientsQuery,
    AdminManyClientsQueryVariables
  >(AdminManyClientsDocument, options);
}
export function useAdminManyClientsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyClientsQuery,
        AdminManyClientsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyClientsQuery,
    AdminManyClientsQueryVariables
  >(AdminManyClientsDocument, options);
}
export type AdminManyClientsQueryHookResult = ReturnType<
  typeof useAdminManyClientsQuery
>;
export type AdminManyClientsLazyQueryHookResult = ReturnType<
  typeof useAdminManyClientsLazyQuery
>;
export type AdminManyClientsSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyClientsSuspenseQuery
>;
export type AdminManyClientsQueryResult = Apollo.QueryResult<
  AdminManyClientsQuery,
  AdminManyClientsQueryVariables
>;
export const AdminListClientsDocument = gql`
    query AdminListClients($input: ConnectionInput, $filter: ClientsFilter) {
  data: clients(filter: $filter, input: $input) {
    nodes {
      ...AdminClientBasics
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminClientBasicsFragmentDoc}`;

/**
 * __useAdminListClientsQuery__
 *
 * To run a query within a React component, call `useAdminListClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListClientsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListClientsQuery,
    AdminListClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListClientsQuery, AdminListClientsQueryVariables>(
    AdminListClientsDocument,
    options,
  );
}
export function useAdminListClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListClientsQuery,
    AdminListClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListClientsQuery,
    AdminListClientsQueryVariables
  >(AdminListClientsDocument, options);
}
export function useAdminListClientsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListClientsQuery,
        AdminListClientsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListClientsQuery,
    AdminListClientsQueryVariables
  >(AdminListClientsDocument, options);
}
export type AdminListClientsQueryHookResult = ReturnType<
  typeof useAdminListClientsQuery
>;
export type AdminListClientsLazyQueryHookResult = ReturnType<
  typeof useAdminListClientsLazyQuery
>;
export type AdminListClientsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListClientsSuspenseQuery
>;
export type AdminListClientsQueryResult = Apollo.QueryResult<
  AdminListClientsQuery,
  AdminListClientsQueryVariables
>;
export const ClientCoachesDocument = gql`
    query clientCoaches($id: ID!, $input: ConnectionInput) {
  client(id: $id) {
    id
    coaches(input: $input, excludeInvalidCoaches: false) {
      count
      edges {
        node {
          ...CoachDetails
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
    ${CoachDetailsFragmentDoc}`;

/**
 * __useClientCoachesQuery__
 *
 * To run a query within a React component, call `useClientCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCoachesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClientCoachesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientCoachesQuery,
    ClientCoachesQueryVariables
  > &
    (
      | { variables: ClientCoachesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClientCoachesQuery, ClientCoachesQueryVariables>(
    ClientCoachesDocument,
    options,
  );
}
export function useClientCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientCoachesQuery,
    ClientCoachesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClientCoachesQuery, ClientCoachesQueryVariables>(
    ClientCoachesDocument,
    options,
  );
}
export function useClientCoachesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientCoachesQuery,
        ClientCoachesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientCoachesQuery,
    ClientCoachesQueryVariables
  >(ClientCoachesDocument, options);
}
export type ClientCoachesQueryHookResult = ReturnType<
  typeof useClientCoachesQuery
>;
export type ClientCoachesLazyQueryHookResult = ReturnType<
  typeof useClientCoachesLazyQuery
>;
export type ClientCoachesSuspenseQueryHookResult = ReturnType<
  typeof useClientCoachesSuspenseQuery
>;
export type ClientCoachesQueryResult = Apollo.QueryResult<
  ClientCoachesQuery,
  ClientCoachesQueryVariables
>;
export const ClientConfigurationIssuesDocument = gql`
    query clientConfigurationIssues($id: ID!) {
  client(id: $id) {
    id
    configurationIssues {
      title
      description
      link
    }
  }
}
    `;

/**
 * __useClientConfigurationIssuesQuery__
 *
 * To run a query within a React component, call `useClientConfigurationIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientConfigurationIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientConfigurationIssuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientConfigurationIssuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClientConfigurationIssuesQuery,
    ClientConfigurationIssuesQueryVariables
  > &
    (
      | { variables: ClientConfigurationIssuesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClientConfigurationIssuesQuery,
    ClientConfigurationIssuesQueryVariables
  >(ClientConfigurationIssuesDocument, options);
}
export function useClientConfigurationIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClientConfigurationIssuesQuery,
    ClientConfigurationIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClientConfigurationIssuesQuery,
    ClientConfigurationIssuesQueryVariables
  >(ClientConfigurationIssuesDocument, options);
}
export function useClientConfigurationIssuesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClientConfigurationIssuesQuery,
        ClientConfigurationIssuesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClientConfigurationIssuesQuery,
    ClientConfigurationIssuesQueryVariables
  >(ClientConfigurationIssuesDocument, options);
}
export type ClientConfigurationIssuesQueryHookResult = ReturnType<
  typeof useClientConfigurationIssuesQuery
>;
export type ClientConfigurationIssuesLazyQueryHookResult = ReturnType<
  typeof useClientConfigurationIssuesLazyQuery
>;
export type ClientConfigurationIssuesSuspenseQueryHookResult = ReturnType<
  typeof useClientConfigurationIssuesSuspenseQuery
>;
export type ClientConfigurationIssuesQueryResult = Apollo.QueryResult<
  ClientConfigurationIssuesQuery,
  ClientConfigurationIssuesQueryVariables
>;
export const AdminCreateClientDocument = gql`
    mutation AdminCreateClient($input: CreateClientInput!) {
  data: createClient(input: $input) {
    client {
      ...AdminClientDetails
    }
  }
}
    ${AdminClientDetailsFragmentDoc}`;
export type AdminCreateClientMutationFn = Apollo.MutationFunction<
  AdminCreateClientMutation,
  AdminCreateClientMutationVariables
>;

/**
 * __useAdminCreateClientMutation__
 *
 * To run a mutation, you first call `useAdminCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateClientMutation, { data, loading, error }] = useAdminCreateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateClientMutation,
    AdminCreateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateClientMutation,
    AdminCreateClientMutationVariables
  >(AdminCreateClientDocument, options);
}
export type AdminCreateClientMutationHookResult = ReturnType<
  typeof useAdminCreateClientMutation
>;
export type AdminCreateClientMutationResult =
  Apollo.MutationResult<AdminCreateClientMutation>;
export type AdminCreateClientMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateClientMutation,
  AdminCreateClientMutationVariables
>;
export const AdminUpdateClientDocument = gql`
    mutation AdminUpdateClient($input: UpdateClientInput!) {
  data: updateClient(input: $input) {
    client {
      ...AdminClientDetails
    }
  }
}
    ${AdminClientDetailsFragmentDoc}`;
export type AdminUpdateClientMutationFn = Apollo.MutationFunction<
  AdminUpdateClientMutation,
  AdminUpdateClientMutationVariables
>;

/**
 * __useAdminUpdateClientMutation__
 *
 * To run a mutation, you first call `useAdminUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateClientMutation, { data, loading, error }] = useAdminUpdateClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateClientMutation,
    AdminUpdateClientMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateClientMutation,
    AdminUpdateClientMutationVariables
  >(AdminUpdateClientDocument, options);
}
export type AdminUpdateClientMutationHookResult = ReturnType<
  typeof useAdminUpdateClientMutation
>;
export type AdminUpdateClientMutationResult =
  Apollo.MutationResult<AdminUpdateClientMutation>;
export type AdminUpdateClientMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateClientMutation,
  AdminUpdateClientMutationVariables
>;
export const AdminListCoachesDocument = gql`
    query AdminListCoaches($id: ID!, $input: ConnectionInput) {
  user(id: $id) {
    id
    fitClient {
      id
      coaches(input: $input) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useAdminListCoachesQuery__
 *
 * To run a query within a React component, call `useAdminListCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListCoachesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminListCoachesQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListCoachesQuery,
    AdminListCoachesQueryVariables
  > &
    (
      | { variables: AdminListCoachesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListCoachesQuery, AdminListCoachesQueryVariables>(
    AdminListCoachesDocument,
    options,
  );
}
export function useAdminListCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListCoachesQuery,
    AdminListCoachesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListCoachesQuery,
    AdminListCoachesQueryVariables
  >(AdminListCoachesDocument, options);
}
export function useAdminListCoachesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListCoachesQuery,
        AdminListCoachesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListCoachesQuery,
    AdminListCoachesQueryVariables
  >(AdminListCoachesDocument, options);
}
export type AdminListCoachesQueryHookResult = ReturnType<
  typeof useAdminListCoachesQuery
>;
export type AdminListCoachesLazyQueryHookResult = ReturnType<
  typeof useAdminListCoachesLazyQuery
>;
export type AdminListCoachesSuspenseQueryHookResult = ReturnType<
  typeof useAdminListCoachesSuspenseQuery
>;
export type AdminListCoachesQueryResult = Apollo.QueryResult<
  AdminListCoachesQuery,
  AdminListCoachesQueryVariables
>;
export const AdminCountryListDocument = gql`
    query AdminCountryList {
  data: countries {
    id
    name
    alpha2
  }
}
    `;

/**
 * __useAdminCountryListQuery__
 *
 * To run a query within a React component, call `useAdminCountryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountryListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCountryListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminCountryListQuery,
    AdminCountryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminCountryListQuery, AdminCountryListQueryVariables>(
    AdminCountryListDocument,
    options,
  );
}
export function useAdminCountryListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminCountryListQuery,
    AdminCountryListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminCountryListQuery,
    AdminCountryListQueryVariables
  >(AdminCountryListDocument, options);
}
export function useAdminCountryListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminCountryListQuery,
        AdminCountryListQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminCountryListQuery,
    AdminCountryListQueryVariables
  >(AdminCountryListDocument, options);
}
export type AdminCountryListQueryHookResult = ReturnType<
  typeof useAdminCountryListQuery
>;
export type AdminCountryListLazyQueryHookResult = ReturnType<
  typeof useAdminCountryListLazyQuery
>;
export type AdminCountryListSuspenseQueryHookResult = ReturnType<
  typeof useAdminCountryListSuspenseQuery
>;
export type AdminCountryListQueryResult = Apollo.QueryResult<
  AdminCountryListQuery,
  AdminCountryListQueryVariables
>;
export const RemoveCoachMembersDocument = gql`
    mutation removeCoachMembers($coachId: ID!, $clientId: ID!) {
  removeCoachMembers(coachId: $coachId, clientId: $clientId)
}
    `;
export type RemoveCoachMembersMutationFn = Apollo.MutationFunction<
  RemoveCoachMembersMutation,
  RemoveCoachMembersMutationVariables
>;

/**
 * __useRemoveCoachMembersMutation__
 *
 * To run a mutation, you first call `useRemoveCoachMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCoachMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCoachMembersMutation, { data, loading, error }] = useRemoveCoachMembersMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useRemoveCoachMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCoachMembersMutation,
    RemoveCoachMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCoachMembersMutation,
    RemoveCoachMembersMutationVariables
  >(RemoveCoachMembersDocument, options);
}
export type RemoveCoachMembersMutationHookResult = ReturnType<
  typeof useRemoveCoachMembersMutation
>;
export type RemoveCoachMembersMutationResult =
  Apollo.MutationResult<RemoveCoachMembersMutation>;
export type RemoveCoachMembersMutationOptions = Apollo.BaseMutationOptions<
  RemoveCoachMembersMutation,
  RemoveCoachMembersMutationVariables
>;
export const AssignFitCoachDocument = gql`
    mutation assignFitCoach($coachId: ID!, $userId: ID!) {
  assignFitCoach(coachId: $coachId, userId: $userId) {
    user {
      ...UserBasics
    }
  }
}
    ${UserBasicsFragmentDoc}`;
export type AssignFitCoachMutationFn = Apollo.MutationFunction<
  AssignFitCoachMutation,
  AssignFitCoachMutationVariables
>;

/**
 * __useAssignFitCoachMutation__
 *
 * To run a mutation, you first call `useAssignFitCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignFitCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignFitCoachMutation, { data, loading, error }] = useAssignFitCoachMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAssignFitCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignFitCoachMutation,
    AssignFitCoachMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignFitCoachMutation,
    AssignFitCoachMutationVariables
  >(AssignFitCoachDocument, options);
}
export type AssignFitCoachMutationHookResult = ReturnType<
  typeof useAssignFitCoachMutation
>;
export type AssignFitCoachMutationResult =
  Apollo.MutationResult<AssignFitCoachMutation>;
export type AssignFitCoachMutationOptions = Apollo.BaseMutationOptions<
  AssignFitCoachMutation,
  AssignFitCoachMutationVariables
>;
export const DeleteFitCoachDocument = gql`
    mutation deleteFitCoach($userId: ID!) {
  deleteFitCoach(userId: $userId) {
    user {
      id
    }
  }
}
    `;
export type DeleteFitCoachMutationFn = Apollo.MutationFunction<
  DeleteFitCoachMutation,
  DeleteFitCoachMutationVariables
>;

/**
 * __useDeleteFitCoachMutation__
 *
 * To run a mutation, you first call `useDeleteFitCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFitCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFitCoachMutation, { data, loading, error }] = useDeleteFitCoachMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteFitCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFitCoachMutation,
    DeleteFitCoachMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFitCoachMutation,
    DeleteFitCoachMutationVariables
  >(DeleteFitCoachDocument, options);
}
export type DeleteFitCoachMutationHookResult = ReturnType<
  typeof useDeleteFitCoachMutation
>;
export type DeleteFitCoachMutationResult =
  Apollo.MutationResult<DeleteFitCoachMutation>;
export type DeleteFitCoachMutationOptions = Apollo.BaseMutationOptions<
  DeleteFitCoachMutation,
  DeleteFitCoachMutationVariables
>;
export const BulkReassignCoachMembersDocument = gql`
    mutation bulkReassignCoachMembers($oldCoachId: ID!, $newCoachId: ID!, $userIds: [ID!], $reassignAllUsers: Boolean) {
  bulkReassignCoachMembers(
    oldCoachId: $oldCoachId
    newCoachId: $newCoachId
    userIds: $userIds
    reassignAllUsers: $reassignAllUsers
  ) {
    userCountReassigned
    userIdsNotReassigned
  }
}
    `;
export type BulkReassignCoachMembersMutationFn = Apollo.MutationFunction<
  BulkReassignCoachMembersMutation,
  BulkReassignCoachMembersMutationVariables
>;

/**
 * __useBulkReassignCoachMembersMutation__
 *
 * To run a mutation, you first call `useBulkReassignCoachMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkReassignCoachMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkReassignCoachMembersMutation, { data, loading, error }] = useBulkReassignCoachMembersMutation({
 *   variables: {
 *      oldCoachId: // value for 'oldCoachId'
 *      newCoachId: // value for 'newCoachId'
 *      userIds: // value for 'userIds'
 *      reassignAllUsers: // value for 'reassignAllUsers'
 *   },
 * });
 */
export function useBulkReassignCoachMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkReassignCoachMembersMutation,
    BulkReassignCoachMembersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BulkReassignCoachMembersMutation,
    BulkReassignCoachMembersMutationVariables
  >(BulkReassignCoachMembersDocument, options);
}
export type BulkReassignCoachMembersMutationHookResult = ReturnType<
  typeof useBulkReassignCoachMembersMutation
>;
export type BulkReassignCoachMembersMutationResult =
  Apollo.MutationResult<BulkReassignCoachMembersMutation>;
export type BulkReassignCoachMembersMutationOptions =
  Apollo.BaseMutationOptions<
    BulkReassignCoachMembersMutation,
    BulkReassignCoachMembersMutationVariables
  >;
export const AdminListCoachUsersDocument = gql`
    query AdminListCoachUsers($id: ID!, $input: ConnectionInput, $filter: CoachUsersFilter) {
  user(id: $id) {
    id
    coachData {
      id
      coachUsers(input: $input, filter: $filter) {
        count
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            fitCoachChat {
              id
              isArchived
            }
            fitClient {
              id
              name
            }
            ...UserBasics
          }
        }
      }
    }
  }
}
    ${UserBasicsFragmentDoc}`;

/**
 * __useAdminListCoachUsersQuery__
 *
 * To run a query within a React component, call `useAdminListCoachUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListCoachUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListCoachUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListCoachUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminListCoachUsersQuery,
    AdminListCoachUsersQueryVariables
  > &
    (
      | { variables: AdminListCoachUsersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListCoachUsersQuery,
    AdminListCoachUsersQueryVariables
  >(AdminListCoachUsersDocument, options);
}
export function useAdminListCoachUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListCoachUsersQuery,
    AdminListCoachUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListCoachUsersQuery,
    AdminListCoachUsersQueryVariables
  >(AdminListCoachUsersDocument, options);
}
export function useAdminListCoachUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListCoachUsersQuery,
        AdminListCoachUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListCoachUsersQuery,
    AdminListCoachUsersQueryVariables
  >(AdminListCoachUsersDocument, options);
}
export type AdminListCoachUsersQueryHookResult = ReturnType<
  typeof useAdminListCoachUsersQuery
>;
export type AdminListCoachUsersLazyQueryHookResult = ReturnType<
  typeof useAdminListCoachUsersLazyQuery
>;
export type AdminListCoachUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminListCoachUsersSuspenseQuery
>;
export type AdminListCoachUsersQueryResult = Apollo.QueryResult<
  AdminListCoachUsersQuery,
  AdminListCoachUsersQueryVariables
>;
export const AdminListDomainsDocument = gql`
    query AdminListDomains($input: ConnectionInput, $filter: DomainsFilter) {
  data: domains(input: $input, filter: $filter) {
    nodes {
      ...DomainBasics
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${DomainBasicsFragmentDoc}`;

/**
 * __useAdminListDomainsQuery__
 *
 * To run a query within a React component, call `useAdminListDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListDomainsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListDomainsQuery,
    AdminListDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListDomainsQuery, AdminListDomainsQueryVariables>(
    AdminListDomainsDocument,
    options,
  );
}
export function useAdminListDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListDomainsQuery,
    AdminListDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListDomainsQuery,
    AdminListDomainsQueryVariables
  >(AdminListDomainsDocument, options);
}
export function useAdminListDomainsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListDomainsQuery,
        AdminListDomainsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListDomainsQuery,
    AdminListDomainsQueryVariables
  >(AdminListDomainsDocument, options);
}
export type AdminListDomainsQueryHookResult = ReturnType<
  typeof useAdminListDomainsQuery
>;
export type AdminListDomainsLazyQueryHookResult = ReturnType<
  typeof useAdminListDomainsLazyQuery
>;
export type AdminListDomainsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListDomainsSuspenseQuery
>;
export type AdminListDomainsQueryResult = Apollo.QueryResult<
  AdminListDomainsQuery,
  AdminListDomainsQueryVariables
>;
export const AdminGetDomainDocument = gql`
    query AdminGetDomain($id: ID!) {
  data: domain(id: $id) {
    ...DomainBasics
  }
}
    ${DomainBasicsFragmentDoc}`;

/**
 * __useAdminGetDomainQuery__
 *
 * To run a query within a React component, call `useAdminGetDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetDomainQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetDomainQuery,
    AdminGetDomainQueryVariables
  > &
    (
      | { variables: AdminGetDomainQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetDomainQuery, AdminGetDomainQueryVariables>(
    AdminGetDomainDocument,
    options,
  );
}
export function useAdminGetDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetDomainQuery,
    AdminGetDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetDomainQuery, AdminGetDomainQueryVariables>(
    AdminGetDomainDocument,
    options,
  );
}
export function useAdminGetDomainSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetDomainQuery,
        AdminGetDomainQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetDomainQuery,
    AdminGetDomainQueryVariables
  >(AdminGetDomainDocument, options);
}
export type AdminGetDomainQueryHookResult = ReturnType<
  typeof useAdminGetDomainQuery
>;
export type AdminGetDomainLazyQueryHookResult = ReturnType<
  typeof useAdminGetDomainLazyQuery
>;
export type AdminGetDomainSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetDomainSuspenseQuery
>;
export type AdminGetDomainQueryResult = Apollo.QueryResult<
  AdminGetDomainQuery,
  AdminGetDomainQueryVariables
>;
export const AdminManyDomainsDocument = gql`
    query AdminManyDomains($ids: [ID!]!) {
  data: manyDomains(ids: $ids) {
    ...DomainBasics
  }
}
    ${DomainBasicsFragmentDoc}`;

/**
 * __useAdminManyDomainsQuery__
 *
 * To run a query within a React component, call `useAdminManyDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyDomainsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyDomainsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyDomainsQuery,
    AdminManyDomainsQueryVariables
  > &
    (
      | { variables: AdminManyDomainsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminManyDomainsQuery, AdminManyDomainsQueryVariables>(
    AdminManyDomainsDocument,
    options,
  );
}
export function useAdminManyDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyDomainsQuery,
    AdminManyDomainsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyDomainsQuery,
    AdminManyDomainsQueryVariables
  >(AdminManyDomainsDocument, options);
}
export function useAdminManyDomainsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyDomainsQuery,
        AdminManyDomainsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyDomainsQuery,
    AdminManyDomainsQueryVariables
  >(AdminManyDomainsDocument, options);
}
export type AdminManyDomainsQueryHookResult = ReturnType<
  typeof useAdminManyDomainsQuery
>;
export type AdminManyDomainsLazyQueryHookResult = ReturnType<
  typeof useAdminManyDomainsLazyQuery
>;
export type AdminManyDomainsSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyDomainsSuspenseQuery
>;
export type AdminManyDomainsQueryResult = Apollo.QueryResult<
  AdminManyDomainsQuery,
  AdminManyDomainsQueryVariables
>;
export const AdminCreateDomainDocument = gql`
    mutation AdminCreateDomain($input: CreateDomainInput!) {
  data: createDomain(input: $input) {
    domain {
      ...DomainBasics
    }
  }
}
    ${DomainBasicsFragmentDoc}`;
export type AdminCreateDomainMutationFn = Apollo.MutationFunction<
  AdminCreateDomainMutation,
  AdminCreateDomainMutationVariables
>;

/**
 * __useAdminCreateDomainMutation__
 *
 * To run a mutation, you first call `useAdminCreateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateDomainMutation, { data, loading, error }] = useAdminCreateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateDomainMutation,
    AdminCreateDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateDomainMutation,
    AdminCreateDomainMutationVariables
  >(AdminCreateDomainDocument, options);
}
export type AdminCreateDomainMutationHookResult = ReturnType<
  typeof useAdminCreateDomainMutation
>;
export type AdminCreateDomainMutationResult =
  Apollo.MutationResult<AdminCreateDomainMutation>;
export type AdminCreateDomainMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateDomainMutation,
  AdminCreateDomainMutationVariables
>;
export const AdminUpdateDomainDocument = gql`
    mutation AdminUpdateDomain($input: UpdateDomainInput!) {
  data: updateDomain(input: $input) {
    domain {
      ...DomainBasics
    }
  }
}
    ${DomainBasicsFragmentDoc}`;
export type AdminUpdateDomainMutationFn = Apollo.MutationFunction<
  AdminUpdateDomainMutation,
  AdminUpdateDomainMutationVariables
>;

/**
 * __useAdminUpdateDomainMutation__
 *
 * To run a mutation, you first call `useAdminUpdateDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateDomainMutation, { data, loading, error }] = useAdminUpdateDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateDomainMutation,
    AdminUpdateDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateDomainMutation,
    AdminUpdateDomainMutationVariables
  >(AdminUpdateDomainDocument, options);
}
export type AdminUpdateDomainMutationHookResult = ReturnType<
  typeof useAdminUpdateDomainMutation
>;
export type AdminUpdateDomainMutationResult =
  Apollo.MutationResult<AdminUpdateDomainMutation>;
export type AdminUpdateDomainMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateDomainMutation,
  AdminUpdateDomainMutationVariables
>;
export const AdminRemoveDomainDocument = gql`
    mutation AdminRemoveDomain($id: ID!) {
  data: removeDomain(id: $id)
}
    `;
export type AdminRemoveDomainMutationFn = Apollo.MutationFunction<
  AdminRemoveDomainMutation,
  AdminRemoveDomainMutationVariables
>;

/**
 * __useAdminRemoveDomainMutation__
 *
 * To run a mutation, you first call `useAdminRemoveDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveDomainMutation, { data, loading, error }] = useAdminRemoveDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveDomainMutation,
    AdminRemoveDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminRemoveDomainMutation,
    AdminRemoveDomainMutationVariables
  >(AdminRemoveDomainDocument, options);
}
export type AdminRemoveDomainMutationHookResult = ReturnType<
  typeof useAdminRemoveDomainMutation
>;
export type AdminRemoveDomainMutationResult =
  Apollo.MutationResult<AdminRemoveDomainMutation>;
export type AdminRemoveDomainMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveDomainMutation,
  AdminRemoveDomainMutationVariables
>;
export const AdminE2ETestUserDocument = gql`
    query AdminE2ETestUser($email: String!) {
  user(email: $email) {
    id
    email
    firstName
    lastName
  }
}
    `;

/**
 * __useAdminE2ETestUserQuery__
 *
 * To run a query within a React component, call `useAdminE2ETestUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminE2ETestUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminE2ETestUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAdminE2ETestUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminE2ETestUserQuery,
    AdminE2ETestUserQueryVariables
  > &
    (
      | { variables: AdminE2ETestUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminE2ETestUserQuery, AdminE2ETestUserQueryVariables>(
    AdminE2ETestUserDocument,
    options,
  );
}
export function useAdminE2ETestUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminE2ETestUserQuery,
    AdminE2ETestUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminE2ETestUserQuery,
    AdminE2ETestUserQueryVariables
  >(AdminE2ETestUserDocument, options);
}
export function useAdminE2ETestUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminE2ETestUserQuery,
        AdminE2ETestUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminE2ETestUserQuery,
    AdminE2ETestUserQueryVariables
  >(AdminE2ETestUserDocument, options);
}
export type AdminE2ETestUserQueryHookResult = ReturnType<
  typeof useAdminE2ETestUserQuery
>;
export type AdminE2ETestUserLazyQueryHookResult = ReturnType<
  typeof useAdminE2ETestUserLazyQuery
>;
export type AdminE2ETestUserSuspenseQueryHookResult = ReturnType<
  typeof useAdminE2ETestUserSuspenseQuery
>;
export type AdminE2ETestUserQueryResult = Apollo.QueryResult<
  AdminE2ETestUserQuery,
  AdminE2ETestUserQueryVariables
>;
export const AdminListEligibleEmailsDocument = gql`
    query AdminListEligibleEmails($input: ConnectionInput, $filter: EligibleEmailAddressesFilter) {
  data: eligibleEmailAddresses(input: $input, filter: $filter) {
    nodes {
      ...AdminEligibleEmailDetails
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminEligibleEmailDetailsFragmentDoc}`;

/**
 * __useAdminListEligibleEmailsQuery__
 *
 * To run a query within a React component, call `useAdminListEligibleEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListEligibleEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListEligibleEmailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListEligibleEmailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListEligibleEmailsQuery,
    AdminListEligibleEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListEligibleEmailsQuery,
    AdminListEligibleEmailsQueryVariables
  >(AdminListEligibleEmailsDocument, options);
}
export function useAdminListEligibleEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListEligibleEmailsQuery,
    AdminListEligibleEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListEligibleEmailsQuery,
    AdminListEligibleEmailsQueryVariables
  >(AdminListEligibleEmailsDocument, options);
}
export function useAdminListEligibleEmailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListEligibleEmailsQuery,
        AdminListEligibleEmailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListEligibleEmailsQuery,
    AdminListEligibleEmailsQueryVariables
  >(AdminListEligibleEmailsDocument, options);
}
export type AdminListEligibleEmailsQueryHookResult = ReturnType<
  typeof useAdminListEligibleEmailsQuery
>;
export type AdminListEligibleEmailsLazyQueryHookResult = ReturnType<
  typeof useAdminListEligibleEmailsLazyQuery
>;
export type AdminListEligibleEmailsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListEligibleEmailsSuspenseQuery
>;
export type AdminListEligibleEmailsQueryResult = Apollo.QueryResult<
  AdminListEligibleEmailsQuery,
  AdminListEligibleEmailsQueryVariables
>;
export const AdminManyEligibleEmailsDocument = gql`
    query AdminManyEligibleEmails($ids: [ID!]!) {
  data: manyEligibleEmailAddresses(ids: $ids) {
    ...AdminEligibleEmailDetails
  }
}
    ${AdminEligibleEmailDetailsFragmentDoc}`;

/**
 * __useAdminManyEligibleEmailsQuery__
 *
 * To run a query within a React component, call `useAdminManyEligibleEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyEligibleEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyEligibleEmailsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyEligibleEmailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyEligibleEmailsQuery,
    AdminManyEligibleEmailsQueryVariables
  > &
    (
      | { variables: AdminManyEligibleEmailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminManyEligibleEmailsQuery,
    AdminManyEligibleEmailsQueryVariables
  >(AdminManyEligibleEmailsDocument, options);
}
export function useAdminManyEligibleEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyEligibleEmailsQuery,
    AdminManyEligibleEmailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyEligibleEmailsQuery,
    AdminManyEligibleEmailsQueryVariables
  >(AdminManyEligibleEmailsDocument, options);
}
export function useAdminManyEligibleEmailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyEligibleEmailsQuery,
        AdminManyEligibleEmailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyEligibleEmailsQuery,
    AdminManyEligibleEmailsQueryVariables
  >(AdminManyEligibleEmailsDocument, options);
}
export type AdminManyEligibleEmailsQueryHookResult = ReturnType<
  typeof useAdminManyEligibleEmailsQuery
>;
export type AdminManyEligibleEmailsLazyQueryHookResult = ReturnType<
  typeof useAdminManyEligibleEmailsLazyQuery
>;
export type AdminManyEligibleEmailsSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyEligibleEmailsSuspenseQuery
>;
export type AdminManyEligibleEmailsQueryResult = Apollo.QueryResult<
  AdminManyEligibleEmailsQuery,
  AdminManyEligibleEmailsQueryVariables
>;
export const AdminGetEligibleEmailDocument = gql`
    query AdminGetEligibleEmail($id: ID) {
  data: eligibleEmailAddress(id: $id) {
    ...AdminEligibleEmailDetails
  }
}
    ${AdminEligibleEmailDetailsFragmentDoc}`;

/**
 * __useAdminGetEligibleEmailQuery__
 *
 * To run a query within a React component, call `useAdminGetEligibleEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetEligibleEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetEligibleEmailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetEligibleEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminGetEligibleEmailQuery,
    AdminGetEligibleEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetEligibleEmailQuery,
    AdminGetEligibleEmailQueryVariables
  >(AdminGetEligibleEmailDocument, options);
}
export function useAdminGetEligibleEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetEligibleEmailQuery,
    AdminGetEligibleEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetEligibleEmailQuery,
    AdminGetEligibleEmailQueryVariables
  >(AdminGetEligibleEmailDocument, options);
}
export function useAdminGetEligibleEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetEligibleEmailQuery,
        AdminGetEligibleEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetEligibleEmailQuery,
    AdminGetEligibleEmailQueryVariables
  >(AdminGetEligibleEmailDocument, options);
}
export type AdminGetEligibleEmailQueryHookResult = ReturnType<
  typeof useAdminGetEligibleEmailQuery
>;
export type AdminGetEligibleEmailLazyQueryHookResult = ReturnType<
  typeof useAdminGetEligibleEmailLazyQuery
>;
export type AdminGetEligibleEmailSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetEligibleEmailSuspenseQuery
>;
export type AdminGetEligibleEmailQueryResult = Apollo.QueryResult<
  AdminGetEligibleEmailQuery,
  AdminGetEligibleEmailQueryVariables
>;
export const AdminCreateEligibleEmailAddressDocument = gql`
    mutation AdminCreateEligibleEmailAddress($input: CreateEligibleEmailAddressInput!) {
  data: createEligibleEmailAddress(input: $input) {
    eligibleEmailAddress {
      ...AdminEligibleEmailDetails
    }
  }
}
    ${AdminEligibleEmailDetailsFragmentDoc}`;
export type AdminCreateEligibleEmailAddressMutationFn = Apollo.MutationFunction<
  AdminCreateEligibleEmailAddressMutation,
  AdminCreateEligibleEmailAddressMutationVariables
>;

/**
 * __useAdminCreateEligibleEmailAddressMutation__
 *
 * To run a mutation, you first call `useAdminCreateEligibleEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateEligibleEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateEligibleEmailAddressMutation, { data, loading, error }] = useAdminCreateEligibleEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateEligibleEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateEligibleEmailAddressMutation,
    AdminCreateEligibleEmailAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateEligibleEmailAddressMutation,
    AdminCreateEligibleEmailAddressMutationVariables
  >(AdminCreateEligibleEmailAddressDocument, options);
}
export type AdminCreateEligibleEmailAddressMutationHookResult = ReturnType<
  typeof useAdminCreateEligibleEmailAddressMutation
>;
export type AdminCreateEligibleEmailAddressMutationResult =
  Apollo.MutationResult<AdminCreateEligibleEmailAddressMutation>;
export type AdminCreateEligibleEmailAddressMutationOptions =
  Apollo.BaseMutationOptions<
    AdminCreateEligibleEmailAddressMutation,
    AdminCreateEligibleEmailAddressMutationVariables
  >;
export const AdminUpdateEligibleEmailAddressDocument = gql`
    mutation AdminUpdateEligibleEmailAddress($input: MutateEligibleEmailAddressInput!) {
  data: updateEligibleEmailAddress(input: $input) {
    eligibleEmailAddress {
      ...AdminEligibleEmailDetails
    }
  }
}
    ${AdminEligibleEmailDetailsFragmentDoc}`;
export type AdminUpdateEligibleEmailAddressMutationFn = Apollo.MutationFunction<
  AdminUpdateEligibleEmailAddressMutation,
  AdminUpdateEligibleEmailAddressMutationVariables
>;

/**
 * __useAdminUpdateEligibleEmailAddressMutation__
 *
 * To run a mutation, you first call `useAdminUpdateEligibleEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateEligibleEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateEligibleEmailAddressMutation, { data, loading, error }] = useAdminUpdateEligibleEmailAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateEligibleEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateEligibleEmailAddressMutation,
    AdminUpdateEligibleEmailAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateEligibleEmailAddressMutation,
    AdminUpdateEligibleEmailAddressMutationVariables
  >(AdminUpdateEligibleEmailAddressDocument, options);
}
export type AdminUpdateEligibleEmailAddressMutationHookResult = ReturnType<
  typeof useAdminUpdateEligibleEmailAddressMutation
>;
export type AdminUpdateEligibleEmailAddressMutationResult =
  Apollo.MutationResult<AdminUpdateEligibleEmailAddressMutation>;
export type AdminUpdateEligibleEmailAddressMutationOptions =
  Apollo.BaseMutationOptions<
    AdminUpdateEligibleEmailAddressMutation,
    AdminUpdateEligibleEmailAddressMutationVariables
  >;
export const AdminRemoveEligibleEmailAddressDocument = gql`
    mutation AdminRemoveEligibleEmailAddress($id: ID!) {
  data: removeEligibleEmailAddress(id: $id)
}
    `;
export type AdminRemoveEligibleEmailAddressMutationFn = Apollo.MutationFunction<
  AdminRemoveEligibleEmailAddressMutation,
  AdminRemoveEligibleEmailAddressMutationVariables
>;

/**
 * __useAdminRemoveEligibleEmailAddressMutation__
 *
 * To run a mutation, you first call `useAdminRemoveEligibleEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveEligibleEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveEligibleEmailAddressMutation, { data, loading, error }] = useAdminRemoveEligibleEmailAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveEligibleEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveEligibleEmailAddressMutation,
    AdminRemoveEligibleEmailAddressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminRemoveEligibleEmailAddressMutation,
    AdminRemoveEligibleEmailAddressMutationVariables
  >(AdminRemoveEligibleEmailAddressDocument, options);
}
export type AdminRemoveEligibleEmailAddressMutationHookResult = ReturnType<
  typeof useAdminRemoveEligibleEmailAddressMutation
>;
export type AdminRemoveEligibleEmailAddressMutationResult =
  Apollo.MutationResult<AdminRemoveEligibleEmailAddressMutation>;
export type AdminRemoveEligibleEmailAddressMutationOptions =
  Apollo.BaseMutationOptions<
    AdminRemoveEligibleEmailAddressMutation,
    AdminRemoveEligibleEmailAddressMutationVariables
  >;
export const AdminListContentGenresDocument = gql`
    query AdminListContentGenres {
  data: contentGenres(filter: {includeHidden: true}) {
    ...GenreBasics
  }
}
    ${GenreBasicsFragmentDoc}`;

/**
 * __useAdminListContentGenresQuery__
 *
 * To run a query within a React component, call `useAdminListContentGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListContentGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListContentGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminListContentGenresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListContentGenresQuery,
    AdminListContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListContentGenresQuery,
    AdminListContentGenresQueryVariables
  >(AdminListContentGenresDocument, options);
}
export function useAdminListContentGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListContentGenresQuery,
    AdminListContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListContentGenresQuery,
    AdminListContentGenresQueryVariables
  >(AdminListContentGenresDocument, options);
}
export function useAdminListContentGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListContentGenresQuery,
        AdminListContentGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListContentGenresQuery,
    AdminListContentGenresQueryVariables
  >(AdminListContentGenresDocument, options);
}
export type AdminListContentGenresQueryHookResult = ReturnType<
  typeof useAdminListContentGenresQuery
>;
export type AdminListContentGenresLazyQueryHookResult = ReturnType<
  typeof useAdminListContentGenresLazyQuery
>;
export type AdminListContentGenresSuspenseQueryHookResult = ReturnType<
  typeof useAdminListContentGenresSuspenseQuery
>;
export type AdminListContentGenresQueryResult = Apollo.QueryResult<
  AdminListContentGenresQuery,
  AdminListContentGenresQueryVariables
>;
export const AdminGetContentGenreDocument = gql`
    query AdminGetContentGenre($id: ID!) {
  data: contentGenre(id: $id) {
    ...GenreBasics
  }
}
    ${GenreBasicsFragmentDoc}`;

/**
 * __useAdminGetContentGenreQuery__
 *
 * To run a query within a React component, call `useAdminGetContentGenreQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetContentGenreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetContentGenreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetContentGenreQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetContentGenreQuery,
    AdminGetContentGenreQueryVariables
  > &
    (
      | { variables: AdminGetContentGenreQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetContentGenreQuery,
    AdminGetContentGenreQueryVariables
  >(AdminGetContentGenreDocument, options);
}
export function useAdminGetContentGenreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetContentGenreQuery,
    AdminGetContentGenreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetContentGenreQuery,
    AdminGetContentGenreQueryVariables
  >(AdminGetContentGenreDocument, options);
}
export function useAdminGetContentGenreSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetContentGenreQuery,
        AdminGetContentGenreQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetContentGenreQuery,
    AdminGetContentGenreQueryVariables
  >(AdminGetContentGenreDocument, options);
}
export type AdminGetContentGenreQueryHookResult = ReturnType<
  typeof useAdminGetContentGenreQuery
>;
export type AdminGetContentGenreLazyQueryHookResult = ReturnType<
  typeof useAdminGetContentGenreLazyQuery
>;
export type AdminGetContentGenreSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetContentGenreSuspenseQuery
>;
export type AdminGetContentGenreQueryResult = Apollo.QueryResult<
  AdminGetContentGenreQuery,
  AdminGetContentGenreQueryVariables
>;
export const AdminManyContentGenresDocument = gql`
    query AdminManyContentGenres($ids: [ID!]!) {
  data: manyContentGenres(ids: $ids) {
    ...GenreBasics
  }
}
    ${GenreBasicsFragmentDoc}`;

/**
 * __useAdminManyContentGenresQuery__
 *
 * To run a query within a React component, call `useAdminManyContentGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyContentGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyContentGenresQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyContentGenresQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyContentGenresQuery,
    AdminManyContentGenresQueryVariables
  > &
    (
      | { variables: AdminManyContentGenresQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminManyContentGenresQuery,
    AdminManyContentGenresQueryVariables
  >(AdminManyContentGenresDocument, options);
}
export function useAdminManyContentGenresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyContentGenresQuery,
    AdminManyContentGenresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyContentGenresQuery,
    AdminManyContentGenresQueryVariables
  >(AdminManyContentGenresDocument, options);
}
export function useAdminManyContentGenresSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyContentGenresQuery,
        AdminManyContentGenresQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyContentGenresQuery,
    AdminManyContentGenresQueryVariables
  >(AdminManyContentGenresDocument, options);
}
export type AdminManyContentGenresQueryHookResult = ReturnType<
  typeof useAdminManyContentGenresQuery
>;
export type AdminManyContentGenresLazyQueryHookResult = ReturnType<
  typeof useAdminManyContentGenresLazyQuery
>;
export type AdminManyContentGenresSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyContentGenresSuspenseQuery
>;
export type AdminManyContentGenresQueryResult = Apollo.QueryResult<
  AdminManyContentGenresQuery,
  AdminManyContentGenresQueryVariables
>;
export const AdminCreateContentGenreDocument = gql`
    mutation AdminCreateContentGenre($input: CreateContentGenreInput!) {
  data: createContentGenre(input: $input) {
    genre {
      ...GenreBasics
    }
  }
}
    ${GenreBasicsFragmentDoc}`;
export type AdminCreateContentGenreMutationFn = Apollo.MutationFunction<
  AdminCreateContentGenreMutation,
  AdminCreateContentGenreMutationVariables
>;

/**
 * __useAdminCreateContentGenreMutation__
 *
 * To run a mutation, you first call `useAdminCreateContentGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateContentGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateContentGenreMutation, { data, loading, error }] = useAdminCreateContentGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateContentGenreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateContentGenreMutation,
    AdminCreateContentGenreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateContentGenreMutation,
    AdminCreateContentGenreMutationVariables
  >(AdminCreateContentGenreDocument, options);
}
export type AdminCreateContentGenreMutationHookResult = ReturnType<
  typeof useAdminCreateContentGenreMutation
>;
export type AdminCreateContentGenreMutationResult =
  Apollo.MutationResult<AdminCreateContentGenreMutation>;
export type AdminCreateContentGenreMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateContentGenreMutation,
  AdminCreateContentGenreMutationVariables
>;
export const AdminUpdateContentGenreDocument = gql`
    mutation AdminUpdateContentGenre($input: UpdateContentGenreInput!) {
  data: updateContentGenre(input: $input) {
    genre {
      ...GenreBasics
    }
  }
}
    ${GenreBasicsFragmentDoc}`;
export type AdminUpdateContentGenreMutationFn = Apollo.MutationFunction<
  AdminUpdateContentGenreMutation,
  AdminUpdateContentGenreMutationVariables
>;

/**
 * __useAdminUpdateContentGenreMutation__
 *
 * To run a mutation, you first call `useAdminUpdateContentGenreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateContentGenreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateContentGenreMutation, { data, loading, error }] = useAdminUpdateContentGenreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateContentGenreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateContentGenreMutation,
    AdminUpdateContentGenreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateContentGenreMutation,
    AdminUpdateContentGenreMutationVariables
  >(AdminUpdateContentGenreDocument, options);
}
export type AdminUpdateContentGenreMutationHookResult = ReturnType<
  typeof useAdminUpdateContentGenreMutation
>;
export type AdminUpdateContentGenreMutationResult =
  Apollo.MutationResult<AdminUpdateContentGenreMutation>;
export type AdminUpdateContentGenreMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateContentGenreMutation,
  AdminUpdateContentGenreMutationVariables
>;
export const SyncContentGenresDocument = gql`
    mutation syncContentGenres {
  syncContentGenres
}
    `;
export type SyncContentGenresMutationFn = Apollo.MutationFunction<
  SyncContentGenresMutation,
  SyncContentGenresMutationVariables
>;

/**
 * __useSyncContentGenresMutation__
 *
 * To run a mutation, you first call `useSyncContentGenresMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncContentGenresMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncContentGenresMutation, { data, loading, error }] = useSyncContentGenresMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncContentGenresMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SyncContentGenresMutation,
    SyncContentGenresMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SyncContentGenresMutation,
    SyncContentGenresMutationVariables
  >(SyncContentGenresDocument, options);
}
export type SyncContentGenresMutationHookResult = ReturnType<
  typeof useSyncContentGenresMutation
>;
export type SyncContentGenresMutationResult =
  Apollo.MutationResult<SyncContentGenresMutation>;
export type SyncContentGenresMutationOptions = Apollo.BaseMutationOptions<
  SyncContentGenresMutation,
  SyncContentGenresMutationVariables
>;
export const AdminGetLocationDocument = gql`
    query AdminGetLocation($id: ID!) {
  data: coachLocation(id: $id) {
    ...AdminLocationDetails
  }
}
    ${AdminLocationDetailsFragmentDoc}`;

/**
 * __useAdminGetLocationQuery__
 *
 * To run a query within a React component, call `useAdminGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetLocationQuery,
    AdminGetLocationQueryVariables
  > &
    (
      | { variables: AdminGetLocationQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetLocationQuery, AdminGetLocationQueryVariables>(
    AdminGetLocationDocument,
    options,
  );
}
export function useAdminGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetLocationQuery,
    AdminGetLocationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetLocationQuery,
    AdminGetLocationQueryVariables
  >(AdminGetLocationDocument, options);
}
export function useAdminGetLocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetLocationQuery,
        AdminGetLocationQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetLocationQuery,
    AdminGetLocationQueryVariables
  >(AdminGetLocationDocument, options);
}
export type AdminGetLocationQueryHookResult = ReturnType<
  typeof useAdminGetLocationQuery
>;
export type AdminGetLocationLazyQueryHookResult = ReturnType<
  typeof useAdminGetLocationLazyQuery
>;
export type AdminGetLocationSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetLocationSuspenseQuery
>;
export type AdminGetLocationQueryResult = Apollo.QueryResult<
  AdminGetLocationQuery,
  AdminGetLocationQueryVariables
>;
export const AdminListLocationsDocument = gql`
    query AdminListLocations($input: ConnectionInput, $filter: CoachLocationsFilter) {
  data: coachLocations(input: $input, filter: $filter) {
    nodes {
      ...AdminLocationDetails
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminLocationDetailsFragmentDoc}`;

/**
 * __useAdminListLocationsQuery__
 *
 * To run a query within a React component, call `useAdminListLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListLocationsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListLocationsQuery,
    AdminListLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminListLocationsQuery,
    AdminListLocationsQueryVariables
  >(AdminListLocationsDocument, options);
}
export function useAdminListLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListLocationsQuery,
    AdminListLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListLocationsQuery,
    AdminListLocationsQueryVariables
  >(AdminListLocationsDocument, options);
}
export function useAdminListLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListLocationsQuery,
        AdminListLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListLocationsQuery,
    AdminListLocationsQueryVariables
  >(AdminListLocationsDocument, options);
}
export type AdminListLocationsQueryHookResult = ReturnType<
  typeof useAdminListLocationsQuery
>;
export type AdminListLocationsLazyQueryHookResult = ReturnType<
  typeof useAdminListLocationsLazyQuery
>;
export type AdminListLocationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminListLocationsSuspenseQuery
>;
export type AdminListLocationsQueryResult = Apollo.QueryResult<
  AdminListLocationsQuery,
  AdminListLocationsQueryVariables
>;
export const AdminManyLocationsDocument = gql`
    query AdminManyLocations($ids: [ID!]!) {
  data: manyCoachLocations(ids: $ids) {
    ...AdminLocationDetails
  }
}
    ${AdminLocationDetailsFragmentDoc}`;

/**
 * __useAdminManyLocationsQuery__
 *
 * To run a query within a React component, call `useAdminManyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyLocationsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyLocationsQuery,
    AdminManyLocationsQueryVariables
  > &
    (
      | { variables: AdminManyLocationsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminManyLocationsQuery,
    AdminManyLocationsQueryVariables
  >(AdminManyLocationsDocument, options);
}
export function useAdminManyLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyLocationsQuery,
    AdminManyLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyLocationsQuery,
    AdminManyLocationsQueryVariables
  >(AdminManyLocationsDocument, options);
}
export function useAdminManyLocationsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyLocationsQuery,
        AdminManyLocationsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyLocationsQuery,
    AdminManyLocationsQueryVariables
  >(AdminManyLocationsDocument, options);
}
export type AdminManyLocationsQueryHookResult = ReturnType<
  typeof useAdminManyLocationsQuery
>;
export type AdminManyLocationsLazyQueryHookResult = ReturnType<
  typeof useAdminManyLocationsLazyQuery
>;
export type AdminManyLocationsSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyLocationsSuspenseQuery
>;
export type AdminManyLocationsQueryResult = Apollo.QueryResult<
  AdminManyLocationsQuery,
  AdminManyLocationsQueryVariables
>;
export const AdminCreateLocationDocument = gql`
    mutation AdminCreateLocation($input: CreateCoachLocationInput!) {
  data: createCoachLocation(input: $input) {
    coachLocation {
      ...AdminLocationDetails
    }
  }
}
    ${AdminLocationDetailsFragmentDoc}`;
export type AdminCreateLocationMutationFn = Apollo.MutationFunction<
  AdminCreateLocationMutation,
  AdminCreateLocationMutationVariables
>;

/**
 * __useAdminCreateLocationMutation__
 *
 * To run a mutation, you first call `useAdminCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateLocationMutation, { data, loading, error }] = useAdminCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateLocationMutation,
    AdminCreateLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateLocationMutation,
    AdminCreateLocationMutationVariables
  >(AdminCreateLocationDocument, options);
}
export type AdminCreateLocationMutationHookResult = ReturnType<
  typeof useAdminCreateLocationMutation
>;
export type AdminCreateLocationMutationResult =
  Apollo.MutationResult<AdminCreateLocationMutation>;
export type AdminCreateLocationMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateLocationMutation,
  AdminCreateLocationMutationVariables
>;
export const AdminUpdateLocationDocument = gql`
    mutation AdminUpdateLocation($input: UpdateCoachLocationInput!) {
  data: updateCoachLocation(input: $input) {
    coachLocation {
      ...AdminLocationDetails
    }
  }
}
    ${AdminLocationDetailsFragmentDoc}`;
export type AdminUpdateLocationMutationFn = Apollo.MutationFunction<
  AdminUpdateLocationMutation,
  AdminUpdateLocationMutationVariables
>;

/**
 * __useAdminUpdateLocationMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLocationMutation, { data, loading, error }] = useAdminUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateLocationMutation,
    AdminUpdateLocationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateLocationMutation,
    AdminUpdateLocationMutationVariables
  >(AdminUpdateLocationDocument, options);
}
export type AdminUpdateLocationMutationHookResult = ReturnType<
  typeof useAdminUpdateLocationMutation
>;
export type AdminUpdateLocationMutationResult =
  Apollo.MutationResult<AdminUpdateLocationMutation>;
export type AdminUpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateLocationMutation,
  AdminUpdateLocationMutationVariables
>;
export const LocationCoachesDocument = gql`
    query locationCoaches($id: ID!, $input: ConnectionInput) {
  coachLocation(id: $id) {
    id
    coaches(input: $input) {
      count
      edges {
        node {
          ...CoachDetails
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}
    ${CoachDetailsFragmentDoc}`;

/**
 * __useLocationCoachesQuery__
 *
 * To run a query within a React component, call `useLocationCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationCoachesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocationCoachesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocationCoachesQuery,
    LocationCoachesQueryVariables
  > &
    (
      | { variables: LocationCoachesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationCoachesQuery, LocationCoachesQueryVariables>(
    LocationCoachesDocument,
    options,
  );
}
export function useLocationCoachesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationCoachesQuery,
    LocationCoachesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LocationCoachesQuery,
    LocationCoachesQueryVariables
  >(LocationCoachesDocument, options);
}
export function useLocationCoachesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LocationCoachesQuery,
        LocationCoachesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LocationCoachesQuery,
    LocationCoachesQueryVariables
  >(LocationCoachesDocument, options);
}
export type LocationCoachesQueryHookResult = ReturnType<
  typeof useLocationCoachesQuery
>;
export type LocationCoachesLazyQueryHookResult = ReturnType<
  typeof useLocationCoachesLazyQuery
>;
export type LocationCoachesSuspenseQueryHookResult = ReturnType<
  typeof useLocationCoachesSuspenseQuery
>;
export type LocationCoachesQueryResult = Apollo.QueryResult<
  LocationCoachesQuery,
  LocationCoachesQueryVariables
>;
export const GenerateSignedUrlDocument = gql`
    mutation generateSignedUrl($input: SignedUrlInput!) {
  generateSignedUrl(input: $input) {
    signedUrl
    key
    photo {
      basePath
      id
    }
  }
}
    `;
export type GenerateSignedUrlMutationFn = Apollo.MutationFunction<
  GenerateSignedUrlMutation,
  GenerateSignedUrlMutationVariables
>;

/**
 * __useGenerateSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSignedUrlMutation, { data, loading, error }] = useGenerateSignedUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateSignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateSignedUrlMutation,
    GenerateSignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateSignedUrlMutation,
    GenerateSignedUrlMutationVariables
  >(GenerateSignedUrlDocument, options);
}
export type GenerateSignedUrlMutationHookResult = ReturnType<
  typeof useGenerateSignedUrlMutation
>;
export type GenerateSignedUrlMutationResult =
  Apollo.MutationResult<GenerateSignedUrlMutation>;
export type GenerateSignedUrlMutationOptions = Apollo.BaseMutationOptions<
  GenerateSignedUrlMutation,
  GenerateSignedUrlMutationVariables
>;
export const AdminManyStudiosDocument = gql`
    query AdminManyStudios($ids: [ID!]!) {
  data: manyStudios(ids: $ids) {
    ...AdminStudioDetails
  }
}
    ${AdminStudioDetailsFragmentDoc}`;

/**
 * __useAdminManyStudiosQuery__
 *
 * To run a query within a React component, call `useAdminManyStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyStudiosQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyStudiosQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyStudiosQuery,
    AdminManyStudiosQueryVariables
  > &
    (
      | { variables: AdminManyStudiosQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminManyStudiosQuery, AdminManyStudiosQueryVariables>(
    AdminManyStudiosDocument,
    options,
  );
}
export function useAdminManyStudiosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyStudiosQuery,
    AdminManyStudiosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminManyStudiosQuery,
    AdminManyStudiosQueryVariables
  >(AdminManyStudiosDocument, options);
}
export function useAdminManyStudiosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyStudiosQuery,
        AdminManyStudiosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyStudiosQuery,
    AdminManyStudiosQueryVariables
  >(AdminManyStudiosDocument, options);
}
export type AdminManyStudiosQueryHookResult = ReturnType<
  typeof useAdminManyStudiosQuery
>;
export type AdminManyStudiosLazyQueryHookResult = ReturnType<
  typeof useAdminManyStudiosLazyQuery
>;
export type AdminManyStudiosSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyStudiosSuspenseQuery
>;
export type AdminManyStudiosQueryResult = Apollo.QueryResult<
  AdminManyStudiosQuery,
  AdminManyStudiosQueryVariables
>;
export const AdminGetStudioDocument = gql`
    query AdminGetStudio($id: ID!) {
  data: studio(id: $id) {
    ...AdminStudioDetails
  }
}
    ${AdminStudioDetailsFragmentDoc}`;

/**
 * __useAdminGetStudioQuery__
 *
 * To run a query within a React component, call `useAdminGetStudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetStudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetStudioQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetStudioQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetStudioQuery,
    AdminGetStudioQueryVariables
  > &
    (
      | { variables: AdminGetStudioQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetStudioQuery, AdminGetStudioQueryVariables>(
    AdminGetStudioDocument,
    options,
  );
}
export function useAdminGetStudioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetStudioQuery,
    AdminGetStudioQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetStudioQuery, AdminGetStudioQueryVariables>(
    AdminGetStudioDocument,
    options,
  );
}
export function useAdminGetStudioSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetStudioQuery,
        AdminGetStudioQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetStudioQuery,
    AdminGetStudioQueryVariables
  >(AdminGetStudioDocument, options);
}
export type AdminGetStudioQueryHookResult = ReturnType<
  typeof useAdminGetStudioQuery
>;
export type AdminGetStudioLazyQueryHookResult = ReturnType<
  typeof useAdminGetStudioLazyQuery
>;
export type AdminGetStudioSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetStudioSuspenseQuery
>;
export type AdminGetStudioQueryResult = Apollo.QueryResult<
  AdminGetStudioQuery,
  AdminGetStudioQueryVariables
>;
export const AdminListStudiosDocument = gql`
    query AdminListStudios($input: ConnectionInput, $filter: StudiosFilter) {
  data: studios(input: $input, filter: $filter) {
    nodes {
      ...AdminStudioDetails
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminStudioDetailsFragmentDoc}`;

/**
 * __useAdminListStudiosQuery__
 *
 * To run a query within a React component, call `useAdminListStudiosQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListStudiosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListStudiosQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListStudiosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListStudiosQuery,
    AdminListStudiosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListStudiosQuery, AdminListStudiosQueryVariables>(
    AdminListStudiosDocument,
    options,
  );
}
export function useAdminListStudiosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListStudiosQuery,
    AdminListStudiosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminListStudiosQuery,
    AdminListStudiosQueryVariables
  >(AdminListStudiosDocument, options);
}
export function useAdminListStudiosSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListStudiosQuery,
        AdminListStudiosQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListStudiosQuery,
    AdminListStudiosQueryVariables
  >(AdminListStudiosDocument, options);
}
export type AdminListStudiosQueryHookResult = ReturnType<
  typeof useAdminListStudiosQuery
>;
export type AdminListStudiosLazyQueryHookResult = ReturnType<
  typeof useAdminListStudiosLazyQuery
>;
export type AdminListStudiosSuspenseQueryHookResult = ReturnType<
  typeof useAdminListStudiosSuspenseQuery
>;
export type AdminListStudiosQueryResult = Apollo.QueryResult<
  AdminListStudiosQuery,
  AdminListStudiosQueryVariables
>;
export const AdminCreateStudioDocument = gql`
    mutation AdminCreateStudio($input: CreateStudioInput!) {
  data: createStudio(input: $input) {
    studio {
      ...AdminStudioDetails
    }
  }
}
    ${AdminStudioDetailsFragmentDoc}`;
export type AdminCreateStudioMutationFn = Apollo.MutationFunction<
  AdminCreateStudioMutation,
  AdminCreateStudioMutationVariables
>;

/**
 * __useAdminCreateStudioMutation__
 *
 * To run a mutation, you first call `useAdminCreateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateStudioMutation, { data, loading, error }] = useAdminCreateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateStudioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateStudioMutation,
    AdminCreateStudioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateStudioMutation,
    AdminCreateStudioMutationVariables
  >(AdminCreateStudioDocument, options);
}
export type AdminCreateStudioMutationHookResult = ReturnType<
  typeof useAdminCreateStudioMutation
>;
export type AdminCreateStudioMutationResult =
  Apollo.MutationResult<AdminCreateStudioMutation>;
export type AdminCreateStudioMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateStudioMutation,
  AdminCreateStudioMutationVariables
>;
export const AdminUpdateStudioDocument = gql`
    mutation AdminUpdateStudio($input: UpdateStudioInput!) {
  data: updateStudio(input: $input) {
    studio {
      ...AdminStudioDetails
    }
  }
}
    ${AdminStudioDetailsFragmentDoc}`;
export type AdminUpdateStudioMutationFn = Apollo.MutationFunction<
  AdminUpdateStudioMutation,
  AdminUpdateStudioMutationVariables
>;

/**
 * __useAdminUpdateStudioMutation__
 *
 * To run a mutation, you first call `useAdminUpdateStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateStudioMutation, { data, loading, error }] = useAdminUpdateStudioMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateStudioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateStudioMutation,
    AdminUpdateStudioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateStudioMutation,
    AdminUpdateStudioMutationVariables
  >(AdminUpdateStudioDocument, options);
}
export type AdminUpdateStudioMutationHookResult = ReturnType<
  typeof useAdminUpdateStudioMutation
>;
export type AdminUpdateStudioMutationResult =
  Apollo.MutationResult<AdminUpdateStudioMutation>;
export type AdminUpdateStudioMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateStudioMutation,
  AdminUpdateStudioMutationVariables
>;
export const AdminRemoveStudioDocument = gql`
    mutation AdminRemoveStudio($id: ID!) {
  data: removeStudio(id: $id)
}
    `;
export type AdminRemoveStudioMutationFn = Apollo.MutationFunction<
  AdminRemoveStudioMutation,
  AdminRemoveStudioMutationVariables
>;

/**
 * __useAdminRemoveStudioMutation__
 *
 * To run a mutation, you first call `useAdminRemoveStudioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveStudioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveStudioMutation, { data, loading, error }] = useAdminRemoveStudioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminRemoveStudioMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminRemoveStudioMutation,
    AdminRemoveStudioMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminRemoveStudioMutation,
    AdminRemoveStudioMutationVariables
  >(AdminRemoveStudioDocument, options);
}
export type AdminRemoveStudioMutationHookResult = ReturnType<
  typeof useAdminRemoveStudioMutation
>;
export type AdminRemoveStudioMutationResult =
  Apollo.MutationResult<AdminRemoveStudioMutation>;
export type AdminRemoveStudioMutationOptions = Apollo.BaseMutationOptions<
  AdminRemoveStudioMutation,
  AdminRemoveStudioMutationVariables
>;
export const GetSystemPreferencesDocument = gql`
    query getSystemPreferences {
  systemPreferences {
    minFitAndroidBuild
    minFitIosBuild
    minPerformAndroidBuild
    minPerformIosBuild
  }
}
    `;

/**
 * __useGetSystemPreferencesQuery__
 *
 * To run a query within a React component, call `useGetSystemPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSystemPreferencesQuery,
    GetSystemPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSystemPreferencesQuery,
    GetSystemPreferencesQueryVariables
  >(GetSystemPreferencesDocument, options);
}
export function useGetSystemPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSystemPreferencesQuery,
    GetSystemPreferencesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSystemPreferencesQuery,
    GetSystemPreferencesQueryVariables
  >(GetSystemPreferencesDocument, options);
}
export function useGetSystemPreferencesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetSystemPreferencesQuery,
        GetSystemPreferencesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSystemPreferencesQuery,
    GetSystemPreferencesQueryVariables
  >(GetSystemPreferencesDocument, options);
}
export type GetSystemPreferencesQueryHookResult = ReturnType<
  typeof useGetSystemPreferencesQuery
>;
export type GetSystemPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetSystemPreferencesLazyQuery
>;
export type GetSystemPreferencesSuspenseQueryHookResult = ReturnType<
  typeof useGetSystemPreferencesSuspenseQuery
>;
export type GetSystemPreferencesQueryResult = Apollo.QueryResult<
  GetSystemPreferencesQuery,
  GetSystemPreferencesQueryVariables
>;
export const SetMinBuildNumberDocument = gql`
    mutation setMinBuildNumber($input: SetMinBuildNumberInput!) {
  setMinBuildNumber(input: $input)
}
    `;
export type SetMinBuildNumberMutationFn = Apollo.MutationFunction<
  SetMinBuildNumberMutation,
  SetMinBuildNumberMutationVariables
>;

/**
 * __useSetMinBuildNumberMutation__
 *
 * To run a mutation, you first call `useSetMinBuildNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMinBuildNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMinBuildNumberMutation, { data, loading, error }] = useSetMinBuildNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetMinBuildNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMinBuildNumberMutation,
    SetMinBuildNumberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetMinBuildNumberMutation,
    SetMinBuildNumberMutationVariables
  >(SetMinBuildNumberDocument, options);
}
export type SetMinBuildNumberMutationHookResult = ReturnType<
  typeof useSetMinBuildNumberMutation
>;
export type SetMinBuildNumberMutationResult =
  Apollo.MutationResult<SetMinBuildNumberMutation>;
export type SetMinBuildNumberMutationOptions = Apollo.BaseMutationOptions<
  SetMinBuildNumberMutation,
  SetMinBuildNumberMutationVariables
>;
export const SelfDocument = gql`
    query self {
  self {
    ...UserDetails
    isAdmin
    isReadOnlyAdmin
    isCoach
    app
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
}
export function useSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export function useSelfSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SelfQuery, SelfQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SelfQuery, SelfQueryVariables>(
    SelfDocument,
    options,
  );
}
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfSuspenseQueryHookResult = ReturnType<
  typeof useSelfSuspenseQuery
>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const AdminSelfDocument = gql`
    query AdminSelf {
  self {
    id
    isAdmin
    isReadOnlyAdmin
    isCoach
    isMegaAdmin
    isTestUser
    firstName
    lastName
  }
}
    `;

/**
 * __useAdminSelfQuery__
 *
 * To run a query within a React component, call `useAdminSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminSelfQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminSelfQuery,
    AdminSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminSelfQuery, AdminSelfQueryVariables>(
    AdminSelfDocument,
    options,
  );
}
export function useAdminSelfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminSelfQuery,
    AdminSelfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminSelfQuery, AdminSelfQueryVariables>(
    AdminSelfDocument,
    options,
  );
}
export function useAdminSelfSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<AdminSelfQuery, AdminSelfQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminSelfQuery, AdminSelfQueryVariables>(
    AdminSelfDocument,
    options,
  );
}
export type AdminSelfQueryHookResult = ReturnType<typeof useAdminSelfQuery>;
export type AdminSelfLazyQueryHookResult = ReturnType<
  typeof useAdminSelfLazyQuery
>;
export type AdminSelfSuspenseQueryHookResult = ReturnType<
  typeof useAdminSelfSuspenseQuery
>;
export type AdminSelfQueryResult = Apollo.QueryResult<
  AdminSelfQuery,
  AdminSelfQueryVariables
>;
export const AdminGetUserDocument = gql`
    query AdminGetUser($id: ID!) {
  data: user(id: $id) {
    ...AdminUserDetails
    ...AdminCoachDataDetails
  }
}
    ${AdminUserDetailsFragmentDoc}
${AdminCoachDataDetailsFragmentDoc}`;

/**
 * __useAdminGetUserQuery__
 *
 * To run a query within a React component, call `useAdminGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUserQuery,
    AdminGetUserQueryVariables
  > &
    (
      | { variables: AdminGetUserQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(
    AdminGetUserDocument,
    options,
  );
}
export function useAdminGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUserQuery,
    AdminGetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(
    AdminGetUserDocument,
    options,
  );
}
export function useAdminGetUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetUserQuery,
        AdminGetUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(
    AdminGetUserDocument,
    options,
  );
}
export type AdminGetUserQueryHookResult = ReturnType<
  typeof useAdminGetUserQuery
>;
export type AdminGetUserLazyQueryHookResult = ReturnType<
  typeof useAdminGetUserLazyQuery
>;
export type AdminGetUserSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetUserSuspenseQuery
>;
export type AdminGetUserQueryResult = Apollo.QueryResult<
  AdminGetUserQuery,
  AdminGetUserQueryVariables
>;
export const AdminGetUserByEmailDocument = gql`
    query AdminGetUserByEmail($email: String!) {
  data: user(email: $email) {
    createdAt
    email
    firstName
    id
    lastName
  }
}
    `;

/**
 * __useAdminGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useAdminGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAdminGetUserByEmailQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUserByEmailQuery,
    AdminGetUserByEmailQueryVariables
  > &
    (
      | { variables: AdminGetUserByEmailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetUserByEmailQuery,
    AdminGetUserByEmailQueryVariables
  >(AdminGetUserByEmailDocument, options);
}
export function useAdminGetUserByEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUserByEmailQuery,
    AdminGetUserByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetUserByEmailQuery,
    AdminGetUserByEmailQueryVariables
  >(AdminGetUserByEmailDocument, options);
}
export function useAdminGetUserByEmailSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetUserByEmailQuery,
        AdminGetUserByEmailQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetUserByEmailQuery,
    AdminGetUserByEmailQueryVariables
  >(AdminGetUserByEmailDocument, options);
}
export type AdminGetUserByEmailQueryHookResult = ReturnType<
  typeof useAdminGetUserByEmailQuery
>;
export type AdminGetUserByEmailLazyQueryHookResult = ReturnType<
  typeof useAdminGetUserByEmailLazyQuery
>;
export type AdminGetUserByEmailSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetUserByEmailSuspenseQuery
>;
export type AdminGetUserByEmailQueryResult = Apollo.QueryResult<
  AdminGetUserByEmailQuery,
  AdminGetUserByEmailQueryVariables
>;
export const AdminListUsersDocument = gql`
    query AdminListUsers($input: ConnectionInput, $filter: UsersFilter) {
  data: users(input: $input, filter: $filter) {
    nodes {
      ...AdminUserBasics
    }
    count
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${AdminUserBasicsFragmentDoc}`;

/**
 * __useAdminListUsersQuery__
 *
 * To run a query within a React component, call `useAdminListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminListUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AdminListUsersQuery,
    AdminListUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(
    AdminListUsersDocument,
    options,
  );
}
export function useAdminListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminListUsersQuery,
    AdminListUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminListUsersQuery, AdminListUsersQueryVariables>(
    AdminListUsersDocument,
    options,
  );
}
export function useAdminListUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminListUsersQuery,
        AdminListUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminListUsersQuery,
    AdminListUsersQueryVariables
  >(AdminListUsersDocument, options);
}
export type AdminListUsersQueryHookResult = ReturnType<
  typeof useAdminListUsersQuery
>;
export type AdminListUsersLazyQueryHookResult = ReturnType<
  typeof useAdminListUsersLazyQuery
>;
export type AdminListUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminListUsersSuspenseQuery
>;
export type AdminListUsersQueryResult = Apollo.QueryResult<
  AdminListUsersQuery,
  AdminListUsersQueryVariables
>;
export const AdminManyUsersDocument = gql`
    query AdminManyUsers($ids: [ID!]!) {
  data: manyUsers(ids: $ids) {
    ...AdminUserBasics
  }
}
    ${AdminUserBasicsFragmentDoc}`;

/**
 * __useAdminManyUsersQuery__
 *
 * To run a query within a React component, call `useAdminManyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminManyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminManyUsersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminManyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminManyUsersQuery,
    AdminManyUsersQueryVariables
  > &
    (
      | { variables: AdminManyUsersQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdminManyUsersQuery, AdminManyUsersQueryVariables>(
    AdminManyUsersDocument,
    options,
  );
}
export function useAdminManyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminManyUsersQuery,
    AdminManyUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdminManyUsersQuery, AdminManyUsersQueryVariables>(
    AdminManyUsersDocument,
    options,
  );
}
export function useAdminManyUsersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminManyUsersQuery,
        AdminManyUsersQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminManyUsersQuery,
    AdminManyUsersQueryVariables
  >(AdminManyUsersDocument, options);
}
export type AdminManyUsersQueryHookResult = ReturnType<
  typeof useAdminManyUsersQuery
>;
export type AdminManyUsersLazyQueryHookResult = ReturnType<
  typeof useAdminManyUsersLazyQuery
>;
export type AdminManyUsersSuspenseQueryHookResult = ReturnType<
  typeof useAdminManyUsersSuspenseQuery
>;
export type AdminManyUsersQueryResult = Apollo.QueryResult<
  AdminManyUsersQuery,
  AdminManyUsersQueryVariables
>;
export const UserAuditLogsDocument = gql`
    query userAuditLogs($id: ID!, $input: ConnectionInput) {
  user(id: $id) {
    id
    auditLogs(input: $input) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          action
          createdAt
          data
          principal {
            __typename
            ... on MachineClient {
              id
            }
            ... on Node {
              id
            }
            ... on User {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUserAuditLogsQuery__
 *
 * To run a query within a React component, call `useUserAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAuditLogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserAuditLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserAuditLogsQuery,
    UserAuditLogsQueryVariables
  > &
    (
      | { variables: UserAuditLogsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAuditLogsQuery, UserAuditLogsQueryVariables>(
    UserAuditLogsDocument,
    options,
  );
}
export function useUserAuditLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAuditLogsQuery,
    UserAuditLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAuditLogsQuery, UserAuditLogsQueryVariables>(
    UserAuditLogsDocument,
    options,
  );
}
export function useUserAuditLogsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        UserAuditLogsQuery,
        UserAuditLogsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    UserAuditLogsQuery,
    UserAuditLogsQueryVariables
  >(UserAuditLogsDocument, options);
}
export type UserAuditLogsQueryHookResult = ReturnType<
  typeof useUserAuditLogsQuery
>;
export type UserAuditLogsLazyQueryHookResult = ReturnType<
  typeof useUserAuditLogsLazyQuery
>;
export type UserAuditLogsSuspenseQueryHookResult = ReturnType<
  typeof useUserAuditLogsSuspenseQuery
>;
export type UserAuditLogsQueryResult = Apollo.QueryResult<
  UserAuditLogsQuery,
  UserAuditLogsQueryVariables
>;
export const AdminGetUserCoachSessionsDocument = gql`
    query AdminGetUserCoachSessions($userId: ID!) {
  user(id: $userId) {
    sessions(orderBy: {direction: desc, order: sessionTime}) {
      count
      nodes {
        id
        sessionType
        sessionStyle
        attendance
        calendar {
          id
          start
        }
        coach {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useAdminGetUserCoachSessionsQuery__
 *
 * To run a query within a React component, call `useAdminGetUserCoachSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserCoachSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserCoachSessionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetUserCoachSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUserCoachSessionsQuery,
    AdminGetUserCoachSessionsQueryVariables
  > &
    (
      | { variables: AdminGetUserCoachSessionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetUserCoachSessionsQuery,
    AdminGetUserCoachSessionsQueryVariables
  >(AdminGetUserCoachSessionsDocument, options);
}
export function useAdminGetUserCoachSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUserCoachSessionsQuery,
    AdminGetUserCoachSessionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetUserCoachSessionsQuery,
    AdminGetUserCoachSessionsQueryVariables
  >(AdminGetUserCoachSessionsDocument, options);
}
export function useAdminGetUserCoachSessionsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetUserCoachSessionsQuery,
        AdminGetUserCoachSessionsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetUserCoachSessionsQuery,
    AdminGetUserCoachSessionsQueryVariables
  >(AdminGetUserCoachSessionsDocument, options);
}
export type AdminGetUserCoachSessionsQueryHookResult = ReturnType<
  typeof useAdminGetUserCoachSessionsQuery
>;
export type AdminGetUserCoachSessionsLazyQueryHookResult = ReturnType<
  typeof useAdminGetUserCoachSessionsLazyQuery
>;
export type AdminGetUserCoachSessionsSuspenseQueryHookResult = ReturnType<
  typeof useAdminGetUserCoachSessionsSuspenseQuery
>;
export type AdminGetUserCoachSessionsQueryResult = Apollo.QueryResult<
  AdminGetUserCoachSessionsQuery,
  AdminGetUserCoachSessionsQueryVariables
>;
export const AdminGetUserAuthHistoryDocument = gql`
    query AdminGetUserAuthHistory($userId: ID!) {
  user(id: $userId) {
    ...UserAuthHistory
  }
}
    ${UserAuthHistoryFragmentDoc}`;

/**
 * __useAdminGetUserAuthHistoryQuery__
 *
 * To run a query within a React component, call `useAdminGetUserAuthHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGetUserAuthHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGetUserAuthHistoryQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminGetUserAuthHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    AdminGetUserAuthHistoryQuery,
    AdminGetUserAuthHistoryQueryVariables
  > &
    (
      | { variables: AdminGetUserAuthHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AdminGetUserAuthHistoryQuery,
    AdminGetUserAuthHistoryQueryVariables
  >(AdminGetUserAuthHistoryDocument, options);
}
export function useAdminGetUserAuthHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AdminGetUserAuthHistoryQuery,
    AdminGetUserAuthHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AdminGetUserAuthHistoryQuery,
    AdminGetUserAuthHistoryQueryVariables
  >(AdminGetUserAuthHistoryDocument, options);
}
export function useAdminGetUserAuthHistorySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AdminGetUserAuthHistoryQuery,
        AdminGetUserAuthHistoryQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    AdminGetUserAuthHistoryQuery,
    AdminGetUserAuthHistoryQueryVariables
  >(AdminGetUserAuthHistoryDocument, options);
}
export type AdminGetUserAuthHistoryQueryHookResult = ReturnType<
  typeof useAdminGetUserAuthHistoryQuery
>;
export type AdminGetUserAuthHistoryLazyQueryHookResult = ReturnType<
  typeof useAdminGetUserAuthHistoryLazyQuery
>;
export type AdminGetUserAuthHistorySuspenseQueryHookResult = ReturnType<
  typeof useAdminGetUserAuthHistorySuspenseQuery
>;
export type AdminGetUserAuthHistoryQueryResult = Apollo.QueryResult<
  AdminGetUserAuthHistoryQuery,
  AdminGetUserAuthHistoryQueryVariables
>;
export const CreateUsersDocument = gql`
    mutation createUsers($input: CreateUsersInput!) {
  createUsers(input: $input) {
    users {
      id
    }
  }
}
    `;
export type CreateUsersMutationFn = Apollo.MutationFunction<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUsersMutation,
    CreateUsersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(
    CreateUsersDocument,
    options,
  );
}
export type CreateUsersMutationHookResult = ReturnType<
  typeof useCreateUsersMutation
>;
export type CreateUsersMutationResult =
  Apollo.MutationResult<CreateUsersMutation>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;
export const AdminCreateUserDocument = gql`
    mutation AdminCreateUser($input: CreateUserInput!) {
  data: createUser(input: $input) {
    user {
      ...AdminUserDetails
      ...AdminCoachDataDetails
    }
  }
}
    ${AdminUserDetailsFragmentDoc}
${AdminCoachDataDetailsFragmentDoc}`;
export type AdminCreateUserMutationFn = Apollo.MutationFunction<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;

/**
 * __useAdminCreateUserMutation__
 *
 * To run a mutation, you first call `useAdminCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateUserMutation, { data, loading, error }] = useAdminCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminCreateUserMutation,
    AdminCreateUserMutationVariables
  >(AdminCreateUserDocument, options);
}
export type AdminCreateUserMutationHookResult = ReturnType<
  typeof useAdminCreateUserMutation
>;
export type AdminCreateUserMutationResult =
  Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables
>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($input: UpdateUserInput!, $coachDataInput: UpdateCoachDataInput!, $updateCoachData: Boolean!, $updateAvatar: Boolean!, $avatarInput: UpdateAvatarInput!) {
  data: updateUser(input: $input) {
    user {
      ...AdminUserDetails
    }
  }
  updateCoachData(input: $coachDataInput) @include(if: $updateCoachData) {
    user {
      id
      ...AdminCoachDataDetails
    }
  }
  updateAvatar(input: $avatarInput) @include(if: $updateAvatar) {
    user {
      id
    }
  }
}
    ${AdminUserDetailsFragmentDoc}
${AdminCoachDataDetailsFragmentDoc}`;
export type AdminUpdateUserMutationFn = Apollo.MutationFunction<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;

/**
 * __useAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateUserMutation, { data, loading, error }] = useAdminUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      coachDataInput: // value for 'coachDataInput'
 *      updateCoachData: // value for 'updateCoachData'
 *      updateAvatar: // value for 'updateAvatar'
 *      avatarInput: // value for 'avatarInput'
 *   },
 * });
 */
export function useAdminUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminUpdateUserMutation,
    AdminUpdateUserMutationVariables
  >(AdminUpdateUserDocument, options);
}
export type AdminUpdateUserMutationHookResult = ReturnType<
  typeof useAdminUpdateUserMutation
>;
export type AdminUpdateUserMutationResult =
  Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminUpdateUserMutation,
  AdminUpdateUserMutationVariables
>;
export const AdminAdminUpdateUserDocument = gql`
    mutation AdminAdminUpdateUser($input: AdminUpdateUserInput!) {
  data: adminUpdateUser(input: $input) {
    user {
      ...AdminUserDetails
    }
  }
}
    ${AdminUserDetailsFragmentDoc}`;
export type AdminAdminUpdateUserMutationFn = Apollo.MutationFunction<
  AdminAdminUpdateUserMutation,
  AdminAdminUpdateUserMutationVariables
>;

/**
 * __useAdminAdminUpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminAdminUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAdminUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAdminUpdateUserMutation, { data, loading, error }] = useAdminAdminUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAdminUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminAdminUpdateUserMutation,
    AdminAdminUpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminAdminUpdateUserMutation,
    AdminAdminUpdateUserMutationVariables
  >(AdminAdminUpdateUserDocument, options);
}
export type AdminAdminUpdateUserMutationHookResult = ReturnType<
  typeof useAdminAdminUpdateUserMutation
>;
export type AdminAdminUpdateUserMutationResult =
  Apollo.MutationResult<AdminAdminUpdateUserMutation>;
export type AdminAdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<
  AdminAdminUpdateUserMutation,
  AdminAdminUpdateUserMutationVariables
>;
export const AdminResetProgressDocument = gql`
    mutation AdminResetProgress($userId: ID!, $contentId: ID, $sectionId: ID) {
  data: resetProgress(
    userId: $userId
    contentId: $contentId
    sectionId: $sectionId
  ) {
    section {
      id
    }
  }
}
    `;
export type AdminResetProgressMutationFn = Apollo.MutationFunction<
  AdminResetProgressMutation,
  AdminResetProgressMutationVariables
>;

/**
 * __useAdminResetProgressMutation__
 *
 * To run a mutation, you first call `useAdminResetProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResetProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResetProgressMutation, { data, loading, error }] = useAdminResetProgressMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      contentId: // value for 'contentId'
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useAdminResetProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminResetProgressMutation,
    AdminResetProgressMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminResetProgressMutation,
    AdminResetProgressMutationVariables
  >(AdminResetProgressDocument, options);
}
export type AdminResetProgressMutationHookResult = ReturnType<
  typeof useAdminResetProgressMutation
>;
export type AdminResetProgressMutationResult =
  Apollo.MutationResult<AdminResetProgressMutation>;
export type AdminResetProgressMutationOptions = Apollo.BaseMutationOptions<
  AdminResetProgressMutation,
  AdminResetProgressMutationVariables
>;
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($userId: ID!, $jira: String, $forgetIterable: Boolean) {
  data: deleteUser(userId: $userId, jira: $jira, forgetIterable: $forgetIterable) {
    userId
  }
}
    `;
export type AdminDeleteUserMutationFn = Apollo.MutationFunction<
  AdminDeleteUserMutation,
  AdminDeleteUserMutationVariables
>;

/**
 * __useAdminDeleteUserMutation__
 *
 * To run a mutation, you first call `useAdminDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteUserMutation, { data, loading, error }] = useAdminDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      jira: // value for 'jira'
 *      forgetIterable: // value for 'forgetIterable'
 *   },
 * });
 */
export function useAdminDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdminDeleteUserMutation,
    AdminDeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdminDeleteUserMutation,
    AdminDeleteUserMutationVariables
  >(AdminDeleteUserDocument, options);
}
export type AdminDeleteUserMutationHookResult = ReturnType<
  typeof useAdminDeleteUserMutation
>;
export type AdminDeleteUserMutationResult =
  Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<
  AdminDeleteUserMutation,
  AdminDeleteUserMutationVariables
>;
