import React from 'react';

import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceInputProps,
  required,
} from 'react-admin';

interface AutocompleteStudioInputProps
  extends Omit<ReferenceInputProps, 'reference' | 'filterToQuery'> {
  isRequired?: boolean;
}

const AutocompleteStudioInput: React.FC<AutocompleteStudioInputProps> = ({
  source,
  label,
  filter,
  isRequired,
  ...props
}) => (
  <ReferenceInput {...props} source={source} filter={filter} reference="Studio">
    <AutocompleteInput
      label={label}
      optionText="name"
      filter="name"
      isRequired={isRequired}
      validate={isRequired ? required('Studio is required') : undefined}
      filterToQuery={(name: string) => ({
        name,
      })}
    />
  </ReferenceInput>
);

export default AutocompleteStudioInput;
