import * as React from 'react';

import { Typography } from '@mui/material';
import {
  Datagrid,
  DateField,
  ListContextProvider,
  TextField,
  useList,
  useRecordContext,
} from 'react-admin';

import {
  AdminUserDetailsFragment,
  useAdminGetUserAuthHistoryQuery,
} from '../../../../graphql/types';

export const MemberAuthHistoryTab: React.FC = () => {
  const record = useRecordContext<AdminUserDetailsFragment>();
  const { data, error, loading } = useAdminGetUserAuthHistoryQuery({
    variables: {
      userId: record!.id, // see skip
    },
    skip: !record?.id,
  });
  const authHistory = data?.user?.authHistory;

  const errorRequestId = error?.graphQLErrors[0]?.extensions?.requestId as
    | string
    | undefined;
  const errorPath = error?.graphQLErrors[0].path?.join(' > ');

  const listContext = useList({ data: authHistory?.nodes, isPending: loading });

  return (
    <>
      <Typography variant="h6" fontSize={14}>
        Note: This only includes data for username/password users, not SSO users
      </Typography>
      {error && (
        <>
          <Typography color="error">
            An error occurred while fetching auth history.
          </Typography>

          {errorPath && (
            <Typography color="error" fontSize={12}>
              Path: {errorPath}
            </Typography>
          )}
          {errorRequestId && (
            <Typography color="error" fontSize={12}>
              Request Id: {errorRequestId}
            </Typography>
          )}
        </>
      )}
      {!error && (
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false} rowClick={false}>
            <DateField source="eventDate" showTime />
            <TextField source="type" />
            <TextField source="status" />
            <TextField source="challengeResponses" />
          </Datagrid>
        </ListContextProvider>
      )}
    </>
  );
};
