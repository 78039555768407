import React, { useEffect } from 'react';

import { AutocompleteArrayInput } from 'react-admin';
import { useWatch } from 'react-hook-form';

import {
  CoachingStyle,
  useAdminListLocationsLazyQuery,
} from '../../../graphql/types';

import { EditCoachClients } from './ListCoachClients';

const ClientAndLocationInput: React.FC = () => {
  const data = useWatch({ name: 'coachData.coachClients' });
  const clientIds = React.useMemo(
    () =>
      (
        data?.edges.map(
          ({ node }: { node: { id: string | null } }) => node.id,
        ) || []
      ).filter((id: string | null) => id),
    [data],
  );

  const [fetchLocations, { data: clientLocationsData }] =
    useAdminListLocationsLazyQuery();

  useEffect(() => {
    fetchLocations({
      variables: {
        input: {
          first: 100,
        },
        filter: { clientIds, studioIsArchived: false },
      },
    });
  }, [fetchLocations, clientIds]);

  return (
    <>
      <EditCoachClients />
      <AutocompleteArrayInput
        label="Locations"
        source="coachData.locations.ids"
        choices={clientLocationsData?.data?.nodes?.map(({ name, id }) => ({
          name: name || '',
          id,
        }))}
        disabled={clientIds.length === 0}
        validate={[
          (selectedLocations, allValues) => {
            const isHybridCoach =
              allValues.coachData.coachingStyle === CoachingStyle.Hybrid;
            const hasNonVirtualLocation = selectedLocations?.some(
              (locationId: string) =>
                !clientLocationsData?.data?.nodes?.find(
                  (location) => location.id === locationId,
                )?.studio?.isVirtual,
            );

            if (isHybridCoach && !hasNonVirtualLocation) {
              return 'An onsite coach must have at least 1 non-virtual location.';
            }
            return undefined;
          },
        ]}
        style={{ marginBottom: 13 }}
        sx={{
          minWidth: '300px',
        }}
      />
    </>
  );
};

export default ClientAndLocationInput;
