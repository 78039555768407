import React, { ReactNode, useCallback } from 'react';

import { ZoneId, ZonedDateTime } from '@js-joda/core';
import { Tooltip } from '@mui/material';
import get from 'lodash/get';
import { FunctionField, FunctionFieldProps } from 'react-admin';

import { dateAndTime, getTimeSince } from '../../../utils/date';

const tooltipFormat = (zdt: ZonedDateTime) =>
  dateAndTime(zdt.withZoneSameInstant(ZoneId.systemDefault()));

const addLineBreaks = (strings: string[]): ReactNode => {
  const result: ReactNode[] = [];
  strings.forEach((line, idx) => {
    if (idx > 0) {
      result.push(<br />);
    }
    result.push(line);
  });
  return result;
};

interface MultiTimeAgoProps extends Omit<FunctionFieldProps, 'render'> {
  subfields: string[];
}

const MultiTimeAgoFieldComponent: React.FC<MultiTimeAgoProps> = ({
  subfields,
  record,
  source,
}) => {
  const lastActivity = source ? get(record, source) : null;
  const zdts: [string, ZonedDateTime][] =
    typeof lastActivity !== 'object'
      ? []
      : subfields.flatMap((subfield) => {
          const date = lastActivity[subfield];
          return date ? [[subfield, ZonedDateTime.parse(date)]] : [];
        });
  const newestZdt = zdts.reduce<ZonedDateTime | null>(
    (curr, [, candidate]) =>
      curr === null || candidate.isAfter(curr) ? candidate : curr,
    null,
  );
  const tooltip = addLineBreaks(
    zdts.map(([label, zdt]) => `${label}:\u00a0${tooltipFormat(zdt)}`),
  );
  if (newestZdt) {
    return (
      <Tooltip title={tooltip}>
        <span>{getTimeSince(newestZdt.toLocalDate())}</span>
      </Tooltip>
    );
  } else {
    return 'unknown';
  }
};

export const MultiTimeAgoField: React.FC<MultiTimeAgoProps> = ({
  source = '',
  subfields,
  ...rest
}) => {
  const render = useCallback(
    (record: any) => (
      <MultiTimeAgoFieldComponent
        record={record}
        subfields={subfields}
        source={source}
      />
    ),
    [subfields, source],
  );

  return <FunctionField {...rest} render={render} />;
};
