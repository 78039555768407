import * as React from 'react';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  Link,
  Button as ReactAdminButton,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useNotify,
  usePermissions,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import { isMegaAdmin } from '../../../admin/permissions';
import {
  AdminCoachDataDetailsFragment,
  AdminUserDetailsFragment,
  CoachType,
  CoachingStyle,
  Prefix,
  UserGoalType,
  useAdminCountryListQuery,
  useAdminListCoachesQuery,
  useAdminResetProgressMutation,
  useAssignFitCoachMutation,
  useDeleteFitCoachMutation,
} from '../../../graphql/types';
import { SplitFlags, useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { EnvLevel, getEnvLevel } from '../../../utils/getEnvLevel';
import ClientAndLocationInput from '../components/ClientAndLocationInput';
import EditToolbar from '../components/EditToolbar';
import ImageUploadInput from '../components/ImageUploadInput';

import { UserTitle, getCoachingStyleLabel } from './util';

const goalMap: { [key in UserGoalType]?: string } = {
  [UserGoalType.PhysicalPerformance]: 'Physical Performance',
  [UserGoalType.Appearance]: 'Weight & Body Composition',
  [UserGoalType.SocialWellbeing]: 'Social Well-Being',
  [UserGoalType.EmotionalWellbeing]: 'Mental Well-Being',
  [UserGoalType.PainManagement]: 'Pain Management',
  [UserGoalType.Health]: 'Overall Health',
  [UserGoalType.EnergyLevels]: 'Energy',
  [UserGoalType.WorkPerformance]: 'Work Performance',
};

const goalOptions = Object.entries(goalMap).map(([value, label]) => ({
  id: value,
  name: label,
}));

const EditUserActions: React.FC<{
  record: AdminUserDetailsFragment;
}> = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [selectedCoachId, setSelectedCoachId] = React.useState<string>();

  const [
    deleteFitCoach,
    { loading: deleteCoachMutationLoading, error: deleteCoachMutationError },
  ] = useDeleteFitCoachMutation();

  const [
    assignFitCoach,
    { loading: assignCoachMutationLoading, error: assignCoachMutationError },
  ] = useAssignFitCoachMutation();

  const { data: coachesData } = useAdminListCoachesQuery({
    variables: {
      id: record.id,
      input: {
        first: 100,
      },
    },
    fetchPolicy: 'cache-first',
  });

  React.useEffect(() => {
    if (assignCoachMutationError) {
      notify(assignCoachMutationError.message, { type: 'error' });
    }
  }, [notify, assignCoachMutationError]);

  React.useEffect(() => {
    if (deleteCoachMutationError) {
      notify(deleteCoachMutationError.message, { type: 'error' });
    }
  }, [notify, deleteCoachMutationError]);

  const isAssignCoachDisabled = assignCoachMutationLoading || !!record.fitCoach;
  const isDeleteCoachDisabled = deleteCoachMutationLoading || !record.fitCoach;

  const handleAssignCoach = async () => {
    if (selectedCoachId) {
      await assignFitCoach({
        variables: {
          userId: `${record.id}`,
          coachId: selectedCoachId,
        },
      });

      refresh();
    }
  };

  const handleDeleteCoach = async () => {
    await deleteFitCoach({
      variables: {
        userId: `${record.id}`,
      },
    });
    setSelectedCoachId('');
    refresh();
  };

  const coachOptions =
    coachesData?.user?.fitClient?.coaches.nodes.map((coach) => ({
      id: coach.id,
      name: `${coach.firstName} ${coach.lastName}`,
    })) || [];

  return (
    <Box borderTop="1px solid #e6e6e6" width="100%" marginTop={2}>
      <Typography variant="h6">Manage Fit Coach</Typography>
      {record.fitCoach?.id && (
        <div>
          <p>
            Current Coach: {record.fitCoach?.firstName}{' '}
            {record.fitCoach?.lastName}
          </p>
          <ReactAdminButton
            id="delete-coach-button"
            disabled={isDeleteCoachDisabled}
            color="error"
            variant="contained"
            onClick={handleDeleteCoach}
            size="medium"
            label={deleteCoachMutationLoading ? 'Loading' : 'Delete Coach'}
          />
        </div>
      )}
      {!record.fitCoach?.id && (
        <div>
          <Box marginBottom={1} marginTop={2}>
            <FormControl fullWidth size="small">
              <InputLabel>Coach</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCoachId}
                label="Coach"
                onChange={({ target }) =>
                  setSelectedCoachId(target.value as string)
                }
              >
                {coachOptions.map(({ id, name }) => (
                  <MenuItem value={id}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            disabled={isAssignCoachDisabled || !selectedCoachId}
            color="secondary"
            variant="contained"
            onClick={handleAssignCoach}
            size="medium"
          >
            {assignCoachMutationLoading ? 'Loading' : 'Assign Coach'}
          </Button>
        </div>
      )}
    </Box>
  );
};

const UserEditComponent: React.FC = () => {
  const envLevel = getEnvLevel();
  const record = useRecordContext<
    AdminUserDetailsFragment & AdminCoachDataDetailsFragment
  >();
  const { flags } = useFeatureFlags();
  const enableCoachClientAvailability =
    flags[SplitFlags.COACH_CLIENT_AVAILABILITY];
  const { data: countryList, loading: countryListLoading } =
    useAdminCountryListQuery();
  const { permissions } = usePermissions();

  const notify = useNotify();

  const [
    resetProgress,
    { loading: resetProgressLoading, error: resetProgressError },
  ] = useAdminResetProgressMutation();

  const programId = record?.currentPractice?.practiceProgram?.id;
  const isResetProgressDisabled = resetProgressLoading || !record?.fitCoach;

  React.useEffect(() => {
    if (resetProgressError) {
      notify(resetProgressError.message, { type: 'error' });
    }
  }, [notify, resetProgressError]);

  const handleResetProgress = () => {
    resetProgress({
      variables: {
        userId: `${record?.id}`,
        contentId: programId,
      },
    });
  };

  return (
    <TabbedForm toolbar={<EditToolbar />} mutationMode="pessimistic">
      <TabbedForm.Tab label="User basics">
        <TextInput source="id" id="id" InputProps={{ disabled: true }} />
        <TextInput source="firstName" id="firstName" />
        <TextInput source="lastName" id="lastName" />
        <TextInput source="email" id="email" disabled />
        <BooleanInput source="active" id="active" disabled />
        <ImageUploadInput
          source="avatar"
          prefix={Prefix.Avatar}
          label="Avatar"
          helperText="Upload a square (1:1 aspect ratio) image"
        />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Roles">
        <BooleanInput
          data-testid="is-admin-toggle"
          source="isAdmin"
          disabled={!isMegaAdmin(permissions)}
        />
        <BooleanInput
          data-testid="is-admin-read-only-toggle"
          source="isReadOnlyAdmin"
          disabled={!isMegaAdmin(permissions)}
        />
        <BooleanInput
          data-testid="is-mega-admin-toggle"
          source="isMegaAdmin"
          disabled={!isMegaAdmin(permissions)}
        />
        <BooleanInput data-testid="is-coach-toggle" source="isCoach" />
        <BooleanInput
          data-testid="is-sales-team-user-toggle"
          source="isSalesTeam"
        />
        <BooleanInput data-testid="is-test-user-toggle" source="isTestUser" />
      </TabbedForm.Tab>
      <TabbedForm.Tab label="Fit">
        <BooleanInput
          source="hasHighPerformanceCoaching"
          label="Has High-Performance Coaching"
          helperText={
            <>
              Whether or not the client has access to the high performance
              coaching experience.
              <br />
              {envLevel === EnvLevel.PROD ? (
                <>
                  Use{' '}
                  <Link to="/EligibleEmailAddress">
                    Eligible Email Addresses
                  </Link>{' '}
                  to control access to this feature.
                </>
              ) : (
                ''
              )}
            </>
          }
          disabled={envLevel === EnvLevel.PROD}
        />
        <Box
          borderTop="1px solid #e6e6e6"
          marginTop={2}
          paddingTop={2}
          width="100%"
        >
          <ReactAdminButton
            id="reset-practice-progress-button"
            disabled={isResetProgressDisabled}
            color="secondary"
            variant="contained"
            onClick={handleResetProgress}
            size="medium"
            label={resetProgressLoading ? 'Loading' : 'Reset Practice Progress'}
          />
        </Box>
        {record && <EditUserActions record={record} />}
      </TabbedForm.Tab>
      {record?.isCoach && (
        <TabbedForm.Tab label="Coach Data">
          <TextInput
            source="coachData.bio"
            label="Bio"
            id="coachData.bio"
            multiline
            fullWidth
          />
          <SelectInput
            label="Coach Type"
            source="coachData.type"
            choices={Object.entries(CoachType).map(([name, coachId]) => ({
              id: coachId,
              name,
            }))}
            defaultValue={CoachType.Coach}
          />
          <SelectInput
            label="Coaching Style"
            source="coachData.coachingStyle"
            choices={Object.entries(CoachingStyle).map(([value, id]) => ({
              id,
              name: getCoachingStyleLabel(value),
            }))}
          />
          <ClientAndLocationInput />
          <ArrayInput source="coachData.titles" label="Titles">
            <SimpleFormIterator>
              <TextInput source="" fullWidth label="Title" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="coachData.degrees" label="Degrees">
            <SimpleFormIterator>
              <TextInput source="" fullWidth label="Degree" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="coachData.passions" label="Passions">
            <SimpleFormIterator>
              <TextInput source="" fullWidth label="Passion" />
            </SimpleFormIterator>
          </ArrayInput>
          <ImageUploadInput
            source="coachData.headshot"
            prefix={Prefix.Avatar}
            label="Headshot Photo"
            helperText="Upload a square (1:1 aspect ratio) image"
          />
          <ImageUploadInput
            source="coachData.bodyPhoto"
            prefix={Prefix.Avatar}
            label="Avatar"
            helperText="Upload a rectangular (3:2 aspect ratio) image"
          />
          <Box
            borderTop="1px solid #e6e6e6"
            marginTop={2}
            paddingTop={4}
            width="100%"
          >
            <Typography variant="h5" fontSize={20}>
              Coach Matching Options
            </Typography>
            {!enableCoachClientAvailability && (
              <BooleanInput
                source="coachData.availableForMemberAssignment"
                label="Available for Member Assignment"
                defaultValue
              />
            )}
            <Typography variant="h6" marginY={2} fontSize={18}>
              International Coach Matching
            </Typography>
            <AutocompleteArrayInput
              label="Supported Countries"
              source="coachData.supportedCountriesAlpha2s"
              optionValue="alpha2"
              optionText="name"
              isLoading={countryListLoading}
              helperText="Remote members from selected countries will be assigned to this coach. Leave this field blank for the default coach matching logic."
              choices={uniqBy(
                [
                  ...(record?.coachData?.supportedCountries || []),
                  ...(countryList?.data || []),
                ],
                'alpha2',
              )}
              sx={{
                minWidth: '300px',
              }}
            />
            <Typography variant="h6" marginY={2} fontSize={18}>
              High Performance Coaching - Goal Matching Support
            </Typography>
            <AutocompleteArrayInput
              label="Supported Goals"
              source="coachData.supportGoalTypes"
              helperText="Users that select this goal will be given preference for this coach. Leave this field blank for the default coach matching logic."
              choices={goalOptions}
              sx={{
                minWidth: '300px',
              }}
            />
          </Box>
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};

export const UserEdit = () => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (
    <Edit
      title={<UserTitle />}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{
        onSuccess: () => {
          refresh();
          notify('Element updated');
        },
      }}
    >
      <UserEditComponent />
    </Edit>
  );
};
