import { useContext } from 'react';

import { SplitContext } from '@splitsoftware/splitio-react';

import { useSelfQuery } from '../graphql/types';

export enum SplitFlags {
  SPA_TEST_DATA_SERVICE = 'spa-test-data-service',
  COACH_CLIENT_CONFIG = 'coach-client-matching-user-flow',
  COACH_CLIENT_AVAILABILITY = 'coach-client-availability',
}

type FeatureFlags = Record<SplitFlags, boolean>;

export const useFeatureFlags = () => {
  const { client, isReady } = useContext(SplitContext) || {};
  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
  });
  const { id: userId = '', isAdmin = false, email = '' } = data?.self ?? {};

  const attributes = {
    userId,
    isAdmin,
    email,
  };

  const treatments =
    client?.getTreatmentsWithConfig(Object.values(SplitFlags), attributes) ??
    {};
  const normalizedTreatments = {} as FeatureFlags;

  Object.keys(treatments).forEach((id) => {
    normalizedTreatments[id as unknown as SplitFlags] =
      treatments[id].treatment === 'on';
  });

  return { isReady, flags: normalizedTreatments };
};
