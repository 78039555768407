import React from 'react';

import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  DateField,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  FormDataConsumer,
  FormTab,
  FunctionField,
  ImageField,
  NumberInput,
  ReferenceArrayField,
  ReferenceField,
  SaveButton,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import { useWatch } from 'react-hook-form';

import {
  ChallengeBasicsFragment,
  ChallengeType,
  Prefix,
} from '../../graphql/types';
import useCanWrite from '../../hooks/useCanWrite';

import AutocompleteClientArrayInput from './components/AutocompleteClientArrayInput';
import { DisplayGenres, GenresEdit } from './components/Genres';
import ImageUploadInput from './components/ImageUploadInput';
import InfiniteList from './components/InfiniteList';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const PostEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const ChallengeFilters = [
  <TextInput label="Title" source="title" alwaysOn />,
  <BooleanInput label="Archived" source="isArchived" defaultValue={false} />,
  <BooleanInput
    label="Exclude Custom Challenges"
    source="excludeCustomChallenges"
    defaultValue
  />,
  <BooleanInput
    label="Exclude Exos Challenges"
    source="excludeExosChallenges"
  />,
];

export const ChallengesList: React.FC = () => (
  <InfiniteList
    filters={ChallengeFilters}
    sort={{ field: 'enabled', order: 'DESC' }}
  >
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <TextField source="title" />
      <BooleanField source="enabled" />
      <BooleanField source="featured" />
      <DateField source="startDateTime" sortBy="startDate" label="Start Date" />
      <DateField source="endDateTime" sortBy="endDate" label="End Date" />
      <TextField
        source="participants.count"
        label="Participants"
        sortable={false}
      />
      <TextField source="teams.count" label="Teams" sortable={false} />
      <WriteOnlyEditButton />
      <ShowButton />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

const challengeTypes = Object.keys(ChallengeType).map((name) => ({
  name: name === 'ClassCount' ? 'ActivityCount' : name,
  id: name,
}));

const ChallengeTitle: React.FC = () => (
  <FunctionField
    render={(record?: Record<string, any>) =>
      `${record?.title} <${record?.id}>`
    }
  />
);

const GoalInput: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const challengeType = useWatch({ name: 'challengeType' });
  let helperText = '';
  if (challengeType === 'Distance') {
    helperText = 'Goal Type = Miles';
  } else if (challengeType === 'Time') {
    helperText = 'Goal Type = Minutes';
  }
  return (
    <NumberInput
      source="goal"
      helperText={helperText}
      required
      InputProps={{ disabled }}
    />
  );
};

const ChallengeShowActions: React.FC = () => {
  const canWrite = useCanWrite();
  const record = useRecordContext<ChallengeBasicsFragment>();

  const canDelete =
    record?.enabled === false &&
    record.participants.count === 0 &&
    record.challenger === null;

  return (
    <TopToolbar>
      <WriteOnlyEditButton />
      {record && canDelete && canWrite && (
        <DeleteWithConfirmButton
          confirmColor="warning"
          confirmTitle="Delete Challenge"
          confirmContent="Are you sure you want to delete this challenge?"
        />
      )}
    </TopToolbar>
  );
};

export const ChallengeShow: React.FC = () => (
  <Show title={<ChallengeTitle />} actions={<ChallengeShowActions />}>
    <TabbedShowLayout>
      <Tab label="General">
        <TextField source="id" label="ID" />
        <FunctionField
          label="Challenge type"
          render={(record: any) =>
            record.challengeType === 'ClassCount'
              ? 'ActivityCount'
              : record.challengeType
          }
        />

        <TextField source="title" />
        <TextField source="subtitle" />
        <TextField source="description" fullWidth />

        <DateField source="startDateTime" />
        <DateField source="endDateTime" />

        <DisplayGenres source="qualifyingGenres" />
        <FunctionField
          source="goal"
          render={(record: any) => {
            if (record.challengeType === 'Distance') {
              return `${record.goal} Miles`;
            } else if (record.challengeType === 'Time') {
              return `${record.goal} Minutes`;
            }
            return `${record.goal} Activities`;
          }}
        />
        <ReferenceField
          source="challenger.id"
          reference="User"
          label="Custom Challenge Creator"
          emptyText="Exos Challenge, no creator"
          link="show"
        >
          <FunctionField
            render={(record: Record<string, any>) =>
              record &&
              `${record.firstName} ${record.lastName} <${record.email}>`
            }
          />
        </ReferenceField>
        <ImageField source="badge.url" label="Badge" />
        <ImageField source="background.url" label="Background" />
      </Tab>

      <Tab label="Visibility">
        <BooleanField source="enabled" />
        <BooleanField source="featured" />
        <BooleanField source="clientSpecific" />
        <ReferenceArrayField
          source="clients.ids"
          reference="Client"
          label="Clients"
        >
          <WriteOnlyDatagrid>
            <TextField source="name" />
            <ShowButton />
          </WriteOnlyDatagrid>
        </ReferenceArrayField>
      </Tab>
      <Tab label="Teams">
        <BooleanField source="team" />
        <ArrayField source="teams.nodes" label="" fullWidth={false}>
          <WriteOnlyDatagrid bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="id" label="ID" />
          </WriteOnlyDatagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const ChallengeEditComponent: React.FC = (props) => {
  const record = useRecordContext(props);
  return (
    <TabbedForm toolbar={<PostEditToolbar />}>
      <FormTab label="General">
        <TextField source="id" label="ID" />

        <SelectInput
          source="challengeType"
          choices={challengeTypes}
          InputProps={{ disabled: true }}
        />

        <TextInput source="title" />
        <TextInput source="subtitle" />
        <TextInput source="description" multiline fullWidth />

        <DateInput source="startDateTime" InputProps={{ disabled: true }} />
        <DateInput source="endDateTime" InputProps={{ disabled: true }} />

        <GenresEdit
          source="qualifyingGenres"
          helperText={`If no genres are selected, we will use "all" genres`}
          label="Qualifying Genres"
          disabled
        />

        <GoalInput disabled />

        <ImageUploadInput
          source="badge"
          prefix={Prefix.Challenge}
          label="Badge"
          helperText="Upload a square (1:1 aspect ratio) image"
        />
        <ImageUploadInput
          source="background"
          prefix={Prefix.Challenge}
          label="Background"
        />
      </FormTab>

      <FormTab label="visibility">
        <BooleanInput source="enabled" />
        <BooleanInput source="featured" />
        <BooleanInput label="Archived" source="isArchived" />
        <BooleanInput source="clientSpecific" disabled />
        {record?.clientSpecific ? (
          <AutocompleteClientArrayInput source="clients.ids" label="Clients" />
        ) : null}
      </FormTab>
      {record?.team ? (
        <FormTab label="team">
          <ArrayInput source="teams.nodes">
            <SimpleFormIterator inline disableReordering>
              <TextInput
                source="name"
                helperText={false}
                style={{ margin: '16px 0 8px' }}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      ) : null}
    </TabbedForm>
  );
};

export const ChallengeEdit: React.FC = () => (
  <Edit title={<ChallengeTitle />} mutationMode="pessimistic" redirect={false}>
    <ChallengeEditComponent />
  </Edit>
);

export const ChallengeCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <SelectInput source="challengeType" choices={challengeTypes} required />

      <TextInput source="title" required />
      <TextInput source="subtitle" />
      <TextInput source="description" multiline fullWidth />

      <DateInput source="startDate" required />
      <DateInput source="endDate" required />

      <GenresEdit
        source="qualifyingGenres"
        helperText={`If no genres are selected, we will use "all" genres`}
        label="Qualifying Genres"
      />

      <GoalInput />

      <BooleanInput source="clientSpecific" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.clientSpecific ? (
            <AutocompleteClientArrayInput
              {...rest}
              source="clients.ids"
              label="Clients"
            />
          ) : null
        }
      </FormDataConsumer>
      <BooleanInput source="team" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.team ? (
            <ArrayInput source="teams.nodes" {...rest}>
              <SimpleFormIterator inline disableReordering>
                <TextInput source="name" helperText={false} />
              </SimpleFormIterator>
            </ArrayInput>
          ) : null
        }
      </FormDataConsumer>

      <ImageUploadInput
        source="badge"
        prefix={Prefix.Challenge}
        label="Badge"
        helperText="Upload a square (1:1 aspect ratio) image"
      />
      <ImageUploadInput
        source="background"
        prefix={Prefix.Challenge}
        label="Background"
      />

      <BooleanInput source="enabled" />
      <BooleanInput source="featured" />
    </SimpleForm>
  </Create>
);
