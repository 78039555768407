import type { ApolloClient } from '@apollo/client';
import type { AuthProvider, UserIdentity } from 'react-admin';

import { AdminSelfDocument, AdminSelfQuery } from '../graphql/types';

class ExosAuthProvider implements AuthProvider {
  constructor(client: ApolloClient<object>, logout: () => Promise<void>) {
    this.client = client;
    this.logout = logout;
  }

  checkAuth = (): Promise<void> => Promise.resolve();

  checkError = (): Promise<void> => Promise.resolve();

  getIdentity = async (): Promise<UserIdentity> => {
    const { data } = await this.client.query<AdminSelfQuery>({
      query: AdminSelfDocument,
    });
    return {
      id: data?.self?.id ?? '',
      fullName: `${data?.self?.firstName} ${data?.self?.lastName}`,
    };
  };

  getPermissions = async (): Promise<string[]> => {
    const { data } = await this.client.query<AdminSelfQuery>({
      query: AdminSelfDocument,
    });
    const self = data?.self;
    if (!self) {
      return [];
    } else {
      const perms: string[] = [];
      if (self.isAdmin) {
        perms.push('admin');
      }
      if (self.isCoach) {
        perms.push('coach');
      }
      if (self.isMegaAdmin) {
        perms.push('mega_admin');
      }
      if (self.isAdmin && !self.isReadOnlyAdmin) {
        perms.push('can_write');
      }
      if (self.isTestUser) {
        perms.push('test_user');
      }
      return perms;
    }
  };

  login = (): Promise<any> => Promise.resolve();

  logout: () => Promise<void>;

  private client: ApolloClient<object>;
}

export default ExosAuthProvider;
