const { origin } = window.location;
const redirectSignIn = `${origin}/app/`;
const redirectSignOut = `${origin}/app/login/`;

const awsExports: Record<string, any> = {
  dev: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_Trac5E2KH',
    aws_user_pools_web_client_id: '1v6k46d9jj1665h2q9b2ko2d6f',
    oauth: {
      domain: 'auth-dev.teamexos.com',
      scope: ['openid'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  },
  preprod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_XTZUekv22',
    aws_user_pools_web_client_id: 'lm8vn137ksmpg3lm194kk4qlp',
    oauth: {},
  },
  prod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_31QwwmhDu',
    aws_user_pools_web_client_id: '1m99gsm8fjbo1ocgvouiakqkgf',
    oauth: {
      domain: 'auth.teamexos.com',
      scope: ['openid'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  },
  // These IDs will be updated in the Github Actions workflow because we don't know what they are ahead of time
  testing: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'COGNITO_USER_POOL_ID',
    aws_user_pools_web_client_id: 'COGNITO_USER_POOL_WEB_CLIENT_ID',
    oauth: {
      domain: 'auth-testing.teamexos.com',
      scope: ['openid'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code',
    },
  },
};

export default awsExports;
