import React from 'react';

import { Typography } from '@mui/material';
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  SimpleFormIterator,
  TextField,
} from 'react-admin';

import { SplitFlags, useFeatureFlags } from '../../../hooks/useFeatureFlags';

export const ListCoachClients: React.FC = () => {
  const { flags } = useFeatureFlags();
  const enableCoachClientAvailability =
    flags[SplitFlags.COACH_CLIENT_AVAILABILITY];

  return (
    <>
      <Typography variant="h5" fontSize={20}>
        Clients
      </Typography>
      <ArrayField resource="Client" source="coachData.coachClients.edges">
        <Datagrid resource="Client" bulkActionButtons={false} rowClick={false}>
          <TextField source="node.displayName" label="Name" />
          {enableCoachClientAvailability && (
            <BooleanField source="availableForAssignment" />
          )}
          <BooleanField source="supportsWellbeingCoaching" />
          <BooleanField source="supportsHighPerformanceCoaching" />

          <ReferenceField reference="Client" source="node.id" label="">
            <ShowButton />
          </ReferenceField>
        </Datagrid>
      </ArrayField>
    </>
  );
};

export const EditCoachClients: React.FC = () => {
  const { flags } = useFeatureFlags();
  const enableCoachClientConfig = flags[SplitFlags.COACH_CLIENT_CONFIG];
  const enableCoachClientAvailability =
    flags[SplitFlags.COACH_CLIENT_AVAILABILITY];
  return (
    <>
      <Typography variant="h5" fontSize={20}>
        Clients
      </Typography>
      <ArrayInput
        resource="Client"
        source="coachData.coachClients.edges"
        label=""
        validate={(values) => {
          if (
            values.some(
              (value: { node: { id: string | undefined } }) => !value.node.id,
            )
          ) {
            return 'All rows must have a client selected. Select a client or remove any empty rows.';
          }
          return undefined;
        }}
        data-testid="coach-clients-array-input"
      >
        <SimpleFormIterator inline disableReordering>
          <ReferenceInput
            source="node.id"
            reference="Client"
            filterToQuery={(name: string) => ({
              name,
            })}
          >
            <AutocompleteInput
              label="Name"
              optionText="name"
              filter="name"
              sx={{
                minWidth: '300px',
              }}
              data-testid="client-autocomplete"
            />
          </ReferenceInput>
          {enableCoachClientAvailability && (
            <BooleanInput
              source="availableForAssignment"
              sx={{ alignSelf: 'center' }}
            />
          )}
          {enableCoachClientConfig && (
            <>
              <BooleanInput
                source="supportsWellbeingCoaching"
                sx={{ alignSelf: 'center' }}
                data-testid="supports-wellbeing-coaching"
              />
              <BooleanInput
                source="supportsHighPerformanceCoaching"
                sx={{ alignSelf: 'center' }}
              />
            </>
          )}
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
