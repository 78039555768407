import React, { useState } from 'react';

import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '@teamexos/fit-shared';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AuthLayout from '../AuthLayout';

const SignIn: React.FC = () => {
  const [isPasswordFlow, setIsPasswordFlow] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { authorize, authorizeWithPassword, userCheck, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const {
    getValues,
    handleSubmit,
    register,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  watch();

  const { palette } = useTheme();

  const signInSSO = async (email: string): Promise<undefined> => {
    const info = await userCheck(email);

    if (info.resetRequired) {
      setError(
        'Password reset is required. Please follow the password reset flow and try again.',
      );
    }

    if (!info.useCognito) {
      setError('Invalid auth configuration, please contact Coach Hub support');
      return;
    }

    const isRegistered = info.registered;
    const isVerified = info?.hasCognitoUser && info.isEmailVerified;
    const isValidCognitoUser = isRegistered && isVerified;
    if (!isValidCognitoUser) {
      setError('Please contact Coach Hub support.');
      return;
    }

    if (!info.isSSO) {
      setIsPasswordFlow(true);
      return;
    }

    try {
      await authorize({
        email,
        connection: info.identity.connection,
        provider: info.identity.provider,
      });
      // if auth is successful, we are necessarily redirecting
      setIsRedirecting(true);
    } catch (_e) {
      setError('Unexpected error. Please try again later.');
    }
  };

  const signInWithPassword = async (email: string, password: string) => {
    try {
      await authorizeWithPassword({
        username: email,
        password,
      });
      // if auth is successful, we are necessarily redirecting
      setIsRedirecting(true);
    } catch (e) {
      if (e.message.includes('Incorrect username or password.')) {
        setError('Incorrect email or password');
      } else {
        setError('Error signing in. Please contact Coach Hub support.');
      }
    }
  };

  const signIn = async () => {
    setError(undefined);
    const values = getValues();
    const email = values.email?.trim();

    try {
      if (isPasswordFlow) {
        await signInWithPassword(email, values.password);
        return;
      }
      await signInSSO(email);
    } catch (_e) {
      setError('Unknown error occurred. Please contact Coach Hub support.');
    }
  };

  const getSubmitIsDisabled = () => {
    if (isSubmitting || isRedirecting) {
      return true;
    }

    if (isPasswordFlow) {
      return !getValues('password') || !getValues('email');
    }

    return !getValues('email');
  };

  if (isLoggedIn) {
    navigate('/app');
  }

  return (
    <AuthLayout>
      <Paper
        elevation={2}
        style={{
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 496,
          height: 496,
          borderRadius: 8,
          paddingTop: 48,
          paddingBottom: 48,
          paddingLeft: 60,
          paddingRight: 60,
        }}
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(signIn)();
        }}
      >
        <Typography
          variant="h3"
          style={{ textAlign: 'center', marginBottom: 48 }}
          data-testid="sign-in-header"
        >
          Sign In
        </Typography>

        <TextField
          fullWidth
          autoFocus
          id="outlined-basic"
          label="Email address"
          variant="outlined"
          InputProps={{
            sx: {
              marginBottom: '24px',
            },
          }}
          {...register('email')}
        />

        {isPasswordFlow && (
          <TextField
            fullWidth
            id="outlined-basic"
            type="password"
            label="Password"
            variant="outlined"
            InputProps={{
              sx: {
                marginBottom: '24px',
              },
            }}
            {...register('password')}
          />
        )}

        {error && (
          <Typography
            variant="subtitle1"
            style={{
              textAlign: 'left',
              color: palette.error.main,
            }}
          >
            {error}
          </Typography>
        )}

        <div style={{ flex: 1 }} />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          style={{ padding: 12, borderRadius: 4, alignSelf: 'flex-end' }}
          disabled={getSubmitIsDisabled()}
        >
          {isSubmitting || isRedirecting ? (
            <CircularProgress color="inherit" size={24.5} />
          ) : (
            'Sign In'
          )}
        </Button>
      </Paper>
    </AuthLayout>
  );
};

export default SignIn;
