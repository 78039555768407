// Based on https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/list/useListController.ts
import { isValidElement, useCallback, useEffect, useMemo } from 'react';

import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import {
  ListControllerProps,
  ListControllerResult,
  RaRecord,
  SORT_ASC,
  SortPayload,
  useAuthenticated,
  useGetResourceLabel,
  useInfiniteGetList,
  useListParams,
  useNotify,
  useRecordSelection,
  useResourceContext,
  useTranslate,
} from 'react-admin';

const defaultSort: SortPayload = {
  field: 'id',
  order: SORT_ASC,
};

type UseInfiniteListParams<T extends RaRecord> = ListControllerProps<T> & {
  //
};

type UseInfiniteListValue<T extends RaRecord> = ListControllerResult<T> & {
  fetchNextPage: () => void;
};

const useInfiniteListController = <RecordType extends RaRecord = any>(
  props: UseInfiniteListParams<RecordType> = {},
): UseInfiniteListValue<RecordType> => {
  const {
    debounce = 500,
    disableAuthentication,
    disableSyncWithLocation,
    exporter = false,
    filter,
    filterDefaultValues,
    perPage = 25,
    queryOptions = {},
    sort = defaultSort,
    storeKey,
  } = props;
  useAuthenticated({ enabled: !disableAuthentication });
  const resource = useResourceContext(props);

  if (!resource) {
    throw new Error(
      `<InfiniteList> was called outside of a ResourceContext and without a resource prop. You must set the resource prop.`,
    );
  }

  if (filter && isValidElement(filter)) {
    throw new Error(
      '<InfiniteList> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.',
    );
  }

  const resourceLabel = useGetResourceLabel()(resource, 2);
  const translate = useTranslate();
  const notify = useNotify();

  const [selectedIds, selectionModifiers] = useRecordSelection({ resource });

  // Query params
  const [query, queryModifiers] = useListParams({
    debounce,
    disableSyncWithLocation,
    filterDefaultValues,
    perPage,
    resource,
    sort,
    storeKey,
  });

  // Force page 1
  // TODO: Remove this completely and/or update functionality (i.e., support loading n pages at one time, etc.)
  useEffect(() => {
    if (query.page !== 1) {
      queryModifiers.setPage(1);
    }
  }, [query.page, queryModifiers.setPage]);

  const currentSort = useMemo(
    () => ({
      field: query.sort,
      order: query.order,
    }),
    [query.sort, query.order],
  );

  // Get data
  const {
    data: rawData,
    error,
    fetchNextPage: next,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage: isFetching,
    isLoading,
    refetch,
    total = 0,
  } = useInfiniteGetList(
    resource,
    {
      pagination: {
        page: query.page,
        perPage: query.perPage,
      },
      sort: { field: query.sort, order: query.order },
      filter: { ...query.filter, ...filter },
      meta: queryOptions.meta,
    },
    {
      refetchOnWindowFocus: false,
      onError: (err) =>
        notify(err?.message || 'ra.notification.http_error', {
          type: 'warning',
          messageArgs: {
            _: err?.message,
          },
        }),
    },
  );

  // Expose throttled version of `fetchNextPage` to prevent excessive API calls
  const fetchNextPage = useCallback(throttle(next, 1000), []);

  // Flatten data and dedupe (just in case)
  const data = useMemo(
    () => uniqBy(rawData?.pages.flatMap((page) => page.data) ?? [], 'id'),
    [rawData?.pages],
  );

  return {
    // ListControllerResult
    sort: currentSort,
    data,
    defaultTitle: translate('ra.page.list', { name: resourceLabel }),
    displayedFilters: query.displayedFilters,
    error,
    exporter,
    filter,
    filterValues: query.filterValues,
    hideFilter: queryModifiers.hideFilter,
    isFetching,
    isLoading,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,
    onSelectAll: () => undefined,
    page: query.page,
    perPage: query.perPage,
    refetch,
    resource,
    selectedIds,
    setFilters: queryModifiers.setFilters,
    setPage: queryModifiers.setPage,
    setPerPage: queryModifiers.setPerPage,
    setSort: queryModifiers.setSort,
    showFilter: queryModifiers.showFilter,
    total,
    hasNextPage: !!hasNextPage,
    hasPreviousPage: !!hasPreviousPage,
    // InfiniteList
    fetchNextPage,
    isPending: false,
  };
};

export default useInfiniteListController;
