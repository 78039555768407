import React from 'react';

import { Typography } from '@mui/material';
import styled from 'styled-components';

import {
  AdminUserDetailsFragment,
  ClientCalendarPlatform,
  ClientVideoPlatform,
} from '../../../graphql/types';
import { notEmpty } from '../../../utils/notEmpty';

const calendarPlatformMap = {
  [ClientCalendarPlatform.Apple]: 'Apple',
  [ClientCalendarPlatform.Exchange]: 'Exchange',
  [ClientCalendarPlatform.Google]: 'Google',
  [ClientCalendarPlatform.LiveConnect]: 'Outlook',
  [ClientCalendarPlatform.Office365]: 'Office 365',
  [ClientCalendarPlatform.ExosOnly]: 'Exos',
};

const conferencingPlatformMap = {
  [ClientVideoPlatform.MsTeams]: 'Microsoft Teams',
  [ClientVideoPlatform.Zoom]: 'Zoom',
  [ClientVideoPlatform.GoTo]: 'GoToMeeting',
  [ClientVideoPlatform.EightByEight]: '8x8',
  [ClientVideoPlatform.Indeed]: 'Indeed',
  [ClientVideoPlatform.Integrated]: 'Integrated',
};

const getClientCalendarPlatformName = (
  calendarPlatform?: ClientCalendarPlatform | null,
  providerService?: string | null,
) => {
  if (!calendarPlatform) {
    return '';
  }

  if (
    calendarPlatform === ClientCalendarPlatform.Exchange &&
    providerService === ClientCalendarPlatform.Office365
  ) {
    return calendarPlatformMap[ClientCalendarPlatform.Office365];
  }

  return calendarPlatformMap[calendarPlatform] ?? '';
};

const getClientConferencingPlatformName = (
  conferencingPlatform?: ClientVideoPlatform | null,
) => {
  if (!conferencingPlatform) {
    return '';
  }

  return conferencingPlatformMap[conferencingPlatform] ?? '';
};

interface Props {
  coach: AdminUserDetailsFragment;
}

const ConnectionSection = styled.div`
  display: block;
  margin: 16px 0;
`;

const ConnectionsList: React.FC<Props> = ({ coach }) => {
  if (!coach.coachData) {
    return null;
  }

  const {
    clientRequiredCalendarProfiles,
    clientRequiredConferencingProfiles,
    calendarProfiles,
    conferencingProfiles,
    calendarConnected,
  } = coach.coachData;

  const requiredCalendarProfiles = clientRequiredCalendarProfiles?.edges
    .map((edge) => edge.node)
    ?.filter(
      (requiredProfile) =>
        requiredProfile.calendarPlatform !== ClientCalendarPlatform.ExosOnly,
    );

  const requiredConferencingProfiles = clientRequiredConferencingProfiles?.edges
    .map((edge) => edge.node)
    ?.filter(
      (requiredProfile) =>
        requiredProfile.videoPlatform !== ClientVideoPlatform.Integrated,
    );

  const missingRequiredCalendarProfiles = requiredCalendarProfiles?.filter(
    (requiredProfile) =>
      !calendarProfiles?.some(
        (calendarProfile) =>
          calendarProfile.platform === requiredProfile.calendarPlatform ||
          calendarProfile.providerService === requiredProfile.calendarPlatform,
      ),
  );

  const missingRequiredConferencingProfiles =
    requiredConferencingProfiles?.filter(
      (requiredProfile) =>
        !conferencingProfiles?.some(
          (conferencingProfile) =>
            conferencingProfile.platform === requiredProfile.videoPlatform,
        ),
    );

  const getConnectedIntegratedConferencingProfiles = () => {
    if (!clientRequiredConferencingProfiles?.edges) {
      return [];
    }

    return clientRequiredConferencingProfiles?.edges
      .filter(
        ({ node: requiredProfile }) =>
          requiredProfile.videoPlatform === ClientVideoPlatform.Integrated,
      )
      .map(({ node: requiredProfile }) =>
        calendarProfiles?.find(
          (calendarProfile) =>
            calendarProfile.platform === requiredProfile.calendarPlatform ||
            calendarProfile.providerService ===
              requiredProfile.calendarPlatform,
        ),
      )
      .filter(notEmpty);
  };

  const connectedIntegratedConferencingProfiles =
    getConnectedIntegratedConferencingProfiles();

  const allConferencingProfiles = [
    ...connectedIntegratedConferencingProfiles,
    ...conferencingProfiles,
  ];

  const hasAllRequiredProfiles =
    calendarConnected &&
    missingRequiredCalendarProfiles.length === 0 &&
    missingRequiredConferencingProfiles.length === 0;

  return (
    <div>
      <ConnectionSection>
        <span>Has all required profiles?</span>{' '}
        {hasAllRequiredProfiles ? 'Yes' : 'No'}
      </ConnectionSection>
      <ConnectionSection>
        <Typography variant="h6">Current Calendar Profiles</Typography>
        {calendarProfiles.length === 0 && <p>No calendar profiles</p>}
        {calendarProfiles.length > 0 &&
          calendarProfiles.map((profile) => (
            <p key={profile.profileId}>
              {profile.profileName} (
              {getClientCalendarPlatformName(
                profile.platform,
                profile.providerService,
              )}
              ){': '}
              {profile.profileConnected ? 'Connected' : 'Disconnected'}
            </p>
          ))}
      </ConnectionSection>
      <ConnectionSection>
        <Typography variant="h6">Current Video Conferencing</Typography>
        {allConferencingProfiles.length === 0 && (
          <p>No video conferencing profiles</p>
        )}
        {allConferencingProfiles.length > 0 &&
          allConferencingProfiles.map((profile) => (
            <p key={profile.profileId}>
              {profile.profileName} (
              {profile.__typename === 'CalendarProfile'
                ? 'Integrated'
                : getClientConferencingPlatformName(
                    profile.platform as ClientVideoPlatform,
                  )}
              ){': '}
              {profile.profileConnected ? 'Connected' : 'Disconnected'}
            </p>
          ))}
      </ConnectionSection>
      <ConnectionSection>
        <Typography variant="h6">Missing Required Calendar Profiles</Typography>
        {missingRequiredCalendarProfiles.length === 0 && (
          <p>No missing required calendar profiles</p>
        )}
        {missingRequiredCalendarProfiles.length > 0 &&
          missingRequiredCalendarProfiles.map((profile) => (
            <p key={profile.id}>
              Client &quot;{profile.displayName}&quot; requires a{' '}
              {getClientCalendarPlatformName(profile.calendarPlatform)} calendar
              connection
            </p>
          ))}
      </ConnectionSection>
      <ConnectionSection>
        <Typography variant="h6">
          Missing Required Video Conferencing
        </Typography>
        {missingRequiredConferencingProfiles.length === 0 && (
          <p>No missing required video conferencing profiles</p>
        )}
        {missingRequiredConferencingProfiles.length > 0 &&
          missingRequiredConferencingProfiles.map((profile) => (
            <p key={profile.id}>
              Client &quot;{profile.displayName}&quot; requires a{' '}
              {getClientConferencingPlatformName(profile.videoPlatform)} video
              conferencing connection
            </p>
          ))}
      </ConnectionSection>
      <ConnectionSection>
        <Typography variant="h6">Current Client Requirements</Typography>
        {coach.coachData.clients.nodes.length === 0 && (
          <p>Coach has no clients assigned</p>
        )}
        {coach.coachData.clients.nodes.map((client) => (
          <p key={client.id}>
            {client.displayName}
            {': '}
            {getClientCalendarPlatformName(client.calendarPlatform)}
            calendar,
            {getClientConferencingPlatformName(client.videoPlatform)}{' '}
            conferencing profile
          </p>
        ))}
      </ConnectionSection>
    </div>
  );
};

export default ConnectionsList;
