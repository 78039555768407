import React from 'react';

import {
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  Filter,
  NumberField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { App, ChatRoomKind } from '../../graphql/types';

import AutocompleteUserArrayInput from './components/AutocompleteUserArrayInput';
import ClipboardField from './components/ClipboardField';
import EditToolbar from './components/EditToolbar';
import InfiniteList from './components/InfiniteList';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const kindChoices = Object.entries(ChatRoomKind).map(([name, id]) => ({
  name,
  id,
}));

const appChoices = Object.entries(App).map(([name, id]) => ({
  name,
  id,
}));

const MaybeEditButton: React.FC = (props) => {
  const record = useRecordContext(props);
  if (record && 'kind' in record && record.kind !== ChatRoomKind.Team) {
    return <WriteOnlyEditButton record={record} />;
  } else {
    return null;
  }
};

const ChatRoomFilters: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <BooleanInput label="Archived" source="isArchived" />
  </Filter>
);

export const ChatRoomList: React.FC = () => (
  <InfiniteList filters={<ChatRoomFilters />}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <ClipboardField source="id" />
      <TextField source="name" />
      <TextField source="kind" />
      <BooleanField source="isArchived" label="Is archived" sortable={false} />
      <NumberField source="users.count" />
      <TextField source="app" />
      <MaybeEditButton />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

export const ChatRoomCreate: React.FC = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput source="kind" choices={kindChoices} />
      <SelectInput source="app" choices={appChoices} />
      <AutocompleteUserArrayInput source="users" />
    </SimpleForm>
  </Create>
);

export const ChatRoomEdit: React.FC = (props) => (
  <Edit {...props} mutationMode="pessimistic" redirect={false}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="id" InputProps={{ disabled: true }} />
      <TextInput source="name" />
      <BooleanInput source="isArchived" />
      <AutocompleteUserArrayInput source="users.ids" />
      <SelectInput source="app" choices={appChoices} />
    </SimpleForm>
  </Edit>
);
