import React from 'react';

import { PlaylistAdd } from '@mui/icons-material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import DefaultIcon from '@mui/icons-material/ViewList';
import {
  Layout,
  Menu,
  MenuItemLink as MuiMenuItemLink,
  useResourceDefinitions,
} from 'react-admin';

import { useSelfQuery } from '../../graphql/types';
import { SplitFlags, useFeatureFlags } from '../../hooks/useFeatureFlags';
import { EnvLevel, getEnvLevel } from '../../utils/getEnvLevel';
import AuthLayout from '../AuthLayout';

const CustomMenu: React.FC = (props) => {
  const { flags } = useFeatureFlags();

  const enableTestDataService = flags[SplitFlags.SPA_TEST_DATA_SERVICE];
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name],
  );

  const { loading, data: selfQueryResponse } = useSelfQuery();

  if (loading && !selfQueryResponse?.self) {
    return <AuthLayout />;
  }

  return (
    <Menu {...props}>
      {resources.map((resource) => (
        <MuiMenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={resource.options?.label ?? resource.name}
          leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
        />
      ))}
      {/* C U S T O M  R O U T E S */}
      {(getEnvLevel() === EnvLevel.DEV || getEnvLevel() === EnvLevel.TESTING) &&
        enableTestDataService && (
          <div>
            <MuiMenuItemLink
              primaryText="Test Data"
              to="/testData"
              leftIcon={<PlaylistAdd />}
            />
          </div>
        )}
      {selfQueryResponse?.self?.isAdmin && (
        <div>
          <MuiMenuItemLink
            primaryText="System Preferences"
            to="/systemPreferences"
            leftIcon={<SettingsApplicationsIcon />}
          />
        </div>
      )}
    </Menu>
  );
};

export const CustomLayout: React.FC<React.PropsWithChildren> = ({
  children,
  ...rest
}) => (
  <Layout {...rest} menu={CustomMenu}>
    {children}
  </Layout>
);
