import React from 'react';

import {
  BooleanField,
  BooleanInput,
  Create,
  Edit,
  Filter,
  FormTab,
  ImageField,
  Labeled,
  Link,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

import {
  AdminClientDetailsFragment,
  ClientCalendarPlatform,
  ClientVideoPlatform,
  ConfigurationIssue,
  Prefix,
  useClientConfigurationIssuesLazyQuery,
} from '../../graphql/types';
import useClientCoachesQuery from '../../hooks/useClientCoachesQuery';
import { EnvLevel, getEnvLevel } from '../../utils/getEnvLevel';

import { Box, Button, Typography } from '@mui/material';
import { BORDER_GRAY } from '../../theme';
import ClipboardField from './components/ClipboardField';
import EditToolbar from './components/EditToolbar';
import ImageUploadInput from './components/ImageUploadInput';
import InfiniteList from './components/InfiniteList';
import { ListCoaches } from './components/ListCoaches';
import { WriteOnlyDatagrid } from './components/WriteOnlyDatagrid';
import { WriteOnlyEditButton } from './components/WriteOnlyEditButton';

const DisplayNameInput: React.FC = () => {
  const charLimit = 20;
  return (
    <TextInput
      source="displayName"
      label="Display Name"
      helperText="This will be seen by app users (max 20 char)"
      inputProps={{ maxLength: charLimit }}
      style={{ marginBottom: 26 }}
      validate={required()}
    />
  );
};

const ConfigurationIssueItem: React.FC<{ issue: ConfigurationIssue }> = ({
  issue,
}) => {
  const { title, description, link } = issue;
  return (
    <div
      style={{
        border: `1px solid ${BORDER_GRAY}`,
        borderRadius: 4,
        padding: 16,
        marginBottom: 16,
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>
      <p>{description}</p>
      {link && (
        <Link to={link} target="_blank" color="primary">
          Link
        </Link>
      )}
    </div>
  );
};

const ClientCoachTab: React.FC = () => {
  const record = useRecordContext<AdminClientDetailsFragment>();
  const { coaches, loadMore, hasNextPage } = useClientCoachesQuery(record?.id);

  return (
    <ListCoaches
      coaches={coaches}
      clientId={record?.id}
      loadMore={loadMore}
      hasNextPage={hasNextPage}
    />
  );
};

const DiagnosticsTab: React.FC = () => {
  const record = useRecordContext<AdminClientDetailsFragment>();
  const clientId = record?.id;

  const [runningDiagnostics, setRunningDiagnostics] = React.useState(false);
  const [fetchIssues, { data }] = useClientConfigurationIssuesLazyQuery();

  const handleClick = async () => {
    if (!clientId) {
      return;
    }

    setRunningDiagnostics(true);
    await fetchIssues({
      variables: {
        id: clientId,
      },
    });
    setRunningDiagnostics(false);
  };

  const configurationIssues = data?.client?.configurationIssues || [];
  return (
    <>
      <Box>
        <Typography variant="h4">Client Diagnostics</Typography>
        <p>
          Press the button below to perform a series of checks on this client's
          configuration. These checks are not comprehensive and are currently
          focused on the following items:
        </p>
        <ul>
          <li>
            Ensure that the provided MBO Studio ID for each non-central studio
            exists in MBO
          </li>
          <li>
            For clients that have "Uses MBO Registration Flow" enabled this will
            check for the following:
            <ul>
              <li>There are in-person studios available for registration</li>
              <li>
                There is at least one valid location with an assigned Hybrid
                coach
              </li>
              <li>The MBO contract is available for each location</li>
              <li>
                The provided MBO studio ID exists in MBO and has a contact email
              </li>
            </ul>
          </li>
        </ul>
        <Button
          onClick={handleClick}
          loading={runningDiagnostics}
          variant="contained"
        >
          Run Diagnostics
        </Button>
      </Box>
      <Box marginTop={2}>
        {!runningDiagnostics && data && configurationIssues.length === 0 && (
          <Typography variant="body1" color="success.main">
            No configuration issues found.
          </Typography>
        )}
        {!runningDiagnostics && (
          <div style={{ width: 'fit-content' }}>
            {configurationIssues.length > 0 && (
              <Typography variant="body1" color="error.main">
                Found the following configuration issue
                {configurationIssues.length > 1 ? 's' : ''}:
              </Typography>
            )}
            {configurationIssues.map((issue) => (
              <ConfigurationIssueItem issue={issue} key={issue.title} />
            ))}
          </div>
        )}
      </Box>
    </>
  );
};

const ClientFilters: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <BooleanInput label="Archived" source="isArchived" />
  </Filter>
);

export const ClientList: React.FC = () => (
  <InfiniteList filters={<ClientFilters />} perPage={20}>
    <WriteOnlyDatagrid bulkActionButtons={false}>
      <ClipboardField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <NumberField source="users.count" label="Users" sortable={false} />
      <BooleanField source="isArchived" label="Is archived" sortable={false} />
      <WriteOnlyEditButton />
      <ShowButton />
    </WriteOnlyDatagrid>
  </InfiniteList>
);

export const ClientEdit: React.FC = () => {
  const envLevel = getEnvLevel();
  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="Basics">
          <TextInput source="id" InputProps={{ disabled: true }} />
          <TextInput source="name" label="Client Name" />
          <DisplayNameInput />
          <TextInput source="contactName" label="Contact Name" />
          <TextInput source="contactEmail" label="Contact Email" />
          <SelectInput
            label="Calendar Platform"
            source="calendarPlatform"
            id="calendarPlatform"
            choices={Object.entries(ClientCalendarPlatform).map(
              ([name, id]) => ({
                id,
                name,
              }),
            )}
            validate={required()}
          />
          <SelectInput
            label="Video Platform"
            source="videoPlatform"
            id="videoPlatform"
            choices={Object.entries(ClientVideoPlatform).map(([name, id]) => ({
              id,
              name,
            }))}
            validate={required()}
          />
          <BooleanInput
            source="strictAppCheck"
            label="Strict App Check"
            helperText="Controls strict jailbreak detection. Leave OFF unless specifically instructed by Engineering and Legal."
          />
          <BooleanInput source="isArchived" label="Is archived" />
        </FormTab>
        <FormTab label="Fit" gap={2} data-testid="tab-Fit">
          <BooleanInput
            source="usesPersonalTraining"
            label="Uses Personal Training"
            helperText="Whether or not the client supports booking personal training sessions"
          />
          <BooleanInput
            source="usesHighPerformanceCoaching"
            label="Uses High-Performance Coaching"
            helperText={
              <>
                Whether or not the client has access to the high performance
                coaching experience.
                <br />
                {envLevel === EnvLevel.PROD ? (
                  <>
                    Use{' '}
                    <Link to="/EligibleEmailAddress">
                      Eligible Email Addresses
                    </Link>{' '}
                    to control access to this feature.
                  </>
                ) : (
                  ''
                )}
              </>
            }
            disabled={envLevel === EnvLevel.PROD}
          />
          <BooleanInput
            source="usesFitnessCenterForEligibility"
            label="Uses Fitness Center For Eligibility"
            helperText={false}
          />
          <BooleanInput
            source="usesCustomChallenges"
            label="Members Can Create Custom Challenges"
          />
          <BooleanInput
            source="usesEligibilityServiceType"
            label="Uses Eligibility Service Type"
            helperText={false}
          />
          <BooleanInput
            source="usesMboRegistrationFlow"
            label="Uses MBO Registration Flow"
            helperText="If true, members will be prompted to create their MBO account when they register."
          />
          <BooleanInput
            source="needsUsToFixTheirUserNames"
            label="Needs Us To Fix Their User Names"
            helperText="Do not enable unless a developer requests it. Changes BRaDLeY to Bradley."
          />
          <BooleanInput
            source="collectsMemberData"
            label="Show Employer Incentive Option"
            helperText={false}
          />
          <BooleanInput
            source="collectsMemberDataForEEP"
            label="Show Employer Engagement Program Option"
            helperText={false}
          />
          <NumberInput
            source="maxFamilyMembers"
            helperText="Maximum number of invitations for family sharing. Set to 0 to disable family sharing for this client."
            min={0}
            max={99}
          />
          <NumberInput
            source="inactivityDuration"
            helperText="Number of minutes before a user will automatically be logged out due to inactivity. Set to 0 to disable inactivity timeout."
            min={0}
            defaultValue={0}
          />
          <ImageUploadInput
            source="logo"
            prefix={Prefix.Uploads}
            label="Logo"
            helperText=""
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ClientCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" label="Client Name" />
      <DisplayNameInput />
      <TextInput source="contactName" label="Contact Name" />
      <TextInput source="contactEmail" label="Contact Email" />

      <BooleanInput
        source="usesFitnessCenterForEligibility"
        label="Uses Fitness Center For Eligibility"
      />
      <BooleanInput
        source="usesEligibilityServiceType"
        label="Uses Eligibility Service Type"
      />
      <BooleanInput
        source="usesMboRegistrationFlow"
        label="Uses MBO Registration Flow"
        helperText="If true, members will be prompted to create their MBO account when they register."
      />
      <BooleanInput
        source="usesCustomChallenges"
        label="Members Can Create Custom Challenges"
        defaultValue
      />
      <BooleanInput
        source="collectsMemberData"
        label="Show Employer Incentive Option"
      />
      <BooleanInput
        source="collectsMemberDataForEEP"
        label="Show Employer Engagement Program Option"
      />
      <SelectInput
        label="Calendar Platform"
        source="calendarPlatform"
        choices={Object.entries(ClientCalendarPlatform).map(([name, id]) => ({
          id,
          name,
        }))}
        validate={required()}
        defaultValue={ClientCalendarPlatform.Google}
      />
      <SelectInput
        label="Video Platform"
        source="videoPlatform"
        choices={Object.entries(ClientVideoPlatform).map(([name, id]) => ({
          id,
          name,
        }))}
        validate={required()}
        defaultValue={ClientVideoPlatform.Integrated}
      />
      <NumberInput
        source="maxFamilyMembers"
        helperText="Maximum number of invitations for family sharing. Set to 0 to disable family sharing for this client."
        min={0}
        max={99}
        defaultValue={0}
      />
      <NumberInput
        source="inactivityDuration"
        helperText="Number of minutes before a user will automatically be logged out due to inactivity. Set to 0 to disable inactivity timeout."
        min={0}
        defaultValue={0}
      />
      <BooleanInput
        source="strictAppCheck"
        label="Strict App Check"
        helperText="Controls strict jailbreak detection. Leave OFF unless specifically instructed by Engineering and Legal."
      />
    </SimpleForm>
  </Create>
);

export const ClientShow: React.FC = () => (
  <Show>
    <TabbedShowLayout>
      <Tab label="Basics">
        <TextField source="id" label="ID" data-testid="id" />
        <TextField source="name" label="Client Name" data-testid="name" />
        <TextField
          source="displayName"
          label="Display Name"
          data-testid="displayName"
        />
        <TextField
          source="contactName"
          label="Contact Name"
          data-testid="contactName"
        />
        <TextField
          source="contactEmail"
          label="Contact Email"
          data-testid="contactEmail"
        />
        <TextField
          source="calendarPlatform"
          label="Calendar Platform"
          data-testid="calendarPlatform"
        />
        <TextField
          source="videoPlatform"
          label="Video Platform"
          data-testid="videoPlatform"
        />
        <BooleanField
          source="strictAppCheck"
          label="Strict App Check"
          data-testid="strictAppCheck"
        />
        <BooleanField
          source="isArchived"
          label="Is archived"
          data-testid="isArchived"
        />
      </Tab>
      <Tab label="Fit" data-testid="tab-Fit">
        <BooleanField
          source="usesPersonalTraining"
          data-testid="usesPersonalTraining"
          label="Uses Personal Training (Whether or not the client supports booking personal training sessions)"
          valueLabelTrue="Supports personal training sessions"
          valueLabelFalse="Does not support personal training sessions"
        />
        <BooleanField
          source="usesHighPerformanceCoaching"
          data-testid="usesHighPerformanceCoaching"
          label="Uses High-Performance Coaching"
          valueLabelTrue="Supports high-performance coaching"
          valueLabelFalse="Does not support high-performance coaching"
        />
        <BooleanField
          source="usesFitnessCenterForEligibility"
          data-testid="usesFitnessCenterForEligibility"
          label="Uses Fitness Center For Eligibility"
        />
        <BooleanField
          source="usesEligibilityServiceType"
          data-testid="usesEligibilityServiceType"
          label="Uses Eligibility Service Type"
        />
        <BooleanField
          source="usesMboRegistrationFlow"
          data-testid="usesMboRegistrationFlow"
          label="Uses MBO Registration Flow"
        />
        <BooleanField
          source="usesCustomChallenges"
          data-testid="usesCustomChallenges"
          label="Members Can Create Custom Challenges"
        />
        <BooleanField
          source="needsUsToFixTheirUserNames"
          data-testid="needsUsToFixTheirUserNames"
          label="Needs Us To Fix Their User Names"
          valueLabelTrue="Names need to be reformatted (ex. BRAD -> Brad)"
          valueLabelFalse="Names are already correctly formatted (ex. Brad not BRAD)"
        />
        <BooleanField source="usesSSO" data-testid="usesSSO" label="Uses SSO" />
        <BooleanField
          source="collectsMemberData"
          data-testid="collectsMemberData"
          label="Show Employer Incentive Option"
        />
        <BooleanField
          source="collectsMemberDataForEEP"
          data-testid="collectsMemberDataForEEP"
          label="Show Employer Engagement Program Option"
        />
        <NumberField source="maxFamilyMembers" data-testid="maxFamilyMembers" />
        <NumberField
          source="inactivityDuration"
          data-testid="inactivityDuration"
          emptyText="Disabled"
        />
        <>
          <ImageField source="logo.url" label="Logo" />
          <Labeled label="Domains" fullWidth>
            <ReferenceArrayField source="domains.ids" reference="Domain">
              <WriteOnlyDatagrid>
                <TextField source="domain" />
                <ShowButton />
              </WriteOnlyDatagrid>
            </ReferenceArrayField>
          </Labeled>
          <Labeled label="Studios" fullWidth>
            <ReferenceArrayField source="studios.ids" reference="Studio">
              <WriteOnlyDatagrid>
                <TextField source="name" />
                <BooleanField source="isVirtual" label="Virtual" />
                <BooleanField source="isArchived" label="Archived" />
                <ShowButton />
              </WriteOnlyDatagrid>
            </ReferenceArrayField>
          </Labeled>
        </>
      </Tab>
      <Tab label="Coaches" data-testid="tab-Coaches">
        <ClientCoachTab />
      </Tab>
      <Tab label="Diagnostics" data-testid="tab-Diagnostics">
        <DiagnosticsTab />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
