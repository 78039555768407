import * as React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Tab, useRecordContext } from 'react-admin';
import styled from 'styled-components';

import {
  AdminUserDetailsFragment,
  CoachSessionAttendance,
  CoachSessionType,
  useAdminGetUserCoachSessionsQuery,
} from '../../../../graphql/types';
import { dateAndTime } from '../../../../utils/date';

const coachingSessionLabel = 'Coaching Session';
const nutritionSessionLabel = 'Nutrition Session';
const ptSessionLabel = 'Personal Training Session';

const coachSessionLabelMap: Record<CoachSessionType, string> = {
  [CoachSessionType.UserCoachingConsult]: coachingSessionLabel,
  [CoachSessionType.CoachingSession]: coachingSessionLabel,
  [CoachSessionType.UserNutritionConsult]: nutritionSessionLabel,
  [CoachSessionType.PersonalTraining]: ptSessionLabel,
};

const attendanceLabelMap = {
  [CoachSessionAttendance.Attended]: 'Attended',
  [CoachSessionAttendance.NoShow]: 'No Show',
  [CoachSessionAttendance.Excused]: 'Excused Absence',
};

const ProfileLabel = styled(Typography)`
  font-weight: bold;
`;

export const MemberSessionsTab: React.FC = () => {
  const record = useRecordContext<AdminUserDetailsFragment>();
  const { data, error } = useAdminGetUserCoachSessionsQuery({
    variables: {
      userId: record!.id, // see skip
    },
    skip: !record?.id,
  });
  const sessions = data?.user?.sessions;

  const errorRequestId = error?.graphQLErrors[0]?.extensions?.requestId as
    | string
    | undefined;
  const errorPath = error?.graphQLErrors[0].path?.join(' > ');

  return (
    <Tab label="Sessions">
      <Box>
        {error && (
          <>
            <Typography color="error">
              An error occurred while fetching sessions.
            </Typography>

            {errorPath && (
              <Typography color="error" fontSize={12}>
                Path: {errorPath}
              </Typography>
            )}
            {errorRequestId && (
              <Typography color="error" fontSize={12}>
                Request Id: {errorRequestId}
              </Typography>
            )}
          </>
        )}
        {sessions && (
          <>
            <ProfileLabel variant="h5">
              Sessions ({sessions.count} total)
            </ProfileLabel>
            {sessions.count === 0 && <Typography>None</Typography>}
            {sessions.count > 0 && (
              <Table>
                <TableHead>
                  <TableCell>Date</TableCell>
                  <TableCell>Style</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Coach</TableCell>
                  <TableCell>Attendance</TableCell>
                </TableHead>
                <TableBody>
                  {sessions?.nodes.map((session) => (
                    <TableRow>
                      <TableCell>
                        {session.calendar?.start
                          ? dateAndTime(session.calendar?.start)
                          : 'Unknown'}
                      </TableCell>
                      <TableCell>{session.sessionStyle}</TableCell>
                      <TableCell>
                        {session.sessionType
                          ? coachSessionLabelMap[session.sessionType]
                          : 'Unknown'}
                      </TableCell>
                      <TableCell>
                        {session.coach.firstName} {session.coach.lastName}
                      </TableCell>
                      <TableCell>
                        {session.attendance
                          ? attendanceLabelMap[session.attendance]
                          : 'Not Tracked'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </>
        )}
      </Box>
    </Tab>
  );
};
