import { Duration, LocalDateTime, ZoneId } from '@js-joda/core';
import camelCase from 'lodash/camelCase';
import omit from 'lodash/omit';
import pluralize from 'pluralize';
import {
  CREATE,
  CreateResult,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  GetListResult,
  GetManyResult,
  GetOneResult,
  UPDATE,
} from 'react-admin';

import {
  AdminCreateChallengeDocument,
  AdminCreateChatRoomDocument,
  AdminCreateClientDocument,
  AdminCreateContentGenreDocument,
  AdminCreateDomainDocument,
  AdminCreateEligibleEmailAddressDocument,
  AdminCreateLocationDocument,
  AdminCreateStudioDocument,
  AdminCreateUserDocument,
  AdminDeleteUserDocument,
  AdminGetChallengeDocument,
  AdminGetChatRoomDocument,
  AdminGetClientDocument,
  AdminGetContentGenreDocument,
  AdminGetDomainDocument,
  AdminGetEligibleEmailDocument,
  AdminGetLocationDocument,
  AdminGetStudioDocument,
  AdminGetUserDocument,
  AdminListChallengesDocument,
  AdminListChatRoomsDocument,
  AdminListClientsDocument,
  AdminListContentGenresDocument,
  AdminListDomainsDocument,
  AdminListEligibleEmailsDocument,
  AdminListLocationsDocument,
  AdminListStudiosDocument,
  AdminListUsersDocument,
  AdminManyChallengesDocument,
  AdminManyChatRoomsDocument,
  AdminManyClientsDocument,
  AdminManyContentGenresDocument,
  AdminManyDomainsDocument,
  AdminManyEligibleEmailsDocument,
  AdminManyLocationsDocument,
  AdminManyStudiosDocument,
  AdminManyUsersDocument,
  AdminRemoveChallengeDocument,
  AdminRemoveDomainDocument,
  AdminRemoveEligibleEmailAddressDocument,
  AdminRemoveStudioDocument,
  AdminUpdateChallengeDocument,
  AdminUpdateChatRoomDocument,
  AdminUpdateClientDocument,
  AdminUpdateContentGenreDocument,
  AdminUpdateDomainDocument,
  AdminUpdateEligibleEmailAddressDocument,
  AdminUpdateLocationDocument,
  AdminUpdateStudioDocument,
  AdminUpdateUserDocument,
  Client,
  CoachType,
  Country,
  DistanceUnit,
  Genre,
  Node,
  PageInfo,
  User,
} from '../graphql/types';

const docs = {
  createChatRoom: AdminCreateChatRoomDocument,
  updateChatRoom: AdminUpdateChatRoomDocument,
  manyUsers: AdminManyUsersDocument,
  manyEligibleEmailAddresses: AdminManyEligibleEmailsDocument,
  manyClients: AdminManyClientsDocument,
  manyChatRooms: AdminManyChatRoomsDocument,
  users: AdminListUsersDocument,
  eligibleEmailAddresses: AdminListEligibleEmailsDocument,
  removeEligibleEmailAddress: AdminRemoveEligibleEmailAddressDocument,
  clients: AdminListClientsDocument,
  chatRooms: AdminListChatRoomsDocument,
  user: AdminGetUserDocument,
  eligibleEmailAddress: AdminGetEligibleEmailDocument,
  client: AdminGetClientDocument,
  chatRoom: AdminGetChatRoomDocument,
  createUser: AdminCreateUserDocument,
  createEligibleEmailAddress: AdminCreateEligibleEmailAddressDocument,
  updateUser: AdminUpdateUserDocument,
  updateEligibleEmailAddress: AdminUpdateEligibleEmailAddressDocument,
  deleteUser: AdminDeleteUserDocument,
  createClient: AdminCreateClientDocument,
  updateClient: AdminUpdateClientDocument,

  studios: AdminListStudiosDocument,
  studio: AdminGetStudioDocument,
  createStudio: AdminCreateStudioDocument,
  updateStudio: AdminUpdateStudioDocument,
  manyStudios: AdminManyStudiosDocument,
  removeStudio: AdminRemoveStudioDocument,
  domain: AdminGetDomainDocument,
  domains: AdminListDomainsDocument,
  manyDomains: AdminManyDomainsDocument,
  removeDomain: AdminRemoveDomainDocument,
  createDomain: AdminCreateDomainDocument,
  updateDomain: AdminUpdateDomainDocument,
  coachLocation: AdminGetLocationDocument,
  coachLocations: AdminListLocationsDocument,
  manyCoachLocations: AdminManyLocationsDocument,
  createCoachLocation: AdminCreateLocationDocument,
  updateCoachLocation: AdminUpdateLocationDocument,

  challenge: AdminGetChallengeDocument,
  challenges: AdminListChallengesDocument,
  updateChallenge: AdminUpdateChallengeDocument,
  createChallenge: AdminCreateChallengeDocument,
  manyChallenges: AdminManyChallengesDocument,
  removeChallenge: AdminRemoveChallengeDocument,

  contentGenre: AdminGetContentGenreDocument,
  contentGenres: AdminListContentGenresDocument,
  manyContentGenres: AdminManyContentGenresDocument,
  updateContentGenre: AdminUpdateContentGenreDocument,
  createContentGenre: AdminCreateContentGenreDocument,
};

const getDoc = (name: keyof typeof docs, type: string) => {
  if (name in docs) {
    return docs[name];
  }

  throw new Error(`${name} ${type} not found`);
};

type QueryHelpers = {
  buildListVariables: (resource: any, params: any) => any;
  parseListPageInfo: (
    resource: any,
    request: any,
    response: any,
  ) => PageInfo | undefined;
};

const buildQuery =
  ({ buildListVariables, parseListPageInfo }: QueryHelpers) =>
  (introspectionResults: any) =>
  (raFetchType: any, resourceName: string, params: any) => {
    const resource = introspectionResults.resources.find(
      (r: any) => r.type.name === resourceName,
    );

    if (!resource) {
      throw new Error(`Resource not found for ${resourceName}`);
    }

    const query = getDoc(resource[raFetchType].name, raFetchType);

    const userFormatter = (user: User) => ({
      ...user,
      chatRooms: {
        ...user.chatRooms,
        ids: (user.chatRooms?.nodes ?? []).map(({ id }: Node) => id),
      },
      coachData: user.coachData
        ? {
            ...user.coachData,
            locations: {
              ...user.coachData.locations,
              ids: (user.coachData.locations?.nodes ?? []).map(
                ({ id }: Node) => id,
              ),
            },
            clients: {
              ...user.coachData.clients,
              ids: (user.coachData.clients?.nodes ?? []).map(
                ({ id }: Node) => id,
              ),
            },
            supportedCountriesAlpha2s: (
              user.coachData.supportedCountries ?? []
            ).map(({ alpha2 }: Country) => alpha2),
            supportGoalTypes: user.coachData.supportedGoals.map(
              ({ type }) => type,
            ),
          }
        : undefined,
    });

    const clientFormatter = (client: Client) => ({
      ...client,
      inactivityDuration: client.inactivityDuration
        ? Duration.parse(client.inactivityDuration).toMinutes()
        : null,
      users: {
        ...client.users,
        ids: client.users.nodes.map(({ id }: Node) => id),
      },
      coaches: {
        ...client.coaches,
        ids: client.coaches.nodes.map(({ id }: Node) => id),
      },
      studios: {
        nodes: client.studios,
        ids: client.studios.map(({ id }: Node) => id),
      },
      domains: {
        nodes: client.domains.nodes,
        ids: client.domains.nodes.map(({ id }: Node) => id),
      },
    });

    const formatCoachClientsUpdates = (user: User) => {
      if (user.coachData?.coachClients) {
        const config = user.coachData.coachClients.edges.map((edge: any) => ({
          clientId: edge.node.id,
          supportsHighPerformanceCoaching:
            edge.supportsHighPerformanceCoaching ?? false,
          supportsWellbeingCoaching: edge.supportsWellbeingCoaching ?? true,
          availableForAssignment: edge.availableForAssignment,
        }));
        return config;
      }
      return undefined;
    };

    switch (raFetchType) {
      case 'GET_ONE':
        return {
          query,
          variables: { ...params, timeZone: ZoneId.systemDefault() },
          parseResponse: (response: any): GetOneResult => {
            switch (resource.type.name) {
              case 'Genre':
                return {
                  data: {
                    ...response.data.data,
                    children: {
                      ...response.data.data.children,
                      ids: response.data.data.children.map(
                        (child: Genre) => child.id,
                      ),
                    },
                  },
                };
              case 'ChatRoom':
                return {
                  data: {
                    ...response.data.data,
                    users: {
                      ...response.data.data.users,
                      ids: response.data.data.users.nodes.map(
                        ({ id }: Node) => id,
                      ),
                    },
                  },
                };
              case 'Studio':
                return {
                  data: {
                    ...response.data.data,
                    locations: {
                      ...response.data.data.locations,
                      ids: response.data.data.locations.nodes.map(
                        ({ id }: Node) => id,
                      ),
                    },
                  },
                };
              case 'Challenge':
                return {
                  data: {
                    ...response.data.data,
                    clients: {
                      ...response.data.data.clients,
                      ids: (response.data.data.clients?.nodes ?? []).map(
                        ({ id }: Node) => id,
                      ),
                    },
                  },
                };
              case 'User':
                return {
                  data: userFormatter(response.data.data),
                };
              case 'Client':
                return {
                  data: clientFormatter(response.data.data),
                };
              default:
                return response.data;
            }
          },
        };
      case 'GET_LIST':
        return {
          query,
          variables: buildListVariables(resource, params),
          parseResponse: (response: any): GetListResult => {
            switch (resourceName) {
              case 'Genre':
                return {
                  data: response.data.data.map((d: any) => ({
                    ...d,
                    numberChildren: d.children.length,
                  })),
                  total: response.data.data.count,
                };
              case 'User':
                return {
                  data: response.data.data.nodes.map(userFormatter),
                  total: response.data.data.count,
                  pageInfo: parseListPageInfo(resource, params, response),
                };
              default:
                return {
                  data: response.data.data.nodes,
                  total: response.data.data.count,
                  pageInfo: parseListPageInfo(resource, params, response),
                };
            }
          },
        };
      case 'GET_MANY':
        return {
          query,
          variables: params,
          parseResponse: (response: any): GetManyResult => {
            switch (resource.type.name) {
              case 'ChatRoom':
                return {
                  data: response.data.data.map((d: any) => ({
                    ...d,
                    users: {
                      ...d.users,
                      ids: d.users.nodes.map(({ id }: Node) => id),
                    },
                  })),
                };
              default:
                return {
                  data: response.data.data,
                };
            }
          },
        };
      case 'CREATE': {
        let createVariables;

        switch (resource.type.name) {
          case 'User':
            createVariables = {
              input: {
                ...params.data,
                adminCreate: true,
              },
            };
            break;
          case 'Client':
            createVariables = {
              input: {
                ...params.data,
                inactivityDuration: params.data.inactivityDuration
                  ? Duration.ofMinutes(params.data.inactivityDuration)
                  : null,
              },
            };
            break;
          case 'Studio':
            createVariables = {
              input: {
                ...params.data,
                isVirtual: !!params.data.isVirtual,
                isCentral: !!params.data.isCentral,
                __typename: undefined,
                locations: undefined,
                client: undefined,
                clientId: params.data.client.id,
              },
            };
            break;
          case 'CoachLocation':
            createVariables = {
              input: {
                ...params.data,
                isHeadquarters: params.data.isHeadquarters ?? false,
                __typename: undefined,
                studio: undefined,
                studioId: params.data.studio.id,
              },
            };
            break;
          case 'Domain':
            createVariables = {
              input: {
                domain: params.data.domain,
                clientId: params.data.client?.id ?? undefined,
              },
            };
            break;
          case 'EligibleEmailAddress':
            createVariables = {
              input: {
                email: params.data.email,
                isArchived: !!params.data.isArchived,
                isStaff: !!params.data.isStaff,
                clientId: params.data.client?.id ?? undefined,
                hpcEligibilityStart: params.data.hasHpcEligibility
                  ? params.data.hpcEligibilityStart
                  : null,
                hpcEligibilityEnd: params.data.hasHpcEligibility
                  ? params.data.hpcEligibilityEnd
                  : null,
              },
            };
            break;
          case 'Genre':
            createVariables = {
              input: {
                ...params.data,
                parentId: params.data.parentId ?? null,
                isHidden: !!params.data.isHidden,
              },
            };
            break;
          case 'Challenge':
            createVariables = {
              input: {
                ...omit(params.data, ['background', 'badge', 'url', 'clients']),
                qualifyingGenres: params.data.qualifyingGenres ?? [],
                featured: params.data.featured ?? false,
                team: params.data.team ?? false,
                enabled: params.data.enabled ?? false,
                badgeId:
                  typeof params.data.badge === 'string'
                    ? params.data.badge
                    : undefined,
                backgroundId:
                  typeof params.data.background === 'string'
                    ? params.data.background
                    : undefined,
                clientIds: params.data.clients?.ids ?? [],
                clientSpecific: params.data.clientSpecific ?? false,
                teams: params.data.teams?.nodes ?? [],
                distanceUnit:
                  params.data.challengeType === 'Distance'
                    ? DistanceUnit.Miles
                    : undefined,
              },
            };
            break;
          default:
            createVariables = {
              input: { ...params.data },
            };
        }
        return {
          query,
          variables: createVariables,
          parseResponse: (response: any): CreateResult => {
            switch (resource.type.name) {
              case 'Client': {
                const client =
                  response.data.data[camelCase(resource.type.name)];
                return {
                  data: {
                    ...client,
                    studios: {
                      nodes: client.studios,
                      ids: client.studios.map(({ id }: Node) => id),
                    },
                  },
                };
              }
              default:
                return {
                  data: response.data.data[camelCase(resource.type.name)],
                };
            }
          },
        };
      }
      case 'UPDATE': {
        let variables;

        switch (resource.type.name) {
          case 'ChatRoom':
            variables = {
              input: {
                id: params.data.id,
                name: params.data.name,
                users: params.data.users.ids,
                app: params.data.app,
                isArchived: !!params.data.isArchived,
              },
            };
            break;
          case 'Genre':
            variables = {
              input: {
                id: params.data.id,
                name: params.data.name,
                value: params.data.value,
                isHidden: !!params.data.isHidden,
              },
            };
            break;
          case 'Studio':
            variables = {
              input: {
                ...params.data,
                __typename: undefined,
                locations: undefined,
                client: undefined,
                isVirtual: !!params.data.isVirtual,
                isCentral: !!params.data.isCentral,
                isArchived: !!params.data.isArchived,
                clientId: params.data.client.id,
              },
            };
            break;
          case 'CoachLocation':
            variables = {
              input: {
                ...params.data,
                isHeadquarters: params.data.isHeadquarters ?? false,
                __typename: undefined,
                studio: undefined,
                coaches: undefined,
                studioId: params.data.studio.id,
              },
            };
            break;
          case 'Client':
            variables = {
              input: {
                id: params.data.id,
                name: params.data.name,
                displayName: params.data.displayName,
                contactName: params.data.contactName,
                contactEmail: params.data.contactEmail,
                calendarPlatform: params.data.calendarPlatform,
                collectsMemberData: params.data.collectsMemberData,
                collectsMemberDataForEEP: params.data.collectsMemberDataForEEP,
                videoPlatform: params.data.videoPlatform,
                usesPersonalTraining: params.data.usesPersonalTraining,
                usesHighPerformanceCoaching:
                  params.data.usesHighPerformanceCoaching,
                usesFitnessCenterForEligibility:
                  params.data.usesFitnessCenterForEligibility,
                usesEligibilityServiceType:
                  params.data.usesEligibilityServiceType,
                usesMboRegistrationFlow: params.data.usesMboRegistrationFlow,
                usesCustomChallenges: params.data.usesCustomChallenges,
                needsUsToFixTheirUserNames:
                  params.data.needsUsToFixTheirUserNames,
                strictAppCheck: params.data.strictAppCheck,
                logoId:
                  typeof params.data.logo === 'string'
                    ? params.data.logo
                    : undefined,
                isArchived: params.data.isArchived,
                maxFamilyMembers: params.data.maxFamilyMembers,
                inactivityDuration: params.data.inactivityDuration
                  ? Duration.ofMinutes(params.data.inactivityDuration)
                  : null,
              },
            };
            break;
          case 'User':
            variables = {
              input: {
                id: params.data.id,
                email: params.data.email,
                firstName: params.data.firstName,
                lastName: params.data.lastName,
                active: params.data.active,
                isAdmin: params.data.isAdmin,
                isCoach: params.data.isCoach,
                isMegaAdmin: params.data.isMegaAdmin,
                isReadOnlyAdmin: params.data.isReadOnlyAdmin,
                isSalesTeam: params.data.isSalesTeam,
                isTestUser: params.data.isTestUser,
                hasHighPerformanceCoaching:
                  params.data.hasHighPerformanceCoaching,
              },
              userId: params.data.id,
              clientId: params.data.client?.id ?? '',
              updateCoachData: params.data.isCoach,
              updateAvatar: typeof params.data.avatar === 'string',
              avatarInput: {
                avatarId:
                  typeof params.data.avatar === 'string'
                    ? params.data.avatar
                    : '',
                userId: params.data.id,
              },
              coachDataInput: {
                id: params.data.id,
                bio: params.data.coachData?.bio ?? '',
                titles: params.data.coachData?.titles ?? [],
                passions: params.data.coachData?.passions ?? [],
                type: params.data.coachData?.type ?? CoachType.Coach,
                coachingStyle: params.data.coachData?.coachingStyle,
                degrees: params.data.coachData?.degrees ?? [],
                coachClients: formatCoachClientsUpdates(params.data),
                locationIds: params.data.coachData?.locations?.ids ?? [],
                bodyPhotoId:
                  typeof params.data.coachData?.bodyPhoto === 'string'
                    ? params.data.coachData?.bodyPhoto
                    : undefined,
                headshotPhotoId:
                  typeof params.data.coachData?.headshot === 'string'
                    ? params.data.coachData?.headshot
                    : undefined,
                supportedCountries:
                  typeof params.data.coachData?.supportedCountriesAlpha2s ===
                  'object'
                    ? params.data.coachData?.supportedCountriesAlpha2s
                    : undefined,
                supportedGoals:
                  typeof params.data.coachData?.supportGoalTypes === 'object'
                    ? params.data.coachData.supportGoalTypes
                    : undefined,
                availableForMemberAssignment:
                  params.data.coachData?.availableForMemberAssignment ??
                  undefined,
              },
            };
            break;
          case 'Domain':
            variables = {
              input: {
                id: params.data.id,
                domain: params.data.domain,
                clientId: params.data.client?.id ?? undefined,
              },
            };
            break;
          case 'EligibleEmailAddress': {
            let { hpcEligibilityStart } = params.data;
            let { hpcEligibilityEnd } = params.data;

            if (params.data.hasHpcEligibility) {
              try {
                hpcEligibilityStart = LocalDateTime.parse(
                  params.data.hpcEligibilityStart,
                )
                  .atZone(ZoneId.UTC)
                  .toString();
              } catch {
                /* empty */
              }

              try {
                hpcEligibilityEnd = LocalDateTime.parse(
                  params.data.hpcEligibilityEnd,
                )
                  .atZone(ZoneId.UTC)
                  .toString();
              } catch {
                /* empty */
              }
            } else {
              hpcEligibilityStart = null;
              hpcEligibilityEnd = null;
            }

            variables = {
              input: {
                id: params.data.id,
                isArchived: !!params.data.isArchived,
                isStaff: !!params.data.isStaff,
                email: params.data.email,
                hpcEligibilityStart,
                hpcEligibilityEnd,
              },
            };
            break;
          }
          case 'Challenge':
            variables = {
              input: {
                id: params.data.id,
                title: params.data.title,
                subtitle: params.data.subtitle,
                enabled: params.data.enabled,
                isArchived: params.data.isArchived,
                featured: params.data.featured,
                description: params.data.description,
                clientIds: params.data.clients?.ids ?? [],
                badgeId:
                  typeof params.data.badge === 'string'
                    ? params.data.badge
                    : undefined,
                backgroundId:
                  typeof params.data.background === 'string'
                    ? params.data.background
                    : undefined,
                teams: (params.data.teams?.nodes ?? []).map((t: any) =>
                  omit(t, ['__typename']),
                ),
                distanceUnit:
                  params.data.challengeType === 'Distance'
                    ? DistanceUnit.Miles
                    : undefined,
              },
            };
            break;
          default:
            variables = {
              input: { ...params.data, __typename: undefined },
            };
        }
        return {
          query,
          variables,
          parseResponse: (response: any): CreateResult => {
            switch (resource.type.name) {
              case 'User':
                return {
                  data: userFormatter(response.data.data.user),
                };
              case 'ChatRoom': {
                return {
                  data: {
                    ...response.data.data[camelCase(resource.type.name)],
                    users: {
                      ...response.data.data[camelCase(resource.type.name)]
                        .users,
                      ids: response.data.data[
                        camelCase(resource.type.name)
                      ].users.nodes.map(({ id }: Node) => id),
                    },
                  },
                };
              }
              case 'Client':
                return {
                  data: clientFormatter(response.data.data.client),
                };
              default:
                return {
                  data: response.data.data[camelCase(resource.type.name)],
                };
            }
          },
        };
      }
      case 'DELETE': {
        let deleteVariables;

        switch (resource.type.name) {
          case 'Client':
            deleteVariables = { clientId: params.id };
            break;
          case 'Domain':
            deleteVariables = { id: params.id };
            break;
          case 'Studio':
            deleteVariables = { id: params.id };
            break;
          case 'User':
            deleteVariables = {
              userId: params.id,
              forgetIterable: params?.meta?.forgetIterable,
            };
            break;
          case 'Challenge':
            deleteVariables = { id: params.id };
            break;
          case 'EligibleEmailAddress':
            deleteVariables = { id: params.id };
            break;
          default:
            deleteVariables = {};
        }
        return {
          query,
          variables: deleteVariables,
          parseResponse: (response: any): GetManyResult => ({
            data: response.data.data,
          }),
        };
      }
      default:
        throw new Error(`No type found for ${raFetchType} of ${resourceName}`);
    }
  };

export default (() => {
  const listPages: { [key: string]: (PageInfo | undefined)[] | undefined } = {};

  const buildListVariables = (resource: any, params: any) => {
    const { name }: { name: string } = resource.type;
    let filters;

    // Filters
    switch (resource.type.name) {
      case 'Client':
        filters = params.filter.q ? { name: params.filter.q } : params.filter;
        break;
      case 'User':
        filters = params.filter.q ? { email: params.filter.q } : params.filter;
        break;
      case 'Challenge':
        filters = {
          // by default, exclude custom challenges and archived challenges
          // this can be overridden by setting the filter
          excludeCustomChallenges: true,
          isArchived: false,
          ...params.filter,
        };
        break;
      default:
        filters = { ...params.filter };
    }
    // Pagination
    const { pagination, ...config } = params;
    const { page = 1, perPage } = pagination;
    const { endCursor = '' } = listPages[name]?.[page - 1] ?? {};

    return {
      ...config,
      filter: filters,
      sort: {
        ...params.sort,
        order: params.sort.order.toLowerCase(),
      },
      input: {
        ...params.input,
        first: perPage,
        after: endCursor,
      },
    };
  };

  const parseListPageInfo = (resource: any, request: any, response: any) => {
    const { name }: { name: string } = resource.type;
    const { page }: { page: number } = request.pagination ?? {};
    const { pageInfo }: { pageInfo?: PageInfo } = response.data?.data ?? {};

    // Update cursor
    const pages = listPages[name] ?? [];
    pages[page] = pageInfo;
    listPages[name] = pages;

    return pageInfo;
  };

  return buildQuery({ buildListVariables, parseListPageInfo });
})();

const resourceNameMapper = (resource: any) => {
  switch (resource.name) {
    case 'Genre':
      // We need this mapping because the type/resource called "Genre" is using CRUD operations with the name "ContentGenre"
      // i.e. the `contentGenres` query doesn't return type ContentGenre, it returns type Genre
      // This all uses introspection which is acceptable magic when the names are consistent but annoying when they're not
      // See also `deleteUser` versus `removeUser` below
      return 'ContentGenre';
    default:
      return resource.name;
  }
};

export const introspection = {
  operationNames: {
    [GET_LIST]: (resource: any) =>
      pluralize(camelCase(resourceNameMapper(resource))),
    [GET_ONE]: (resource: any) => camelCase(resourceNameMapper(resource)),
    [GET_MANY]: (resource: any) =>
      `many${pluralize(resourceNameMapper(resource))}`,
    [GET_MANY_REFERENCE]: (resource: any) =>
      `all${pluralize(resourceNameMapper(resource))}`,
    [CREATE]: (resource: any) => `create${resourceNameMapper(resource)}`,
    [UPDATE]: (resource: any) => `update${resourceNameMapper(resource)}`,
    [DELETE]: (resource: any) =>
      resource.name === 'User'
        ? 'deleteUser'
        : `remove${resourceNameMapper(resource)}`,
  },
  exclude: undefined,
  include: undefined,
};
