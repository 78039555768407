import React, { useCallback, useEffect, useRef } from 'react';

import { Waypoint } from 'react-waypoint';

interface Props {
  callback: () => void;
}

const PaginationWaypoint: React.FC<Props> = ({ callback }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleWaypointEnter = useCallback(() => {
    if (!ref.current) {
      return;
    }

    if (ref.current.offsetTop < 100) {
      return;
    }

    callback();
  }, [callback]);

  ref.current?.getBoundingClientRect().top;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (ref.current.getBoundingClientRect().top >= window.innerHeight) {
      return;
    }

    callback();
  }, [callback]);

  return (
    <div ref={ref}>
      <Waypoint scrollableAncestor="window" onEnter={handleWaypointEnter} />
    </div>
  );
};

export default PaginationWaypoint;
