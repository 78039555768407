import { createTheme } from '@mui/material/styles';
import { RaThemeOptions } from 'react-admin';

import './styles/fonts.css';

// Blue
const EXOS_BLUE = '#201C52'; // Blue 800

// Red
export const STATUS_ERROR = '#BD3732'; // Red 900

// Gray (Steel)
const GRAY400 = '#9D9D9D';
const WHITE = '#FFFFFF';
const BLACK = '#000000';

// Aquamarine
export const AQUAMARINE = '#00DBA8'; // Green 300
export const GREEN400 = '#00D294';

// Non-palette colours
const SECONDARY_HOVER_GRAY = '#F0F0F0';
export const BORDER_GRAY = '#E3E3E3'; // Border

const customTheme = createTheme({
  palette: {
    primary: {
      main: AQUAMARINE,
    },
    secondary: {
      main: EXOS_BLUE,
      contrastText: WHITE,
    },
    success: {
      main: AQUAMARINE,
    },
    error: {
      main: STATUS_ERROR,
    },
    background: {
      default: SECONDARY_HOVER_GRAY,
      paper: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: BLACK,
    },
  },
  typography: {
    fontFamily: 'inter', // use inter font everywhere except titles
    h1: {
      fontFamily: 'poppins',
      fontSize: 60,
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'poppins',
      fontSize: 48,
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'poppins',
      fontSize: 34,
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'poppins',
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontFamily: 'poppins',
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'poppins',
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: 'poppins',
      fontSize: 16,
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'inter',
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'inter',
      fontSize: 14,
      fontWeight: 400,
    },
    button: {
      fontFamily: 'inter',
      fontSize: 16,
      fontWeight: 600,
      color: EXOS_BLUE,
      textTransform: 'uppercase',
    },
    overline: {
      fontFamily: 'inter',
      fontSize: 12,
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: 'Poppins',
          fontWeight: 600,
          fontSize: 14,
          color: 'black',
          opacity: '0.54',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: WHITE,
        },
        root: {
          color: GRAY400,
          '&$active': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: EXOS_BLUE,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: EXOS_BLUE,
          },
        },
      },
    },
    // The MuiContainer is the container for the entire screen. Added this to the theme so that we don't have to specify a background color every time we create a new screen.
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: BORDER_GRAY,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: 14,
          height: 14,
          borderRadius: 7,
          backgroundColor: AQUAMARINE,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          color: EXOS_BLUE,
          backgroundColor: SECONDARY_HOVER_GRAY,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          paddingLeft: 20,
          paddingRight: 20,
        },
      },
    },
  },
});

const theme = {
  ...customTheme,
  components: {
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          '& .RaTabbedShowLayout-content': {
            padding: 16,
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          '& .RaSimpleShowLayout-stack': {
            padding: 16,
          },
        },
      },
    },
    RaListToolbar: {
      styleOverrides: {
        root: { marginBottom: 8 },
      },
    },
  },
};

export default theme as RaThemeOptions;
