import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { BooleanField, ShowButton } from 'react-admin';

import { CoachDetailsFragment, CoachType } from '../../../graphql/types';
import { SplitFlags, useFeatureFlags } from '../../../hooks/useFeatureFlags';
import PaginationWaypoint from '../../reusable/PaginationWaypoint';

interface ListCoachesProps {
  loadMore: () => void;
  coaches?: CoachDetailsFragment[];
  clientId?: string;
  hasNextPage?: boolean;
}
export const ListCoaches: React.FC<ListCoachesProps> = ({
  coaches,
  clientId,
  loadMore,
  hasNextPage,
}) => {
  const { flags } = useFeatureFlags();

  const enableCoachClientAvailability =
    flags[SplitFlags.COACH_CLIENT_AVAILABILITY];
  const displayCoachClientAvailabilityColumns =
    clientId && enableCoachClientAvailability;
  const enableCoachClientConfig = flags[SplitFlags.COACH_CLIENT_CONFIG];
  const displayCoachClientConfigColumns = clientId && enableCoachClientConfig;
  const getDisplayType = (type?: CoachType | null) => {
    switch (type) {
      case CoachType.Dietitian:
        return 'Nutrition';
      case CoachType.PerformCoach:
        return 'Perform';
      default:
        return 'Coach';
    }
  };

  if (!coaches) {
    return null;
  }

  return (
    <>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Style</TableCell>
              <TableCell>Locations</TableCell>
              <TableCell>Countries</TableCell>
              {(clientId || !enableCoachClientAvailability) && (
                <TableCell>Assignable</TableCell>
              )}
              {displayCoachClientConfigColumns && (
                <>
                  <TableCell>Supports Wellbeing</TableCell>
                  <TableCell>Supports High Performance</TableCell>
                </>
              )}
              <TableCell>Member Count</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coaches.length === 0 && (
              <TableRow>
                <TableCell colSpan={1000} style={{ textAlign: 'center' }}>
                  No coaches found
                </TableCell>
              </TableRow>
            )}
            {coaches?.map((coach) => (
              <TableRow key={coach.id}>
                <TableCell>{coach.firstName}</TableCell>
                <TableCell>{coach.lastName}</TableCell>
                <TableCell>{coach.email}</TableCell>
                <TableCell>{getDisplayType(coach.coachData?.type)}</TableCell>
                <TableCell>{coach.coachData?.coachingStyle}</TableCell>
                <TableCell>
                  {coach.coachData?.locations?.nodes
                    .map((location) => location.name)
                    .join(', ')}
                </TableCell>
                <TableCell>
                  {coach.coachData?.supportedCountries
                    ?.map((country) => country.name)
                    .join(', ')}
                </TableCell>
                {(clientId || !enableCoachClientAvailability) && (
                  <TableCell>
                    {displayCoachClientAvailabilityColumns ? (
                      <BooleanField
                        record={{
                          availableForAssignment:
                            coach.coachData?.coachClients?.edges?.find(
                              (edge) => edge.node.id === clientId,
                            )?.availableForAssignment ?? false,
                        }}
                        source="availableForAssignment"
                      />
                    ) : (
                      coach.coachData && (
                        <BooleanField
                          record={coach.coachData}
                          source="availableForMemberAssignment"
                        />
                      )
                    )}
                  </TableCell>
                )}
                {displayCoachClientConfigColumns && (
                  <>
                    <TableCell>
                      <BooleanField
                        record={{
                          supportsWellbeingCoaching:
                            coach.coachData?.coachClients?.edges?.find(
                              (edge) => edge.node.id === clientId,
                            )?.supportsWellbeingCoaching ?? false,
                        }}
                        source="supportsWellbeingCoaching"
                      />
                    </TableCell>
                    <TableCell>
                      <BooleanField
                        record={{
                          supportsHighPerformanceCoaching:
                            coach.coachData?.coachClients?.edges?.find(
                              (edge) => edge.node.id === clientId,
                            )?.supportsHighPerformanceCoaching ?? false,
                        }}
                        source="supportsHighPerformanceCoaching"
                      />
                    </TableCell>
                  </>
                )}
                <TableCell>{coach.coachData?.coachUsers.count ?? 0}</TableCell>
                <TableCell>
                  <ShowButton record={coach} resource="User" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasNextPage && <div style={{ textAlign: 'center' }}>Loading...</div>}
      {hasNextPage && <PaginationWaypoint callback={loadMore} />}
    </>
  );
};
