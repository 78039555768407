import {
  ChronoField,
  ChronoUnit,
  DateTimeFormatter,
  DateTimeFormatterBuilder,
  Duration,
  Instant,
  LocalDate,
  LocalDateTime,
  Period,
  TemporalAccessor,
  ZoneId,
  ZonedDateTime,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import pluralize from 'pluralize';

const dayOrdinals: Record<number, string> = {};
for (let i = 1; i <= 31; i += 1) {
  dayOrdinals[i] = i.toString();
}

const applyDateFormat =
  (fmt: DateTimeFormatter) => (date: string | TemporalAccessor) => {
    const temporal =
      typeof date === 'string' ? ZonedDateTime.parse(date) : date;
    return fmt.format(temporal);
  };

const shortDateFormat = DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(
  Locale.US,
);

export const shortDate = applyDateFormat(shortDateFormat);

/**
 * F O R M A T T E R S
 */

export const iso8601DateTime = applyDateFormat(DateTimeFormatter.ISO_INSTANT);

export const dateAndTime = applyDateFormat(
  DateTimeFormatter.ofPattern('MMM d, yyyy, h:mm a').withLocale(Locale.US),
);

// Oct 22, 2001
const monthDayYear = DateTimeFormatter.ofPattern('MMM d, y').withLocale(
  Locale.US,
);

/**
 * T I M E Z O N E   S H E N A N I G A N S
 *
 * ZonedDateTime.now()                  = "2021-05-14T12:23:56.586-04:00[America/New_York]"
 * .withZoneSameInstant(ZoneId.SYSTEM)  = "2021-05-14T12:23:56.586-04:00[SYSTEM]"
 */

export const getTimeSince = (
  date?: LocalDate,
  today: LocalDate = LocalDate.now(),
): string => {
  const unknown = 'unknown';
  try {
    if (!date) {
      return unknown;
    }

    const years = date.until(today, ChronoUnit.YEARS);
    const months = date.until(today, ChronoUnit.MONTHS);
    const weeks = date.until(today, ChronoUnit.WEEKS);
    const days = date.until(today, ChronoUnit.DAYS);

    if (years > 0) {
      return `${pluralize('year', years, true)} ago`;
    } else if (months > 0) {
      return `${pluralize('month', months, true)} ago`;
    } else if (weeks > 0) {
      return `${pluralize('week', weeks, true)} ago`;
    } else if (days === 0) {
      return 'Today';
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days > 0) {
      return `${days} days ago`;
    } else if (days === -1) {
      return 'Tomorrow';
    } else if (days < -1) {
      return date.format(monthDayYear);
    } else {
      return unknown;
    }
  } catch {
    return unknown;
  }
};
